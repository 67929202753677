


const defaultState = {
  policeList: [],
  nextPoliceId: '',
  nextDisabled: false,
  previousDisabled: false,
  previousPoliceId: '',
  isArchived: false,
  alreadyGet: false
}


export default (state = defaultState, action) => {
  switch (action.type) {

    case "SET_POLICE_ONLINE_LIST": {
      return {
        ...state,
        policeList: action.value,
        alreadyGet: true
      }
    }

    case 'SET_NEXT_POLICE_ONLINE_ID': {

      const { policeList } = state

      var { previousDisabled, nextDisabled, previousPoliceId } = state
      const id = action.value

      const index = policeList.findIndex(item => item.id == id)



      var nextId = null

      if (policeList.length == 1) {
        //处理只有一条政策的时候
        return {
          previousDisabled: true,
          ...state,
          nextDisabled: true
        }
      }

      if (index === 0) {//下标为0,当前是第一个
        nextId = policeList[index + 1].id
        return {
          ...state,
          previousDisabled: true,
          nextDisabled: false,
          nextPoliceId: nextId,
          previousPoliceId
        }
      }
      //最后一个
      if (index == policeList.length - 1) {

        previousPoliceId = policeList[index - 1].id

        return {
          ...state,
          previousDisabled: false,
          nextDisabled: true,
          previousPoliceId
        }
      }

      //大多数情况
      previousPoliceId = policeList[index - 1].id
      nextId = policeList[index + 1].id
      return {
        ...state,
        previousDisabled: false,
        nextDisabled: false,
        previousPoliceId,
        nextPoliceId: nextId
      }
    }
    default:
      return state
  }
}

