import React, { useState, useEffect } from 'react';

import { Button, Input, Space, Row, Col, message } from 'antd'

import { paasql } from '@api'
const CreateInvestmentAgency = (props) => {
  const { parkId, hideModal, reflushData, parkName } = props

  const [investmentAgencyInfo, setInvestmentAgencyInfo] = useState({
    name: null,
    uscc: null,
    bank_account_name: null,
    bank_name: null,
    bank_branch: null,
    bank_account_number: null,
    phone_number: null,
    bank_code: null
  })


  const showList = [
    {
      label: "名称",
      value: investmentAgencyInfo.name,
      field: 'name'
    }, {
      label: "统一征信代码",
      value: investmentAgencyInfo.uscc,
      field: "uscc"
    }, {
      label: "户名",
      value: investmentAgencyInfo.bank_account_name,
      field: "bank_account_name"
    }, {
      label: "开户行",
      value: investmentAgencyInfo.bank_name,
      field: "bank_name"

    }, {
      label: "银行网点",
      value: investmentAgencyInfo.bank_branch,
      field: "bank_branch"

    }, {
      label: "银行账号",
      value: investmentAgencyInfo.bank_account_number,
      field: "bank_account_number"

    }, {
      label: "银行行联号",
      value: investmentAgencyInfo.bank_code,
      field: "bank_code"
    }, {
      label: "联系电话",
      value: investmentAgencyInfo.phone_number,
      field: "phone_number"
    }]

  const changeValue = (e, item) => {
    const value = e.target.value
    setInvestmentAgencyInfo({
      ...investmentAgencyInfo,
      [item.field]: value
    })
  }


  const saveChange = () => {
    const {
      name,
      uscc,
      bank_account_name,
      bank_name,
      bank_branch,
      bank_account_number,
      phone_number, bank_code
    } = investmentAgencyInfo

    if (name == null) {
      message.error('名字是必填项')
      return
    }

    const cmd_data = {
      object: {
        eip_park_id: parkId,
        eip_park_name: parkName,
        name,
        uscc,
        bank_account_name,
        bank_name,
        bank_branch,
        bank_account_number,
        phone_number,
        bank_code
      }

    }
    paasql("eip.eip_investment_agency.create", cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        message.success("保存成功")
        reflushData(result.id)
        hideModal()
      } else {
        message.error(data.error.message)
      }
    })
  }
  return (
    <div>

      <div>
        <Row>
          {
            showList.map(item => {
              return (
                <Col span={24} key={item.label}>
                  <Row className='create-agency-container'>
                    <Col className='agency-label'>
                      {`${item.label}:`}
                    </Col>
                    <Col className='agency-input'>
                      <Input
                        placeholder={`请输入${item.label}`}
                        className='create-investment-agency-input'
                        style={{ width: '100%' }}
                        min={0} max={100}
                        value={item.value}
                        onChange={(e) => changeValue(e, item)}
                      />
                    </Col>
                  </Row>

                </Col>
              )
            })
          }
        </Row>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>
            取消
          </Button>
          <Button
            type='primary'
            onClick={saveChange}
          >
            保存
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default CreateInvestmentAgency;