import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteAgencyEnterprisesTaxRefundStatementById,
  updateAgencyTaxRefundStatementArchived,
} from "@api/paasql";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  message,
  Button,
  Modal,
  Spin,
  Space,
  Pagination,
  Radio,
  Row,
  Col,
} from "antd";

import { moneyFormat } from "@utils/index";
import { paasql, gpi, exportExcel, exportSeasonTax } from "@api";
import TaxRefundTable from "./component/TaxRefundTable";
import CreateTaxRefundStatement from "./component/CreateTaxRefundStatement";
import SetLastStatementBalance from "./component/SetLastStatementBalance";
import UploadModal from "./UploadModal";
import SelectFieldModal from "./component/SelectFieldModal";

import {
  CreateIcon,
  ImportDataIcon,
  ExportDataIcon,
  DeleteDocIcon,
  LastBalanceIcon,
} from "@icon";
import { ButtonLabel } from "@component";

const AgencyTaxRefund = (props) => {
  const [parkId, setParkId] = useState("");
  const [parkName, setParkName] = useState("");
  const [taxRefundStatementList, setTaxRefundStatementList] = useState([]);
  const [selectTaxRefundStatement, setSelectTaxRefundStatement] = useState(
    null
  );
  const [selectTaxId, setSelectTaxId] = useState(null);
  const [
    createTaxRefundModalvisable,
    setCreateTaxRefundModalvisable,
  ] = useState(false);
  const [selectFieldModalVisiable, setSelectFieldModalVisiable] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataForExport, setTableDataForExport] = useState([]);
  const [totalPage, setTotalPage] = useState(12);

  //统计数据
  const [total, setTotal] = useState({});
  //当前的页码
  const [currentPage, setCurrentPage] = useState(1);
  //每页大小
  const [pageSize, setPageSize] = useState(20);

  const [totalNumber, setTotalNumber] = useState(0);
  //结算上次余留
  const [lastStatementModalvisable, setLastStatementModalvisable] = useState(
    false
  );

  //上传文件
  const [uploadFileModalVisable, setUploadFileModalVisable] = useState(false);
  const [uploadFileModalChange, setUploadFileModalChange] = useState(false);

  useEffect(() => {
    paasql("eip.park.list").then((data) => {
      const parkId = data.result[0].id;
      const parkName = data.result[0].name;
      //id和name的持久化处理
      setParkId(parkId);
      setParkName(parkName);
      getTaxRefundStatementListByParkId(parkId);
    });
  }, []);
  const getTaxRefundStatementListByParkId = (parkId) => {
    const cmd_data = {
      eip_park_id: parkId,
    };
    paasql(
      "eip.eip_agency_administered_enterprises_tax_refund_statement.list",
      cmd_data
    ).then((data) => {
      const result = data.result;
      //如果当前的列表为空

      //处理合计数

      if (result.length == 0) {
        setTaxRefundStatementList([]);
        setTotalPage(1);
        setTableData([]);
      } else {
        for (let i = 0; i < result.length; i++) {
          const year_and_quarter = result[i].year + result[i].quarter;
          result[i].year_and_quarter = year_and_quarter; //增加年和季度的字段
          const archivedText = result[i].archived === 1 ? "已归档" : "未归档";
          result[i].archivedText = archivedText;
        }
        const result_with_sort = result.sort(function (first, last) {
          return Number(last.year_and_quarter) - Number(first.year_and_quarter);
        });
        setSelectTaxRefundStatement(result_with_sort[0]); //最新季度

        const isArchived = result_with_sort[0].id == "1" ? true : false;
        setArchived(isArchived);
        setSelectTaxId(result_with_sort[0].id);
        const cmd_data = {
          size: pageSize,
          page: 1,
          id: result_with_sort[0].id,
        };
        getTaxRefundStatementItemById(cmd_data);
        setTaxRefundStatementList(result_with_sort);
      }
    });
  };
  //新增总库表

  const getTaxRefundStatementItemById = (request) => {
    const { id, page, size } = request;
    const cmd_data = {
      attached_to_object_id: id,
      page: page,
      size: size,
      table: "eip_agency_administered_enterprises_tax_refund_statement_record",
    };
    const method = "query_statement_and_statistic_record_by_page";
    gpi(method, cmd_data).then((data) => {
      const { list, total_page, total } = data;
      setTotalNumber(total);
      const {
        actual_agency_amount_paid,
        actual_amount_paid,
        agency_corporate_income_tax_refund,
        agency_pending_payment,
        agency_value_added_tax_refund,
        current_balance,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        total_agency_management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      } = data;

      setTotal({
        actual_agency_amount_paid,
        actual_amount_paid,
        agency_corporate_income_tax_refund,
        agency_pending_payment,
        agency_value_added_tax_refund,
        current_balance,
        total_agency_management_fee,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      });
      setTableData(list);
      setTotalPage(total_page);
    });
  };

  //显示创建总库表的模态框
  const createTaxRefund = () => {
    setCreateTaxRefundModalvisable(true);
  };
  //隐藏创建总库表的模态框
  const hideCreateTaxRefundModal = () => {
    setCreateTaxRefundModalvisable(false);
  };
  //处理创建总库表时的loading状态
  const handleCreateTaxStatementLoading = (loadingStatus) => {
    setLoading(loadingStatus);
  };
  //创建总库表并刷新
  const reflushData = () => {
    getTaxRefundStatementListByParkId(parkId);
  };
  //结算上次余留
  const setLastStatementBalance = () => {
    setLastStatementModalvisable(true);
  };
  const hideSetLastStatementBalance = () => {
    setLastStatementModalvisable(false);
  };

  //上传文件
  const uploadFile = () => {
    setUploadFileModalVisable(true);
    setUploadFileModalChange(!uploadFileModalChange);
  };

  //导出数据
  const exportData = () => {
    if (selectTaxId == null) {
      message.error("当前没有选择核算表，无法导出数据");
      return;
    }
    const cmd_data = {
      attached_to_object_id: selectTaxId,
      page: 1,
      size: totalPage * pageSize,
      table: "eip_agency_administered_enterprises_tax_refund_statement_record",
    };
    const method = "query_statement_and_statistic_record_by_page";
    setLoading(true);
    gpi(method, cmd_data)
      .then((data) => {
        const { list } = data;

        const keyArr = [
          "tax_payable",
          "total_value_added_tax_in_quarter",
          "enterprise_value_added_tax_refund",
          "agency_value_added_tax_refund",
          "total_corporate_income_tax_in_quarter",
          "enterprise_corporate_income_tax_refund",
          "agency_corporate_income_tax_refund",
          "total_enterprise_tax_refund_in_quarter",

          "total_individual_income_tax",
          "enterprise_individual_income_tax_refund",
          "agency_individual_income_tax_refund",

          "total_property_income_tax",
          "enterprise_property_tax_refund",
          "agency_property_tax_refund",

          "last_statement_balance",
          "payment_difference",
          "management_fee",
          "payment_on_behalf",
          "prepayment",
          "accounts_payable",
          "current_balance",
          "pending_payment",
          "actual_amount_paid",
          "total_agency_tax_refund_in_quarter",
          "agency_last_statement_balance",
          "agency_payment_difference",
          "agency_payment_on_behalf",
          "agency_prepayment",
          "agency_accounts_payable",
          "agency_current_balance",
          "agency_pending_payment",
          "actual_agency_amount_paid",
        ];
        //处理千分位的隔断
        list.forEach((list) => {
          keyArr.forEach((key) => {
            list[key] = Number(list[key]);
          });
        });
        setTableDataForExport(list);
      })
      .finally(() => {
        setLoading(false);
        setSelectFieldModalVisiable(!setSelectFieldModalVisiable);
        setSelectFieldModalVisiable(true);
      });
  };
  const reflushTableData = () => {
    const cmd_data = {
      size: pageSize,
      page: currentPage,
      id: selectTaxId,
    };
    getTaxRefundStatementItemById(cmd_data);
  };

  //删除核算表
  const destroy = () => {
    Modal.confirm({
      onOk() {
        destroyTaxRefund();
      },
      width: 600,
      content: "删除后不可恢复，是否继续",
      icon: <ExclamationCircleOutlined />,
      title: `确认要删除${selectTaxRefundStatement.name}的中介招入企业扶持资金结算表吗？`,
    });
  };
  const destroyTaxRefund = () => {
    const cmd_data = {
      id: selectTaxId,
    };
    setLoading(true);
    deleteAgencyEnterprisesTaxRefundStatementById(cmd_data)
      .then((data) => {
        if (data.success) {
          //归档上一张表
          if (taxRefundStatementList.length == 1) {
            message.success("删除核算表成功", 3);
            getTaxRefundStatementListByParkId(parkId);
          } else {
            const cmd_data = {
              id: taxRefundStatementList[1].id,
              archived: 0,
            };
            updateAgencyTaxRefundStatementArchived(cmd_data)
              .then((data) => {
                if (data.success) {
                  message.success(
                    "删除核算表成功，成功将上一张核算表取消归档",
                    3
                  );
                } else {
                  message.error("删除核算表成功，但取消归档上一张失败", 3);
                }
              })
              .finally(() => {
                reflushData();
              });
          }
          getTaxRefundStatementListByParkId(parkId);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //页码改变的回调函数
  const pageChange = (page) => {
    setCurrentPage(page);
    const cmd_data = {
      size: pageSize,
      page: page,
      id: selectTaxId,
    };
    getTaxRefundStatementItemById(cmd_data);
  };

  //处理核算表列表的点击事件
  const handleTaxStatementListChange = (item) => {
    setCurrentPage(1);
    const id = item.id;
    const isArchived = item.archived == "1" ? true : false;
    setArchived(isArchived);
    setSelectTaxId(id);
    const cmd_data = {
      id,
      page: 1,
      size: pageSize,
    };
    getTaxRefundStatementItemById(cmd_data);
    setSelectTaxRefundStatement(item);
  };

  //企业扶持资金直接拨付情况表（空表）
  const exportSupportFund = () => {
    const cmd_data = {
      original_file_id: "3be4b38478c04d7caa5b54b26eacc8f0",
      eip_park_id: parkId,
      season: String(selectTaxRefundStatement.quarter),
      year: selectTaxRefundStatement.year,
    };
    exportSeasonTax(cmd_data);
  };

  //贷记凭证批量发放数据模版（空表）
  const exportCreditVoucher = () => {};
  const notArchivedButtonList = [
    {
      name: "新增核算表",
      type: "ce",
      width: 146,
      action: createTaxRefund,
      className: "ceshi",
      icon: <CreateIcon />,
    },
    {
      name: "结算上次余留",
      type: "",
      width: 146,
      action: setLastStatementBalance,
      className: "ces",
      icon: <LastBalanceIcon />,
    },
    {
      name: "导入核对表",
      type: "",
      width: 146,
      action: uploadFile,
      className: "ces",
      icon: <ImportDataIcon />,
    },
    {
      name: "导出数据",
      type: "",
      width: 146,
      action: exportData,
      className: "ces",
      width: 146,
      icon: <ExportDataIcon />,
    },
    //  {
    //   name: "导出扶持资金拨付表",
    //   type: '',
    //   width: 146,
    //   action: exportSupportFund,
    //   className: 'ces',
    //   width: 146,
    //   icon: <ExportDataIcon />

    // }, {
    //   name: "导出贷记凭证表",
    //   type: '',
    //   width: 146,
    //   action: exportCreditVoucher,
    //   className: 'ces',
    //   width: 146,
    //   icon: <ExportDataIcon />

    // },
    {
      name: "删除",
      width: 118,
      action: destroy,
      className: "danger",
      icon: <DeleteDocIcon />,
    },
  ];
  const archivedButtonList = [
    {
      name: "新增核算表",
      width: 146,
      action: createTaxRefund,
      className: "ceshi",
      icon: <CreateIcon />,
    },
    {
      name: "导出数据",
      size: "large",
      width: 118,
      action: exportData,
      icon: <ExportDataIcon />,
    },
    //  {
    //   name: "导出扶持资金拨付表",
    //   type: '',
    //   width: 146,
    //   action: exportSupportFund,
    //   className: 'ces',
    //   width: 146,
    //   icon: <ExportDataIcon />
    // }, {
    //   name: "导出贷记凭证表",
    //   type: '',
    //   width: 146,
    //   action: exportCreditVoucher,
    //   className: 'ces',
    //   width: 146,
    //   icon: <ExportDataIcon />
    // },
  ];

  const totalList = [
    {
      label: "增值税税额",
      value: total.total_value_added_tax_in_quarter,
    },
    {
      label: "增值税扶持额(企)",
      value: total.enterprise_value_added_tax_refund,
    },
    {
      label: "增值税扶持额(招)",
      value: total.agency_value_added_tax_refund,
    },
    {
      label: "企业所得税税额",
      value: total.total_corporate_income_tax_in_quarter,
    },
    {
      label: "企业所得税扶持额(企)",
      value: total.enterprise_corporate_income_tax_refund,
    },
    {
      label: "企业所得税扶持额(招)",
      value: total.agency_corporate_income_tax_refund,
    },
    {
      label: "本季总扶持额(企)",
      value: total.total_enterprise_tax_refund_in_quarter,
    },
    {
      label: "管理费(企)",
      value: total.management_fee,
    },
    {
      label: "本季待付款金额(招)",
      value: total.agency_pending_payment,
    },
    {
      label: "上次余留(企)",
      value: total.last_statement_balance,
    },
    {
      label: "补差金额(企)",
      value: total.payment_difference,
    },
    {
      label: "实际支付(招)",
      value: total.actual_agency_amount_paid,
    },
    {
      label: "代扣其他(企)",
      value: total.payment_on_behalf,
    },
    {
      label: "本次余留(企)",
      value: total.current_balance,
    },

    {
      label: "管理费(招)",
      value: total.total_agency_management_fee,
    },

    {
      label: "预付金额(企)",
      value: total.prepayment,
    },
    {
      label: "本季待付款金额(企)",
      value: total.pending_payment,
    },
    {
      label: "实际支付金额(企)",
      value: total.actual_amount_paid,
    },
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <Col style={{ width: "calc(100% - 160px)" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div style={{ overflow: "auto" }}>
                <Button.Group size={10}>
                  <ButtonLabel size="large" />
                  {archived
                    ? archivedButtonList.map((item) => {
                        return (
                          <Button
                            size="large"
                            key={item.name}
                            icon={item.icon}
                            onClick={item.action}
                          >
                            {item.name}
                          </Button>
                        );
                      })
                    : notArchivedButtonList.map((item) => {
                        return (
                          <Button
                            size="large"
                            key={item.name}
                            icon={item.icon}
                            onClick={item.action}
                          >
                            {item.name}
                          </Button>
                        );
                      })}
                </Button.Group>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Row className="seach-total-record-container">
                  {totalList.map((item) => {
                    return (
                      <Col
                        key={item.label}
                        span={8}
                        className="total-record-item"
                      >
                        <Row>
                          <Col
                            span={11}
                            style={{ textAlign: "right" }}
                            className="total-record-item-label"
                          >
                            {`${item.label}:`}
                          </Col>
                          <Col span={13} className="total-record-item-value">
                            {`${moneyFormat(item.value)}`}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                <TaxRefundTable
                  pageSize={pageSize}
                  currentPage={currentPage}
                  reflushTableData={reflushTableData}
                  selectTaxRefundStatement={selectTaxRefundStatement}
                  archived={archived}
                  tableData={tableData}
                ></TaxRefundTable>
              </div>

              <div style={{ textAlign: "right", marginTop: 20 }}>
                <Pagination
                  showQuickJumper={true}
                  current={currentPage}
                  pageSize={1}
                  showTotal={(total, range) => `共 ${totalNumber}条`}
                  showSizeChanger={false}
                  total={totalPage}
                  onChange={pageChange}
                ></Pagination>
              </div>
            </div>
          </Col>
          <Col
            style={{
              width: "160px",

              height: "calc(100vh - 150px)",
              overflow: "auto",
            }}
          >
            <div
              className="right-selector"
              style={{ height: "calc(100vh - 150px)", overflow: "auto" }}
            >
              <div className="right-quarter-list">
                <Radio.Group
                  value={selectTaxRefundStatement}
                  className="quarter-list-button-group"
                >
                  {taxRefundStatementList.map((item, index) => {
                    return (
                      <Radio.Button
                        className="quarter-list-button"
                        key={item.id}
                        onClick={() => handleTaxStatementListChange(item)}
                        value={item}
                      >
                        <div className="quarter-list-button-group-text">
                          {item.name}
                        </div>
                        <div className="quarter-list-button-group-text">
                          {item.archivedText}
                        </div>
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          destroyOnClose={true}
          //新增核算表的模态框
          visible={createTaxRefundModalvisable}
          title="创建核算表"
          footer={null}
          onCancel={() => {
            setCreateTaxRefundModalvisable(false);
          }}
        >
          <CreateTaxRefundStatement
            parkId={parkId}
            destroyOnClose={true}
            handleLoading={handleCreateTaxStatementLoading}
            parkName={parkName}
            reflushData={reflushData}
            taxRefundStatementList={taxRefundStatementList}
            hideModal={hideCreateTaxRefundModal}
          />
        </Modal>
        <Modal
          //结算上次余留的本部核算表
          destroyOnClose={true}
          visible={lastStatementModalvisable}
          title="结算上次余留"
          footer={null}
          width={600}
          onCancel={() => {
            setLastStatementModalvisable(false);
          }}
        >
          <SetLastStatementBalance
            reflushData={reflushData}
            handleLoading={handleCreateTaxStatementLoading}
            hideSetLastStatementBalance={hideSetLastStatementBalance}
            selectTaxRefundStatement={selectTaxRefundStatement}
          />
        </Modal>
        <UploadModal
          parkId={parkId}
          parkName={parkName}
          taxRefundId={selectTaxId}
          reflushData={reflushData}
          selectTaxRefundStatement={selectTaxRefundStatement}
          uploadFileModalChange={uploadFileModalChange}
          visible={uploadFileModalVisable}
        ></UploadModal>
        <SelectFieldModal
          selectTaxRefundStatement={selectTaxRefundStatement}
          totalPage={totalPage}
          pageSize={pageSize}
          selectTaxId={selectTaxId}
          tableData={tableDataForExport}
          modelVisible={selectFieldModalVisiable}
        ></SelectFieldModal>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(AgencyTaxRefund);
