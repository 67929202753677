import React, { useContext, useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import { ReachableContext, } from '../UploadModal'
import { exportExcel } from '@api'
const headerData = [
  { display_name: "税管码", name: "tax_file_number" },
  { display_name: "企业名称", name: "name" },
  { display_name: "异常原因", name: "reason" },
]
const ImportResult = (props) => {
  const { importResultData } = useContext(ReachableContext)
  const { not_matched, success_num } = importResultData

  const [title, setTitle] = useState('')
  useEffect(() => {
    const titleStr = `成功导入${success_num}条数据`
    setTitle(titleStr)
  }, [importResultData, success_num])
  const exportResult = () => {
    const result = not_matched
    var tmp_related_data = []
    var header_data = []
    var header_data_key = []

    //将选中的数据放在一个数组，后续根据这个数组中的属性来作为key，来将key对应的value值push进一个数组
    for (let i = 0; i < headerData.length; i++) {
      header_data.push(headerData[i].display_name)
      header_data_key.push(headerData[i].name)//表头的key的数组
    }
    tmp_related_data.push(header_data)
    //表头数据部分
    // //表格数据部分
    for (let i = 0; i < result.length; i++) {
      var single_row_data = []
      for (var k = 0; k < header_data_key.length; k++) {//循环单行数据，根据表头的key
        for (var key in result[i]) {
          if (header_data_key[k] == key) {
            single_row_data.push(result[i][key])//单行数据
          } else {
          }
        }
      }
      tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，导入
    }
    //表的数据部分

    //导出
    const fileName = '导入支付核对表结果'
    exportExcel(tmp_related_data, fileName)
  }
  const Title = () => {
    return (
      <>
        <div style={{ fontSize: 14, fontFamily: 'HiraginoSansGB-W6,HiraginoSansGB' }}>
          {title}
        </div>
      </>
    )
  }
  const SubTitle = () => {
    return (
      <>
        <div style={{ fontSize: 14, fontFamily: 'HiraginoSansGB-W3,HiraginoSansGB' }}>
          点击导出按钮，查看本次导入结果
        </div>
      </>
    )
  }
  return (
    <>
      <div className="subModalHeader">
        数据导入结果
      </div>
      <Result
        style={{ padding: 10 }}
        status="success"
        title={<Title />}
        subTitle={<SubTitle />}
        extra={[
          <Button type="primary" key="export" onClick={exportResult} style={{ borderRadius: 4, width: 100 }}>
            立即导出
      </Button>,

        ]}
      />
    </>
  );
}

export default ImportResult;