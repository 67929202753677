import React, { useEffect, useState } from 'react';
import { Button, message, Row, Col, Select, Space, InputNumber } from 'antd'
import { paasql, createAgencyTaxRefundStatistics } from '@api'
import {  CaretDownOutlined } from '@ant-design/icons';



const { Option } = Select;

const quarterList = [
  {
    "id": 1,
    "name": "1季度",
    "quarter": 1
  }, {
    "id": 2,
    "name": "2季度",
    "quarter": 2
  }, {
    "id": 3,
    "name": "3季度",
    "quarter": 3
  }, {
    "id": 4,
    "name": "4季度",
    "quarter": 4
  }
]
const CreateTaxRefundStatistics = (props) => {
  const { hideModal, reflushData, handleLoading, parkId, parkName, taxRefundStatisticsList } = props
  const [businessYearList, setBusinessYearList] = useState([])
  const [year, setYear] = useState(null)
  const [startQuarter, setStartQuarter] = useState('1')
  const [endQuarter, setEndQuarter] = useState(null)
  useEffect(() => {
    paasql("eip.eip_business_year.list").then(list => {
      if (list.error == null) {
        const businessYearList = list.result
        const businessYearListWithSort = businessYearList.sort(function (first, last) {
          return Number(first.name) - Number(last.name)
        })
        setBusinessYearList(businessYearListWithSort)
      } else {
        message.error(list.error.message)
      }
    })
  }, [])


  //请求api
  //需要归档上一张
  const handleRequest = (cmd_data) => {
    hideModal()
    handleLoading(true)
    createAgencyTaxRefundStatistics(cmd_data).then(data => {
      if(data.result.success){
        message.success("创建成功")
      }
    }).finally(() => {
      reflushData()
      handleLoading(false)
    })
  }

  //初始化的时候请求api
  //不需要归档上一张
  const createTaxRefund = () => {
    //判断是否填写了信息
    if (year === null || endQuarter === null) {
      message.error("请先选择统计年份和结束季度")
      return
    }
    const name = `${year}年第1季度-第${endQuarter}季度`
    const currentYearAndEndedQuarter = `${year}${endQuarter}`
    const cmd_data = {
      "is_agency": true,
      "eip_park_id": parkId,
      "eip_park_name": parkName,
      "statistics_year": year,
      "quarter_started_at": 1,
      "quarter_ended_at": Number(endQuarter),
      "name": name
    }

    if (taxRefundStatisticsList.length == 0) {
      //初始状态，可以创建
      handleRequest(cmd_data)
    } else {
      //普通状态
      //判断是否已有统计表
      var haveRecord = false
      for (var i = 0; i < taxRefundStatisticsList.length; i++) {//开始判断是否有相同的报表
        var time = `${taxRefundStatisticsList[i].statistics_year}${String(taxRefundStatisticsList[i].quarter_ended_at)}`

        if (currentYearAndEndedQuarter == time) {
          var haveRecord = true
          break;
        } else {
        }
      }
      if (haveRecord) {//说明有重复的报表
        message.error("统计表已存在，请勿重复创建")
        return
      } else {
        message.success('开始创建')
        handleRequest(cmd_data)
      }
    }
  }
  const handleYear = (value) => {
    setYear(value)
  }
  const handleEndQuarter = (value) => {
    setEndQuarter(value)
  }

  return (
    <>
      <div>
        <Row>
          <Col span={6} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            统计年份：
          </Col>
          <Col span={18} style={{ height: 40, lineHeight: '40px' }}>
            <Select placeholder={"请选择年"} suffixIcon={<CaretDownOutlined />} style={{ width: 250 }} onChange={handleYear}>
              {
                businessYearList.map(item => {
                  return (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>

          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            开始季度：
          </Col>
          <Col span={18} style={{ height: 40, lineHeight: '40px' }}>
            <Select value={startQuarter} disabled={true} placeholder={"请选择季度"} suffixIcon={<CaretDownOutlined />} style={{ width: 250 }}>
              {
                quarterList.map(item => {
                  return (
                    <Option key={item.id}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>

          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            结束季度：
          </Col>
          <Col span={18} style={{ height: 40, lineHeight: '40px' }}>
            <Select placeholder={"请选择季度"} suffixIcon={<CaretDownOutlined />} onChange={handleEndQuarter} style={{ width: 250 }}>
              {
                quarterList.map(item => {
                  return (
                    <Option key={item.id}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>

          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>
            取消
           </Button>
          <Button onClick={createTaxRefund}>
            创建
          </Button>
        </Space>
      </div>

    </>
  );
}

export default CreateTaxRefundStatistics;