import React, { useEffect, useState } from 'react';
import { Button, message, Row, Col, Select, Space, InputNumber } from 'antd'
import { paasql } from '@api'
import { eipMonthlyAccumulatedTax as eipMonthlyAccumulatedTaxApi } from '@api/paasql'
import { UploadOutlined, CaretDownOutlined } from '@ant-design/icons';



const { Option } = Select;

const quarterList = [
  {
    "id": 1,
    "name": "1季度",
    "quarter": 1
  }, {
    "id": 2,
    "name": "2季度",
    "quarter": 2
  }, {
    "id": 3,
    "name": "3季度",
    "quarter": 3
  }, {
    "id": 4,
    "name": "4季度",
    "quarter": 4
  }
]
const CreateTaxRefundStatement = (props) => {
  const { hideModal, reflushData, handleLoading, parkId, parkName } = props
  const [businessYearList, setBusinessYearList] = useState([])
  const [year, setYear] = useState(null)
  const [quarter, setQuarter] = useState()
  const [parkTaxRefundStatement, setParkTaxRefundStatement] = useState([])
  const [eipMonthlyAccumulatedTax, setEipMonthlyAccumulatedTax] = useState([])
  const [managementFee, setManagementFee] = useState(2500)
  useEffect(() => {
    paasql("eip.eip_business_year.list").then(list => {
      if (list.error == null) {
        const businessYearList = list.result
        const businessYearListWithSort = businessYearList.sort(function (first, last) {
          return Number(first.name) - Number(last.name)
        })
        setBusinessYearList(businessYearListWithSort)
        const cmd_data = {
          eip_park_id: parkId
        }
        paasql("eip.eip_park_administered_enterprises_tax_refund_statement.list", cmd_data).then(data => {
          if (data.error == null) {
            const result = data.result
            const statementList = []
            for (var i = 0; i < result.length; i++) {
              const attr_obj = result[i]
              var attr_value = attr_obj.year + attr_obj.quarter
              attr_obj.year_and_quarter = attr_value//增加年和季度的组合字段，用来实现排序
              statementList.push(attr_obj)
            }
            const statementListSort = statementList.sort(function (first, last) {
              return Number(last.year_and_quarter) - Number(first.year_and_quarter)
            })
            setParkTaxRefundStatement(statementListSort)
          } else {

          }
        })
        eipMonthlyAccumulatedTaxApi(cmd_data).then(res => {
          setEipMonthlyAccumulatedTax(res.list)
        })
      } else {
        message.error(list.error.message)
      }
    })
  }, [])


  //请求api
  //需要归档上一张
  const handleRequest = (cmd_data) => {
    hideModal()
    handleLoading(true)
    return fetch("/cpx/administeredAddSeasonTaxRecord",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              ...cmd_data
            }
          }
        })
      })
      .then(res => res.json()).then(res => {
        if (res.error == null) {
          var cmd_data = {
            "id": parkTaxRefundStatement[0].id,//最新的表
            "archived": 1
          }
          //刷新上级页面的数据
          return paasql("eip.eip_park_administered_enterprises_tax_refund_statement.update_archived", cmd_data).then(archived_data => {
            if (archived_data.error == null) {
              //这里
              reflushData()
              message.success("创建核算表成功，成功归档上一张核算表", 2)
            } else {
              message.error("创建核算表成功，但归档上一张核算表失败")
              message.error(res.error.message)
            }
          })
            .catch((error) => {

            })
            .finally(() => {
              handleLoading(false)
            })
        } else {
          message.error(`创建失败，失败原因：${res.error.message}`)
          handleLoading(false)

        }
      })
  }

  //初始化的时候请求api
  //不需要归档上一张
  const handleRequestEmpty = (cmd_data) => {
    hideModal()
    handleLoading(true)
    return fetch("/cpx/administeredAddSeasonTaxRecord",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              ...cmd_data
            }
          }
        })
      })
      .then(res => res.json()).then(res => {
        if (res.error == null) {
          reflushData()
        } else {
          message.error(res.error.message)
        }
      }).finally(() => {
        handleLoading(false)
      })
  }
  const createTaxRefund = () => {
    var newestMonth = year + ("0" + String(quarter * 3)).slice(-2)//当前的最新季度的最大月份    
    const cmd_data = {
      "eip_park_id": parkId,
      "eip_park_name": parkName,
      "eip_business_year": year,
      "quarter": Number(quarter),
      "is_settled": true,
      "default_management_fee": String(managementFee),
    }
    var isNewest = false
    //这里需要判断当前的最新季度
    for (var i = 0; i < eipMonthlyAccumulatedTax.length; i++) {
      if (eipMonthlyAccumulatedTax[i].ended_at == newestMonth) {

        isNewest = true
        break
      } else {
        isNewest = false
      }
    }

    if (!isNewest) {
      message.error("请先上传本季度所有税收总库表")
      return
    }

    if (parkTaxRefundStatement.length == 0) {
      //为空的时候可以创建
      message.success('开始创建')
      handleRequestEmpty(cmd_data)
    } else {
      var latest_time = Number(parkTaxRefundStatement[0].year_and_quarter)
      var current_time = Number(year + quarter)
      if (current_time < latest_time) {
        message.error('不允许创建已结算的季度之前的报表')
        return
      } else {
        var have_record = false
        for (var i = 0; i < parkTaxRefundStatement.length; i++) {//开始判断是否有相同的报表
          var time = Number(parkTaxRefundStatement[i].year + parkTaxRefundStatement[i].quarter)
          if (current_time == time) {
            var have_record = true
            break;
          } else {

          }
        }
        if (have_record) {//说明有重复的报表
          message.error("报表已存在，请勿重复创建")
          return
        }
        message.success('开始创建')
        //开始创建
        handleRequest(cmd_data)
      }
    }

  }
  const handleYear = (value) => {
    setYear(value)
  }
  const handleQuarter = (value) => {
    setQuarter(value)
  }
  const handleManagement = (value) => {
    setManagementFee(value)
  }
  return (
    <>
      <div>
        <Row>
          <Col span={6} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            年度：
          </Col>
          <Col span={18} style={{ height: 40, lineHeight: '40px' }}>
            <Select placeholder={"请选择年"} suffixIcon={<CaretDownOutlined />} style={{ width: 250 }} onChange={handleYear}>
              {
                businessYearList.map(item => {
                  return (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>

          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            季度：
          </Col>
          <Col span={18} style={{ height: 40, lineHeight: '40px' }}>
            <Select placeholder={"请选择季度"} suffixIcon={<CaretDownOutlined />} onChange={handleQuarter} style={{ width: 250 }}>
              {
                quarterList.map(item => {
                  return (
                    <Option key={item.id}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>

          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>
            取消
           </Button>
          <Button onClick={createTaxRefund}>
            创建
          </Button>
        </Space>
      </div>

    </>
  );
}

export default CreateTaxRefundStatement;