import React, { useState, useEffect } from 'react';
import { paasql } from '@api'
import { Input, Button, Space, message, Select, Row, Col } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';

const { Option } = Select;
const EditBankInfo = (props) => {
  const {
    enterpriseId, enterpriseInfo,
    hideBankInfoModal
  } = props
  const [bankAccountNumber, setBankAccountNumber] = useState(null)
  const [bankAccountName, setBankAccountName] = useState(null)
  const [bankBranch, setBankBranch] = useState(null)
  const [bankCode, setBankCode] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [currency, setCurrency] = useState(null)

  const currencyList = [
    {
      id: '0',
      name: "人民币",
    }, {
      id: '1',
      name: "美元",
    }, {
      id: '2',
      name: "日元",
    }, {
      id: '3',
      name: "欧元",
    }
  ]

  useEffect(() => {
    const cmd_data = {
      "id": enterpriseId
    }
    paasql('eip.eip_enterprise.attr_for_edit_bank_information', cmd_data).then(data => {
      const result = data.result[0]
      const {
        bank_account_name,
        bank_account_number, bank_branch,
        bank_name,
        bank_code,
        currency
      } = result
      setBankAccountNumber(bank_account_number)
      setBankAccountName(bank_account_name)
      setBankBranch(bank_branch)
      setBankName(bank_name)
      setBankCode(bank_code)
      setCurrency(currency)
    })
  }, [enterpriseId, enterpriseInfo])
  const changeBankAccountNumber = (e) => {
    const value = e.target.value
    setBankAccountNumber(value)
  }
  const changeBankAccountName = (e) => {
    const value = e.target.value
    setBankAccountName(value)
  }
  const changeBankBranch = (e) => {
    const value = e.target.value
    setBankBranch(value)
  }
  const changeBankName = (e) => {
    const value = e.target.value
    setBankName(value)
  }

  const changeBankCode = (e) => {
    const value = e.target.value
    setBankCode(value)
  }

  const changeCurrency = (v, option) => {
    const { key, value } = option
    setCurrency(value)
  }
  const showList = [
    {
      label: "户名",
      value: bankAccountName,
      action: changeBankAccountName
    }, {
      label: "开户行",
      value: bankName,
      action: changeBankName

    }, {
      label: "银行网点",
      value: bankBranch,
      action: changeBankBranch

    }, {
      label: "银行账户",
      value: bankAccountNumber,
      action: changeBankAccountNumber
    }, {
      label: "银行行联号",
      value: bankCode,
      action: changeBankCode
    }, {
      label: "币种",
      value: currency,
      action: changeCurrency,
      component:
        <Select
          placeholder={'请选择'}
          size='large'
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          value={currency}
          className='edit-bank-info-select'
          onChange={changeCurrency}
        >
          {
            currencyList.map(item => {
              return (
                <Option
                  value={item.name}
                  key={item.id}
                >{item.name}</Option>
              )
            })
          }
        </Select>
    }
  ]

  const cancelEdit = () => {
    hideBankInfoModal()
  }
  const saveEdit = () => {
    // 'eip.eip_enterprise.update_bank_information'
    const cmd_data = {
      id: enterpriseId,
      "bank_account_name": bankAccountName,
      "bank_name": bankName,
      "bank_branch": bankBranch,
      "bank_account_number": bankAccountNumber,
      "bank_code": bankCode,
      "currency": currency,


    }
    paasql("eip.eip_enterprise.update_bank_information", cmd_data).then(data => {
      if (data.error == null) {
        message.success("更新成功")
        hideBankInfoModal()
      } else {
        message.success(data.error.message)
      }
    })
  }
  return (
    <div>
      {
        showList.map(item => {
          return (
            <div key={item.label}>

              <Row className='edit-bank-info-item'>
                <Col
                  span={6}
                  className='edit-bank-info-label'
                >
                  {`${item.label}:`}
                </Col>
                <Col
                  span={18}
                >
                  {
                    item.component === undefined ?
                      <Input className="edit-bank-info-input" value={item.value} onChange={item.action} />
                      :
                      item.component
                  }
                </Col>
              </Row>

            </div>
          )
        })
      }
      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <Space>
          <Button onClick={cancelEdit}>
            取消
            </Button>
          <Button type="primary" onClick={saveEdit}>
            保存
            </Button>
        </Space>
      </div>
    </div>
  );
}

export default EditBankInfo;