import React, { useState, useEffect } from "react";

import echarts from "echarts";

import { queryEipMonthlyTotalByYear, queryEipMonthlyByYear } from "@api";
//园区创税
function TaxTrend() {
  const parkId = "2b9312bccbf94297a5172f3277ac8fa8";
  //获取数据的函数
  async function getDate() {
    //时间
    const first_year = new Date().getFullYear();
    const secound_year = first_year - 1;
    const third_year = first_year - 2;

    //参数
    const first_params = {
      eip_park_id: parkId,
      eip_business_year: String(first_year),
    };
    const secound_params = {
      eip_park_id: parkId,
      eip_business_year: String(secound_year),
    };
    const third_params = {
      eip_park_id: parkId,
      eip_business_year: String(third_year),
    };

    //原始数据
    const first = await queryEipMonthlyByYear(first_params);
    const secound = await queryEipMonthlyByYear(secound_params);
    const third = await queryEipMonthlyByYear(third_params);

    //处理成要的数据
    //处理legend的数据
    const legend = [`${first_year}年`, `${secound_year}年`, `${third_year}年`];

    const firstYearData = [];
    const secoundYearData = [];
    const thirdYearData = [];

    first.forEach((element) => {
      firstYearData.push(element.tax_payable);
    });
    secound.forEach((element) => {
      secoundYearData.push(element.tax_payable);
    });

    third.forEach((element) => {
      thirdYearData.push(element.tax_payable);
    });

    const series = [
      {
        name: `${first_year}年`,
        type: "line",
        data: firstYearData,
        // itemStyle: {
        //   normal: {
        //     color: "#3A84FF",
        //     lineStyle: {
        //       color: "#3A84FF",
        //       width: 1,
        //     },
        //     areaStyle: {
        //       color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
        //         {
        //           offset: 0,
        //           color: "rgba(58,132,255,0)",
        //         },
        //         {
        //           offset: 1,
        //           color: "rgba(58,132,255,0.35)",
        //         },
        //       ]),
        //     },
        //   },
        // },
      },
      {
        name: `${secound_year}年`,
        type: "line",
        data: secoundYearData,
      },
      {
        name: `${third_year}年`,
        type: "line",
        data: thirdYearData,
      },
    ];
    return {
      series,
      legend,
    };
  }
  useEffect(() => {
    //请求三次接口
    getDate().then((res) => {
      const { series, legend } = res;

      const option = {
        title: {
          text: "历年同期纳税趋势",
          left: 20,
          top: 10,
        },
        color: ["#23B899", "#FFB0E3", "#333FFF"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legend,
          right: 20,
          top: 20,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        series: series,
      };

      const dom = document.getElementById("park-tax-trend");
      if (dom !== null) {
        var myEchart = echarts.init(dom);
        myEchart.setOption(option);
        window.addEventListener("resize", () => {
          myEchart.resize();
        });
      }
    });
  }, []);

  useEffect(() => {
    //获取数据
  }, []);
  return (
    <>
      <div className="park-tax-trend-container">
        <div
          style={{
            height: "100%",
            minHeight: "200px",
            width: "100%",
          }}
          id="park-tax-trend"
        ></div>
      </div>
    </>
  );
}

export default TaxTrend;
