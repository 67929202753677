import React, { useState, useEffect, memo } from 'react';


import {
  Button, Input, DatePicker, Select,
  Col, Row, Space, InputNumber, message, Collapse
} from 'antd'
import moment from 'moment'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'



import { CreateDocIcon, CancelEditIcon } from '@icon'
import { paasql, enterpriseGpi } from '@api'

const inputClassName = 'edit-enterprise-info-input-form'
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse

const ButtonGroup = Button.Group
const inputPlaceholder = '请输入'


const selectPlaceHolder = '请选择'
const isInPark = [{
  "id": "1",
  "name": "是"
}, {
  "id": "0",
  "name": "否"
}]
const enterpriseSourceList = [
  {
    id: '0',
    name: '园区注册'
  }, {
    id: '1',
    name: '迁入'
  }, {
    id: '2',
    name: '迁入(区外)'
  }
]
const currencyList = [
  {
    id: '0',
    name: "人民币",
  }, {
    id: '1',
    name: "美元",
  }, {
    id: '2',
    name: "日元",
  }, {
    id: '3',
    name: "欧元",
  }
]
const EnterpriseCreate = (props) => {
  const { dispatch } = props
  const [pageChange, setPageChange] = useState(false)
  const [parkId, setParkId] = useState(null)
  const [parkName, setParkName] = useState(null)
  const [cpStaffList, setCpStaffList] = useState([])
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])

  //检测招商员的数据，防止出现id
  const [staffName, setStaffName] = useState(undefined)


  const [investmentAgencyStaffList, setInvestmentAgencyStaffList] = useState([])

  const [investmentAgencyStaffListAll, setInvestmentAgencyStaffListAll] = useState([])


  const [settledByList, setSettledByList] = useState([])
  //招商部门
  const [investmentDepartmentList, setInvestmentDepartmentList] = useState([])

  const [settledStatusList, setSettledStatusList] = useState([])
  const [recordingGenreList, setRecordingGenreList] = useState([])

  const [taxStatusList, setTaxStatusList] = useState([])

  //内外资区分
  const [registeredGenreList, setRegisteredGenreList] = useState([])

  //工商状态
  const [licenseStatusList, setLicenseStatusList] = useState([])
  //企业类型registered_enre
  const [enterpriseGenreList, setEnterpriseGenreList] = useState([])


  const [enterpriseInfo, setEnterpriseInfo] = useState({
    currency: '人民币',
    enterprise_source: '园区注册',
  })


  const [enterpriseValueAddedTaxRate, setEnterpriseValueAddedTaxRate] = useState(0)
  const [enterpriseCorporateIncomeTaxRate, setEnterpriseCorporateIncomeTaxRate] = useState(0)
  const [agencyValueAddedTaxRate, setAgencyValueAddedTaxRate] = useState(0)
  const [agencyCorporateIncomeTaxRate, setAgencyCorporateIncomeTaxRate] = useState(0)

  const [exitedAtChange, setExitedAtChange] = useState(false)
  const [settledAt, setSettledAt] = useState(null)
  const [settledAtChange, setSettledAtChange] = useState(true)

  const [isSettledInPark, setIsSettledInPark] = useState(true)

  const [aleadyChangeUscc, setAleadyChangeUscc] = useState(true)
  useEffect(() => {
    //设置默认的入园时间。当前时间
    const time = moment()

    setSettledAt(time)
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        setParkId(data.result[0].id)
        setParkName(data.result[0].name)

      } else {
        message.error(data.error.message)
        return
      }
    })
      .finally(() => {

      })

  }, [props.location.pathname])
  useEffect(() => {
    handelEnterpriseInfo()
  }, [])
  //处理企业的信息
  const handelEnterpriseInfo = () => {
    paasql('eip.eip_enterprise.get_eip_park_cp_staff_eip_investment_agency_eip_investment_agency_staff.list').then(data => {
      const [l, cp_staff_list, investment_agency_list, investment_agency_staff_list] = data.result.parallel_list_object
      //中介列表
      const cpStaffList = cp_staff_list.cp_staff_name
      const investmentAgencyList = investment_agency_list.eip_investment_agency_name
      const investmentAgencyStaffList = investment_agency_staff_list.eip_investment_agency_staff_name
      setCpStaffList(cpStaffList)

      setInvestmentAgencyList(investmentAgencyList)

      const a = [
        {
          id: 'park',
          name: '本部'
        }, ...investmentAgencyList]

      setInvestmentDepartmentList(a)

      setInvestmentAgencyStaffListAll(investmentAgencyStaffList)

      paasql('eip.eip_enterprise.get_cp_standard_data_from_cp_standard_collection').then(data => {
        const result = data.result.parallel_list_object


        //处理面包屑导航
        const navArr = ['基本信息管理', '企业库', '新增企业']
        dispatch({
          type: 'SET_BREADCRUMB_LIST',
          value: navArr
        })

        //处理下拉列表的数据问题
        const [
          settled_by,
          investment_department,
          settled_status,
          recording_genre,
          tax_status,
          registered_genre,
          enterprise_genre,
          license_status
        ] = result
        //招商类别
        const settledBy = settled_by.settled_by[0].result

        //入驻状态
        const settledStatus = settled_status.settled_status[0].result


        //注册类型
        const recordingGenre = recording_genre.recording_genre[0].result

        //税务状态
        const taxStatus = tax_status.tax_status[0].result

        //内外资区分类型
        const registeredGenre = registered_genre.registered_genre[0].result

        //企业类型
        const enterpriseGenre = enterprise_genre.enterprise_genre[0].result

        //工商状态
        const licenseStatus = license_status.license_status[0].result



        //设置入驻状态的默认选项

        const settledAt = moment().format('YYYY-MM-DD').replace(/-/g, '')
        setEnterpriseInfo({
          ...enterpriseInfo,
          //这里由于是标准数据，排序现在不方便更改，这里的默认项是确立的下标是1
          settled_status: settledStatus[1].id,
          settled_at: settledAt,
          settled_status_code: settledStatus[1].code,
          recording_genre: recordingGenre[0].id,
          recording_genre_code: recordingGenre[0].code,
          tax_status: taxStatus[0].id,
          registered_genre: registeredGenre[0].id,
        })


        //设置下拉列表
        setSettledByList(settledBy)
        setSettledStatusList(settledStatus)
        setRecordingGenreList(recordingGenre)
        setTaxStatusList(taxStatus)
        //内外资区分
        setRegisteredGenreList(registeredGenre)

        setEnterpriseGenreList(enterpriseGenre)
        setLicenseStatusList(licenseStatus)

      })
    })
  }

  const cancelEdit = () => {
    props.history.push(`/enterprise-database`)
  }
  const confirmCreate = () => {

    const { name } = enterpriseInfo
    if (
      name === null ||
      name === undefined ||
      name === ''
    ) {
      message.error('企业名称是必填的')
      return
    }
    //1、创建扶持点的记录
    const cmd_data = {
      "object": {
        "name": "测试",
        "enterprise_corporate_income_tax_refund_rate": enterpriseCorporateIncomeTaxRate,
        "enterprise_value_added_tax_refund_rate": enterpriseValueAddedTaxRate
      }
    }
    paasql('eip.eip_enterprise_tax_refund_rate.create', cmd_data).then(
      eip_enterprise_tax_refund_rate => {
        if (eip_enterprise_tax_refund_rate.error == null) {
          var eip_enterprise_tax_refund_rate_id = eip_enterprise_tax_refund_rate.result.id//刚创建表的id
          var cmd_data = {
            "object": {
              "name": "测试",
              "agency_value_added_tax_refund_rate": agencyValueAddedTaxRate,
              "agency_corporate_income_tax_refund_rate": agencyCorporateIncomeTaxRate
            }
          }
          paasql('eip.eip_investment_agency_tax_refund_rate.create', cmd_data).then(
            eip_investment_agency_tax_refund_rate => {
              if (eip_investment_agency_tax_refund_rate.error == null) {
                var eip_investment_agency_tax_refund_rate_id = eip_investment_agency_tax_refund_rate.result.id//刚创建表的id
                var cmd_data = {//拿到创建两个表的id
                  object: {
                    ...enterpriseInfo,
                    eip_park_id: parkId,
                    eip_park_name: parkName,
                    eip_enterprise_tax_refund_rate_id,
                    eip_investment_agency_tax_refund_rate_id
                  }
                }
                paasql('eip.eip_enterprise.create', cmd_data).then(create => {
                  if (create.error == null) {
                    const createEnterprise = create.result
                    const enterpriseId = create.result.id
                    // props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                    const {
                      eip_investment_agency_id,
                      settled_at,
                      exited_at,
                      id,
                      name,
                      legal_representative
                    } = createEnterprise
                    const settledAtYear = moment(settled_at).year()
                    const settledAtMonth = ('0' + String(moment(settled_at).month() + 1)).slice(-2)
                    const settledAtDay = ('0' + String(moment(settled_at).date())).slice(-2)

                    //创建联系人信息

                    //判断法人的姓名是否为空
                    if (
                      legal_representative === null
                      || legal_representative === ''
                      || legal_representative == undefined
                    ) {
                    } else {

                      const cmd_data = {
                        object: {
                          name: legal_representative,
                          //法人的标准数据id，不可更改
                          position_id: '4ce56fc2f9a740ff8ac3511d8315ad9b',
                          position_name: '法人',
                          eip_enterprise_id: id,
                          eip_enterprise_name: name,
                        }

                      }
                      paasql("eip.eip_enterprise_contact.create", cmd_data).then(data => {
                        if (data.error == null) {
                        } else {
                          message.error('创建法人记录失败')
                        }
                      })
                    }


                    //处理离进园记录

                    if (settledAtChange) {
                      var create_settled_at_record = {
                        "object": {
                          "eip_park_id": parkId,
                          "eip_park_name": parkName,
                          "eip_enterprise_id": enterpriseId,//新生成的id
                          eip_investment_agency_id,
                          "settled_status": "eaf5b81dcea241aa83d6172998194cdc",
                          "settled_status_code": "01",
                          year: settledAtYear,
                          month: settledAtMonth,
                          day: settledAtDay
                        }
                      }

                      paasql("eip.eip_enterprise_settled_or_exited_record.create",
                        create_settled_at_record
                      ).then(
                        create_settled_at_record_result => {
                          if (create_settled_at_record_result.error == null) {
                            if (exitedAtChange) {
                              const exitedAtYear = moment(exited_at).year()
                              const exitedAtMonth = ('0' + String(moment(exited_at).month() + 1)).slice(-2)
                              const exitedAtDay = ('0' + String(moment(exited_at).date())).slice(-2)
                              var create_exited_at_record = {
                                "object": {

                                  "eip_park_id": parkId,
                                  "eip_park_name": parkName,
                                  "eip_enterprise_id": enterpriseId,//新生成的id
                                  eip_investment_agency_id,
                                  "settled_status": "0ecdc3865bb544479e17abae5a041cb4",
                                  "settled_status_code": "02",
                                  year: exitedAtYear,
                                  month: exitedAtMonth,
                                  day: exitedAtDay,
                                }
                              }
                              paasql('eip.eip_enterprise_settled_or_exited_record.create', create_exited_at_record).then(
                                create_exited_at_record_result => {
                                  if (create_exited_at_record_result.error == null) {
                                    message.success("更新企业信息，离进园记录成功")
                                    props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                                  } else {
                                    props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                                    message.success("更新企业信息，创建进园记录成功，离园信息失败")
                                    message.error(create_exited_at_record_result.error.message)
                                  }
                                }
                              )
                            } else {
                              message.error('进园信息更改，离园信息未更改')
                              props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                            }
                          } else {
                            message.error(create_settled_at_record_result.error.message)
                          }
                        }
                      )
                    } else {
                      //只更新了离园信息
                      if (exitedAtChange) {
                        const exitedAtYear = moment(exited_at).year()
                        const exitedAtMonth = ('0' + String(moment(exited_at).month() + 1)).slice(-2)
                        const exitedAtDay = ('0' + String(moment(exited_at).date())).slice(-2)

                        var create_exited_at_record = {
                          "object": {

                            "eip_park_id": parkId,
                            "eip_park_name": parkName,
                            "eip_enterprise_id": enterpriseId,//新生成的id
                            eip_investment_agency_id,
                            "settled_status": "0ecdc3865bb544479e17abae5a041cb4",
                            "settled_status_code": "02",
                            year: exitedAtYear,
                            month: exitedAtMonth,
                            day: exitedAtDay,
                          }
                        }
                        paasql('eip.eip_enterprise_settled_or_exited_record.create', create_exited_at_record).then(
                          create_exited_at_record_result => {
                            if (create_exited_at_record_result.error == null) {
                              message.success("更新企业信息，离进园记录成功")
                              props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                            } else {
                              props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                              message.success("更新企业信息成功，进园信息未更改，离园信息更改失败")
                              message.error(create_exited_at_record_result.error.message)
                            }
                          }
                        )
                      } else {
                        message.error('进园信息更改，离进园信息未更改')
                        props.history.push(`/enterprise-database/detail/${enterpriseId}`)
                      }
                    }
                    //开始处理离进园记录
                  } else {
                    message.error(create.error.message)
                  }
                })
              }
              else {
                message.error(eip_investment_agency_tax_refund_rate.error.message)
              }
            })

        } else {
          message.error(eip_enterprise_tax_refund_rate.error.message)
        }
      })
  }



  //更改统一信用代码，，默认税号，税管码一样，输入的时候应该覆盖他们
  const changeUscc = (e) => {
    const value = e.target.value
    if (value === '') {
      setAleadyChangeUscc(true)
      setEnterpriseInfo({
        ...enterpriseInfo,
        uscc: value,
        tax_file_number: value,
        taxpayer_registration_number: value
      })
      return
    }
    setAleadyChangeUscc(false)
    setEnterpriseInfo({
      ...enterpriseInfo,
      uscc: value,
      tax_file_number: value,
      taxpayer_registration_number: value
    })
  }


  const SynchronizedInfo = () => {
    const { uscc } = enterpriseInfo
    const cmd_data = {
      uscc
    }

    //查看库里的uscc是否存在
    paasql("eip.eip_enterprise.create_enterprise_check_uscc", cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        //未查询到接口
        if (result.length === 0) {
          const method = 'qi_query'
          enterpriseGpi(method, uscc).then(res => {
            if (res.error == null) {
              const {
                Name, No, BelongOrg, OperName,
                StartDate, EndDate, Status, Province, UpdatedDate, CreditCode,
                RegistCapi, EconKind, OriginalName,
                Address, Scope, TermStart, TeamEnd, OrgNo, RecCap,
              } = res
              //处理日期
              const business_term_started_at = moment(TermStart).format('YYYY-MM-DD')
              const business_term_ended_at = moment(TeamEnd).format('YYYY-MM-DD')
              const established_in = moment(StartDate).format('YYYY-MM-DD')

              //处理实收和实缴的金额处理

              const RecCapNumber =
                RecCap === '' ||
                  RecCap === null ? '0' :
                  RecCap.split('万元')[0]
              const RegistCapiNumber =
                RegistCapi === '' ||
                  RegistCapi === null
                  ? '0' :
                  RegistCapi.split('万元')[0]
              //开始处理营业年限
              const business_term_started_at_year = moment(TermStart).year()
              const business_term_ended_at_year = moment(TeamEnd).year()
              const business_term = business_term_ended_at_year - business_term_started_at_year

              const former_name = OriginalName.length === 0 ? null : OriginalName[0].Name


              //处理工商同步时间，同步成功后才设置
              const nowTime = moment().format('YYYY-MM-DD')
              const synchronized_at = nowTime.replace(/-/g, '')



              const cmd_data1 = {
                name: Name,//名称
                uscc: CreditCode,//社会统一信用代码
                license_status: Status,//工商状态
                legal_representative: OperName,//法人
                registered_capital: RegistCapiNumber, //注册资本
                paidup_capital: RecCapNumber,//实收资本
                business_term: business_term, //营业年限
                business_term_started_at: business_term_started_at,//营业期限自
                business_term_ended_at: business_term_ended_at,//营业期限至
                registered_by: BelongOrg,//登记机关
                established_in: established_in,//成立日期
                enterprise_genre: EconKind,//公司类型
                registration_number: No,//注册号
                former_name: former_name,//曾用名
                scope_of_business: Scope,//经营范围
                registered_address: Address,//注册地址
                business_address: Address, //经营地址
                synchronized_at: synchronized_at,
              }


              //可以保存信息了
              setEnterpriseInfo({
                ...enterpriseInfo,
                ...cmd_data1
              })
              //
            } else {
              message.error(res.error.message)
            }
          })
        } else {
          message.warn('该企业已存在，请再次确认输入的统一征信人代码！', 10)
        }
      } else {
        message.error(data.error.message)
      }
    })

  }

  //大部分情况
  const changeFilelds = ({ fields, value }) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      [fields]: value
    })
  }
  //改变企业名
  const changeName = (e) => {
    const value = e.target.value

    setEnterpriseInfo({
      ...enterpriseInfo,
      name: value
    })
  }
  //改变档案号
  const changeFileNumber = (e) => {
    const value = e.target.value

    setEnterpriseInfo({
      ...enterpriseInfo,
      file_number: value
    })
  }


  //更改工商状态
  const changeLicenseStatus = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      license_status: value
    })
  }

  //更改注册号
  const changeRegistrationNumber = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      registration_number: value
    })
  }

  //更改法定代表人
  const changeLegalRepresentative = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      legal_representative: value
    })
  }

  //更改注册资本
  const changeRegisteredCapital = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_capital: value
    })
  }

  //更改实收资本
  const changePaidupCapital = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      paidup_capital: value
    })
  }


  //更改营业年限
  const changeBusinessTerm = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term: value
    })
  }

  //营业期限自
  const changeBusinessTermStartAt = (data, dateString) => {

    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term_started_at: time
    })
  }
  //营业期限至
  const changeBusinessTermEndAt = (date, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term_ended_at: time
    })
  }

  //更改登记机关
  const changeRegisteredBy = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_by: value
    })
  }

  //更改成立日期
  const changeEstablishedIn = (date, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      established_in: time
    })
  }

  //更改企业类型
  //enterprise_genre
  const changeEnterpriseGenre = (v, option) => {

    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      enterprise_genre: value
    })
  }

  //更改行业分类
  const changeIndustry = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      industry: value
    })
  }


  //更改曾用名
  const changeFormerName = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      former_name: value
    })
  }

  //更改内外资
  const changeRegisteredGenre = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_genre: value,
    })
  }

  //更改经营范围
  const changeScopeOfBusiness = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'scope_of_business',
      value
    })
  }


  //大多数修改的情况

  //更改注册地址
  const changeRegisteredAddress = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'registered_address',
      value
    })
  }


  //更改经营地址
  const changeBusinessAddress = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'business_address',
      value
    })
  }
  //公司邮编
  const changeZip = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'zip_code',
      value
    })
  }
  //更改固定电话
  const changeTelephoneNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'telephone_number',
      value
    })
  }

  //更改备注
  const changeRemarks = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'remarks',
      value
    })
  }


  const changeRecordingGenre = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      recording_genre: value,
      recording_genre_code: key,
    })
  }




  //更改币种
  const changeCurrency = (v, option) => {

    const { key, value } = option

    changeFilelds({
      fields: 'currency',
      value
    })
  }
  const enterpriseBaseInfoList = [
    {
      id: "enterprise_genre",
      span: 12,
      label: '公司类型:',
      //企业类型
      component:
        <Select
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          size='large'
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.enterprise_genre}
          onChange={changeEnterpriseGenre} >
          {
            enterpriseGenreList.map(item => {
              return (
                <Option
                  key={item.id}
                  value={item.name} >{item.name}</Option>
              )
            })
          }
        </Select>

    }, {
      id: 'shame',
      span: 12
    }, {
      id: "registered_capital",
      span: 6,
      label: '注册资本(万元):',
      component:
        <InputNumber
          value={enterpriseInfo.registered_capital}
          placeholder={inputPlaceholder}
          style={{ width: '100%' }}
          className={inputClassName} min={0} size='large' precision={2} onChange={changeRegisteredCapital} />
    }, {
      id: "established_in",
      span: 6,
      label: '成立日期:',
      component:
        <DatePicker
          style={{ width: '100%' }}
          allowClear={false}
          value={enterpriseInfo.established_in == undefined ? null :
            moment(enterpriseInfo.established_in, 'YYYY-MM-DD')
          }
          className={inputClassName}

          onChange={changeEstablishedIn} />
    }, {
      id: "business_term_started_at",
      span: 6,
      label: '营业期限自:',
      component:
        <DatePicker

          value={enterpriseInfo.business_term_started_at == undefined ? null :
            moment(enterpriseInfo.business_term_started_at, 'YYYY-MM-DD')
          }
          allowClear={false}
          style={{ width: '100%' }}
          className={inputClassName}
          onChange={changeBusinessTermStartAt} />
    }, {
      id: "business_term_ended_at",
      span: 6,
      label: '营业期限至:',
      component:
        <DatePicker
          value={enterpriseInfo.business_term_ended_at == undefined ? null :
            moment(enterpriseInfo.business_term_ended_at, 'YYYY-MM-DD')
          }
          allowClear={false}
          style={{ width: '100%' }}
          className={inputClassName}

          onChange={changeBusinessTermEndAt} />
    }, {
      id: "paidup_capital",
      span: 6,
      label: '实收资本(万元):',
      component:
        <InputNumber
          value={enterpriseInfo.paidup_capital}
          placeholder={inputPlaceholder}
          style={{ width: '100%' }}

          className={inputClassName} min={0} size='large' onChange={changePaidupCapital} />
    }, {
      id: "business_term",
      span: 6,
      label: '营业年限:',
      component:
        <InputNumber
          value={enterpriseInfo.business_term}
          placeholder={inputPlaceholder}
          style={{ width: '100%' }}
          className={inputClassName} size='large' precision={0} min={0} onChange={changeBusinessTerm} />
    }, {
      id: "currency",
      span: 6,
      label: '币种',
      component:
        <Select
          size='large'
          value={enterpriseInfo.currency}
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeCurrency}
        >
          {
            currencyList.map(item => {
              return (
                <Option
                  key={item.id}
                  value={item.name}
                  item={item}
                >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {

      id: "license_status",
      label: '工商状态:',
      span: 6,
      component:

        //

        <Select
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          size='large'
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.license_status}
          onChange={changeLicenseStatus} >
          {
            licenseStatusList.map(item => {
              return (
                <Option
                  key={item.id}
                  value={item.name} >{item.name}</Option>
              )
            })
          }
        </Select>
    }, {
      id: "registered_address",
      span: 18,
      label: '注册地址:',
      component:
        <Input
          value={enterpriseInfo.registered_address}
          placeholder={inputPlaceholder}
          size='large' className={inputClassName} onChange={changeRegisteredAddress} />
    }, {
      id: "telephone_number",
      span: 6,
      label: '固定电话:',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName} type='tel' onChange={changeTelephoneNumber} />
    }, {
      id: "business_address",
      span: 18,
      label: '经营地址:',
      component:
        <Input
          value={enterpriseInfo.business_address}
          placeholder={inputPlaceholder}
          size='large' className={inputClassName} onChange={changeBusinessAddress} />
    }, {
      id: "zip_code",
      span: 6,
      label: '公司邮编:',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName} onChange={changeZip} />
    }, {
      id: "scope_of_business",
      span: 24,
      label: '经营范围:',
      component:
        <TextArea
          placeholder={inputPlaceholder}
          value={enterpriseInfo.scope_of_business}
          className={inputClassName}
          autoSize={{ minRows: 1, maxRows: 3 }}
          onChange={changeScopeOfBusiness} />
    }
  ]

  //更改 税管码
  const changeTaxFileNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'tax_file_number',
      value
    })
  }


  //更改首次纳税月 //6.1日已不需要
  // const changeFirstTaxPayoutAt = (data, dateString) => {

  //   const time = dateString.replace(/-/g, '')
  //   setEnterpriseInfo({
  //     ...enterpriseInfo,
  //     first_tax_payout_at: time
  //   })
  //   //设置
  // }


  //更改税务状况
  const changeTaxStatus = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      tax_status: value
    })
  }


  //更改税号
  const changeTaxpayerRegistrationNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'taxpayer_registration_number',
      value
    })
  }
  const taxInfoList = [
    {
      id: "taxpayer_registration_number",
      label: '税号:',
      span: 6,
      component:
        <Input size='large'
          value={enterpriseInfo.taxpayer_registration_number}
          placeholder={inputPlaceholder}
          className={inputClassName}
          onChange={changeTaxpayerRegistrationNumber} />,
    },
    {
      id: "tax_file_number",
      label: '税管码:',
      span: 6,
      component:
        <Input size='large'
          value={enterpriseInfo.tax_file_number}

          placeholder={inputPlaceholder} className={inputClassName}
          onChange={changeTaxFileNumber} />,
    },
    {
      id: "tax_status",
      span: 6,
      label: '税务状况:',
      component:
        <Select
          size='large'
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.tax_status}
          onChange={changeTaxStatus} >
          {
            taxStatusList.map(item => {
              return (
                <Option key={item.id}

                >{item.name}</Option>
              )
            })
          }
        </Select>
    }
  ]


  //更改银行账户名
  const changeBankAccountName = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'bank_account_name',
      value
    })
  }

  //更改银行名
  const changeBankName = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'bank_name',
      value
    })
  }

  //更改开户行
  const changeBankBranch = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'bank_branch',
      value
    })
  }

  //更改银行账号名
  const changeBankAccountNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'bank_account_number',
      value
    })
  }


  //更改行号
  const changeBankCode = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'bank_code',
      value
    })
  }

  const bankInfoList = [
    {
      id: "bank_account_name",
      label: '户名',
      span: 12,
      component:
        <Input size='large'
          placeholder={inputPlaceholder} className={inputClassName} onChange={changeBankAccountName} />,
    }, {
      id: "bank_name",
      span: 12,
      label: '开户行',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName}
          onChange={changeBankName} />,
    }, {
      id: "bank_branch",
      span: 6,
      label: '银行网点',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName}
          onChange={changeBankBranch} />,
    }, {
      id: "bank_account_number",
      span: 6,
      label: '银行账号',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName}
          onChange={changeBankAccountNumber} />,
    },
    {
      id: "bank_code",
      span: 6,
      label: '银行行联号',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName}
          onChange={changeBankCode} />,
    },
  ]


  const changeSettledAt = (data, dateString) => {
    setSettledAtChange(true)
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      settled_at: time
    })
  }

  const changeExitedAt = (data, dateString) => {
    setExitedAtChange(true)
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      exited_at: time
    })

  }


  const changeRegisteredInPark = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      is_registered_in_this_park: value
    })
  }


  const changeSettledStatus = (v, option) => {
    //这里的key是code，value是id
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      settled_status: value,
      settled_status_code: key
    })
  }





  //更改企业来源
  const changeEnterpriseSource = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      enterprise_source: value,
    })
  }

  //园区入驻信息列表
  const settleList = [
    {
      id: "is_registered_in_this_park",
      span: 6,
      label: '是否本园区注册',
      component:
        <Select
          size='large'
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeRegisteredInPark}
        >
          {
            isInPark.map(item => {
              return (
                <Option key={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    }, {
      id: "settled_status",
      span: 6,

      //原字段 （入驻状态）
      label: '企业情况',
      component:
        <Select
          placeholder={selectPlaceHolder}
          size='large'
          value={enterpriseInfo.settled_status}
          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeSettledStatus}
        >
          {
            settledStatusList.map(item => {
              return (
                <Option key={item.code} value={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    }, {
      id: "enterprise_source",
      span: 6,
      label: '企业来源',
      component:
        <Select
          className={inputClassName}
          size='large'
          value={enterpriseInfo.enterprise_source}
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          style={{ width: '100%' }}
          onChange={changeEnterpriseSource}
        >
          {
            enterpriseSourceList.map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }
  ]

  //更改园区登记类型
  const changeSettledBy = (v, option) => {
    // const { key, value, children } = option
    // if (children == '中介招入') {
    //   (false)
    // } else {
    //   setIsParkSettle(true)
    // }
    // setEnterpriseInfo({
    //   ...enterpriseInfo,
    //   settled_by: value,
    //   settled_by_code: key,
    // })
  }

  //更改本部招商员名 
  const changeStaffName = (value, option) => {

    setStaffName(value)
    const { children } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      cp_staff_name: children,
      cp_staff_id: value
    })
  }

  //

  const changeInvestmentAgency = (v, option) => {
    const { children, value } = option

    const tmpList =
      investmentAgencyStaffListAll.filter(item =>
        item.eip_investment_agency_id == value
      )
    setInvestmentAgencyStaffList(tmpList)
    setEnterpriseInfo({
      ...enterpriseInfo,
      eip_investment_agency_id: value,
      eip_investment_agency_name: children,
    })
  }
  const changeInvestmentAgencyStaff = (value, option) => {
    setStaffName(value)

    const { children } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      eip_investment_agency_staff_id: value,
      eip_investment_agency_staff_name: children
    })
  }


  //更改招商部门
  const changeInvestmentDepartment = (v, option) => {
    //需要设置settled_by和code
    setStaffName(undefined)

    const { value, key, children } = option

    if (value === 'park') {
      //选中的是园区本部的
      setIsSettledInPark(true)
      const index = settledByList.findIndex(item => item.name === '本部')
      setEnterpriseInfo({
        ...enterpriseInfo,
        investment_department_name: null,
        investment_department_id: null,
        settled_by: settledByList[index].id,
        settled_by_code: settledByList[index].code
      })
    } else {
      setIsSettledInPark(false)
      const index = settledByList.findIndex(item => item.name === '中介')

      const tmp_list = investmentAgencyStaffListAll.filter(
        item => item.eip_investment_agency_id == value
      )
      setInvestmentAgencyStaffList(tmp_list)
      setEnterpriseInfo({
        ...enterpriseInfo,
        eip_investment_agency_id: value,
        eip_investment_agency_name: children,
        cp_staff_name: null,
        cp_staff_id: null,
        settled_by: settledByList[index].id,
        settled_by_code: settledByList[index].code
      })
    }
  }




  const zhongJieList = [
    {
      id: "file_number",
      label: '档案号:',
      span: 6,
      component:
        <Input value={enterpriseInfo.file_number} placeholder={inputPlaceholder} size='large' className={inputClassName} onChange={changeFileNumber} />
    },
    {
      id: "name",
      label: '企业名称:',
      span: 12,
      component:
        <Input value={enterpriseInfo.name} placeholder={inputPlaceholder} className={inputClassName} size='large' onChange={changeName} />
    },
    {
      id: "1",
      label: '',
      span: 6
    },
    {
      id: "settled_at",
      label: '入园时间',
      span: 6,
      component:
        <DatePicker
          allowClear={false}
          value={settledAt}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeSettledAt}
        />,
    }, {
      id: "enterprise_source",
      span: 6,
      label: '企业来源',
      component:
        <Select
          className={inputClassName}
          size='large'
          value={enterpriseInfo.enterprise_source}
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          style={{ width: '100%' }}
          onChange={changeEnterpriseSource}
        >
          {
            enterpriseSourceList.map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: "investment_department",
      span: 6,
      label: '招商部门',
      component:

        <Select
          size='large'
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeInvestmentDepartment}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch

        >
          {
            investmentDepartmentList.map(item => {
              return (
                <Option key={item.name} value={item.id}  >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    },
    {
      id: "settled_status",
      span: 6,

      //原字段 （入驻状态）
      label: '企业情况',
      component:
        <Select
          placeholder={selectPlaceHolder}
          size='large'
          value={enterpriseInfo.settled_status}
          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeSettledStatus}
        >
          {
            settledStatusList.map(item => {
              return (
                <Option key={item.code} value={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    },
    {
      id: "recording_genre",
      span: 6,
      label: '注册类型:',
      component:
        <Select
          placeholder={selectPlaceHolder}
          size='large'
          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.recording_genre}
          onChange={changeRecordingGenre} >
          {
            recordingGenreList.map(item => {
              return (
                <Option key={item.code} value={item.id} >{item.name}</Option>
              )
            })
          }
        </Select>
    }, {
      id: "industry",
      span: 6,
      label: '行业分类:',
      component:
        <Input
          placeholder={inputPlaceholder}

          size='large' className={inputClassName} onChange={changeIndustry} />
    },
    {
      id: "cp_staff_name",
      span: 6,
      label: '招商员',
      component:

        <Select
          size='large'
          suffixIcon={<CaretDownOutlined />}
          placeholder={selectPlaceHolder}
          className={inputClassName}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={staffName}
          showSearch
          onChange={isSettledInPark ? changeStaffName : changeInvestmentAgencyStaff}
        >
          {
            (
              isSettledInPark ?
                cpStaffList : investmentAgencyStaffList).map(item => {
                  return (
                    <Option key={item.id} >
                      {item.name}
                    </Option>
                  )
                })
          }
        </Select>,
    },
    {
      id: "registered_genre",
      span: 6,
      label: '内外资区分:',
      component:
        <Select
          placeholder={selectPlaceHolder}
          size='large'
          value={enterpriseInfo.registered_genre}
          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          onChange={changeRegisteredGenre} >
          {
            registeredGenreList.map(item => {
              return (
                <Option
                  key={item.id}
                  value={item.id}
                >{item.name}
                </Option>
              )
            })
          }
        </Select>
    },
    {
      id: "former_name",
      span: 6,
      label: '曾用名:',
      component:
        <Input
          placeholder={inputPlaceholder}
          value={enterpriseInfo.former_name}
          size='large' className={inputClassName} onChange={changeFormerName} />
    }, {
      id: "remarks",
      span: 12,
      label: '备注:',
      component:
        <Input
          placeholder={inputPlaceholder}
          size='large' className={inputClassName} onChange={changeRemarks} />
    },
  ]

  //改变企业增值税扶持点
  const changeEnterpriseValueAddedTaxRate = (value) => {
    setEnterpriseValueAddedTaxRate(value)
  }
  //改变企业所得税扶持点
  const changeEnterpriseCorporateIncomeTaxRate = (value) => {
    setEnterpriseCorporateIncomeTaxRate(value)
  }

  //更改招商点增值税扶持点
  const changeAgencyValueAddedTaxRate = (value) => {
    setAgencyValueAddedTaxRate(value)
  }
  //更改  招商点所得税扶持点
  const changeAgencyCorporateIncomeTaxRate = (value) => {
    setAgencyCorporateIncomeTaxRate(value)
  }
  const rateList = [
    {
      id: "enterprise_value_added_tax_refund_rate",
      span: 6,
      label: '企业增值税扶持点:',
      component:
        <InputNumber
          placeholder={inputPlaceholder}
          size='large'
          style={{ width: '100%' }}
          max={100} min={0}
          className={inputClassName}
          onChange={changeEnterpriseValueAddedTaxRate} />,
    }, {
      id: "enterprise_corporate_income_tax_refund_rate",
      span: 6,
      label: '企业所得税扶持点:',
      component:
        <InputNumber
          placeholder={inputPlaceholder}
          size='large'
          style={{ width: '100%' }}
          max={100} min={0}
          className={inputClassName}
          onChange={changeEnterpriseCorporateIncomeTaxRate} />,
    }, {
      id: "agency_value_added_tax_refund_rate",
      span: 6,
      label: '招商点增值税扶持点:',
      component:
        <InputNumber
          placeholder={inputPlaceholder}
          size='large'
          max={100} min={0}
          style={{ width: '100%' }}
          className={inputClassName}
          onChange={changeAgencyValueAddedTaxRate} />,
    }, {
      id: "agency_corporate_income_tax_refund_rate",
      span: 6,
      label: '招商点所得税扶持点:',
      component:
        <InputNumber
          size='large'
          max={100} min={0}
          style={{ width: '100%' }}
          placeholder={inputPlaceholder}
          className={inputClassName}
          onChange={changeAgencyCorporateIncomeTaxRate} />,
    }
  ]




  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '40px',
        marginBottom: '10px'
      }}
      >
        <div>
          <Row >
            <Col span={6}
              style={{
                fontFamily: 'HiraginoSansGB-W6, HiraginoSansGB',
                paddingRight: 20,
                textAlign: 'right'
              }}>
              统一信用代码:
            </Col>
            <Col span={10}>
              <Input placeholder={'请输入统一信用代码'} onChange={changeUscc} />
            </Col>
            <Col span={8}
              style={{ paddingLeft: 20, lineHeight: '40px' }}
            >
              <Button disabled={aleadyChangeUscc} onClick={SynchronizedInfo}>
                一键同步工商信息
              </Button>
            </Col>
          </Row>
        </div>

        <div style={{ textAlign: 'right' }}>
          <ButtonGroup>
            <Button
              icon={<CancelEditIcon />}
              onClick={cancelEdit}
            >
              取消
          </Button>
            <Button
              icon={<CreateDocIcon />}
              onClick={confirmCreate}
            >
              确认新增
          </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className='create-enterprise-info-container' >
        <Collapse
          defaultActiveKey={['1', '2', '3', '4', '5']}
          className="site-collapse-custom-collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <Panel
            header={

              <div className="show-enterprise-info-title">
                企业基本信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="1">
            <div className='basic-info'>
              <Row>
                {
                  zhongJieList.map(item => {
                    return (
                      <Col className="create-enterprise-info-item" key={item.id} span={item.span}>
                        <Row >
                          <Col
                            className="create-enterprise-info-label"
                            style={{ textAlign: 'right', width: '100px' }}>
                            {item.label}
                          </Col>
                          <Col className='create-enterprise-info-box' style={{ width: 'calc(100% - 100px)' }}>
                            {item.component}
                          </Col>
                        </Row>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                营业执照信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="2">
            <div className='basic-info'>
              <Row >
                {
                  enterpriseBaseInfoList.map(item => {
                    return (
                      <Col className="create-enterprise-info-item" key={item.id} span={item.span}>
                        <Row >
                          <Col
                            className="create-enterprise-info-label"
                            style={{ textAlign: 'right', width: '120px' }}>
                            {item.label}
                          </Col>
                          <Col className='create-enterprise-info-box' style={{ width: 'calc(100% - 120px)' }}>
                            {item.component}
                          </Col>
                        </Row>
                      </Col>)
                  })}
              </Row>
            </div>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                税务证照信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="3">
            <div className='basic-info'>
              <Row >
                {
                  taxInfoList.map(item => {
                    return (
                      <Col className="create-enterprise-info-item" key={item.id} span={item.span}>
                        <Row >
                          <Col
                            className="create-enterprise-info-label"
                            style={{ textAlign: 'right', width: '120px' }}>
                            {item.label}
                          </Col>
                          <Col className='create-enterprise-info-box' style={{ width: 'calc(100% - 120px)' }}>
                            {item.component}
                          </Col>
                        </Row>
                      </Col>)
                  })}
              </Row>
            </div>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                企业银行账户信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="4">
            <div className='basic-info'>
              <Row >
                {
                  bankInfoList.map(item => {
                    return (
                      <Col className="create-enterprise-info-item" key={item.id} span={item.span}>
                        <Row >
                          <Col
                            className="create-enterprise-info-label"
                            style={{ textAlign: 'right', width: '120px' }}>
                            {`${item.label}:`}
                          </Col>
                          <Col className='create-enterprise-info-box' style={{ width: 'calc(100% - 120px)' }}>
                            {item.component}
                          </Col>
                        </Row>
                      </Col>)
                  })}
              </Row>
            </div>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                扶持点信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="5">
            <div className='basic-info'>
              <Row>
                {
                  rateList.map(item => {
                    return (
                      <Col
                        className="create-enterprise-info-item"
                        key={item.id} span={item.span}>
                        <Row >
                          <Col className="create-enterprise-info-label"
                            style={{ textAlign: 'right', width: '50%' }}>
                            {item.label}
                          </Col>
                          <Col className='create-enterprise-info-box' style={{ width: '50%' }}>
                            {item.component}
                          </Col>
                        </Row>

                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(memo(EnterpriseCreate));
