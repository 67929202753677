import React, { useState, useEffect } from "react";
import { paasql } from "@api";
import { message, Space, Button, InputNumber, Row, Col } from "antd";
const EditTaxRefundRate = (props) => {
  const { enterpriseId, hideRateInfoModal } = props;
  const [enterpriseValueAddedRate, setEnterpriseValueAddedRate] = useState(0);
  const [
    enterpriseCorporateIncomeRate,
    setEnterpriseCorporateIncomeRate,
  ] = useState(0);

  //个税
  const [
    enterpriseIndividualIncomeRate,
    setEnterpriseIndividualIncomeRate,
  ] = useState(0);
  //房产税
  const [enterprisePropertyRate, setEnterprisePropertyRate] = useState(0);

  const [agencyCorporateIncomeRate, setAgencyCorporateIncomeRate] = useState(0);
  const [agencyValueAddedRate, setAgencyValueAddedRate] = useState(0);

  //个税
  const [agencyIndividualIncomeRate, setAgencyIndividualIncomeRate] = useState(
    0
  );
  //房产税
  const [agencyPropertyRate, setAgencyPropertyRate] = useState(0);

  const [enterpriseHasId, setEnterpriseHasId] = useState(false);

  const [agencyHasId, setAgencyHasId] = useState(false);

  useEffect(() => {
    let cmd_data = {
      id: enterpriseId,
    };
    paasql(
      "eip.eip_enterprise.get_eip_enterprise_tax_refund_rate_id_and_eip_investment_agency_tax_refund_rate_id",
      cmd_data
    ).then((data) => {
      if (data.error == null) {
        const {
          eip_enterprise_tax_refund_rate_id,
          eip_investment_agency_tax_refund_rate_id,
        } = data.result;

        if (eip_enterprise_tax_refund_rate_id == null) {
          setEnterpriseHasId(true);
        }
        if (eip_investment_agency_tax_refund_rate_id == null) {
          setAgencyHasId(true);
        }
        let cmd_data1 = {
          id: eip_enterprise_tax_refund_rate_id,
        };

        //处理企业
        paasql(
          "eip.eip_enterprise_tax_refund_rate.query_by_id",
          cmd_data1
        ).then((enterpriseRate) => {
          if (enterpriseRate.error == null) {
            let result = enterpriseRate.result;
            const {
              enterprise_value_added_tax_refund_rate,
              enterprise_corporate_income_tax_refund_rate,
              enterprise_individual_income_tax_refund_rate,
              enterprise_property_tax_refund_rate,
            } = result;
            setEnterpriseValueAddedRate(enterprise_value_added_tax_refund_rate);
            setEnterpriseCorporateIncomeRate(
              enterprise_corporate_income_tax_refund_rate
            );
            setEnterpriseIndividualIncomeRate(
              enterprise_individual_income_tax_refund_rate
            );
            setEnterprisePropertyRate(enterprise_property_tax_refund_rate);
          } else {
            message.error(enterpriseRate.error.message);
          }
        });
        let cmd_data2 = {
          id: eip_investment_agency_tax_refund_rate_id,
        };

        //处理招商点
        paasql(
          "eip.eip_investment_agency_tax_refund_rate.query_by_id",
          cmd_data2
        ).then((investmentRate) => {
          if (investmentRate.error == null) {
            let result = investmentRate.result;
            const {
              agency_corporate_income_tax_refund_rate,
              agency_value_added_tax_refund_rate,
              agency_individual_income_tax_refund_rate,
              agency_property_tax_refund_rate,
            } = result;
            setAgencyCorporateIncomeRate(
              agency_corporate_income_tax_refund_rate
            );
            setAgencyValueAddedRate(agency_value_added_tax_refund_rate);
            setAgencyIndividualIncomeRate(
              agency_individual_income_tax_refund_rate
            );
            setAgencyPropertyRate(agency_property_tax_refund_rate);
          } else {
            message.error(investmentRate.error.message);
          }
        });
      } else {
        message.error(data.error.message);
      }
    });
  }, []);
  const changeEnterpriseValueAddedRate = (value) => {
    setEnterpriseValueAddedRate(value);
  };
  const changeEnterpriseCorporateIncomeRate = (value) => {
    setEnterpriseCorporateIncomeRate(value);
  };
  const changeAgencyValueAddedRate = (value) => {
    setAgencyValueAddedRate(value);
  };
  const changeAgencyCorporateIncomeRate = (value) => {
    setAgencyCorporateIncomeRate(value);
  };

  const changeEnterpriseIndividualIncomeRate = (value) => {
    setEnterpriseIndividualIncomeRate(value);
  };

  const changeEnterprisePropertyRate = (value) => {
    setEnterprisePropertyRate(value);
  };

  const changeAgencyIndividualIncomeRate = (value) => {
    setAgencyIndividualIncomeRate(value);
  };

  const changeAgencyPropertyRate = (value) => {
    setAgencyPropertyRate(value);
  };
  const showList = [
    {
      label: "增值税(企)",
      value: enterpriseValueAddedRate,
      action: changeEnterpriseValueAddedRate,
    },
    {
      label: "企业所得税(企)",
      value: enterpriseCorporateIncomeRate,
      action: changeEnterpriseCorporateIncomeRate,
    },
    {
      label: "所得税(企)",
      value: enterpriseIndividualIncomeRate,
      action: changeEnterpriseIndividualIncomeRate,
    },
    {
      label: "房产税(企)",
      value: enterprisePropertyRate,
      action: changeEnterprisePropertyRate,
    },
    {
      label: "增值税(招)",
      value: agencyValueAddedRate,
      action: changeAgencyValueAddedRate,
    },
    {
      label: "企业所得税(招)",
      value: agencyCorporateIncomeRate,
      action: changeAgencyCorporateIncomeRate,
    },
    {
      label: "所得税(招)",
      value: agencyIndividualIncomeRate,
      action: changeAgencyIndividualIncomeRate,
    },
    {
      label: "房产税(招)",
      value: agencyPropertyRate,
      action: changeAgencyPropertyRate,
    },
  ];

  const cancelEdit = () => {
    hideRateInfoModal();
  };
  const saveEdit = () => {
    const cmd_data1 = {
      object: {
        eip_enterprise_id: enterpriseId,
        enterprise_corporate_income_tax_refund_rate: enterpriseCorporateIncomeRate,
        enterprise_value_added_tax_refund_rate: enterpriseValueAddedRate,
        enterprise_individual_income_tax_refund_rate: enterpriseIndividualIncomeRate,
        enterprise_property_tax_refund_rate: enterprisePropertyRate,
      },
    };
    paasql("eip.eip_enterprise_tax_refund_rate.create", cmd_data1).then(
      (enterpriseRate) => {
        if (enterpriseRate.error == null) {
          const enterpriseRateid = enterpriseRate.result.id;
          const cmd_data2 = {
            object: {
              eip_enterprise_id: enterpriseId,
              agency_value_added_tax_refund_rate: agencyValueAddedRate,
              agency_corporate_income_tax_refund_rate: agencyCorporateIncomeRate,
              agency_individual_income_tax_refund_rate: agencyIndividualIncomeRate,
              agency_property_tax_refund_rate: agencyPropertyRate,
            },
          };
          paasql(
            "eip.eip_investment_agency_tax_refund_rate.create",
            cmd_data2
          ).then((investmentRate) => {
            if (investmentRate.error == null) {
              const investmentRateid = investmentRate.result.id;
              const cmd_data3 = {
                id: enterpriseId,
                eip_investment_agency_tax_refund_rate_id: investmentRateid,
                eip_enterprise_tax_refund_rate_id: enterpriseRateid,
              };
              paasql(
                "eip.eip_enterprise.update_tax_refund_rate_id",
                cmd_data3
              ).then((data) => {
                if (data.error == null) {
                  message.success("保存成功");
                  hideRateInfoModal();
                } else {
                  message.error(data.error.message);
                }
              });
            } else {
              message.error(investmentRate.error.message);
            }
          });
        } else {
          message.error(enterpriseRate.error.message);
        }
      }
    );
    hideRateInfoModal();
  };
  return (
    <div>
      {showList.map((item) => {
        return (
          <Row className="edit-rate-info-item">
            <Col className="edit-rate-info-label">{`${item.label}:`}</Col>
            <Col className="edit-rate-info-input">
              <InputNumber
                style={{ width: "100%" }}
                suffix="%"
                min={0}
                max={100}
                value={item.value}
                onChange={item.action}
              />
            </Col>
            <Col className="edit-rate-info-suffix">%</Col>
          </Row>
        );
      })}
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Space>
          <Button onClick={cancelEdit}>取消</Button>
          <Button type="primary" onClick={saveEdit}>
            保存
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default EditTaxRefundRate;
