import React, { useState, useEffect, memo } from 'react';

import { Button, Select, Row, Col, Radio, DatePicker, message, Input, Space } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import moment from 'moment'

import { paasql } from '@api'

const { Option } = Select;

const { RangePicker } = DatePicker
const enterpriseSourceList = [
  {
    id: '0',
    name: '园区注册'
  }, {
    id: '1',
    name: '迁入'
  }, {
    id: '2',
    name: '迁入(区外)'
  }
]
const FilterData = (props) => {
  const { filterData, hideModal, clearCondition } = props
  const [enterpriseInfoEmpty, setEnterpriseInfoEmpty] = useState([])
  const [enterpriseInfo, setEnterpriseInfo] = useState([])



  const [settledStatusList, setSettledStatusList] = useState([])
  const [formerRegisteredAtList, setFormerRegisteredAtList] = useState([])

  const [registeredGenreList, setRegisteredGenreList] = useState([])
  const [recordingGenreList, setRecordingGenreList] = useState([])
  const [taxStatusList, setTaxStatusList] = useState([])
  const [licenseStatusList, setLicenseStatusList] = useState([])
  const [enterpriseGenreList, setEnterpriseGenreList] = useState([])

  const [settledByList, setSettledByList] = useState([])
  const [cpStaffList, setCpStaffList] = useState([])
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])
  const [investmentAgencyStaffList, setInvestmentAgencyStaffList] = useState([])
  const [nowShowStaffList, setNowShowStaffList] = useState([])
  const [staffList, setStaffList] = useState([])

  const [nowIsCpStaff, setNowIsCpStaff] = useState(true)
  const [filterConditionObj, setFilterConditionObj] = useState({})

  //查看是否为招商部门是否为园区
  const [isPark, setIsPark] = useState(false)

  const [settledByInfo, setSettledByInfo] = useState(null)
  //招商部门

  const [establishedIn, setEstablishedIn] = useState([])
  const [settledAt, setSettledAt] = useState([])
  //员工的处理
  const [staff, setStaff] = useState(undefined)
  const [zhaoshangbumen, setzhaoshangbumen] = useState(undefined)

  useEffect(() => {
    getConfig()
    getStandardDataList()
    getStaffList()
  }, [])



  const getStaffList = () => {
    paasql('eip.eip_enterprise.get_eip_park_cp_staff_eip_investment_agency_eip_investment_agency_staff.list').then(data => {
      const result = data.result.parallel_list_object
      const [eip_park, cp_staff_list,
        investment_agency_list, investment_agency_staff_list
      ] = result
      const cpStaffList = cp_staff_list.cp_staff_name

      const investmentAgencyList = investment_agency_list.eip_investment_agency_name
      const investmentAgencyStaffList = investment_agency_staff_list.eip_investment_agency_staff_name
      setCpStaffList(cpStaffList)
      setInvestmentAgencyList(investmentAgencyList)
      setInvestmentAgencyStaffList(investmentAgencyStaffList)
    })
  }

  //筛选页面的处理


  const getStandardDataList = () => {
    paasql('eip.eip_enterprise_info.cp_standard_list').then(data => {
      if (data.error == null) {
        const result = data.result.parallel_list_object
        const [
          former_registered_at,
          settled_status, tax_status,
          registered_genre,
          settled_by,
          license_status,
          enterprise_genre,
          recording_genre
        ] = result

        //迁入前地址
        const former_registered_at_list = former_registered_at.former_registered_at[0].result//下拉列表配置
        setFormerRegisteredAtList(former_registered_at_list)

        //企业情况
        const settled_status_list = settled_status.settled_status[0].result//下拉列表配置
        setSettledStatusList(settled_status_list)

        //税务状态
        const tax_status_list = tax_status.tax_status[0].result//下拉列表配置
        setTaxStatusList(tax_status_list)

        // 内外资
        const registered_genre_list = registered_genre.registered_genre[0].result//下拉列表配置
        setRegisteredGenreList(registered_genre_list)

        //招商类别
        const settled_by_list = settled_by.settled_by[0].result
        setSettledByList(settled_by_list)

        //工商状态

        const license_status_list = license_status.license_status[0].result//下拉列表配置
        setLicenseStatusList(license_status_list)

        //企业类型
        const enterprise_genre_list = enterprise_genre.enterprise_genre[0].result
        setEnterpriseGenreList(enterprise_genre_list)


        //注册类型
        const recording_genre_list = recording_genre.recording_genre[0].result
        setRecordingGenreList(recording_genre_list)
        //需要加入中介的姓名
      } else {
        message.error(data.error.message)
      }
    })
  }
  const getConfig = () => {
    paasql('eip.eip_enterprise.filter_condition_config').then(data => {
      const enterpriseInfo = data.result.body.fields
      for (let i = 0; i < enterpriseInfo.length; i++) {
        if (enterpriseInfo[i].value_type == 'date') {//这里处理的是日期范围的数据
          enterpriseInfo[i].dateType = 1
        }
        enterpriseInfo[i].attribute = enterpriseInfo[i].field//这里全局添加字段名
      }
      setEnterpriseInfo(enterpriseInfo)
      setEnterpriseInfoEmpty(enterpriseInfo)
      return enterpriseInfo
    })

  }
  const confirmFilter = () => {
    const enterprise_attribute_filter = []
    for (const key in filterConditionObj) {
      let obj = new Object()

      if (filterConditionObj[key] == undefined) {

      } else {
        if (key === "established_in"
          || key === "settled_at") {
          obj.attribute = key
          obj.operator = '<>'
          obj.value = [...filterConditionObj[key]]
        } else {
          obj.attribute = key
          obj.operator = 'contain'
          obj.value = [filterConditionObj[key]]
        }
        enterprise_attribute_filter.push(obj)
      }
      //处理日期

    }
    filterData(enterprise_attribute_filter)
  }




  //改变名字
  const changeName = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      name: value
    })
  }

  //更改uscc
  const changeUscc = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      uscc: value
    })
  }

  //更改税管码
  const changeTaxFileNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      tax_file_number: value
    })
  }


  //更改档案号
  const changeFileNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      file_number: value
    })
  }
  //更改税号
  const changeTaxpayerRegistrationNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      taxpayer_registration_number: value
    })
  }

  //更改法人代表
  const changeLegalRepresentative = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      legal_representative: value
    })
  }

  //更改成立日期
  const changeEstablishedIn = (dates, dateStrings) => {
    setEstablishedIn(dates)

    const [start, end] = dateStrings
    const startTime = start.replace(/-/g, '')
    const endTime = end.replace(/-/g, '')
    const valueArr = [startTime, endTime]

    setFilterConditionObj({
      ...filterConditionObj,
      established_in: valueArr
    })
  }
  //更改入园时间
  const changeSettledAt = (dates, dateStrings) => {

    setSettledAt(dates)

    const [start, end] = dateStrings
    const startTime = start.replace(/-/g, '')
    const endTime = end.replace(/-/g, '')
    const valueArr = [startTime, endTime]

    setFilterConditionObj({
      ...filterConditionObj,
      settled_at: valueArr
    })

  }


  //更改注册地址
  const changeRegisteredAddress = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      registered_address: value
    })
  }
  //税务状态改变
  const taxStatusChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      tax_status: value
    })
  }
  // 注册类型改变
  const registeredGenreChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      registered_genre: value
    })

  }



  //企业情况改变
  const settledStatusChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      settled_status: value
    })
  }

  //更改企业来源
  const changeEnterpriseSource = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      enterprise_source: value
    })
  }



  //更改工商状态
  const changeLicenseStatus = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      license_status: value
    })
  }

  //更改企业类型
  const changeEnterpriseGenre = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      enterprise_genre: value
    })
  }


  //更改注册类型
  const changeRecordingGenre = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      recording_genre: value
    })
  }


  //更改招商部门
  const benbu = [{
    id: ' benbu',
    name: '本部'
  }]
  const changeZhaoShangBumen = (v, option) => {
    const { key, value } = option
    var settled_by = null

    setzhaoshangbumen(value)
    setStaff(undefined)
    if (value === '本部') {
      const index = settledByList.findIndex(item => item.name === '本部')
      settled_by = settledByList[index].id
      setStaffList(cpStaffList)
      setNowIsCpStaff(true)
      setFilterConditionObj({
        ...filterConditionObj,
        settled_by,
        eip_investment_agency_id: undefined,
        cp_staff_id: undefined,
        eip_investment_agency_staff_id: undefined
      })
      return
    } else {
      //设置筛选条件

      const index = settledByList.findIndex(item => item.name === '中介')
      settled_by = settledByList[index].id
      const tmp_list = investmentAgencyStaffList.filter(
        item => item.eip_investment_agency_id == key
      )
      setNowIsCpStaff(false)
      setStaffList(tmp_list)

      //清空选中的数据
      setFilterConditionObj({
        ...filterConditionObj,
        settled_by,
        eip_investment_agency_id: key,
        cp_staff_id: undefined,
        eip_investment_agency_staff_id: undefined
      })
      //处理招商员的下拉列表
    }

  }

  const changeStaff = (v, option) => {
    const { key, value } = option
    //判断是中介还是园区工作人员
    setStaff(value)
    if (nowIsCpStaff) {
      setFilterConditionObj({
        ...filterConditionObj,
        cp_staff_id: key,
        eip_investment_agency_staff_id: undefined
      })
      return
    } else {
      setFilterConditionObj({
        ...filterConditionObj,
        cp_staff_id: undefined,
        eip_investment_agency_staff_id: key
      })
    }
  }
  const filterList = [
    {
      id: 'name',
      span: 12,
      field: 'name',
      label: '企业名称',
      component: <Input
        placeholder={'企业名称'}
        value={filterConditionObj.name}
        onChange={changeName}
      />
    },
    {
      id: 'uscc',
      field: 'uscc',
      span: 12,
      label: '统一信用代码',
      component: <Input
        placeholder={'统一信用代码'}

        value={filterConditionObj.uscc}
        onChange={changeUscc}
      />
    }, {
      id: 'tax_file_number',
      field: 'tax_file_number',
      label: '税管码',
      span: 12,
      component: <Input
        placeholder={'税管码'}
        value={filterConditionObj.tax_file_number}
        onChange={changeTaxFileNumber}
      />
    }, {
      id: 'file_number',
      field: 'file_number',
      label: '档案号',
      span: 12,
      component: <Input
        placeholder={'档案号'}
        value={filterConditionObj.file_number}
        onChange={changeFileNumber}
      />
    }, {
      id: 'zhaoshangbumen',
      field: 'zhaoshangbumen',
      label: '招商部门',
      span: 6,
      component:
        <Select
          placeholder={'招商部门'}
          value={zhaoshangbumen}
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          showSearch
          onChange={changeZhaoShangBumen}
        >
          {
            (benbu.concat(investmentAgencyList)).map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: '',
      field: 'zhaoshangyuan',
      label: '招商员',
      span: 6,
      component:
        <Select
          value={staff}
          placeholder='全部'
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          onChange={changeStaff}
        >
          {
            staffList.map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: 'enterprise_source',
      field: 'enterprise_source',
      label: '企业来源',
      span: 6,
      component: <Select
        placeholder="企业来源"
        value={filterConditionObj.enterprise_source}
        suffixIcon={<CaretDownOutlined />}
        style={{ width: '100%' }}
        onChange={changeEnterpriseSource}
      >
        {
          enterpriseSourceList.map(item => {
            return (
              <Option key={item.id} value={item.name} >
                {item.name}
              </Option>
            )
          })
        }
      </Select>
    }, {
      id: 'settled_status',
      field: 'settled_status',
      label: '企业情况',
      span: 6,
      component: <Select
        placeholder={'企业情况'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.settled_status}
        onChange={settledStatusChange}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          settledStatusList.map(item => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
      </Select>
    }, {
      id: 'enterprise_genre',
      field: 'enterprise_genre',
      label: '公司类型',
      span: 12,
      component:
        <Select
          placeholder={'公司类型'}
          suffixIcon={<CaretDownOutlined />}
          value={filterConditionObj.enterprise_genre}
          style={{ width: '100%', fontSize: '14px' }}
          onChange={changeEnterpriseGenre}
        >
          {
            enterpriseGenreList.map(item => {
              return (
                <Option key={item.id} value={item.name}>{item.name}</Option>
              )
            })}
        </Select>
    }, {
      id: 'established_in',
      field: 'established_in',
      label: '成立日期',
      span: 12,
      component: <RangePicker
        value={
          establishedIn === [] ? [] :
            establishedIn
        }
        onChange={changeEstablishedIn}
      />
    }, {
      id: 'recording_genre',
      field: 'recording_genre',
      label: '注册类型',
      span: 6,
      component: <Select
        placeholder={'注册类型'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.recording_genre}
        onChange={changeRecordingGenre}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          recordingGenreList.map(item => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
      </Select>
    }, {
      id: 'license_status',
      field: 'license_status',
      label: '工商状态',
      span: 6,
      //licenseStatusList
      component: <Select
        placeholder={'工商状态'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.license_status}
        onChange={changeLicenseStatus}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          licenseStatusList.map(item => {
            return (
              <Option key={item.id} value={item.name}>{item.name}</Option>
            )
          })}
      </Select>
    }, {
      id: 'settled_at',
      field: 'settled_at',
      label: '入园时间',
      span: 12,
      component: <DatePicker
        value={filterConditionObj.settled_at == null ? undefined :
          filterConditionObj.settled_at}
        onChange={changeSettledAt}
      />
    }, {
      id: 'taxpayer_registration_number',
      field: 'taxpayer_registration_number',
      label: '税号',
      span: 12,
      component: <Input
        placeholder={'税号'}
        value={filterConditionObj.taxpayer_registration_number}
        onChange={changeTaxpayerRegistrationNumber}
      />
    }, {
      id: 'legal_representative',
      field: 'legal_representative',
      label: '法人代表',
      span: 6,
      component: <Input
        placeholder={'法人代表'}
        value={filterConditionObj.legal_representative}
        onChange={changeLegalRepresentative}
      />
    }, {
      id: 'tax_status',
      field: 'tax_status',
      label: '税务状态',
      span: 6,
      component: <Select
        placeholder={'税务状况'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.tax_status}
        onChange={taxStatusChange}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          taxStatusList.map(item => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
      </Select>
    }, {
      id: 'registered_address',
      field: 'registered_address',
      label: '注册地址',
      span: 12,
      component: <Input
        placeholder={'注册地址'}
        value={filterConditionObj.registered_address}
        onChange={changeRegisteredAddress} />
    }, {
      id: 'registered_genre',
      field: 'registered_genre',
      label: '内外资区分',
      span: 12,
      component: <Select
        placeholder={'内外资区分'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.registered_genre}
        onChange={registeredGenreChange}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          registeredGenreList.map(item => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
      </Select>
    }
  ]








  const handleClearCondition = () => {
    //这是清空页面的显示
    setFilterConditionObj({})
    setSettledAt([])
    setEstablishedIn([])
    //招商部门清空数据
    setzhaoshangbumen(undefined)
    setStaff(undefined)
  }

  return (
    <div>
      <div>
        <Row className="enterprise-filter-container" >
          {
            filterList.map(item => {
              return (
                <Col key={item.field} span={item.span} className='filter-condition-item-group'>
                  <Row className='filter-condition-item'>
                    <Col className='filter-condition-label'>
                      {`${item.label}：`}
                    </Col>
                    <Col className='filter-condition-value'>
                      {
                        item.field === 'established_in' ?
                          <RangePicker
                            size='small'
                            value={
                              establishedIn === [] ? [] :
                                establishedIn
                            }
                            onChange={changeEstablishedIn}
                          />
                          :
                          item.field == 'settled_at' ?
                            <RangePicker
                              size='small'
                              value={
                                settledAt === [] ? [] :
                                  settledAt
                              }
                              onChange={changeSettledAt}
                            /> :
                            item.component
                      }
                    </Col>
                  </Row>
                </Col>
              )
            })
          }
        </Row>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <div>
          <Button onClick={handleClearCondition}>
            清空筛选条件
          </Button>
        </div>
        <div>
          <Space>
            <Button
              onClick={hideModal}
            >
              取消
          </Button>
            <Button
              type='primary'
              onClick={confirmFilter}
            >
              确认
          </Button>
          </Space>
        </div>


      </div>
    </div>
  );
}

export default memo(FilterData);