import React, { useState, useEffect, createContext } from 'react';
import { Modal, Steps, Space, Button, message } from 'antd'
import {
  readExcelHeader, enterpriseRefundRateUpdate, postGpi,
  importManagementFee
} from '@api'
import UploadFile from './UploadFile'
import SelectHeader from './SelectHeader'
import FieldMapping from './FieldMapping'
import FieldPurpose from './FieldPurpose'
import ImportResult from './ImportResult'
export const ReachableContext = createContext();

const { Step } = Steps;



const ImportManagementFee = (props) => {
  //配置
  const steps = [
    {
      title: '第1步',
      description: "上传文件",
      content: <UploadFile />
    },
    {
      title: '第2步',
      description: "选择表头",
      content: <SelectHeader />
    },
  ];
  const {
    visible,
    uploadFileModalChange,
    taxRefundId,
    parkId,
    reflushData
  } = props
  const [modalVisable, setModalVisable] = useState(false)
  const [current, setCurrent] = useState(0)//当前页面
  const [importLoading, setImportLoading] = useState(false)
  const [importModalVisable, setImportModalVisable] = useState(false)
  //请求有关的数据

  const [originalRawDataId, setOriginalFileId] = useState(null)
  const [page, setPage] = useState(0)
  //映射字段



  //所有字段的列表
  useEffect(() => {
    setModalVisable(visible)
  }, [visible, uploadFileModalChange])

  //去选择表头页面
  const goSelectHeader = (fileId) => {
    if (fileId == null) {
      message.error('未上传文件，请先上传文件')
      return
    }
    setOriginalFileId(fileId)
    setCurrent(1)
  }

  //从选择表头页回到上传文件页
  const backToUploadFile = () => {
    setCurrent(0)
  }


  //处理选择表头，cpx需要清企业的id和page
  const choseHeader = (request) => {
    const { endLine, page } = request
    setPage(page)
    setImportModalVisable(true)
  }

  //处理回到选择表头页面
  const backToSelectHeader = () => {
    setCurrent(1)
  }


  //隐藏导入的确定模态框
  const hideImportModal = () => {
    setImportModalVisable(false)
  }



  const confirmImport = () => {

    const cmd_data = {
      "eip_park_id": parkId,
      "page": 0,
      "file": originalRawDataId
    }
    setImportLoading(true)
    importManagementFee(cmd_data)
      .then(data => {

        if (data.error == null) {
          message.success('上传成功')
          setImportModalVisable(false)
          setModalVisable(false)
        }
      })
      .finally(() => {
        setImportLoading(false)
      })
  }

  const closeModal = () => {
    setModalVisable(false)
    setCurrent(0)
    reflushData()
  }
  return (
    <>
      <ReachableContext.Provider
        value={{
          parkId,
          goSelectHeader,
          backToUploadFile,
          choseHeader,
          backToSelectHeader,
          originalRawDataId,
        }}>

        <Modal
          title="导入管理费"
          style={{ maxHeight: '800px', overflow: 'auto' }}
          maskClosable={false}
          width={1000}
          centered={true}
          footer={null}
          visible={modalVisable}
          onCancel={closeModal}
        >

          <Steps progressDot current={current}>
            {
              steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} />
              ))
            }
          </Steps>
          <div className="steps-content">
            {
              steps[current].content
            }
          </div>

        </Modal>

        <Modal
          closable={false}
          visible={importModalVisable}
          footer={null}
          title="确认导入管理费"
        >
          <div style={{ textAlign: 'right' }}>
            <Space>
              {
                importLoading ? <></> :
                  <Button onClick={hideImportModal}>
                    取消
              </Button>
              }

              <Button
                type='primary'
                loading={importLoading}
                onClick={confirmImport}
              >
                {
                  importLoading ? "正在上传中..." : '确认上传'
                }
              </Button>
            </Space>

          </div>
        </Modal>
      </ReachableContext.Provider>

    </>
  );
}
export default ImportManagementFee;