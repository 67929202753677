import React, { useState, useEffect } from 'react';
import { Radio, message, Button, Col, Row } from 'antd';


import { ButtonGroupLable, ExportDataIcon } from '@icon'
import TotalTable from './Index/Table'
import TotalTaxChart from './Index/TotalTaxChart'
import MonthlyTaxChart from './Index/MonthlyTaxChart'
import SelectFielsModal from './Index/SelectFieldModal'
import { paasql, gpi } from '@api'
import './index.less'

const ButtonGroup = Button.Group

const TaxManagement = () => {

  const [showType, setShowType] = useState('chart')
  const [parkId, setParkId] = useState(null)

  //年税收情况图表数据项
  const [year, setYear] = useState("2018")
  const [legendData, setLegendData] = useState([])
  const [XData, setXData] = useState([])

  // 年/月税收情况构成图表数据项
  const [yearList, setYearList] = useState([])//年份列表
  const [monthList, setMonthList] = useState([])//月份列表

  const [monthlyAccumulatedTax, setMonthlyAccumulatedTax] = useState([])//总库表列表

  const [defaultYear, setDefaultYear] = useState({})//默认选择年
  const [selectedYear, setSelectedYear] = useState(null)//当前选择年

  const [defaultMonth, setDefaultMonth] = useState("2月")//默认选择月
  const [selectedMonth, setSelectMonth] = useState(null)//当前选择月

  //年税收情况图表数据项和表格的数据项
  const [totalTaxRecord, setTotalRecord] = useState([])
  const [tableData, setTableData] = useState([])

  const [modelVisible, setModelVisible] = useState(false)//模态框的显示值
  //请求数据，初始化进入页面的时候
  useEffect(() => {
    const year = new Date().getFullYear()//获取最新年
    setYear(year)
    getData(year)

  }, [])

  useEffect(() => {
    //处理两件事
    //当前选中的list
    handleNowMonthList(2020)

  }, [monthlyAccumulatedTax])
  //改变显示的形式 表格或者图表
  const showTypeChange = (e) => {
    setShowType(e.target.value)
  }


  //获取纳税汇总表
  const getDataByYear = (cmd_data) => {
    paasql('eip.eip_total_monthly_tax_payment_record.query_by_year', cmd_data).then(data => {
      const result = data.result
      const Ydata = []
      const xData = []
      //按月排序
      const dataWithSort = result.sort((first, last) => {
        return Number(first.statistical_month) - Number(last.statistical_month)
      })

      for (var i = 0; i < dataWithSort.length; i++) {
        const { tax_payable, statistical_month } = dataWithSort[i]
        const time = `${statistical_month}月`

        //设置税收增长情况的页面
        xData.push(time)
        Ydata.push((tax_payable / 10000).toFixed(2))
      }
      //设置表格样式
      setLegendData(Ydata)
      setXData(xData)
      setTotalRecord(dataWithSort)
    })
  }
  const getData = async (year) => {
    paasql('eip.park.list').then(data => {
      setParkId(data.result[0].id)
      const id = data.result[0].id

      //目前先使用2018年作为数据源
      const cmd_data = {
        eip_park_id: id,
        // statistical_year: 'year'
        statistical_year: year
      }

      getDataByYear(cmd_data)
      //获取表格和图表数据

      getTotalYearData(cmd_data)
      getTaxMonthlyList(id)

      setSelectedYear(String(year))
    })
  }
  const getTotalYearData = (cmd_data) => {
    const { eip_park_id, statistical_year } = cmd_data
    const params = {
      "eip_park_id": eip_park_id,
      "eip_business_year": String(statistical_year),
    }
    gpi("query_eip_monthly_total_by_year", params).then(res => {
      const data = res.list[0]

      setTableData(data)
    })
  }
  const getTaxMonthlyList = (id) => {
    const cmd_data = {
      eip_park_id: id
    }
    paasql("eip.eip_monthly_accumulated_tax.list", cmd_data).then(data => {
      if (data.error == null) {
        const MonthlyAccumulatedTax = data.result
        const tmp_list = MonthlyAccumulatedTax.sort((first, last) => {
          return Number(last.ended_at) - Number(first.ended_at)
        })
        //排序

        const yearList = []
        for (let i = 0; i < tmp_list.length; i++) {
          const endTime = String(tmp_list[i].ended_at)
          const year = endTime.slice(0, 4)
          const month = endTime.slice(4, 6)

          yearList.push(year)
          tmp_list[i].year = year
          tmp_list[i].month = month
        }

        //全部的总库表
        setMonthlyAccumulatedTax(tmp_list)


        const yearListWithSort = yearList.filter(function (item, index, arr) {
          return arr.indexOf(item, 0) === index;
        });
        const yearListObjWithSort = []
        for (let i = 0; i < yearListWithSort.length; i++) {
          const value = yearListWithSort[i]
          const yearItemObj = {
            display_name: value,
            value: value
          }
          yearListObjWithSort.push(yearItemObj)
        }

        setYearList(yearListObjWithSort)
      } else {
        message.success(data.error.message)
      }
    })
  }

  //改变年份时的回调函数
  const changeYear = (item) => {
    //还未做=》当切换年份时，处理第二个的echart的数据

    //先清空当前的月份的选中状态
    setSelectMonth(null)
    //清空目前的显示
    const tmpYearList = []
    for (let i = 0; i < yearList.length; i++) {
      var tmpObj = Object.assign(yearList[i])
      tmpObj.display_name = `${tmpObj.value}`
      tmpYearList.push(tmpObj)
    }
    setYearList(tmpYearList)


    handleNowMonthList(item.value)
    setSelectedYear(item.value)
    const cmd_data = {
      eip_park_id: parkId,
      // statistical_year: 'year'
      statistical_year: item.value
    }

    getDataByYear(cmd_data)
    getTotalYearData(cmd_data)
    setYear(item.value)
  }

  // 改变月份时的处理
  const changeMonth = (item) => {
    //需要做两件事：1、如果是已点击的值，取消选中状态 2、如果不是，选中当前项，
    //请求数据，整理数据
    const tmp_item = item === selectedMonth ? null : item
    const tmpYearList = []
    for (let i = 0; i < yearList.length; i++) {
      var tmpObj = Object.assign(yearList[i])
      if (tmpObj.value == selectedYear) {//
        if (tmp_item) {//判断是增加display_name还是恢复
          tmpObj.display_name = `${tmpObj.value}.${item.month}`
        } else {
          tmpObj.display_name = `${tmpObj.value}`
        }
      }
      tmpYearList.push(tmpObj)
    }
    setYearList(tmpYearList)
    setSelectMonth(tmp_item)

    //开始处理数据
    // query_eip_monthly_accumulated_tax_record_by_filter_by_page
    // attached_to_object_id: "8c2d5f7fc4844be4ac0742e3f21088b4"
    // page: 1
    // size: 20
    // attribute_filter: []
    const record = totalTaxRecord
    //拿到当前
    var currentTaxRecord = {}
    for (let i = 0; i < record.length; i++) {
      if (record[i].statistical_month == item.month) {
        currentTaxRecord = record[i]
      }
    }

    setTableData(currentTaxRecord)
  }
  //点击全年时的操作

  const exportExcel = () => {
    //根据年来导出数据，使用模态框
    setModelVisible(!modelVisible)
  }
  const allYear = () => {
    //清除年份的操作
    //设置数据
    setSelectMonth('all')
    const tmpYearList = []

    for (let i = 0; i < yearList.length; i++) {
      var tmpObj = Object.assign(yearList[i])
      if (tmpObj.value == selectedYear) {//
        tmpObj.display_name = `${tmpObj.value}`
      }
      tmpYearList.push(tmpObj)
    }
    const cmd_data = {
      eip_park_id: parkId,
      statistical_year: year
    }
    getTotalYearData(cmd_data)
  }

  const handleNowMonthList = (year) => {

    const sameYearList = []

    for (let i = 0; i < monthlyAccumulatedTax.length; i++) {
      if (monthlyAccumulatedTax[i].year == year) {
        sameYearList.push(monthlyAccumulatedTax[i])
      }
    }
    const nowShowMonthList = []


    const sameYearListWithSort = sameYearList.sort(function (first, last) {
      return Number(first.month) - Number(last.month)
    })

    for (let i = 0; i < sameYearListWithSort.length; i++) {
      nowShowMonthList.push(sameYearListWithSort[i])
    }
    setMonthList(nowShowMonthList)

  }
  return (
    <>
      <div >
        <Row style={{ display: 'flex', height: '100%' }} >
          <Col span={20}>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex' }} >
                {
                  showType == 'chart' ? <></> :

                    <ButtonGroup>

                      <Button size='large'>
                        <ButtonGroupLable>

                        </ButtonGroupLable>
                      </Button>
                      <Button icon={<ExportDataIcon />}  size="large"
                        onClick={exportExcel}
                      >
                        导出数据
                </Button>
                    </ButtonGroup>

                }
                <div style={{ textAlign: 'right' }} className='show-type-btn-group'>
                  <Radio.Group onChange={showTypeChange} defaultValue={showType} size="large">
                    <Radio.Button className='left-button' value="chart">图表</Radio.Button>
                    <Radio.Button className='right-button' value="table">数据</Radio.Button>
                  </Radio.Group>
                </div>
              </div>


              {/* 根据上面的单选框，显示图表或表格 */}

              <div style={{ width: '100%', maxWidth: '1200px', minWidth: '600px', }}>
                {
                  showType == 'chart' ?
                    <div className='echart-container' >
                      <TotalTaxChart
                        XData={XData}
                        year={year}
                        legendData={legendData}
                      >

                      </TotalTaxChart>
                      <MonthlyTaxChart

                        tableData={tableData}
                        year={year}
                        month={selectedMonth}
                        XData={XData}
                        title={"标题"}
                        legendData={legendData}
                      >

                      </MonthlyTaxChart>
                    </div>
                    :

                    <div
                      style={{ paddingTop: '20px', paddingBottom: '20px' }}
                    >
                      <TotalTable
                        className='table'
                        totalRecord={totalTaxRecord}
                      />
                    </div>

                }
              </div>

            </div>

          </Col>


          <Col span={4}>
            <div className="right-selector" style={{}}>
              <div>
                <Radio.Group defaultValue={defaultYear}
                  value={selectedYear}
                  className='year-list-button-group'
                >
                  {
                    yearList.map((item, index) => {
                      return (
                        <Radio.Button

                          className="year-list-button"
                          key={index} onChange={() => changeYear(item)} value={item.value}>{item.display_name}</Radio.Button>
                      )
                    })
                  }

                </Radio.Group>
              </div>
              <div className='right-month-list' >
                <Radio.Group defaultValue={defaultMonth}
                  value={selectedMonth}
                  className='month-list-button-group'
                >
                  <Radio.Button
                    className="month-list-button"
                    value='all'
                    onChange={allYear}
                  >
                    全年
              </Radio.Button>
                  {
                    monthList.map((item, index) => {
                      return (
                        <Radio.Button
                          className="month-list-button"
                          key={index} onClick={() => changeMonth(item)} value={item}>{`${item.month}月`}</Radio.Button>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>



      </div>

      <SelectFielsModal
        totalRecord={totalTaxRecord}
        modelVisible={modelVisible}
      />
    </>
  );
}
export default TaxManagement;