// 合并的表头
export const multiHeader = [

  ['', '', '是否结转上次余留:是/否', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '招商明细', '', '季度税收合计', '增值税', '', '', '', '', '企业所得税', '', '', '', '', '所得税', '', '', '', '', '房产税', '', '', '', '', '本季度扶持金额合计(企)', '加:上次余留(企)', '加:补差金额(企)', '补差备注(企)', '减:管理费(企)', '减:代扣其他(企)', '减:预付金额(企)', '本季应扶持金额(企)', '减:本次余留(企)', '本季待付款金额(企)', '是否支付成功(企)', '支付日期(企)', '实际支付金额(企)', '本季招商点合计扶持金额', '加:招商点上次余留', '加:招商点补差金额', '招商点补差备注', '减:招商点管理费', '减:招商点代扣其他', '减:招商点预付扶持金额', '招商点本季应扶持金额', '减:招商点本次余留', '招商点本季待付款金额', '招商点是否支付成功', '招商点支付日期', '招商点实际支付', '备注']
]

//表头数据部分
export const header = ['', '', '企业名称', '税管码', '', '税额', '扶持比例(企)', '扶持额(企)', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(企)', '扶持额(企)', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(企)', '扶持额(企)', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(企)', '扶持额(企)', '扶持比例(招)', '扶持额(招)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']

export const filterVal = [
  'index',
  'eip_investment_agency_name',
  'name',
  'tax_file_number',
  'tax_payable',
  'total_value_added_tax_in_quarter',
  'enterprise_value_added_tax_refund_rate',
  'enterprise_value_added_tax_refund',
  'agency_value_added_tax_refund_rate',
  'agency_value_added_tax_refund',
  'total_corporate_income_tax_in_quarter',
  'enterprise_corporate_income_tax_refund_rate',
  'enterprise_corporate_income_tax_refund',
  'agency_corporate_income_tax_refund_rate',
  'agency_corporate_income_tax_refund',
  'total_individual_income_tax',
  'enterprise_individual_income_tax_refund_rate',
  'enterprise_individual_income_tax_refund',
  'agency_individual_income_tax_refund_rate',
  'agency_individual_income_tax_refund',
  'total_property_tax',
  'enterprise_property_tax_refund_rate',
  'enterprise_property_tax_refund',
  'agency_property_tax_refund_rate',
  'agency_property_tax_refund',
  'total_enterprise_tax_refund_in_quarter',
  'last_statement_balance',
  'payment_difference',
  'payment_difference_remark',
  'management_fee',
  'payment_on_behalf',
  'prepayment',
  'accounts_payable',
  'current_balance',
  'pending_payment',
  'is_payment_succeeded',
  'paid_at',
  'actual_amount_paid',
  'total_agency_tax_refund_in_quarter',
  'agency_last_statement_balance',
  'agency_payment_difference',
  'agency_payment_difference_remark',
  'agency_management_fee',
  'agency_payment_on_behalf',
  'agency_prepayment',
  'agency_accounts_payable',
  'agency_current_balance',
  'agency_pending_payment',
  'is_agency_payment_succeeded',
  'agency_paid_at',
  'actual_agency_amount_paid',
  'remark'
]


export const merges = ['C1:Z1', 'A3:A4', 'B3:B4', 'C3:D3', 'E3:E4', 'F3:J3', 'K3:O3', 'P3:T3', 'U3:Y3', 'Z3:Z4', 'AA3:AA4', 'AB3:AB4', 'AC3:AC4', 'AD3:AD4', 'AE3:AE4', 'AF3:AF4', 'AG3:AG4', 'AH3:AH4', 'AI3:AI4', 'AJ3:AJ4', 'AK3:AK4', 'AL3:AL4', 'AM3:AM4', 'AN3:AN4', 'AO3:AO4', 'AP3:AP4', 'AQ3:AQ4', 'AR3:AR4', 'AS3:AS4', 'AT3:AT4', 'AU3:AU4', 'AV3:AV4', 'AW3:AW4', 'AX3:AX4', 'AY3:AY4', 'AZ3:AZ4']


// 只有企业部分

export const enterpriseMultiHeader = [
  ['', '', '是否结转上次余留:是/否', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '招商明细', '', '季度税收合计', '增值税', '', '', '企业所得税', '', '', '所得税', '', '', '房产税', '', '', '本季度扶持金额合计(企)', '加:上次余留(企)', '加:补差金额(企)', '补差备注(企)', '减:管理费(企)', '减:代扣其他(企)', '减:预付金额(企)', '本季应扶持金额(企)', '减:本次余留(企)', '本季待付款金额(企)', '是否支付成功(企)', '支付日期(企)', '实际支付金额(企)', '备注']
]
export const enterpriseHeader = ['', '', '企业名称', '税管码', '', '税额', '扶持比例(企)', '扶持额(企)', '税额', '扶持比例(企)', '扶持额(企)', '税额', '扶持比例(企)', '扶持额(企)', '税额', '扶持比例(企)', '扶持额(企)', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
export const enterpriseFilterVal = ['index', 'eip_investment_agency_name', 'name', 'tax_file_number', 'tax_payable', 'total_value_added_tax_in_quarter', 'enterprise_value_added_tax_refund_rate', 'enterprise_value_added_tax_refund', 'total_corporate_income_tax_in_quarter', 'enterprise_corporate_income_tax_refund_rate', 'enterprise_corporate_income_tax_refund', 'total_individual_income_tax', 'enterprise_individual_income_tax_refund_rate', 'enterprise_individual_income_tax_refund', 'total_property_tax', 'enterprise_property_tax_refund_rate', 'enterprise_property_tax_refund', 'total_enterprise_tax_refund_in_quarter', 'last_statement_balance', 'payment_difference', 'payment_difference_remark', 'management_fee', 'payment_on_behalf', 'prepayment', 'accounts_payable', 'current_balance', 'pending_payment', 'is_payment_succeeded', 'paid_at', 'actual_amount_paid', 'remark']
export const enterpriseMerges = ['E1:Z1', 'C2:D2', 'A3:A4', 'B3:B4', 'C3:D3', 'E3:E4', 'F3:H3', 'I3:K3', 'L3:N3', 'O3:Q3', 'R3:R4', 'S3:S4', 'T3:T4', 'U3:U4', 'V3:V4', 'W3:W4', 'X3:X4', 'Y3:Y4', 'Z3:Z4', 'AA3:AA4', 'AB3:AB4', 'AC3:AC4', 'AD3:AD4', 'AE3:AE4']


// 只有中介部分

export  const agencyMultiHeader = [
 
  ['', '', '是否结转上次余留:是/否', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '招商明细', '', '季度税收合计', '增值税', '', '', '企业所得税', '', '', '所得税', '', '', '房产税', '', '', '本季招商点合计扶持金额', '加:招商点上次余留', '加:招商点补差金额', '招商点补差备注', '减:招商点管理费', '减:招商点代扣其他', '减:招商点预付扶持金额', '招商点本季应扶持金额', '减:招商点本次余留', '招商点本季待付款金额', '招商点是否支付成功', '招商点支付日期', '招商点实际支付', '备注']
]
export const agencyHeader = ['', '', '企业名称', '税管码', '', '税额', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(招)', '扶持额(招)', '税额', '扶持比例(招)', '扶持额(招)', '', '', '', '', '', '', '', '', '', '', '', '', '', '']
export const agencyFilterVal = ['index', 'eip_investment_agency_name', 'name', 'tax_file_number', 'tax_payable', 'total_value_added_tax_in_quarter', 'agency_value_added_tax_refund_rate', 'agency_value_added_tax_refund', 'enterprise_corporate_income_tax_refund', 'agency_corporate_income_tax_refund_rate', 'agency_corporate_income_tax_refund', 'total_individual_income_tax', 'agency_individual_income_tax_refund_rate', 'agency_individual_income_tax_refund', 'enterprise_property_tax_refund', 'agency_property_tax_refund_rate', 'agency_property_tax_refund', 'total_agency_tax_refund_in_quarter', 'agency_last_statement_balance', 'agency_payment_difference', 'agency_payment_difference_remark', 'agency_management_fee', 'agency_payment_on_behalf', 'agency_prepayment', 'agency_accounts_payable', 'agency_current_balance', 'agency_pending_payment', 'is_agency_payment_succeeded', 'agency_paid_at', 'actual_agency_amount_paid', 'remark']


export const agencyMerges = ['C1:Z1', 'A3:A4', 'B3:B4', 'C3:D3', 'E3:E4', 'F3:H3', 'I3:K3', 'L3:N3', 'O3:Q3', 'R3:R4', 'S3:S4', 'T3:T4', 'U3:U4', 'V3:V4', 'W3:W4', 'X3:X4', 'Y3:Y4', 'Z3:Z4', 'AA3:AA4', 'AB3:AB4', 'AC3:AC4', 'AD3:AD4', 'AE3:AE4']

