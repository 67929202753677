import axios from 'axios'
import { message } from 'antd'


//使用cp的登陆接口
export const sign_in_with_cp_passport = (domain, id, password) => {
  var cmd_data = {
    "id": 0,
    "method": 'csas.account.sign_in',
    "domain": domain,
    "params": {
      "id": id,
      "token": password,
      "mechanism": "cp_passport"
    }
  }
  return fetch('/bp', {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(cmd_data)
  }).then(data => {
    let ret = data.json();
    return ret
  }).then(result => {
    if (result.error == null) {
      let str_my_identity = JSON.stringify(result.result);

      window.localStorage.setItem('my_identity', str_my_identity);
      // this.set('my_identity', null);
      return result;
    } else {
      //清空缓存
      window.localStorage.setItem('my_identity', null);
      return result;
    }
  })
}


//每次paasql的时候都需要使用session中的信息
export const getSession = () => {
  const MyIdentity = window.localStorage.getItem("my_identity");
  if (MyIdentity != null) {
    const MyIDObject = JSON.parse(MyIdentity);
    const { session, account, permissions, roles, primary_identity } = MyIDObject
    const { domain } = primary_identity
    return {
      session,
      domain,
      account,
      permissions,
      roles,
      primary_identity,

    }
  } else {
    return {
      session: null,
      domain: null,
      account: null,
      permissions: null,
      roles: null,
      identity: null,
      primary_identity:
      {
        domain: null
      }
    }
  }
}


//设置全局的passql接口
export const paasql = (method, params_data) => {
  //拿到session和domain
  const MyIdentity = getSession()
  const { session, primary_identity } = MyIdentity;
  if (MyIdentity == null) {
    // alert('您需要转入登陆页面进行登陆。');
    message.error('[cp-session]您需要转入登陆页面进行登陆.');
  } else {
    var cmd_data = {
      "id": 0,
      "method": method,
      "session": session,
      "domain": primary_identity.domain,
      "params": {
        "define": params_data
      }
    }

    return fetch('/paasql',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(cmd_data)
      }
    ).then(function (response) {
      const res = response.json()
      return res
    })
  }
}


//上传excel文件的接口
export const createObjectWithBinary = (request) => {
  const MyIdentity = getSession()
  const { session, domain } = MyIdentity;
  const { cp_schema_id, cp_object_attribute, file_object } = request
  return fetch("/object/binary/create", {
    headers: {
      "X-CP-SESSION": session,
      "X-CP-SCHEMA-ID": cp_schema_id,
      "X-CP-OBJECT-ATTRIBUTE": cp_object_attribute,
      "X-CP-DOMAIN": domain
    },
    method: 'POST',
    body: file_object // file_object = new FormData.append("file", input.files[0])
  }).then(
    response => response.json() // if the response is a JSON object
      .then(data => {
        //下一步  
        return data
      })
      .catch(error => {
        message.error("Error", error)
      })
  );
}

export const uploadImg = (request) => {
  const MyIdentity = getSession()
  const { session, domain } = MyIdentity;
  const { cp_schema_id, cp_object_attribute, file_object } = request
  return fetch("/object/binary/create", {
    headers: {
      "X-CP-SESSION": session,
      "X-CP-SCHEMA-ID": cp_schema_id,
      "X-CP-OBJECT-ATTRIBUTE": cp_object_attribute,
      "X-CP-DOMAIN": domain
    },
    method: 'POST',
    body: file_object // file_object = new FormData.append("file", input.files[0])
  }).then(
    response => response.json() // if the response is a JSON object
      .then(data => {
        //下一步  
        return data
      })
      .catch(error => {
        message.error("Error", error)
      })
  );
}


//更新上传的文件
export const updateObjectWithBinary = (cmd_data) => {
  const { cp_object_id, cp_object_attribute, cp_schema_id, file_list } = cmd_data
  const MyIdentity = getSession()
  const { session, domain } = MyIdentity;
  return fetch("/object/binary/update", {
    headers: {
      "X-CP-OBJECT-ID": cp_object_id,
      "X-CP-SESSION": session,
      "X-CP-SCHEMA-ID": cp_schema_id,
      "X-CP-OBJECT-ATTRIBUTE": cp_object_attribute,
      "X-CP-DOMAIN": domain
    },
    method: 'POST',
    body: file_list
  }).then(
    response => response.json()  // if the response is a JSON object
  );
}

export const updateUploadImg = (cmd_data) => {
  const { cp_object_id, cp_object_attribute, cp_schema_id, file_list } = cmd_data
  const MyIdentity = getSession()
  const { session, domain } = MyIdentity;
  return fetch("/object/binary/update", {
    headers: {
      "X-CP-OBJECT-ID": cp_object_id,
      "X-CP-SESSION": session,
      "X-CP-SCHEMA-ID": cp_schema_id,
      "X-CP-OBJECT-ATTRIBUTE": cp_object_attribute,
      "X-CP-DOMAIN": domain
    },
    method: 'POST',
    body: file_list
  }).then(
    response => response.json()  // if the response is a JSON object
  );
}









//总库表接口
export const eipMonthlyAccumulatedTax = (cmd_data) => {
  return paasql("eip.eip_monthly_accumulated_tax.list", cmd_data).then(data => {
    if (data.error == null) {
      return {
        list: data.result
      }
    } else {
    }
  })
}


//通过id删除中介退税核算表
export const deleteAgencyEnterprisesTaxRefundStatementById = (request) => {
  return paasql('eip.eip_agency_administered_enterprises_tax_refund_statement_and_record.delete', request).then(data => {
    if (data.error == null) {
      return {
        success: true
      }
    } else {
      message.error(data.error.message)
      return {
        success: false
      }
    }
  })
}

//更新中介核算表的archived 
export const updateAgencyTaxRefundStatementArchived = (request) => {
  return paasql('eip.eip_agency_administered_enterprises_tax_refund_statement.update_archived', request).then(data => {
    if (data.error == null) {
      return {
        success: true
      }
    } else {
      message.error(data.error.message)
      return {
        success: false
      }
    }

  })
}


