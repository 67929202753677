import React, { useState, useEffect } from 'react';
import { Modal, message, Checkbox, Row, Col } from 'antd'
import { paasql } from '@api'
import './selectField.less'
import { moneyFormat } from '@utils/index'
const table_col_header_data = [//表头数据的配置项
  { display_name: "税收期间", width: 150, name: "tax_paid_at", weight: 30 },//这个还未进行处理
  { display_name: "应税销售额(元)", width: 150, name: "taxable_income", weight: 30 },
  { display_name: "税收合计(元)", width: 150, name: "tax_payable", weight: 30 },
  { display_name: "消费税(元)", width: 150, name: "consumption_tax", weight: 30 },
  { display_name: "增值税(元)", width: 150, name: "value_added_tax", weight: 30 },
  { display_name: "营业税(元)", width: 150, name: "business_tax", weight: 50 },
  { display_name: "企业所得税(元)", width: 150, name: "corporate_income_tax", weight: 50 },
  { display_name: "所得税(元)", width: 150, name: "individual_income_tax", weight: 50 },
  { display_name: "城市维护建设税(元)", width: 150, name: "city_maintenance_and_construction_tax", weight: 50 },
  { display_name: "车船使用税(元)", width: 150, name: "vehicle_and_vessel_usage_tax", weight: 50 },
  { display_name: "房产税(元)", width: 150, name: "property_tax", weight: 50 },
  { display_name: "印花税(元)", width: 150, name: "stamp_tax", weight: 50 },
  { display_name: "土地增值税(元)", width: 150, name: "land_appreciation_tax", weight: 50 },
  { display_name: "土地使用税(元)", width: 150, name: "land_use_tax", weight: 50 },
  { display_name: "环保税(元)", width: 150, name: "environmental_tax", weight: 50 },
]


// //导出时加千分位
const keyArr = ['tax_payable', 'taxable_income',
  'consumption_tax', 'value_added_tax', 'business_tax',
  'corporate_income_tax', 'individual_income_tax', 'city_maintenance_and_construction_tax',
  'vehicle_and_vessel_usage_tax', 'property_tax', 'stamp_tax', 'land_appreciation_tax',
  'land_use_tax', 'environmental_tax']
const SelectFielsModal = (props) => {
  const { modelVisible, totalRecord } = props
  const [visiblable, setVisiblable] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)

  const [allCheckList, setAllCheckList] = useState([])
  const [checkAll, setCheckAll] = useState(true)

  const [checkList, setCheckList] = useState([])
  const [options, setOptions] = useState([
    { label: 'Apple', value: 'Apple' },
    { label: 'Pear', value: 'Pear' },
    { label: 'Orange', value: 'Orange' },
  ])

  useEffect(() => {
    setVisiblable(true)
  }, [modelVisible])
  useEffect(() => {
    setVisiblable(false)
    const cmd_data = {

    }
    paasql("eip.eip_total_monthly_tax_payment_record.attrs", cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result.body.fields
        const defaultValue = []
        for (let i = 0; i < result.length; i++) {
          result[i].label = result[i].display_name
          result[i].value = result[i].name
          defaultValue.push(result[i].name)
        }
        setAllCheckList(defaultValue)
        setCheckList(defaultValue)
        setOptions(result)
      } else {
        message.error(data.error.message)
      }
    })
  }, [])
  const exportExcel = () => {

    var table_row_data = totalRecord



    table_row_data.forEach(element => {
      keyArr.forEach(key => {
        element[key] = `$${element[key]}`
      })
    })


    for (let i = 0; i < table_row_data.length; i++) {
      const tax_paid_at = `${table_row_data[i].statistical_year}年${table_row_data[i].statistical_month}月`
      table_row_data[i].tax_paid_at = tax_paid_at
    }


    var tmp_related_data = []
    var header_data = []
    const selected_item = checkList
    for (let i = 0; i < table_col_header_data.length; i++) {
      for (let j = 0; j < selected_item.length; j++) {
        if (table_col_header_data[i].name == selected_item[j]) {
          header_data.push(table_col_header_data[i].display_name)
        }
        else {
        }
      }
    }
    tmp_related_data.push(header_data)
    //表头数据部分
    for (var i = 0; i < table_row_data.length; i++) {
      var single_row_data = []
      for (var j = 0; j < selected_item.length; j++) {
        for (var key in table_row_data[i]) {
          if (selected_item[j] == key) {
            single_row_data.push(table_row_data[i][key])//单行数据
          } else {
          }
        }

      }
      tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，可以导入
    }

    //表的数据部分


    fetch("/cpx/office/excel/export",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              "related_data": tmp_related_data
            }
          }
        })
      })
      .then(res => res.blob())
      .then(blob => {
        // 通过 blob 对象获取对应的 url
        const url = URL.createObjectURL(blob)
        let a = document.createElement('a')

        //这里还可以优化
        a.download = '新建文件.xlsx'
        a.href = url
        document.body.appendChild(a)
        a.click()
        a.remove() // document.body.removeChild(a)
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        console.error(err)
      })
  }


  const checkClick = (checkedValue) => {
    const checkAll = checkedValue.length === allCheckList.length
    setCheckAll(checkAll)
    setCheckList(checkedValue)
  }
  const onCheckAllChange = (e) => {
    const options = e.target.checked ? allCheckList : []
    setCheckAll(e.target.checked)
    setCheckList(options)
  }
  return (
    <>
      <Modal
        title="请选择要导出的列"
        visible={visiblable}
        onOk={exportExcel}
        cancelText="取消"
        okText='确认导出'
        onCancel={() => {
          setVisiblable(false)
        }}
      >
        <Checkbox.Group value={checkList} onChange={checkClick}
        >
          <Row>
            {
              options.map(item => {
                return (
                  <Col
                    key={item.value}
                    span={12}
                  >
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                )
              })
            }
          </Row>

        </Checkbox.Group>
        <Checkbox
          style={{ paddingTop: 10 }}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          全选
        </Checkbox>
      </Modal>
    </>
  );
}

export default SelectFielsModal;