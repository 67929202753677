import React, { useEffect, useState } from "react";

import echarts, { disConnect } from "echarts";

import { parkYearCompletionAmount, paasql } from "@api";

function YearAmount() {
  const [plan, setPlan] = useState(0);
  const year = new Date().getFullYear();

  const [currentMonth, setCurrentMonth] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  useEffect(() => {
    //获取纳税计划
    const cmd_data = {
      id: "2b9312bccbf94297a5172f3277ac8fa8",
    };
    paasql("eip.eip_park.query_yuan_and_range", cmd_data).then((data) => {
      const { yearly_tax_plan_in_yuan } = data.result;
      setPlan(yearly_tax_plan_in_yuan);
    });

    parkYearCompletionAmount().then((res) => {
      const result = res.result;

      const data = [];

      var indexOfMax = 0;
      // var max = result.reduce((a, c, i) => c.tax_payable > a ? (indexOfMax = i, c.tax_payable) : a, 0)
      const max = Math.max.apply(
        Math,
        result.map(function (item) {
          return item.tax_payable;
        })
      );

      const index = result.findIndex((item) => item.tax_payable == max);

      const currentMonth = result[index].statistical_month;
      setCurrentMonth(currentMonth);
      const totalTax = result[index].tax_payable;
      setTotalTax(totalTax);
      result.forEach((element) => {
        data.push(element.tax_payable);
      });

      data.length = index + 1;
      const option = {
        title: {
          text: "年度纳税指标截止各月完成额",
          top: 10,
          left: 20,
          textStyle: {
            color: "#333",
            fontSize: 18,
            fontStyle: "normal",
            align: "left",
            fontWeight: "bolder",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["累计纳税额"],
          selectedMode: "multiple",
          orient: "horizontal",
          x: "right",
          y: 50,
          show: false,
        },
        calculable: true,
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        grid: {
          left: "10%",
          bottom: "30%",
        },
        series: [
          {
            name: "累计纳税额",
            type: "bar",
            data: data,
            barWidth: 20,
          },
        ],
        color: [
          "#ff7f50",
          "#87cefa",
          "#da70d6",
          "#32cd32",
          "#6495ed",
          "#ff69b4",
          "#ba55d3",
          "#cd5c5c",
          "#ffa500",
          "#40e0d0",
          "#1e90ff",
          "#ff6347",
          "#7b68ee",
          "#00fa9a",
          "#ffd700",
          "#6699FF",
          "#ff6666",
          "#3cb371",
          "#b8860b",
          "#30e0e0",
        ],
        grid: {
          x: 100,
        },
      };

      var myEchart = echarts.init(document.getElementById("year-amount"));
      myEchart.setOption(option);
      window.addEventListener("resize", () => {
        myEchart.resize();
      });
    });
  },[]);

  return (
    <div className="year-amount-container">
      <div
        id="year-amount"
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          minHeight: 170,
        }}
      ></div>
      <div className="info">
        <div>{year}年预计纳税总额：{(plan / 10000).toFixed(2)}万元</div>

        <div>
      {year}年截止{currentMonth}月累计纳税总额:{(totalTax / 10000).toFixed(2)}
          万元
        </div>
        <div>纳税目标完成度{((totalTax / plan) * 100).toFixed(2)}%</div>
      </div>
    </div>
  );
}

export default YearAmount;
