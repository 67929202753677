

import React, { useState, useEffect } from 'react';

import {
  Button, List, Input, Select,
  message, Empty, Tabs, Row, Col, Modal
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { paasql } from '@api'
import { CreateIcon, RightOutlinedIcon } from '@icon'
import ButtonLabel from '@component/ButtonLabel'
import AccountDetail from './AccountDetail'
import CreateAccount from './CreateAccount'
import './index.less'
const { TabPane } = Tabs;
const ButtonGroup = Button.Group
const { Option } = Select

const UserManagement = () => {

  const [empty, setEmpty] = useState(false)
  const [activeAccountId, setActiveAccountId] = useState(null)
  const [accountList, setAccountList] = useState([])
  const [accountListAll, setAccountListAll] = useState([])
  const [createAccountVisable, setCreateAccountVisable] = useState(false)
  const [seachWord, setSeachWord] = useState('')
  useEffect(() => {
    paasql("eip.cp_account.list").then(data => {
      if (data.error == null) {
        const accountList = data.result
        if (accountList.length === 0) {
          setEmpty(true)
          return
        }
        var accountListSort = accountList.sort(function (first_attr_item, last_attr_item) {
          return first_attr_item.name.localeCompare(last_attr_item.name, 'zh');
        })
        const id = accountListSort.length == 0 ? undefined : accountListSort[0].id
        setActiveAccountId(id)
        setAccountList(accountListSort)
        setAccountListAll(accountListSort)
      } else {
        message.error(data.error.message, 5)
      }
    })

  }, [])



  const changeListClick = (item, index) => {
    setActiveAccountId(item.id)
    // setIsEdit(false)
    const tmpAccountList = accountList
    for (let i = 0; i < tmpAccountList.length; i++) {
      if (i === index) {
        tmpAccountList[i].isActive = true

      } else {
        tmpAccountList[i].isActive = false
      }
    }
    setAccountList([...tmpAccountList])
  }
  //创建账号
  const createAccount = () => {
    setCreateAccountVisable(true)
  }
  const reflushData = (id) => {
    paasql("eip.cp_account.list").then(data => {
      if (data.error == null) {
        const accountList = data.result
        if (accountList.length === 0) {
          setEmpty(true)
          return
        }
        var accountListSort = accountList.sort(function (first_attr_item, last_attr_item) {
          return first_attr_item.name.localeCompare(last_attr_item.name, 'zh');
        })

        if (id !== undefined) {
          setActiveAccountId(id)
        } else {
          const id = accountListSort.length == 0 ? undefined : accountListSort[0].id
          setActiveAccountId(id)
        }

        setAccountList(accountListSort)
        setAccountListAll(accountListSort)
      } else {
        message.error(data.error.message, 5)
      }
    })
  }

  const hideModal = () => {
    setCreateAccountVisable(false)
  }


  const filterData = (e) => {
    const value = e.target.value
    setSeachWord(value)
    if (value == '') {
      setAccountList(accountListAll)
    }

  }

  const fuzzyQuery = (list, keyWord) => {//查找列表中的名字包含了搜索的关键字的
    var reg = new RegExp(keyWord);
    var arr = [];
    for (var i = 0; i < list.length; i++) {
      if (reg.test(list[i].name)) {
        arr.push(list[i]);
      }
    }
    return arr;
  }

  const seachResult = () => {
    if (seachWord === '') {
      setAccountList(accountListAll)
    } else {
      const seach_result = fuzzyQuery(accountListAll, seachWord)
      setAccountList(seach_result)
    }
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div >

          <ButtonGroup>
            <ButtonLabel
              size='large'
            >

            </ButtonLabel>
            <Button
              size='large'
              icon={<CreateIcon />}
              onClick={createAccount}
            >

              <span
              // style={{paddingLeft: 10 }}
              >
                新增账号
                </span>
            </Button>
          </ButtonGroup>

        </div>
        <div className='right-button-group'>
          <Input placeholder='请输入账号名' onPressEnter={seachResult} className="account-seach-input" onChange={filterData} />
          <Button
            size='large'
            type="primary"
            icon={<SearchOutlined />}
            onClick={seachResult}
          >
            开始搜索
            </Button>
        </div>
      </div>
      <div className='account-management-container'
        style={{
          maxHeight: 'calc(100vh  - 230px)',
          overflow: 'auto',
        }} >
        {
          empty ? <Empty
          /> :
            <Row
            >
              <Col span={4}
                style={{ borderRight: '1px solid #E0E0E0' }}
              >
                <List
                  pagination={{
                    simple: true,
                    pageSize: 15,
                    hideOnSinglePage: true
                  }}
                  className='account-list'
                  dataSource={accountList}
                  renderItem={(item, index) => (
                    <List.Item
                      className={`account-list-item ${activeAccountId === item.id ? 'active-account-item' : ''}`}
                      key={item.id}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                      onClick={() => changeListClick(item, index)}
                    >

                      <span
                      // className={activeAccountId === item.id ? 'active-account-item' : ''}
                      >
                        {item.name}
                      </span>

                      <div>
                        {
                          item.id === activeAccountId ? <>
                            <RightOutlinedIcon />
                          </> : <> </>
                        }
                      </div>

                    </List.Item>
                  )}
                />
              </Col>
              <Col span={20}
                className="account-detail-edit-container"
              >
                <AccountDetail
                  reflushData={reflushData}
                  accountId={activeAccountId}
                />
              </Col>
            </Row>

        }

        <div>

        </div>

      </div>
      <Modal
        title='新增账号'
        onCancel={() => {
          setCreateAccountVisable(false)
        }}
        destroyOnClose={true}
        footer={null}
        visible={createAccountVisable}
      >
        <CreateAccount
          hideModal={hideModal}
          reflushData={reflushData}
        />
      </Modal>
    </div>
  );
}

export default UserManagement;