// 用于显示按钮组的第一个button，无事件处理

import React from 'react';

import { Button } from 'antd'
import { ButtonGroupLable } from '@icon'
const ButtonLabel = (props) => {
  return (
    <Button {...props} >
      <ButtonGroupLable />
    </Button>
  );
}

export default ButtonLabel;