import React, { useEffect, useState } from 'react';
import { Descriptions } from 'antd'
import { paasql } from '@api'
const config = [
  {
    display_name: "法人代表",
    field: "legal_representative"
  }, {
    display_name: "工商状态",
    field: "license_status"
  }, {
    display_name: "成立时间",
    field: "established_in"
  }, {
    display_name: "招商中介",
    field: "eip_investment_agency_name"
  }, {
    display_name: "税务状态",
    field: "tax_status"
  }, {
    display_name: "注册类型",
    field: "registered_genre"
  }, {
    display_name: "入驻状态",
    field: "settled_status_code"
  },
  {
    display_name: "入园时间",
    field: "settled_at"
  },
  {
    display_name: "注册地址",
    field: "registered_address"
  }, {
    display_name: "迁入前地址",
    field: "former_registered_at"
  }
]

const EnterpriseInfoPopup = (props) => {
  const { enterpriseId } = props
  const [info, setInfo] = useState([])
  useEffect(() => {
    if (enterpriseId != null) {
      getData()
    }
  }, [enterpriseId])

  const getData = () => {
    const cmd_data = {
      "eip_enterprise_id": enterpriseId
    }
    paasql("eip.eip_enterprise.enterprise_and_standard_data_by_enterprise_id", cmd_data).then(data => {
      //处理结果
      if (data.result.length == 0) {
        setInfo([])
      } else {
        const result = data.result[0]
        const {
          paasql_parallel
        } = result
        var tax_status = null//code
        var registered_genre = null //code
        var settled_status_code = null//code
        for (var i = 0; i < paasql_parallel.length; i++) {
          for (const key in paasql_parallel[i]) {
            if (key == "tax_status_code") {
              tax_status = paasql_parallel[i][key].name
            }
            if (key == "registered_genre_code") {
              registered_genre = paasql_parallel[i][key].name
            }
            //招商部门
            // if (key === 'settled_code') {

            // }
            if (key == "settled_status_code") {
              settled_status_code = paasql_parallel[i][key].name
            }
          }
        }
        //获取企业的信息，包含标准数据

        var enterprise_info = {
          ...result,
          tax_status,
          registered_genre,
          settled_status_code
        }
        for (let i = 0; i < config.length; i++) {
          for (const key in enterprise_info) {
            if (key == config[i].field) {
              config[i].value = enterprise_info[key]
            }
          }
        }
        setInfo([...config])
      }
    })
  }
  return (
    <>
      <Descriptions
        size='small'
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        style={{ width: 800 }} >
        {
          info.map(item => {
            return (
              <Descriptions.Item
                key={item.field}
                label={item.display_name}>
                {item.value}
              </Descriptions.Item>
            )
          })
        }
      </Descriptions>
    </>
  );
}

export default EnterpriseInfoPopup;