import React, { useState, useEffect } from 'react';
import { Table, Popover } from 'antd'
import { paasql } from '@api'
import { moneyFormat } from '@utils/index'
import EnterpriseInfoPopup from '../ImportData/component/EnterpriseInfoPopup'
const config = [
  {
    display_name: "法人代表",
    field: "legal_representative"
  }, {
    display_name: "工商状态",
    field: "license_status"
  }, {
    display_name: "成立时间",
    field: "established_in"
  }, {
    display_name: "招商中介",
    field: "eip_investment_agency_name"
  }, {
    display_name: "税务状态",
    field: "tax_status"
  }, {
    display_name: "注册类型",
    field: "registered_genre"
  }, {
    display_name: "入驻状态",
    field: "settled_status_code"
  },
  {
    display_name: "入园时间",
    field: "settled_at"
  },
  {
    display_name: "注册地址",
    field: "registered_address"
  }, {
    display_name: "迁入前地址",
    field: "former_registered_at"
  }
]

const taxInfoConfig = [
  { display_name: "消费税(元)", name: "consumption_tax", },
  { display_name: "增值税(元)", name: "value_added_tax" },
  { display_name: "营业税(元)", name: "business_tax" },
  { display_name: "企业所得税(元)", name: "corporate_income_tax" },
  { display_name: "所得税(元)", name: "individual_income_tax" },
  { display_name: "城市维护建设税(元)", name: "city_maintenance_and_construction_tax" },
  { display_name: "车船使用税(元)", name: "vehicle_and_vessel_usage_tax" },
  { display_name: "房产税(元)", name: "property_tax" },
  { display_name: "印花税(元)", name: "stamp_tax" },
  { display_name: "土地增值税(元)", name: "land_appreciation_tax" },
  { display_name: "土地使用税(元)", name: "land_use_tax" },
  { display_name: "环保税(元)", name: "environmental_tax" },
]
const MonthlyAccumulatedTaxRecord = (props) => {
  const { Data, totalPage, loading, pageSize,
    showMoreCondition,
    currentPage, tableShortChange } = props

  const [tableData, setTableData] = useState([])
  const [enterpriseInfo, setEnterpriseInfo] = useState([])
  const [taxInfo, setTaxInfo] = useState([])
  const [enterpriseId, setEnterpriseId] = useState(null)
  useEffect(() => {
    setTableData(Data)
  }, [Data, totalPage])

  const clickEnterprise = (text, record, index) => {
    setEnterpriseId(record.eip_enterprise_id)
  }

  const handleTaxInfo = (text, record, index) => {
    const enterpriseTaxInfo = {
      ...record
    }
    for (let i = 0; i < taxInfoConfig.length; i++) {
      for (const key in enterpriseTaxInfo) {
        if (key == taxInfoConfig[i].name) {
          taxInfoConfig[i].value = enterpriseTaxInfo[key]
        }
      }
    }
    setTaxInfo(taxInfoConfig)
  }
  const columns = [
    {
      title: '序号',
      width: 70,
      align: 'center',
      fixed: 'left',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '企业名称',
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
      width: 200,
      fixed: 'left',
      render: (text, record, index) =>
        text == '合计' ? text :
          <Popover key={index}
            placement='leftTop'
            content={
              <EnterpriseInfoPopup
                enterpriseId={enterpriseId}
                enterpriseInfo={enterpriseInfo}
              />
            }
            title={text}
            trigger="click"
          >
            <a onClick={() => clickEnterprise(text, record, index)}>{text}</a>
          </Popover>,
    }, {
      title: '税管码',
      dataIndex: 'tax_file_number',
      key: 'tax_file_number',
      width: 200,
    },
    {
      title: '应税销售额（元）',
      dataIndex: 'taxable_income',
      key: 'taxable_income',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (
          moneyFormat(text)
        )
      },
      sorter: (a, b) => { }
    }, {
      title: '税收合计',
      dataIndex: 'tax_payable',
      key: 'tax_payable',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { },
    },
    {
      title: '消费税(元)',
      dataIndex: 'consumption_tax',
      key: 'consumption_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '增值税(元)',
      dataIndex: 'value_added_tax',
      key: 'value_added_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '营业税(元)',
      dataIndex: 'business_tax',
      key: 'business_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '企业所得税(元)',
      dataIndex: 'corporate_income_tax',
      key: 'corporate_income_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '所得税(元)',
      dataIndex: 'individual_income_tax',
      key: 'individual_income_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    }, {
      title: '城市维护建设税(元)',
      dataIndex: 'city_maintenance_and_construction_tax',
      key: 'city_maintenance_and_construction_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '车船使用税(元)',
      dataIndex: 'vehicle_and_vessel_usage_tax',
      key: 'vehicle_and_vessel_usage_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
    {
      title: '房产税(元)',
      dataIndex: 'property_tax',
      key: 'property_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    }, {
      title: '印花税(元)',
      dataIndex: 'stamp_tax',
      key: 'stamp_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    }, {
      title: '土地增值税(元)',
      dataIndex: 'land_appreciation_tax',
      key: 'land_appreciation_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    }, {
      title: '土地使用税(元)',
      dataIndex: 'land_use_tax',
      key: 'land_use_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    }, {
      title: '环保税(元)',
      dataIndex: 'environmental_tax',
      key: 'environmental_tax',
      width: '120',
      align: 'right',
      render: (text, record) => {
        return (moneyFormat(text))
      },
      sorter: (a, b) => { }
    },
  ];

  const a = [
    { display_name: "所得税(元)", width: 150, name: "individual_income_tax", weight: 50 },
    { display_name: "城市维护建设税(元)", width: 150, name: "city_maintenance_and_construction_tax", weight: 50 },
    { display_name: "车船使用税(元)", width: 150, name: "vehicle_and_vessel_usage_tax", weight: 50 },
    { display_name: "房产税(元)", width: 150, name: "property_tax", weight: 50 },
    { display_name: "印花税(元)", width: 150, name: "stamp_tax", weight: 50 },
    { display_name: "土地增值税(元)", width: 150, name: "land_appreciation_tax", weight: 50 },
    { display_name: "土地使用税(元)", width: 150, name: "land_use_tax", weight: 50 },
    { display_name: "环保税(元)", width: 150, name: "environmental_tax", weight: 50 },
  ]
  const handleTableChange = (pagination, filters, sorter, extra) => {

    const sorterField = sorter.field
    const sorterKey = sorter.order

    //order有三种状态，
    //ascend,升序
    //descend ,降序
    //undefined ，取消排序  
    //降序
    var shorterCondition = null
    if (sorterKey === "ascend") {
      shorterCondition = `${sorterField} asc`
    } else if (sorterKey === "descend") {
      shorterCondition = `${sorterField} desc`
    } else if (sorterKey === undefined) {
    }
    tableShortChange(shorterCondition)
  }
  return (
    <>
      <div className='seach-data-table-container' style={{ width: '100%' }}>
        <Table
          size='small'
          loading={loading}
          pagination={{
            pageSize: 21,
            total: 21,
            hideOnSinglePage: true,
            defaultCurrent: 1,
          }}
          showSorterTooltip={false}
          onChange={handleTableChange}
          scroll={{ y: `calc(100vh  - ${showMoreCondition ? '454' : '410'}px)`, x: 'calc(2500px)' }}
          columns={columns}
          bordered
          className={"data-seach-table"}
          dataSource={tableData}
          rowKey={record => record.id}
        />
      </div>
    </>
  )
}



export default MonthlyAccumulatedTaxRecord;
