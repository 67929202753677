import React, { useState, useEffect ,memo} from "react";

import { agencyAccumulatedTax } from "@api";
import echarts from "echarts";
function TaxCompare(props) {
  const { parkId, eipInvestmentAgencyId } = props;
  useEffect(() => {
    if (eipInvestmentAgencyId !== null) {
      initChart();
    }
  }, [eipInvestmentAgencyId]);

  function initChart() {
    agencyAccumulatedTax(eipInvestmentAgencyId, parkId).then((res) => {
      const result = res;
      const data = [];
      const max = Math.max.apply(
        Math,
        result.map(function (item) {
          return item.tax_payable;
        })
      );

      const index = result.findIndex((item) => item.tax_payable == max);

      // const currentMonth = result[index].statistical_month;
      // setCurrentMonth(currentMonth);
      const totalTax = result[index].tax_payable;
      // setTotalTax(totalTax);
      result.forEach((element) => {
        data.push(element.tax_payable);
      });

      data.length = index + 1;

      const option = {
        title: {
          text: "园中园截止各月完成额",
          subtext: "元",
          top: 10,
          left: 20,
          textStyle: {
            color: "#333",
            fontSize: 18,
            fontStyle: "normal",
            align: "left",
            fontWeight: "bolder",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["累计纳税额"],
          selectedMode: "multiple",
          orient: "horizontal",
          x: "right",
          y: 50,
          show: false,
        },
        calculable: true,
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        grid: {
          left: "3%",
          bottom: "10%",
        },
        series: [
          {
            name: "累计纳税额",
            type: "bar",
            data: data,
            barWidth: 20,
          },
        ],
        color: [
          "#ff7f50",
          "#87cefa",
          "#da70d6",
          "#32cd32",
          "#6495ed",
          "#ff69b4",
          "#ba55d3",
          "#cd5c5c",
          "#ffa500",
          "#40e0d0",
          "#1e90ff",
          "#ff6347",
          "#7b68ee",
          "#00fa9a",
          "#ffd700",
          "#6699FF",
          "#ff6666",
          "#3cb371",
          "#b8860b",
          "#30e0e0",
        ],
        grid: {
          x: 100,
        },
      };

      const myEchart2 = echarts.init(
        window.document.getElementById("taxCompare")
      );
      window.addEventListener("resize", () => {
        myEchart2.resize();
      });
      if (myEchart2 !== null) {
        myEchart2.setOption(option);
      }
    });
  }
  return (
    <div className="tax-compare-container">
      <div
        id="taxCompare"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
      </div>
    </div>
  );
}

export default memo(TaxCompare);
