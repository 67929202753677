import React, { useState, useEffect } from "react";
import ReactEchart from "echarts-for-react";

import { parkEnterpriseLevelCount } from "@api";
function ParkEnterpriseLevelCount() {
  const [option, setOption] = useState({});
  useEffect(() => {
    parkEnterpriseLevelCount().then((res) => {
      const keydata = JSON.parse(JSON.stringify(res.result));

      const keyArr = [];
      keydata.length = 6;

      keydata.forEach((element) => {
        keyArr.push(element.level);
      });
      let data = res.result;
      const series = [];
      keyArr.forEach((element, index) => {
        const item = [];
        data.forEach((element1) => {
          if (element1.level == element) {
            item.push(element1.amount);
          }
        });

        var barName = "";
        if (index == 0) {
          barName = `${(Number(element.replace(">", "")) / 10000).toFixed(
            0
          )}万以下`;
        } else if (index == keyArr.length - 1) {
          barName = `${(Number(element.replace(">", "")) / 10000).toFixed(
            0
          )}万以上`;
        } else {
          barName = `${(
            Number(keyArr[index - 1].replace(">", "")) / 10000
          ).toFixed(0)}万-${(Number(element.replace(">", "")) / 10000).toFixed(
            0
          )}万`;
        }

        const obj = {
          name: barName,
          type: "bar",
          data: item,
        };
        series.push(obj);
      });
      const option = {
        color: [
          "#F4DF58",
          "#FFB0E3",
          "#58CFFF",
          "#BD2444",
          "#7A79FF",
          "#333FFF",
        ],
        title: {
          text: "企业纳税档位分布",
          subText: "家",
          left: 20,
          top: 10,
          textStyle: {
            color: "#333",
            fontSize: 18,
            fontStyle: "normal",
            align: "left",
            fontWeight: "bolder",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          selectedMode: "multiple",
          orient: "vertical",
          x: "right",
          align: "right",
          y: 20,
          itemWidth: 20,
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: ["第1季度", "第2季度", "第3季度", "第4季度"],
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            //设置轴线
            axisLine: {
              show: false,
              onZero: false,
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
          },
        ],
        series: series,
        grid: {
          x: 70,
          right: 130,
        },
      };
      setOption(option);
    });
  }, []);

  return (
    <ReactEchart
      className="park-enterprise-level-count"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "260px",
      }}
      option={option}
    />
  );
}

export default ParkEnterpriseLevelCount;
