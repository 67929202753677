import React, { useEffect, useState, createRef } from "react";
import E from "wangeditor";
import { Input, Button, Space, message, Upload } from "antd";
import { connect } from "react-redux";
import { uploadImg, getSession } from "@api/paasql";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { paasql } from "@api";
const editorRef = createRef();
const FamousEnterpriseCreate = (props) => {
  const { dispatch } = props;
  const [parkId, setParkId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const [stringLength, setStringLength] = useState(0);
  const [htmlContent, setHtmlContent] = useState(null);
  const [title, setTitle] = useState("");

  //用户头像
  const [file, setFile] = useState(null);
  //封面图
  const [bannerFile, setBannerFile] = useState(null);

  const [confirmUploadShow, setConfirmUploadShow] = useState(false);
  const [imgUrl, setImageUrl] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  useEffect(() => {
    //初始化
    initEditor();
    //处理园区id
    paasql("eip.park.list").then((data) => {
      if (data.error == null) {
        const result = data.result;
        const parkId = result[0].id;
        setParkId(parkId);
      } else {
        message.error(data.error.message);
      }
    });

    const { domain } = getSession();
    const request = {
      eip_cms_space_domain: domain,
      eip_cms_space_name: "明星企业简介",
    };
    //处理spaceid
    paasql("eip.eip_cms_space.list", request)
      .then((data) => {
        if (data.error == null) {
          const result = data.result;
          const spaceId = result[0].id;

          setSpaceId(spaceId);
        } else {
          message.error(data.error.message);
        }
      })
      .finally(() => {
        const navArr = ["招商管理", "新增明星企业"];
        dispatch({
          type: "SET_BREADCRUMB_LIST",
          value: navArr,
        });
      });
  }, []);

  //初始化编辑器
  const initEditor = () => {
    const editor = new E(editorRef.current);
    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      setHtmlContent(html);
    };
    editor.customConfig.menus = [
      "head",
      "bold",
      "italic",
      "underline",
      "justify",
      "image",
    ];
    editor.customConfig.customUploadImg = function (files, insert) {
      //eip_cms_doc_file
      const formData = new FormData();
      files.forEach((file) => {
        // fileList 是要上传的文件数组
        formData.append("file", file);
      });

      const request = {
        cp_schema_id: "eip_cms_doc_file",
        cp_object_attribute: "cp_file_id",
        file_object: formData,
      };
      uploadImg(request).then((data) => {
        if (data.error == null) {
          const fileId = data.result.cp_file_id;
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
          insert(url);
        } else {
          message.error(data.error.message);
        }
      });
    };
    editor.create();
  };
  const changeTitle = (e) => {
    setStringLength(e.target.value.length);
    setTitle(e.target.value);
  };

  const cancelCreate = () => {
    props.history.push("/famous-enterprise");
  };

  function createBannerFile(doc_id) {
    const bannerFormData = new FormData();

    bannerFormData.append("file", bannerFile);
    const request1 = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_object: bannerFormData,
    };

    uploadImg(request1).then((data) => {
      if (data.error == null) {
        const fileId = data.result.cp_file_id;
        const doc_file_id = data.result.id;
        //拿到文件的ID后，再更新doc的id
        const cmd_data = {
          object: {
            id: doc_file_id,
            eip_cms_doc_id: doc_id,
            level: "1",
          },
        };
        paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
          if (data.error == null) {
          } else {
            message.error(data.error.message);
          }
        });
      } else {
        message.error(data.error.message);
      }

      //处理接口
    });
  }

  async function handleUpload(id) {
    if (file == null) {
    } else {
      await createDocFile(id);
    }

    if (bannerFile == null) {
    } else {
      await createBannerFile(id);
    }
  }

  function createDocFile(doc_id) {
    //处理文件
    const formData = new FormData();
    formData.append("file", file);
    const request = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_object: formData,
    };
    uploadImg(request).then((data) => {
      if (data.error == null) {
        const fileId = data.result.cp_file_id;
        const doc_file_id = data.result.id;
        //拿到文件的ID后，再更新doc的id
        const cmd_data = {
          object: {
            id: doc_file_id,
            eip_cms_doc_id: doc_id,
            level: "0",
          },
        };
        paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
          if (data.error == null) {
          } else {
            message.error(data.error.message);
          }
        });
      } else {
        message.error(data.error.message);
      }

      //处理接口
    });
  }
  const createDoc = async () => {
    if (title === "") {
      message.error("标题是必须的");
      return;
    }
    const cmd_data = {
      object: {
        eip_cms_space_id: spaceId,
        eip_park_id: parkId,
        name: title,
        content: htmlContent,
      },
    };

    paasql("eip.eip_cms_doc.create", cmd_data).then((data) => {
      if (data.error == null) {
        message.success("创建明星企业成功");
        const result = data.result;
        const { id } = result;
        //上传文件
        //判断有没有文件

        handleUpload(id);

        props.history.push(`/famous-enterprise/detail/${id}`);

        //这里需要处理封面图的处理
      } else {
        message.error(data.error.message);
      }
    });
  };

  const handleChange = (info) => {
    getBase64(info.file, (imageUrl) => {
      setFile(info.file);
      setImageUrl(imageUrl);
    });
  };
  const handleBeforeUpload = () => {
    setConfirmUploadShow(true);
    return false;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleBannerChange(info) {
    getBase64(info.file, (imageUrl) => {
      setBannerFile(info.file);
      setBannerImageUrl(imageUrl);
    });
  }

  function handleBannerBeforeUpload() {
    setConfirmUploadShow(true);
    return false;
  }
  return (
    <div className="create-police-container">
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button onClick={cancelCreate}>取消编辑</Button>
          <Button type="primary" onClick={createDoc}>
            确认新增
          </Button>
        </Space>
      </div>
      <div>
        <span className="create-police-info-label">编辑标题内容</span>
        <Input
          size="large"
          onChange={changeTitle}
          placeholder={"请输入标题"}
          maxLength={50}
          suffix={`${stringLength}/50`}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <span className="edit-police-info-label">上传企业头像</span>
          <Upload
            onChange={handleChange}
            showUploadList={false}
            listType="picture-card"
            beforeUpload={handleBeforeUpload}
          >
            {imgUrl ? (
              <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              <PlusOutlined />
            )}
          </Upload>
        </div>
        <div>
          <span className="edit-police-info-label">上传企业封面</span>

          <Upload
            onChange={handleBannerChange}
            showUploadList={false}
            listType="picture-card"
            beforeUpload={handleBannerBeforeUpload}
          >
            {bannerImageUrl ? (
              <img
                src={bannerImageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <>
                <PlusOutlined />
              </>
            )}
          </Upload>
        </div>
      </div>
      <div>
        <span className="create-police-info-label">编辑文档内容</span>
        <div className="police-create-eidtor" ref={editorRef} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamousEnterpriseCreate);
