import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom'


import { message } from 'antd'

import Frame from './Frame'
import { getSession } from '@api'

import { appRouter } from './../../router'
const Index = (props) => {
  return (
    <>
      <Frame {...props} >
        <Switch>
          {
            appRouter.map(route => {
              return (
                <Route
                  key={route.pathname}
                  path={route.pathname}
                  exact={route.exact}
                  render={(routeProps) => {
                    document.title = route.title
                    return (
                      <route.component {...routeProps} />
                    )
                  }}
                >
                </Route>
              )
            })
          }
        </Switch>
      </Frame>


    </>
  );
}

export default Index;