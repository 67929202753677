import React, { useState, useEffect } from 'react';

import {
  Button, message, Empty, Modal,
  Row, Col, List, Input
} from 'antd'
import { connect } from 'react-redux'


import { paasql, getCpStaffListAndInvestmentStaffList } from '@api'
import { fuzzyQuery } from '@utils'

import InvestmentAgencyDetail from './InvestmentAgencyDetail'
import ParkStaffDetail from './ParkStaffDetail'
import CreateInvestmentAgency from './CreateInvestmentAgency'
import { RightOutlinedIcon } from '@icon'

import './index.less'
const InvestmentAgency = (props) => {

  // const { dispatch, InvestmentAgencyManagement } = props
  const [parkId, setParkId] = useState(null)
  const [parkName, setParkName] = useState(null)


  const [empty, setEmpty] = useState(false)


  const [investmentAgencyList, setInvestmentAgencyList] = useState([])
  const [investmentAgencyListAll, setInvestmentAgencyListAll] = useState([])

  const [cpStaffList, setCpStaffList] = useState([])
  const [cpStaffListAll, setCpStaffListAll] = useState([])



  const [createInvestmentAgencyVisable, setCreateInvestmentAgencyVisable] = useState(false)
  const [activeId, setActiveId] = useState('park')


  const [isBenbu, setIsBenbu] = useState(true)


  const [filterCondition, setFilterCondition] = useState('')

  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const parkId = data.result[0].id
        setParkId(data.result[0].id)
        setParkName(data.result[0].name)
        const cmd_data = {
          eip_park_id: parkId
        }

        //招商中介信息
        paasql("eip.eip_investment_agency.list", cmd_data).then(data => {
          if (data.error == null) {
            const investmentAgencyList = data.result
            if (investmentAgencyList.length === 0) {
              setEmpty(true)
              return
            }
            var investmentAgencyListSort = investmentAgencyList.sort(function (first_attr_item, last_attr_item) {
              return first_attr_item.name.localeCompare(last_attr_item.name, 'zh');
            })
            // setActiveId(investmentAgencyListSort[0].id)
            setInvestmentAgencyList(investmentAgencyListSort)
            setInvestmentAgencyListAll(investmentAgencyListSort)
          } else {
            message.error(data.error.message, 5)
          }
        })

        //园区工作人员

        getDataByParkId(parkId)
      } else {
        message.error(data.error.message)
      }
    })
  }, [])



  const getDataByParkId = (parkId) => {
    const cmd_data = {
      eip_park_id: parkId
    }

    paasql('eip.cp_staff.list_by_eip_park_id', cmd_data).then(staffList => {
      if (staffList.error == null) {
        const result = staffList.result
        if (result.length == 0) {
        } else {
          setCpStaffList(result)
          setCpStaffListAll(result)
        }
      } else {
        message.error(staffList.error.message)
      }
    })
  }

  //创建中介人员
  const createInvestmentAgency = () => {
    setCreateInvestmentAgencyVisable(true)
  }
  const reflushData = (id) => {
    const cmd_data = {
      eip_park_id: parkId
    }
    paasql("eip.eip_investment_agency.list", cmd_data).then(data => {
      if (data.error == null) {
        const investmentAgencyList = data.result
        if (investmentAgencyList.length === 0) {
          setEmpty(true)
          return
        }
        var investmentAgencyListSort = investmentAgencyList.sort(function (first_attr_item, last_attr_item) {
          return first_attr_item.name.localeCompare(last_attr_item.name, 'zh');
        })
        if (id !== undefined) {
          setIsBenbu(false)
          setActiveId(id)
        } else {
          setIsBenbu(false)
          setActiveId(investmentAgencyListSort[0].id)
        }
        setInvestmentAgencyList(investmentAgencyListSort)
      } else {
        message.error(data.error.message, 5)
      }
    })
  }

  //隐藏模态框
  const hideModal = () => {
    setCreateInvestmentAgencyVisable(false)
  }

  //列表的点击
  const changeListClick = (item, index) => {
    setActiveId(item.id)

    if (item.id == 'park') {
      setIsBenbu(true)
      return
    } else {
      setIsBenbu(false)
    }

  }

  const seachResult = () => {

  }

  const selectSatff = (value, option) => {

    if (value == 'park') {
      setIsBenbu(true)
      return
    }
    setActiveId(value)
  }


  const centralDepartment = [{
    id: 'park',
    name: '本部'
  }]


  const filterData = (e) => {
    //处理查找的逻辑
    const value = e.target.value
    setFilterCondition(value)
  }

  const seachData = () => {
    const value = filterCondition
    if (value == '') {
      setCpStaffList(cpStaffListAll)
      setInvestmentAgencyList(investmentAgencyListAll)
      return
    }
    const cmd_data = {
      name: String(value)
    }
    getCpStaffListAndInvestmentStaffList(cmd_data).then(data => {
      const { agency_list, cp_staff_list } = data.result
      setCpStaffList(cp_staff_list)
      setInvestmentAgencyList(agency_list)
    })
  }

  const onPressEnter = (e) => {
    const value = e.target.value
    if (value == '') {
      setCpStaffList(cpStaffListAll)
      setInvestmentAgencyList(investmentAgencyListAll)
      return
    }
    const cmd_data = {
      name: String(value)
    }

    getCpStaffListAndInvestmentStaffList(cmd_data).then(data => {
      const { agency_list, cp_staff_list } = data.result
      setCpStaffList(cp_staff_list)
      setInvestmentAgencyList(agency_list)
    })
  }

  return (
    <div>
      <div className='investment-agency-container' >
        {
          empty ? <Empty
          /> :
            <Row>
              <Col
                style={{ borderRight: '1px solid #E0E0E0', width: '200px' }}
              >
                <div
                  style={{
                    margin: '20px 20px 10px 20px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Input
                    size='small'
                    placeholder='请输入'
                    onPressEnter={onPressEnter}
                    style={{ width: '100px', borderRadius: '4px' }} onChange={filterData} />
                  <Button
                    style={{
                      backgroundColor: '#EFEFEF',
                      lineHeight: '20px',
                      width: '50px', padding: '5px'
                    }}
                    onClick={seachData}
                  >
                    搜索
                  </Button>
                </div>

                <List
                  pagination={{
                    simple: true,
                    size: 'small',
                    fontSize: '12px',
                    pageSize: 15,
                    responsive: true,
                    hideOnSinglePage: true
                  }}
                  className='investment-list'
                  dataSource={
                    cpStaffList.length === 0 ? investmentAgencyList : centralDepartment.concat(investmentAgencyList)
                  }
                  renderItem={
                    (item, index) => (
                      <List.Item
                        key={item.id}
                        className={`investment-list-item ${activeId === item.id ? 'investment-list-active-item' : ''}`}
                        onClick={() =>
                          changeListClick(item, index)}
                      >
                        <span
                        // className={activeId === item.id ? 'active-staff-item' : ''}
                        >
                          {item.name}
                        </span>
                        <div>
                          {
                            activeId === item.id ? <>
                              <RightOutlinedIcon />
                            </> : <> </>
                          }
                        </div>

                      </List.Item>
                    )}
                />
              </Col>
              <Col
                style={{ width: 'calc(100% - 200px)' }}
                className="park-staff-detail-edit-container"
              >
                {
                  isBenbu ?
                    <ParkStaffDetail
                      cpStaffList={cpStaffList}
                      setIsBenbu={setIsBenbu}
                      centralDepartment={centralDepartment}
                      setCreateInvestmentAgencyVisable={setCreateInvestmentAgencyVisable}
                      parkId={parkId}
                      parkName={parkName}
                      setActiveId={setActiveId}
                      investmentAgencyList={investmentAgencyList}
                      reflushData={reflushData}
                      staffId={activeId}
                    />
                    :
                    <InvestmentAgencyDetail
                      setIsBenbu={setIsBenbu}
                      centralDepartment={centralDepartment}
                      parkId={parkId}
                      setCreateInvestmentAgencyVisable={setCreateInvestmentAgencyVisable}
                      createInvestmentAgency={createInvestmentAgency}
                      setActiveId={setActiveId}
                      investmentAgencyList={investmentAgencyList}
                      reflushData={reflushData}
                      investmentAgencyId={activeId}
                    >
                    </InvestmentAgencyDetail>
                }

              </Col>
            </Row>
        }

        <div>

        </div>

      </div>
      <Modal
        title='新增招商部门'
        onCancel={() => {
          setCreateInvestmentAgencyVisable(false)
        }}
        destroyOnClose={true}
        footer={null}
        visible={createInvestmentAgencyVisable}
      >
        <CreateInvestmentAgency
          hideModal={hideModal}
          parkId={parkId}
          parkName={parkName}
          reflushData={reflushData}
        />
      </Modal>
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    InvestmentAgencyManagement: state.InvestmentAgencyManagement
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvestmentAgency);