import React, { useEffect, memo } from "react";

import { agencyMonthlyTotalTax } from "@api";
import echarts from "echarts";

function TaxCumulative(props) {
  const { parkId, eipInvestmentAgencyId } = props;
  useEffect(() => {
    if (eipInvestmentAgencyId !== null) {
      initChart();
    }
  }, [eipInvestmentAgencyId]);

  function initChart() {
    agencyMonthlyTotalTax(eipInvestmentAgencyId, parkId).then((res) => {
      var data = [];
      if (res !== null) {
        res.forEach((element) => {
          data.push(element.tax_payable);
        });
      }
      const option = {

        title: {
          text: "每月纳税总额对比",
          top: 10,
          left: 20,
          textStyle: {
            color: "#333",
            fontSize: 18,
            fontStyle: "normal",
            align: "left",
            fontWeight: "bolder",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["累计纳税额"],
          selectedMode: "multiple",
          orient: "horizontal",
          x: "right",
          y: 50,
          show: false,
        },
        calculable: true,
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        grid: {
          left: "10%",
          bottom: "30%",
        },
        series: [
          {
            name: "累计纳税额",
            type: "bar",
            data: data,
            barWidth: 20,
          },
        ],
        color: [
          "rgb(65,116,245)",
        ],
        grid: {
          x: 100,
        },
      };

      const myEchart1 = echarts.init(
        window.document.getElementById("TaxCumulative")
      );
      window.addEventListener("resize", () => {
        myEchart1.resize();
      });
      if (myEchart1 !== null) {
        myEchart1.setOption(option);
      }
    });
  }
  return (
    <div className="tax-cumulative-container">
      <div
        id="TaxCumulative"
        style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
    </div>
  );
}

export default memo(TaxCumulative);
