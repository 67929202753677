import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Row, Col, Radio } from "antd";
import { exportExcel as exportExcelApi } from "@api";

import {
  multiHeader,
  merges,
  filterVal,
  header,
  enterpriseMultiHeader,
  enterpriseHeader,
  enterpriseFilterVal,
  enterpriseMerges,
  agencyMultiHeader,
  agencyHeader,
  agencyFilterVal,
  agencyMerges,
} from "./exportConfig";
import { export_json_to_excel } from "@assets/xlsx";
import { formatJson } from "@utils";
import FormItemInput from "antd/lib/form/FormItemInput";
const table_col_header_data = [
  { display_name: "序号", name: "index" },
  { display_name: "招商点", name: "eip_investment_agency_name" },
  { display_name: "企业名称", name: "name" },
  { display_name: "税管码", name: "tax_file_number" },
  { display_name: "税收合计", name: "tax_payable" },
  {
    display_name: "增值税（本季）税额",
    name: "total_value_added_tax_in_quarter",
  },
  {
    display_name: "增值税（本季）扶持比例",
    name: "enterprise_value_added_tax_refund_rate",
  },
  {
    display_name: "增值税（本季）扶持额",
    name: "enterprise_value_added_tax_refund",
  },

  {
    display_name: "企业所得税（本季）税额",
    name: "total_corporate_income_tax_in_quarter",
  },
  {
    display_name: "企业所得税（本季）扶持比例",
    name: "enterprise_corporate_income_tax_refund_rate",
  },
  {
    display_name: "企业所得税（本季）扶持额",
    name: "enterprise_corporate_income_tax_refund",
  },

  { display_name: "所得税（本季）税额", name: "total_individual_income_tax" },
  {
    display_name: "所得税（本季）扶持比例",
    name: "enterprise_individual_income_tax_refund_rate",
  },
  {
    display_name: "所得税（本季）扶持额",
    name: "enterprise_individual_income_tax_refund",
  },

  { display_name: "房产税（本季）税额", name: "total_property_tax" },
  {
    display_name: "房产税（本季）扶持比例",
    name: "enterprise_property_tax_refund_rate",
  },
  {
    display_name: "房产税（本季）扶持额",
    name: "enterprise_property_tax_refund",
  },

  {
    display_name: "增值税（本季）招商点扶持比例",
    width: 200,
    name: "agency_value_added_tax_refund_rate",
  },
  {
    display_name: "增值税（本季）招商点扶持额",
    width: 200,
    name: "agency_value_added_tax_refund",
  },

  {
    display_name: "企业所得税（本季）招商点扶持比例",
    width: 200,
    name: "agency_corporate_income_tax_refund_rate",
  },
  {
    display_name: "企业所得税（本季）招商点扶持额",
    width: 200,
    name: "agency_corporate_income_tax_refund",
  },
  //total_individual_income_tax

  {
    display_name: "所得税（本季）招商点扶持比例",
    width: 200,
    name: "agency_individual_income_tax_refund_rate",
  },
  {
    display_name: "所得税（本季）招商点扶持额",
    width: 200,
    name: "agency_individual_income_tax_refund",
  },

  {
    display_name: "房产税（本季）招商点扶持比例",
    width: 200,
    name: "agency_property_tax_refund_rate",
  },
  {
    display_name: "房产税（本季）招商点扶持额",
    width: 200,
    name: "agency_property_tax_refund",
  },

  {
    display_name: "本季度扶持金额合计",
    name: "total_enterprise_tax_refund_in_quarter",
  },
  { display_name: "加：上次余留", name: "last_statement_balance" },
  { display_name: "是否计算上次余留", name: "is_last_statement_balance_added" },
  { display_name: "加：补差金额", name: "payment_difference" },
  { display_name: "补差备注", name: "payment_difference_remark" },
  { display_name: "减：管理费", name: "management_fee" },
  { display_name: "减：代扣其他", name: "payment_on_behalf" },
  { display_name: "减：预付金额", name: "prepayment" },
  { display_name: "本季应扶持金额", name: "accounts_payable" },
  { display_name: "减：本次余留", name: "current_balance" },
  { display_name: "本季待付款金额", name: "pending_payment" },
  { display_name: "是否支付成功", name: "is_payment_succeeded" },
  { display_name: "支付日期", name: "paid_at" },
  { display_name: "实际支付金额", name: "actual_amount_paid" },
  {
    display_name: "本季招商点合计扶持额",
    name: "total_agency_tax_refund_in_quarter",
  },
  { display_name: "加：招商点上次余留", name: "agency_last_statement_balance" },
  {
    display_name: "招商点是否计算上次余留",
    width: 180,
    name: "is_agency_last_statement_balance_added",
  },
  { display_name: "加：招商点补差金额", name: "agency_payment_difference" },
  { display_name: "招商点补差备注", name: "agency_payment_difference_remark" },
  { display_name: "减：招商点管理费", name: "agency_management_fee" },
  { display_name: "减：招商点代扣其他", name: "agency_payment_on_behalf" },
  { display_name: "减：招商点预付金", name: "agency_prepayment" },
  { display_name: "招商点本季应扶持金额", name: "agency_accounts_payable" },
  { display_name: "减：招商点本次余留", name: "agency_current_balance" },
  { display_name: "招商点本季待付款金额	", name: "agency_pending_payment" },
  { display_name: "招商点是否支付成功", name: "is_agency_payment_succeeded" },
  { display_name: "招商点支付日期", name: "agency_paid_at" },
  { display_name: "招商点实际支付", name: "actual_agency_amount_paid" },
  { display_name: "备注", name: "remark" },
];
const SelectFieldModal = (props) => {
  const { modelVisible, tableData, selectTaxRefundStatement } = props;
  const [visiblable, setVisiblable] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const [allCheckList, setAllCheckList] = useState([]);
  const [checkAll, setCheckAll] = useState(true);

  const [checkList, setCheckList] = useState([]);
  const [options, setOptions] = useState([]);

  const [exportType, setExportType] = useState("all");
  const exportTypes = [
    { label: "所有", value: "all" },
    { label: "企业", value: "enterprise" },
    { label: "中介", value: "agency" },
  ];

  useEffect(() => {
    setVisiblable(true);
  }, [modelVisible]);
  useEffect(() => {
    setVisiblable(false);
    const list = tableData;
    const tmp_check_list = [];
    //处理招商点的处理
    for (var i = 0; i < list.length; i++) {
      tmp_check_list.push(list[i].eip_investment_agency_name);
    } //拿到所有的招商点名字
    var check_list_without_duplication = [];
    for (var i = 0; i < tmp_check_list.length; i++) {
      if (tmp_check_list.indexOf(tmp_check_list[i]) == i) {
        check_list_without_duplication.push(tmp_check_list[i]);
      }
    } //去重
    var check_list = [];
    for (var i = 0; i < check_list_without_duplication.length; i++) {
      const attr_obj = {};
      attr_obj.label = check_list_without_duplication[i];
      attr_obj.checked = true;
      attr_obj.value = check_list_without_duplication[i];
      check_list.push(attr_obj);
    } //组合成选择框的数据结构
    setAllCheckList(check_list_without_duplication);
    setCheckList(check_list_without_duplication);
    setOptions(check_list);
    setCheckAll(true);
  }, [tableData]);

  // 更改导出的类型
  function changeExportType(e) {
    const type = e.target.value;
    setExportType(type);
  }
  const exportExcel = () => {
    var table_row_data = tableData;
    var tmp_related_data = [];

    var selected_item = checkList;
    var header_data = [];
    var header_data_key = []; //这是给表格数据的表头数据的key
    for (var i = 0; i < table_col_header_data.length; i++) {
      header_data.push(table_col_header_data[i].display_name); //这个是表头行导出数据
      header_data_key.push(table_col_header_data[i].name);
    }
    tmp_related_data.push(header_data); //表头数据
    // //表格数据部分
    var index = 1;

    // const item
    const table = [];

    // 显示企业
    table_row_data.forEach((tb, i) => {
      const obj = tb;
      selected_item.forEach((item, j) => {
        if (item == tb.eip_investment_agency_name) {
          table.push(obj);
        }
      });
    });

    // 处理企业的 是和否 和序号
    table.forEach((item, index) => {
      item.index = index + 1;
      for (const key in item) {
        if (
          key === "is_agency_last_statement_balance_added" ||
          key === "is_last_statement_balance_added" ||
          key === "is_payment_succeeded" ||
          key == "is_agency_payment_succeeded"
        ) {
          if (item[key] === "1") {
            item[key] = "是";
          } else {
            item[key] = "否";
          }
        }
      }
    });

    //表的数据部分

    const config = switchConfig(table);

    const { multiHeader, header, filterVal, merges, data, filename } = config;
    const _data = formatJson(filterVal, data);

    export_json_to_excel({
      multiHeader,
      header,
      merges,
      data: _data,
      noBorderNumber: 2,
      filename: filename,
    });
  };

  function switchConfig(table) {
    switch (exportType) {
      case "all": {
        const filename = `${selectTaxRefundStatement.name}中介招入企业扶持资金结算申报表`;
        const _mult = [
          "",
          "",
          `${filename}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        multiHeader.unshift(_mult);
        return {
          multiHeader: multiHeader,
          header: header,
          filterVal: filterVal,
          merges: merges,
          data: table,
          filename: filename,
        };
      }
      case "enterprise": {
        const filename = `${selectTaxRefundStatement.name}中介招入企业扶持资金结算申报表(企业分开)`;
        const _mult = [
          "",
          "",
          "",
          "",
          `${filename}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        enterpriseMultiHeader.unshift(_mult);
        const _data = table.filter(
          (item) => item.total_enterprise_tax_refund_in_quarter != "0"
        );
        _data.forEach((item, index) => {
          item.index = index + 1;
        });
        return {
          multiHeader: enterpriseMultiHeader,
          header: enterpriseHeader,
          filterVal: enterpriseFilterVal,
          merges: enterpriseMerges,
          data: _data,
          filename: filename,
        };
      }
      case "agency": {
        const filename = `${selectTaxRefundStatement.name}中介招入企业扶持资金结算申报表(中介分开)`;
        const _mult = [
          "",
          "",
          `${filename}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        agencyMultiHeader.unshift(_mult);
        return {
          multiHeader: agencyMultiHeader,
          header: agencyHeader,
          filterVal: agencyFilterVal,
          merges: agencyMerges,
          data: table,
          filename: filename,
        };
      }
      default: {
        return {
          multiHeader: [],
          header: [],
          filterVal: [],
          merges: [],
          data: [],
        };
      }
    }
  }
  const checkClick = (checkedValue) => {
    const checkAll = checkedValue.length === allCheckList.length;
    setCheckAll(checkAll);
    setCheckList(checkedValue);
  };
  const onCheckAllChange = (e) => {
    const options = e.target.checked ? allCheckList : [];
    setCheckAll(e.target.checked);
    setCheckList(options);
  };
  return (
    <>
      <Modal
        title="请选择招商点"
        visible={visiblable}
        destroyOnClose={true}
        onOk={exportExcel}
        cancelText="取消"
        okText="确认导出"
        onCancel={() => {
          setVisiblable(false);
        }}
      >
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
          <Checkbox.Group value={checkList} onChange={checkClick}>
            <Row>
              {options.map((item) => {
                return (
                  <Col key={item.value} span={12}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </div>

        <Checkbox
          style={{ paddingTop: 10 }}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          全选
        </Checkbox>

        <div style={{ marginTop: 10, lineHeight: "28px" }}>
          <span style={{ paddingRight: 10 }}>请选择导出表的范围:</span>
          <Radio.Group
            options={exportTypes}
            onChange={changeExportType}
            value={exportType}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectFieldModal;
