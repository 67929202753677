import React, { useEffect, useState } from 'react';
import { Table, Modal, Row, Col, message } from 'antd'
import { paasql } from '@api'
import { moneyFormat } from '@utils/index'
//配置表头
const table_col_header_data = [
  { display_name: "招商点", width: 150, name: "eip_park_name", weight: 20 },
  { display_name: "企业名称", width: 300, name: "name", weight: 20 },
  { display_name: "税务管理码", width: 150, name: "tax_file_number", weight: 30 },
  { display_name: "增值税总税额", width: 150, name: "total_value_added_tax", weight: 40 },
  { display_name: "增值税扶持金额", width: 150, name: "total_value_added_tax_refund", weight: 40 },
  { display_name: "企业所得税总税额", width: 150, name: "total_corporate_income_tax", weight: 50 },
  { display_name: "企业所得税扶持金额", width: 150, name: "total_corporate_income_tax_refund", weight: 50 },
  { display_name: "扶持金额合计", width: 150, name: "total_tax_refund", weight: 50 },
  { display_name: "上季余留", width: 150, name: "last_year_statement_balance", weight: 50 },
  { display_name: "累计补差", width: 150, name: "total_payment_difference", weight: 50 },
  { display_name: "累计管理费", width: 150, name: "total_management_fee", weight: 50 },
  { display_name: "累计代扣", width: 150, name: "total_payment_on_behalf", weight: 50 },
  { display_name: "累计预付款", width: 150, name: "total_prepayment", weight: 50 },
  { display_name: "累计应支付金额", width: 150, name: "total_accounts_payable", weight: 50 },
  { display_name: "累计已支付金额", width: 150, name: "total_actual_amount_paid", weight: 50 },
  { display_name: "所选时段未支付金额", width: 150, name: "unpaid_amount", weight: 50 },
]
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 60,
    fixed: 'left',
    align: 'center',
  }, {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    fixed: 'left',
  }, {
    title: '税号',
    dataIndex: 'tax_file_number',
    key: 'tax_file_number',
    width: 200,
  }, {
    title: '增值税总金额（元）',
    dataIndex: 'total_value_added_tax',
    key: 'total_value_added_tax',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '增值税扶持金额（元）',
    dataIndex: 'total_value_added_tax_refund',
    key: 'total_value_added_tax_refund',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '企业所得税总金额（元）',
    dataIndex: 'total_corporate_income_tax',
    key: 'total_corporate_income_tax',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '企业所得税扶持金额（元）',
    dataIndex: 'total_corporate_income_tax_refund',
    key: 'total_corporate_income_tax_refund',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '扶持金额合计（元）',
    dataIndex: 'total_tax_refund',
    key: 'total_tax_refund',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '上季余留（元）',
    dataIndex: 'last_year_statement_balance',
    key: 'last_year_statement_balance',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  }, {
    title: '累计补差（元）',
    dataIndex: 'total_payment_difference',
    key: 'total_payment_difference',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '累计管理费（元）',
    dataIndex: 'total_management_fee',
    key: 'total_management_fee',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  }, {
    title: '累计代扣（元）',
    dataIndex: 'total_payment_on_behalf',
    key: 'total_payment_on_behalf',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  }, {
    title: '累计预付款（元）',
    dataIndex: 'total_prepayment',
    key: 'total_prepayment',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  }, {
    title: '累计应支付金额（元）',
    dataIndex: 'total_accounts_payable',
    key: 'total_accounts_payable',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
  {
    title: '累计已支付金额（元）',
    dataIndex: 'total_actual_amount_paid',
    key: 'total_actual_amount_paid',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  }, {
    title: '本期未支付金额（元）',
    dataIndex: 'unpaid_amount',
    key: 'unpaid_amount',
    align: 'right',
    render: (text, record) => {
      return moneyFormat(text)
    }
  },
];
const TaxRefundStatisticsTable = (props) => {
  const { tableData, archived, reflushTableData, pageSize, currentPage } = props
  const [data, setData] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [activedId, setActiveId] = useState(null)
  const [modalTitle, setModalTitle] = useState('测试使用')
  const [taxFileNumber, setTaxFileNumber] = useState('')
  useEffect(() => {
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].index = (currentPage - 1) * pageSize + i + 1
      //增加是否支付成功
      tableData[i].is_payment_succeeded_name = tableData[i].is_payment_succeeded == '1' ? '是' : '否'
    }
    setModalVisible(false)
    setData(tableData)
  }, [tableData])

  const handleOk = (info) => {
    const cmd_data = {
      ...info
    }
    //eip.eip_agency_administered_enterprises_tax_refund_statement_record.update

    paasql("eip.eip_agency_administered_enterprises_tax_refund_statement_record.update", cmd_data).then(data => {
      if (data.error == null) {
        message.success("更改成功")
        setModalVisible(false)
        reflushTableData()
      } else {
        message.error(data.error.message)
      }
    })
  }
  const handleCancel = () => {
    setModalVisible(false)
  }

  return (
    <>
      <Table
        size='small'
        columns={columns}
        dataSource={data}
        bordered
        rowKey={
          record => record.id
        }
        pagination={{
          pageSize: 20,
          hideOnSinglePage: true
        }}
        //设置宽度
        scroll={{ x: 'calc(2800px)', y: 'calc(100vh - 500px)' }}
      />
      <Modal
        destroyOnClose={true}
        closable={false}
        title={
          <div >
            <div style={{ fontSize: '14px', textAlign: 'center' }} className='tax-refund-modal-title'>
              {modalTitle}
            </div>
            <div style={{ fontSize: '14px', textAlign: 'center' }} >
              税管码:{taxFileNumber}
            </div>
          </div>
        }
        width={920}
        footer={null}
        visible={modalVisible}
      >
      </Modal>
    </>
  );
}

export default TaxRefundStatisticsTable;