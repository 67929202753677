import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Button, Divider, message, Space, Modal, Row, Col, List } from 'antd'
import { paasql } from '@api'
import { ExclamationCircleOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';


import { BackToIcon, SendListIcon, ArchivedDocIcon } from '@icon'
import actionType from '@actions/actionType'
import moment from 'moment'
const ArchivedPoliceDetail = (props) => {
  const { PoliceManagementReducer, dispatch } = props

  const { alreadyGet, nextPoliceId, previousPoliceId, previousDisabled, nextDisabled } = PoliceManagementReducer
  const policeId = props.match.params.id


  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [time, setTime] = useState('')
  const [parkId, setParkId] = useState(null)
  const [archived, setArchived] = useState(null)
  const [policeList, setPoliceList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [enterpriseList, setEnterpriseList] = useState([])
  useEffect(
    () => {
      const cmd_data = {
        id: policeId
      }
      paasql("eip.eip_cms_doc.query_by_id", cmd_data).then(data => {
        const result = data.result
        //处理

        if (result.length != 0) {
          const { content, name, cp_timestamp, archived, eip_park_id } = result[0]

          //处理面包屑导航
          const navArr = ['在线政策', '已归档政策', name]

          dispatch({
            type: 'SET_BREADCRUMB_LIST',
            value: navArr
          })

          //处理页面显示
          setParkId(eip_park_id)
          setArchived(archived)

          if (archived === 0) {
            message.error('这是未归档的政策')
            props.history.push(`/police-management/implementing/${policeId}`)
            return
          }
          setContent(content)
          setTitle(name)
          //处理日期
          const time = moment(cp_timestamp / 1000).format('YYYY-MM-DD HH:mm')
          setTime(time)
          const request = {
            eip_cms_doc_id: policeId
          }
          paasql('eip.eip_enterprise_o2m_eip_cms_doc.enterprise_list', request).then(enterprise_list => {
            if (enterprise_list.error == null) {
              setEnterpriseList(enterprise_list.result)
            } else {
              message.error(enterprise_list.error.message)
            }
          })
          const cmd_data = {
            id: policeId,
            archived,
            eip_park_id,
            name: "在线政策",
          }
          paasql("eip.eip_cms_doc.list_by_space_id", cmd_data).then(policeList => {
            if (policeList.error == null) {
              const result = policeList.result[0].content

              const policeListWithSort = result.sort(function (first, last) {
                return Number(last.cp_timestamp) - Number(first.cp_timestamp)
              })
              dispatch({
                type: actionType.SET_POLICE_LIST,
                value: policeListWithSort
              })
              dispatch({
                type: actionType.SET_NEXT_POLICEID,
                value: policeId
              })
              setPoliceList(policeListWithSort)
            } else {
              message.error(policeList.error.message)
            }
          })
        }

      })
    }, [policeId])

  const confirmcancelArchivedPolice = () => {
    const cmd_data = {
      archived: '0',
      id: policeId
    }
    paasql('eip.cms_doc.update_archived', cmd_data).then(data => {
      if (data.error == null) {
        message.success('取消归档成功')
        props.history.push(`/police-management/implementing/${policeId}`)
      } else {
        message.error(data.error.message)
      }
    })
  }
  const cancelArchived = () => {


    Modal.confirm({
      onOk() {
        confirmcancelArchivedPolice()
      },
      width: 600,
      content: '将移动该政策到进行中的政策中，进行中的政策可以编辑和发送，是否取消归档？',
      icon: <ExclamationCircleOutlined />,
      title: `归档政策`
    })


  }

  const showEnterpriseList = () => {
    setModalVisible(true)
  }
  const hideModal = () => {
    setModalVisible(false)
  }

  const backPoliceList = () => {
    props.history.push('/police-management')
  }



  const goPreviousPolice = () => {
    //previousPoliceId
    props.history.push(`/police-management/archived/${previousPoliceId}`)

  }
  const goNextPolice = () => {
    props.history.push(`/police-management/archived/${nextPoliceId}`)
  }
  return (
    <div className="police-detail-container" >
      <div
        className='police-detail-header'
      >
        <div>
          <span style={{ paddingRight: 20 }}  >
            <LeftOutlined /> <a target="_blank" disabled={previousDisabled} onClick={goPreviousPolice} rel="noopener noreferrer"> 上一篇</a>
          </span>
          <span >
            <a target="_blank" disabled={nextDisabled} onClick={goNextPolice} rel="noopener noreferrer">下一篇 </a><RightOutlined />
          </span>
        </div>
        <div>

          <Button.Group>
            <Button
              size='large'
              icon={<BackToIcon />}
              onClick={backPoliceList}>
              返回
        </Button>
            <Button
              size='large'
              icon={<SendListIcon />}
              onClick={showEnterpriseList}>
              发送名单  {enterpriseList.length}
            </Button>
            <Button
              size='large'
              icon={<ArchivedDocIcon />}

              onClick={cancelArchived}>
              取消归档
            </Button>
          </Button.Group>


        </div>


      </div>


      <div className='police-detail-container'>
        <div className='police-detail-container-title'>
          <div>
            {title}
          </div>
          <div className='police-detail-container-time'>
            {time}
          </div>
        </div>
        <Divider>
          <div
            className='divider-container'
          >

          </div>
        </Divider>

        <div
          dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </div>

      <Modal
        visible={modalVisible}
        title={
          <div className='police-detail-modal-title'>
            发送名单
      </div>
        }
        width={800}
        footer={null}
        destroyOnClose={true}
        closable={false}
      >

        <div>
          <Row>
            <Col span={6} style={{ borderRight: '1px solid #E0E0E0' }}>
              <div className="send-enterprise-list-label">
                已发送名单 {enterpriseList.length} >
              </div>
            </Col  >

            <Col span={18} flex={2} style={{ paddingLeft: 30 }}>
              <List
                itemLayout="horizontal"
                style={{
                  height: '500px',
                  overflow: 'auto'
                }}
                dataSource={enterpriseList}
                renderItem={item => (
                  <List.Item
                  >
                    <List.Item.Meta
                      description={
                        item.eip_enterprise_name
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={hideModal}>
              关闭
            </Button>
          </div>

        </div>

      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {

  return {
    PoliceManagementReducer: state.PoliceManagement
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchivedPoliceDetail);