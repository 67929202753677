import React, { useEffect, useState } from 'react';
import {
  Button, Input, message, Row, Col, DatePicker,
  Select, InputNumber, Radio, Pagination, Spin, Statistic
} from 'antd'
import moment from 'moment'
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';

import { paasql, queryMonthlyPaymentRecordApi, queryTaxRecord } from '@api'
import SelectFieldModal from './SelectFieldModal'
import MonthlyAccumulatedTaxRecord from './dateSeachTable'//表格展示组件
import { FilterIcon, ExportDataIcon, CancelEditIcon } from '@icon'
import { ButtonLabel } from '@component'
import number from '@svg/number-range.svg'
import './index.less'

const { RangePicker } = DatePicker

const { Option } = Select;
export const keyArr = ['taxable_income', 'tax_payable', 'consumption_tax',
  'value_added_tax', 'business_tax', 'corporate_income_tax', 'individual_income_tax',
  'city_maintenance_and_construction_tax', 'vehicle_and_vessel_usage_tax', 'property_tax', 'stamp_tax',
  'land_appreciation_tax', 'land_use_tax', 'environmental_tax']
const enterpriseSourceList = [
  {
    id: '0',
    name: '园区注册'
  }, {
    id: '1',
    name: '迁入'
  }, {
    id: '2',
    name: '迁入(区外)'
  }
]

const currentEndTime = moment().format('YYYY-MM')

const DataSeach = () => {

  //设置state，临时数据，与业务无关
  const [state, setState] = useState({
    //这两个是页面显示使用的（UI配置）
    enterpriseInfo: [],
    taxInfo: []
  })
  const [pullState, setPullState] = useState({
    //清空操作后的处理
    enterpriseInfo: [],
    taxInfo: []
  })
  const [filterConditionObj, setFilterConditionObj] = useState({})
  //api请求的时候使用的，防止页面刷新的时候失去焦点和页面重新渲染，（数据配置）
  const [enterpriseInfo, setEnterpriseInfo] = useState([])// 企业信息
  const [parkId, setParkId] = useState(null)
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [taxInfo, setTaxInfo] = useState([])//税的信息

  const [isSameMonth, setIsSameMonth] = useState(false)//判断是汇总还是单月
  const [taxPaidAt, setTaxPaidAt] = useState(currentEndTime)//单月时需要用到的数据
  const [taxPaidAtForShow, setTaxPaidAtForShow] = useState(moment())
  const [taxPaidAt1, setTaxPaidAt1] = useState([])//汇总需要的数据


  //五个下拉列表的数据配置
  const [formerRegisteredAtList, setFormerRegisteredAtList] = useState([])
  const [settledStatusList, setSettledStatusList] = useState([])
  const [taxStatusList, setTaxStatusList] = useState([])
  const [registeredGenreList, setRegisteredGenreList] = useState([])
  const [settledByList, setSettledByList] = useState([])

  const [tableLoading, setTableLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)

  //是否显示更多筛选条件
  const [showMoreCondition, setShowMoreCondition] = useState(false)
  const [modelVisible, setModelVisible] = useState(false)

  const [orderBy, setOrderBy] = useState(undefined)
  const [totalTaxRecord, setTotalTaxRecord] = useState([])
  const [taxTimeInfo, setTaxTimeInfo] = useState({})

  //展示的三个数据项
  const [enterpriseSize, setEnterpriseSize] = useState(null)

  //下拉列表的数据配置项
  const [cpStaffList, setCpStaffList] = useState([])
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])
  const [investmentAgencyStaffList, setInvestmentAgencyStaffList] = useState([])
  const [establishedIn, setEstablishedIn] = useState([])
  const [settledAt, setSettledAt] = useState([])
  //员工的处理
  const [staff, setStaff] = useState(undefined)
  const [zhaoshangbumen, setzhaoshangbumen] = useState(undefined)
  const [staffList, setStaffList] = useState([])
  const [nowIsCpStaff, setNowIsCpStaff] = useState(true)
  //招商员的下拉列表,包含本部和中介招入
  const [recordingGenreList, setRecordingGenreList] = useState([])


  //staffInfo，存一个筛选条件，
  const [radio, setRadio] = useState('total')

  const [totalTax, setTotalTax] = useState({})
  useEffect(() => {
    //获取配置文件
    getConfig()
    //获取标准数据
    paasql("eip.enterprise_monthly_tax_payment_record.cp_standard_list").then(list => {
      if (list.error == null) {
        const result = list.result.parallel_list_object
        const [
          former_registered_at,
          settled_status, tax_status,
          registered_genre,
          settled_by,
          recording_genre
        ] = result



        const former_registered_at_list = former_registered_at.former_registered_at[0].result//下拉列表配置
        setFormerRegisteredAtList(former_registered_at_list)
        const settled_status_list = settled_status.settled_status[0].result//下拉列表配置
        setSettledStatusList(settled_status_list)
        const tax_status_list = tax_status.tax_status[0].result//下拉列表配置
        setTaxStatusList(tax_status_list)
        const registered_genre_list = registered_genre.registered_genre[0].result//下拉列表配置
        setRegisteredGenreList(registered_genre_list)

        const settled_by_list = settled_by.settled_by[0].result
        setSettledByList(settled_by_list)

        const recording_genre_list = recording_genre.recording_genre[0].result
        setRecordingGenreList(recording_genre_list)
      } else {
        message.error(list.error.message)
      }
    })


    //这里需要处理
    paasql('eip.park.list').then(parkList => {
      if (parkList.error == null) {
        const time = moment()
        //设置日期的默认显示
        const timeArr = [time, time]
        setTaxPaidAt1(timeArr)
        setParkId(parkList.result[0].id)

        //防止切换后报错
        const timeInfo = {
          start_year: String(time.year()),
          start_month: `${('0' + String(time.month() + 1)).slice(-2)}`,
          end_year: String(time.year()),
          end_month: `${('0' + String(time.month() + 1)).slice(-2)}`,
        }
        setTaxTimeInfo(timeInfo)
        const cmd_data = {
          "eip_park_id": parkList.result[0].id,
          "page": 1,
          "size": pageSize,
          ...timeInfo
        }
        setTableLoading(true)
        queryTaxRecord(cmd_data).then(data => {
          const { list,
            total_page,
            taxable_income, tax_payable,
            enterprise_size,
            business_tax, city_maintenance_and_construction_tax, consumption_tax,
            corporate_income_tax, environmental_tax, individual_income_tax,
            land_appreciation_tax, land_use_tax,
            property_tax, stamp_tax, value_added_tax,
            vehicle_and_vessel_usage_tax
          } = data

          const totalTax = {
            taxable_income, tax_payable,
            business_tax, city_maintenance_and_construction_tax, consumption_tax,
            corporate_income_tax, environmental_tax, individual_income_tax,
            land_appreciation_tax, land_use_tax,
            property_tax, stamp_tax, value_added_tax,
            vehicle_and_vessel_usage_tax, name: '合计'
          }
          setTotalTax({
            ...totalTax
          })


          //这里需要将合计的值放进去，由于合计的值不需要序
          //这里需要做一层循环
          for (let i = 0; i < list.length; i++) {
            list[i].index = i + 1
          }

          if (total_page == 1) {
            list.push(totalTax)
          }

          setEnterpriseSize(enterprise_size)
          setTableData(list)
          setTotalPage(total_page)
        }).finally(() => {
          setTableLoading(false)
        })
      } else {

      }

    })


  }, [])

  const getConfig = () => {
    paasql("eip.eip_enterprise_monthly_tax_payment_statistics.filter_condition_config").then(data => {
      if (data.error == null) {
        const result = data.result.body.fields
        const enterpriseInfo = result.filter(item => item.schema_id == "eip_enterprise")
        const taxInfo = result.filter(item => item.schema_id == "eip_monthly_accumulated_tax_record")
        //企业相关的
        for (let i = 0; i < enterpriseInfo.length; i++) {
          if (enterpriseInfo[i].value_type == 'date') {//这里处理的是日期范围的数据
            enterpriseInfo[i].dateType = 1
          }

          //日期的处理
          if (
            enterpriseInfo[i].id === 'established_in' ||
            enterpriseInfo[i].id === 'settled_at'
          ) {
            enterpriseInfo[i].span = 12
          } else {
            enterpriseInfo[i].span = 6
          }
          enterpriseInfo[i].attribute = enterpriseInfo[i].id//这里全局添加字段名
        }

        //税相关的
        for (let i = 0; i < taxInfo.length; i++) {
          taxInfo[i].attribute = taxInfo[i].id//这里全局添加字段名
        }
        //ui
        setState({
          enterpriseInfo,
          taxInfo
        })
        setPullState({
          enterpriseInfo,
          taxInfo
        })

        //api数据
        setEnterpriseInfo(enterpriseInfo)
        setTaxInfo(taxInfo)
      } else {
        message.error(data.error.message)
      }
    })

    //处理标准数据
    paasql('eip.eip_enterprise.get_eip_park_cp_staff_eip_investment_agency_eip_investment_agency_staff.list').then(data => {
      const result = data.result.parallel_list_object
      const [eip_park, cp_staff_list,
        investment_agency_list, investment_agency_staff_list
      ] = result
      const cpStaffList = cp_staff_list.cp_staff_name


      const investmentAgencyList = investment_agency_list.eip_investment_agency_name
      const investmentAgencyStaffList = investment_agency_staff_list.eip_investment_agency_staff_name
      setCpStaffList(cpStaffList)
      setInvestmentAgencyList(investmentAgencyList)
      setInvestmentAgencyStaffList(investmentAgencyStaffList)
    })
  }


  const moreCondition = () => {
    setShowMoreCondition(!showMoreCondition)

  }


  const handleTotalTax = (data) => {
    const {
      tax_payable, taxable_income,
      business_tax, city_maintenance_and_construction_tax, consumption_tax,
      corporate_income_tax, environmental_tax, individual_income_tax,
      land_appreciation_tax, land_use_tax,
      property_tax, stamp_tax, value_added_tax,
      vehicle_and_vessel_usage_tax
    } = data


    const totalTax = {
      tax_payable, taxable_income,
      business_tax, city_maintenance_and_construction_tax, consumption_tax,
      corporate_income_tax, environmental_tax, individual_income_tax,
      land_appreciation_tax, land_use_tax,
      property_tax, stamp_tax, value_added_tax,
      vehicle_and_vessel_usage_tax, name: '合计'
    }
    return totalTax
  }

  const exportData = () => {

    //1、判断是同月还是不是同月
    //2、分别请求接口数据
    //3、导出
    const attribute_filter = []

    const enterprise_attribute_filter = []
    for (const key in filterConditionObj) {
      let obj = new Object()
      if (filterConditionObj[key] == undefined) {
      } else {
        if (key === "established_in"
          || key === "settled_at") {
          obj.attribute = key
          obj.operator = '<>'
          obj.value = [...filterConditionObj[key]]
        } else {
          obj.attribute = key
          obj.operator = 'contain'
          obj.value = [filterConditionObj[key]]
        }
        enterprise_attribute_filter.push(obj)
      }
      //处理日期
    }
    if (isSameMonth) {//说明同月
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": taxPaidAt,
        "page": 1,
        "size": pageSize * totalPage,

        attribute_filter,
        enterprise_attribute_filter,
        order_by: orderBy
      }
      setTableLoading(true)

      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const { table_row_data } = data


        const totalTax = handleTotalTax(data)
        setTotalTax(totalTax)
        //还需要处理税收期间
        const time = taxPaidAt.replace('-', '')
        const year = time.slice(0, 4)
        const month = time.slice(4, 6)
        const tmpTaxPaidAt = `${year}年${month}月`


        //处理导出时的数据格式千分位，隔开
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].tax_paid_at = tmpTaxPaidAt
          table_row_data[i].index = String(i + 1)
          keyArr.forEach(key => {
            table_row_data[i][key] = `$${String(Number( table_row_data[i][key]))}`
          })
        }
        //处理招商部门和招商中介


        table_row_data.forEach(element => {
          if (element.settled_by_code == '02') {//说明是中介
            element.investment_department = element.eip_investment_agency_name
            element.investment_staff = element.eip_investment_agency_staff_name
          } else {
            element.investment_department = '本部'
            element.investment_staff = element.cp_staff_name
          }
        });

        setTotalTaxRecord(table_row_data)
      }).finally(() => {
        setTableLoading(false)
        setModelVisible(!modelVisible)
      })
    } else { //说明是汇总

      const cmd_data = {
        "eip_park_id": parkId,
        ...taxTimeInfo,
        "page": 1,
        "size": pageSize * totalPage,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter,
        order_by: orderBy
      }
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        const { table_row_data } = data
        const totalTax = handleTotalTax(data)
        setTotalTax(totalTax)

        const { start_month, start_year, end_month, end_year } = taxTimeInfo
        const tmpTaxPaidAt = `${start_year} 年${start_month}-${end_month}月`
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].tax_paid_at = tmpTaxPaidAt
          table_row_data[i].index = String(i + 1)
          keyArr.forEach(key => {
            table_row_data[i][key] = `$${table_row_data[i][key]}`
          })
        }
        table_row_data.forEach(element => {
          if (element.settled_by_code == '02') {//说明是中介
            element.investment_department = element.eip_investment_agency_name
            element.investment_staff = element.eip_investment_agency_staff_name
          } else {
            element.investment_department = '本部'
            element.investment_staff = element.cp_staff_name
          }
        })
        setTotalTaxRecord(table_row_data)
      }).finally(() => {
        setTableLoading(false)
        setModelVisible(!modelVisible)
      })
    }
  }

  //重置筛选条件
  const clearCondition = () => {
    setFilterConditionObj({})
    setSettledAt([])
    setEstablishedIn([])
    //招商部门清空数据
    setzhaoshangbumen(undefined)
    setStaff(undefined)
    setTaxPaidAtForShow(null)
    setTaxPaidAt1([])
  }
  const seachResult = () => {
    setCurrentPage(1)//回到第一页
    //清除当前的排序条件
    setOrderBy(undefined)
    //处理筛选的条件
    const attribute_filter = []
    const enterprise_attribute_filter = []

    for (const key in filterConditionObj) {
      let obj = new Object()
      //处理日期
      if (filterConditionObj[key] == undefined) {

      } else {
        if (key === "established_in"
          || key === "settled_at") {
          obj.attribute = key
          obj.operator = '<>'
          obj.value = [...filterConditionObj[key]]
        } else {
          obj.attribute = key
          obj.operator = 'contain'
          obj.value = [filterConditionObj[key]]
        }
        //处理税管码

        enterprise_attribute_filter.push(obj)
      }

    }


    if (isSameMonth) {
      const time = taxPaidAt.replace('-', '')
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": time,
        "page": 1,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const { table_row_data, total_page,
          enterprise_size, tax_payable
        } = data
        const totalTax = handleTotalTax(data)
        setTotalTax(totalTax)
        //序号
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = i + 1
        }


        if (total_page == 1) {
          table_row_data.push(totalTax)
        }
        setEnterpriseSize(enterprise_size)

        setTableData(table_row_data)
        setTableLoading(false)
        setTotalPage(total_page)
        setShowMoreCondition(false)
      })
      //如果单月的话，就使用queryMonthlyPaymentRecord
    }
    else {
      if (
        //四个属性只判断一个就行
        taxTimeInfo.start_month == undefined) {
        message.error('请先选择时间', 10)
        return
      }
      const cmd_data = {
        "eip_park_id": parkId,
        ...taxTimeInfo,
        "page": 1,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter
      }
      //如果不是单月的话，就使用queryTaxRecord
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        //表格的数据和页码
        const { table_row_data, total_page,
          enterprise_size, tax_payable
        } = data

        const totalTax = handleTotalTax(data)
        setTotalTax(totalTax)
        //序号
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = i + 1
        }
        if (total_page == 1) {
          table_row_data.push(totalTax)
        }
        setEnterpriseSize(enterprise_size)
        setTableData(table_row_data)
        setTotalPage(total_page)

        //清空刚刚的筛选条件Todo
      }).finally(() => {

        setTableLoading(false)
        setShowMoreCondition(false)
      })
    }
  }
  const dateChange = (dates, dateStrings) => {
    const [start, end] = dateStrings

    //设置接口的判断条件，是单月还是汇总
    if (start === end) {
      setIsSameMonth(true)
    } else {
      setIsSameMonth(false)
    }
    //
    const taxPaidAt = end.replace('-', '')//这是单月数据的查询接口
    const [startTime, endTime] = dateStrings
    const startYear = startTime.slice(0, 4)
    const startMonth = startTime.slice(5, 7)
    const endYear = endTime.slice(0, 4)
    const endMonth = endTime.slice(5, 7)

    //设置跨月查询的api请求条件
    setTaxTimeInfo({
      start_year: String(startYear),
      start_month: String(startMonth),
      end_month: String(endMonth),
      end_year: String(endYear),
    })
    setTaxPaidAt(taxPaidAt)
  }




  //改变企业名字
  const changeName = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      name: value
    })
  }

  //更改uscc
  const changeUscc = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      uscc: value
    })
  }

  //更改税管码
  const changeTaxFileNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      tax_file_number: value
    })
  }


  //更改档案号
  const changeFileNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      file_number: value
    })
  }
  //更改税号
  const changeTaxpayerRegistrationNumber = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      taxpayer_registration_number: value
    })
  }

  //更改法人代表
  const changeLegalRepresentative = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      legal_representative: value
    })
  }

  //更改成立日期
  const changeEstablishedIn = (dates, dateStrings) => {
    setEstablishedIn(dates)

    const [start, end] = dateStrings
    const startTime = start.replace(/-/g, '')
    const endTime = end.replace(/-/g, '')
    const valueArr = [startTime, endTime]

    setFilterConditionObj({
      ...filterConditionObj,
      established_in: valueArr
    })
  }
  //更改入园时间
  const changeSettledAt = (dates, dateStrings) => {

    setSettledAt(dates)

    const [start, end] = dateStrings
    const startTime = start.replace(/-/g, '')
    const endTime = end.replace(/-/g, '')
    const valueArr = [startTime, endTime]

    setFilterConditionObj({
      ...filterConditionObj,
      settled_at: valueArr
    })

  }


  //更改注册地址
  const changeRegisteredAddress = (e) => {
    const value = e.target.value
    setFilterConditionObj({
      ...filterConditionObj,
      registered_address: value
    })
  }
  //税务状态改变
  const taxStatusChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      tax_status: value
    })
  }
  // 注册类型改变
  const registeredGenreChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      registered_genre: value
    })

  }



  //企业情况改变
  const settledStatusChange = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      settled_status: value
    })
  }

  //更改企业来源
  const changeEnterpriseSource = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      enterprise_source: value
    })
  }



  //更改工商状态
  const changeLicenseStatus = (v, option) => {
    const { key, value } = option

    setFilterConditionObj({
      ...filterConditionObj,
      license_status: value
    })
  }

  //更改企业类型
  const changeEnterpriseGenre = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      enterprise_genre: value
    })
  }


  //更改注册类型
  const changeRecordingGenre = (v, option) => {
    const { key, value } = option
    setFilterConditionObj({
      ...filterConditionObj,
      recording_genre: value
    })
  }


  //更改招商部门
  const benbu = [{
    id: ' benbu',
    name: '本部'
  }]
  const changeZhaoShangBumen = (v, option) => {
    const { key, value } = option
    var settled_by = null

    setzhaoshangbumen(value)
    setStaff(undefined)
    if (value === '本部') {
      const index = settledByList.findIndex(item => item.name === '本部')
      settled_by = settledByList[index].id
      setStaffList(cpStaffList)
      setNowIsCpStaff(true)
      setFilterConditionObj({
        ...filterConditionObj,
        settled_by,
        eip_investment_agency_id: undefined,
        cp_staff_id: undefined,
        eip_investment_agency_staff_id: undefined
      })
      return
    } else {
      //设置筛选条件
      const index = settledByList.findIndex(item => item.name === '中介')
      settled_by = settledByList[index].id
      const tmp_list = investmentAgencyStaffList.filter(
        item => item.eip_investment_agency_id == key
      )
      setNowIsCpStaff(false)
      setStaffList(tmp_list)

      //清空选中的数据
      setFilterConditionObj({
        ...filterConditionObj,
        settled_by,
        eip_investment_agency_id: key,
        cp_staff_id: undefined,
        eip_investment_agency_staff_id: undefined
      })
      //处理招商员的下拉列表
    }

  }

  const changeStaff = (v, option) => {
    const { key, value } = option
    //判断是中介还是园区工作人员
    setStaff(value)

    const tmp_list = filterConditionObj
    //删除属性eip_investment_agency_staff_id
    if (nowIsCpStaff) {
      delete tmp_list.eip_investment_agency_staff_id

      setFilterConditionObj({
        ...tmp_list,
        cp_staff_id: key,
      })
      return
    } else {
      delete tmp_list.cp_staff_id
      setFilterConditionObj({
        ...tmp_list,
        eip_investment_agency_staff_id: key
      })
    }
  }

  //更改是单月还是跨月
  const changeRadio = (e) => {
    const value = e.target.value
    if (value === 'single') {
      setIsSameMonth(true)
    } else {
      setIsSameMonth(false)
    }
    setRadio(value)
  }



  const changeTaxPaidAt1 = (dates, dateStrings) => {
    const [startTime, endTime] = dateStrings

    const startYear = startTime.slice(0, 4)
    const startMonth = startTime.slice(5, 7)
    const endYear = endTime.slice(0, 4)
    const endMonth = endTime.slice(5, 7)

    //设置跨月查询的api请求条件
    setTaxTimeInfo({
      start_year: String(startYear),
      start_month: String(startMonth),
      end_month: String(endMonth),
      end_year: String(endYear),
    })
    setTaxPaidAt1(dates)
  }
  const changeTaxPaidAt = (date, dateString) => {
    const time = dateString.replace(/-/g, '')
    setTaxPaidAtForShow(date)
    setTaxPaidAt(time)
  }
  const filterList = [
    {
      id: 'name',
      span: 6,
      field: 'name',
      label: '企业名称：',
      component: <Input
        placeholder={'企业名称'}
        value={filterConditionObj.name}
        onChange={changeName}
      />
    }, {
      id: 'tax_file_number',
      field: 'tax_file_number',
      label: '税管码：',
      span: 6,
      component: <Input
        placeholder={'税管码'}
        value={filterConditionObj.tax_file_number}
        onChange={changeTaxFileNumber}
      />
    },
    {
      id: 'date_select',
      span: 12,
      field: 'date_select',
      label: '单月/汇总：',
      component:
        <Radio.Group
          onChange={changeRadio}
          value={radio}
        >
          <Radio value={'single'}>单月</Radio>
          <Radio value={'total'}>汇总</Radio>
        </Radio.Group>
    },
    {
      id: 'zhaoshangbumen',
      field: 'zhaoshangbumen',
      label: '招商部门：',
      span: 6,
      component:
        <Select
          placeholder={'所有招商部门'}
          value={zhaoshangbumen}
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          showSearch
          onChange={changeZhaoShangBumen}
        >
          {
            (benbu.concat(investmentAgencyList)).map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: '',
      field: 'zhaoshangyuan',
      label: '招商员：',
      span: 6,
      component:
        <Select
          value={staff}
          placeholder='所有招商员'
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          onChange={changeStaff}
        >
          {
            staffList.map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    },
    {
      id: 'tax_paid_at',
      field: 'tax_paid_at',
      label: '纳税月份：',
      span: 12,
    }, {
      id: 'established_in',
      field: 'established_in',
      label: '成立日期：',
      span: 6,
      component: <RangePicker
        style={{ width: '180' }}
        value={

          establishedIn === [] ? [] :
            establishedIn
        }
        onChange={changeEstablishedIn}
      />
    }, {
      id: 'settled_at',
      field: 'settled_at',
      label: '入园时间：',
      span: 6,
      component: <DatePicker
        style={{ width: '180' }}

        value={filterConditionObj.settled_at == null ? undefined :
          filterConditionObj.settled_at}
        onChange={changeSettledAt}
      />
    },
    {
      id: 'recording_genre',
      field: 'recording_genre',
      label: '注册类型：',
      span: 6,
      component: <Select
        placeholder={'注册类型'}
        suffixIcon={<CaretDownOutlined />}
        value={filterConditionObj.recording_genre}
        onChange={changeRecordingGenre}
        style={{ width: '100%', fontSize: '14px' }}
      >
        {
          recordingGenreList.map(item => {
            return (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )
          })}
      </Select>
    },
  ]

  const shortFilterList = [
    {
      id: 'name',
      span: 6,
      field: 'name',
      label: '企业名称：',
      component: <Input
        placeholder={'企业名称'}
        value={filterConditionObj.name}
        onChange={changeName}
      />
    }, {
      id: 'tax_file_number',
      field: 'tax_file_number',
      label: '税管码：',
      span: 6,
      component: <Input
        placeholder={'税管码'}
        value={filterConditionObj.tax_file_number}
        onChange={changeTaxFileNumber}
      />
    },
    {
      id: 'date_select',
      span: 12,
      field: 'date_select',
      label: '单月/汇总：',
      component:
        <Radio.Group
          onChange={changeRadio}
          value={radio}
        >
          <Radio value={'single'}>单月</Radio>
          <Radio value={'total'}>汇总</Radio>
        </Radio.Group>
    },
    {
      id: 'zhaoshangbumen',
      field: 'zhaoshangbumen',
      label: '招商部门：',
      span: 6,
      component:
        <Select
          placeholder={'所有招商部门'}
          value={zhaoshangbumen}
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          showSearch
          onChange={changeZhaoShangBumen}
        >
          {
            (benbu.concat(investmentAgencyList)).map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: '',
      field: 'zhaoshangyuan',
      label: '招商员：',
      span: 6,
      component:
        <Select
          value={staff}
          placeholder='所有招商员'
          suffixIcon={<CaretDownOutlined />}
          style={{ width: '100%' }}
          onChange={changeStaff}
        >
          {
            staffList.map(item => {
              return (
                <Option key={item.id} value={item.name} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    },
    {
      id: 'tax_paid_at',
      field: 'tax_paid_at',
      label: '纳税月份：',
      span: 12,
    },
  ]
  const totalTaxList = [
    {
      label: '应税销售额',
      value: totalTax.taxable_income
    }, {
      label: '税收合计',
      value: totalTax.tax_payable
    }, {
      label: '消费税',
      value: totalTax.consumption_tax
    }, {
      label: '增值税',
      value: totalTax.value_added_tax
    }, {
      label: '营业税',
      value: totalTax.business_tax
    }, {
      label: '企业所得税',
      value: totalTax.corporate_income_tax
    }, {
      label: '所得税',
      value: totalTax.individual_income_tax
    }, {
      label: '城市维护建设税',
      value: totalTax.city_maintenance_and_construction_tax
    }, {
      label: '车船使用税',
      value: totalTax.vehicle_and_vessel_usage_tax
    }, {
      label: '房产税',
      value: totalTax.property_tax
    }, {
      label: '印花税',
      value: totalTax.stamp_tax
    }, {
      label: '土地增值税',
      value: totalTax.land_appreciation_tax
    }, {
      label: '土地使用税',
      value: totalTax.land_use_tax
    }, {
      label: '环保税',
      value: totalTax.environmental_tax
    }
  ]
  // 改变页码，，需要请求api
  const pageChange = (page) => {
    const attribute_filter = []
    //同月的情况 
    const enterprise_attribute_filter = []
    for (const key in filterConditionObj) {
      let obj = new Object()
      if (filterConditionObj[key] == undefined) {

      } else {
        if (key === "established_in"
          || key === "settled_at") {
          obj.attribute = key
          obj.operator = '<>'
          obj.value = [...filterConditionObj[key]]
        } else {
          obj.attribute = key
          obj.operator = 'contain'
          obj.value = [filterConditionObj[key]]
        }
        enterprise_attribute_filter.push(obj)
      }
      //处理日期

    }
    if (isSameMonth) {
      const time = taxPaidAt.replace('-', '')
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": time,
        "page": page,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter,
        order_by: orderBy
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const {
          table_row_data,
          total_page, taxable_income, tax_payable,
          enterprise_size
        } = data



        //这里需要将合计的值放进去，由于合计的值不需要序
        //这里需要做一层循环

        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = (page - 1) * pageSize + i + 1
        }

        if (page === totalPage) {
          table_row_data.push(totalTax)
        }

        setEnterpriseSize(enterprise_size)

        //表格数据

        setTableData(table_row_data)

        setTableLoading(false)
        setTotalPage(total_page)
        setCurrentPage(page)
        setShowMoreCondition(false)
      })
    } else {
      const cmd_data = {
        "eip_park_id": parkId,
        ...taxTimeInfo,
        "page": page,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter,
        order_by: orderBy
      }
      //如果不是单月的话，就使用queryTaxRecord
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        //表格的数据和页码
        const {
          table_row_data, total_page,
          taxable_income, tax_payable,
          enterprise_size
        } = data
        //这里需要将合计的值放进去，由于合计的值不需要序
        //这里需要做一层循环

        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = (page - 1) * pageSize + i + 1
        }

        if (page === totalPage) {
          table_row_data.push(totalTax)
        }
        setEnterpriseSize(enterprise_size)


        setCurrentPage(page)
        setTableData(table_row_data)
        setTotalPage(total_page)

        //清空刚刚的筛选条件Todo
      }).finally(() => {

        setTableLoading(false)
        setShowMoreCondition(false)
      })
    }
  }
  //企业信息的ui配置


  //更改排序的字段名
  const tableShortChange = (shorter_condition) => {
    //清除排序
    const order_by = shorter_condition
      === null ? undefined : shorter_condition
    //判断是否跨月

    setOrderBy(order_by)
    const attribute_filter = taxInfo.filter(item => item.value != undefined)
    const enterprise_attribute_filter = enterpriseInfo.filter(item => item.value != undefined)

    if (isSameMonth) {
      const time = taxPaidAt.replace('-', '')
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": time,
        "page": currentPage,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter,
        order_by
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const {
          table_row_data,
          total_page, taxable_income, tax_payable,
          enterprise_size
        } = data


        //这里的序号
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = (currentPage - 1) * pageSize + i + 1
        }
        if (total_page === 1) {
          table_row_data.push(totalTax)
        } else if (currentPage == totalPage) {
          table_row_data.push(totalTax)
        }

        setEnterpriseSize(enterprise_size)

        //表格数据
        setTableData(table_row_data)
        setTableLoading(false)
        setTotalPage(total_page)
        setShowMoreCondition(false)
      })
    } else {
      const cmd_data = {
        "eip_park_id": parkId,
        ...taxTimeInfo,
        "page": currentPage,
        "size": pageSize,
        attribute_filter: attribute_filter,
        enterprise_attribute_filter: enterprise_attribute_filter,
        order_by
      }
      //如果不是单月的话，就使用queryTaxRecord
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        //表格的数据和页码
        const {
          table_row_data, total_page,
          enterprise_size
        } = data


        setEnterpriseSize(enterprise_size)
        setTableData(table_row_data)
        setTotalPage(total_page)

        //清空刚刚的筛选条件Todo
      }).finally(() => {

        setTableLoading(false)
        setShowMoreCondition(false)
      })
    }
  }

  return (
    <>
      <Spin spinning={tableLoading}>
        <Button.Group>
          <ButtonLabel
            size='large'
          />
          <Button
            size='large'

            icon={<FilterIcon />}
            onClick={moreCondition}
          >
            {
              //need checked
              showMoreCondition ? "收起筛选条件" : "更多筛选条件"
            }
          </Button>
          <Button
            size='large'
            icon={<CancelEditIcon />}
            onClick={clearCondition}
          >
            重置筛选条件
        </Button>
          <Button
            size='large'
            icon={<ExportDataIcon />}
            onClick={exportData}
          >
            导出数据
        </Button>

          <Button
            icon={<SearchOutlined />}
            size='large'
            onClick={seachResult}
            type='primary'
          >
            开始搜索
        </Button>

        </Button.Group>

        <div className="data-seach-condition" >


          <div >
            {
              <Row style={{ display: "none" }} >
                <Col span={6} style={{ paddingRight: 20, margin: '10px 0px' }}>

                  {
                    isSameMonth ?
                      <DatePicker
                        allowClear={false}
                        size='small'
                        value={
                          taxPaidAtForShow === undefined ? undefined :
                            taxPaidAtForShow
                        }
                        onChange={changeTaxPaidAt}
                        picker="month"
                      >
                      </DatePicker>
                      : <RangePicker
                        allowClear={false}
                        picker="month"
                        value={taxPaidAt1 === [] ? [] :
                          taxPaidAt1
                        }
                        size='small'
                        onChange={changeTaxPaidAt1}
                      >
                      </RangePicker>


                  }

                </Col>
                <Col span={5} style={{ paddingRight: 20, marginTop: '10px', height: '35px !important', lineHeight: '35px' }}>
                  <Input onChange={changeName} value={filterConditionObj.name} className='input' placeholder='企业名称' />
                </Col>
                <Col span={5} style={{ paddingRight: 20, marginTop: '10px', height: '35px !important', lineHeight: '35px' }}>
                  <Select
                    placeholder={'招商部门'}
                    value={zhaoshangbumen}
                    suffixIcon={<CaretDownOutlined />}
                    style={{ width: '100%' }}
                    showSearch
                    onChange={changeZhaoShangBumen}
                  >
                    {
                      (benbu.concat(investmentAgencyList)).map(item => {
                        return (
                          <Option key={item.id} value={item.name} >
                            {item.name}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
                <Col span={4} style={{ paddingRight: 20, marginTop: '10px', height: '35px !important', lineHeight: '35px' }}>
                  <Select
                    value={staff}
                    placeholder='全部'
                    suffixIcon={<CaretDownOutlined />}
                    style={{ width: '100%' }}
                    onChange={changeStaff}
                  >
                    {
                      staffList.map(item => {
                        return (
                          <Option key={item.id} value={item.name} >
                            {item.name}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </Row>
            }
          </div>

          <Row className="enterprise-info-container"
            style={{
              // display: showMoreCondition ? "" : "none",
              marginTop: '20px'
            }}
          >
            {

              (
                showMoreCondition ? filterList :
                  shortFilterList
              ).map(item => {
                return (
                  <Col key={item.field} span={item.span} className='filter-condition-item-group'>
                    <Row className='filter-condition-item'>
                      <Col className='filter-condition-label'>
                        {`${item.label}`}
                      </Col>
                      <Col className='filter-condition-value'>
                        {
                          item.field === 'established_in' ?
                            <RangePicker
                              size='small'
                              allowClear={false}
                              value={
                                establishedIn === [] ? [] :
                                  establishedIn
                              }
                              onChange={changeEstablishedIn}
                            />
                            :
                            item.field == 'settled_at' ?
                              <RangePicker
                                size='small'
                                allowClear={false}
                                value={
                                  settledAt === [] ? [] :
                                    settledAt
                                }
                                onChange={changeSettledAt}
                              /> :
                              // 这里是纳税区间的处理
                              item.field == 'tax_paid_at' ?
                                radio == 'single' ?
                                  <DatePicker
                                    allowClear={false}
                                    size='small'
                                    value={
                                      taxPaidAtForShow === undefined ? undefined :
                                        taxPaidAtForShow
                                    }
                                    onChange={changeTaxPaidAt}
                                    picker="month" /> :
                                  <RangePicker
                                    allowClear={false}
                                    picker="month"
                                    value={taxPaidAt1 === [] ? [] :
                                      taxPaidAt1
                                    }
                                    size='small'
                                    onChange={changeTaxPaidAt1}
                                  />
                                : item.component
                        }
                      </Col>
                    </Row>
                  </Col>
                )
              })
            }
          </Row>
        </div>
        {/* <div>
          <Row className="seach-total-record-container" >
            {
              totalTaxList.map(item => {
                return (
                  <Col key={item.label} span={6} className='total-record-item'>
                    <Row>
                      <Col span={11} style={{ textAlign: 'right' }} className='total-record-item-label'>
                        {`${item.label}:`}
                      </Col>
                      <Col span={13} className='total-record-item-value'>
                        {moneyFormat(item.value)}
                      </Col>
                    </Row>
                  </Col>
                )
              })
            }
          </Row>
        </div> */}
        <div
        >
          <MonthlyAccumulatedTaxRecord
            showMoreCondition={showMoreCondition}
            tableShortChange={tableShortChange}
            Data={tableData}
            pageSize={pageSize}
            currentPage={currentPage}
            totalPage={totalPage}
          />
        </div>
        <div
          style={{ textAlign: 'right', paddingTop: 20 }}
        >
          <Pagination
            showLessItems={true}
            size='large'
            showQuickJumper={true}
            showSizeChanger={false}
            hideOnSinglePage={true}
            current={currentPage}
            pageSize={pageSize}
            total={totalPage * pageSize}
            showTotal={(total, range) => `共 ${enterpriseSize} 条`}
            onChange={pageChange}
          />
        </div>

      </Spin>
      <SelectFieldModal
        totalTax={totalTax}
        totalRecord={totalTaxRecord}
        modelVisible={modelVisible}
      />
    </>

  );
}


export default DataSeach;