import React, { useState, useEffect } from 'react';

import { Button, Input, Space, Row, Col, message, Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';

import { paasql } from '@api'


const { Option } = Select;
const sexList = [{
  id: 'man',
  name: '男'
},
{
  id: 'woman',
  name: '女'
}
]
const CreateParkStaff = (props) => {
  const { parkId, hideModal, reflushData, parkName } = props

  const [investmentAgencyInfo, setInvestmentAgencyInfo] = useState({
    name: null,
    uscc: null,
    bank_account_name: null,
    bank_name: null,
    bank_branch: null,
    bank_account_number: null,
    phone_number: null
  })


  const changeName = (e) => {
    const value = e.target.value
    setInvestmentAgencyInfo({
      ...investmentAgencyInfo,
      name: value
    })
  }


  const saveChange = () => {
    const {
      name,
      sex,
      china_mobile_phone_number
    } = investmentAgencyInfo

    if (name == null) {
      message.error('名字是必填项')
      return
    }

    const cmd_data = {
      object: {
        eip_park_id: parkId,
        // eip_park_name: parkName
        name,
        sex,
        china_mobile_phone_number
      }

    }
    paasql("eip.cp_staff.create", cmd_data).then(data => {
      if (data.error == null) {
        message.success("保存成功")
        const id = data.result.id
        reflushData(id)
        hideModal()
      } else {
        message.error(data.error.message)
      }
    })
  }
  const changeTel = (e) => {
    const value = e.target.value
    setInvestmentAgencyInfo({
      ...investmentAgencyInfo,
      china_mobile_phone_number: value
    })
  }

  return (
    <div>

      <div>
        <Row>
          <Col span={24} >
            <div className='edit-bank-info-label'>
              名称
            </div>
            <Input
              placeholder={`请输入名称`} className='create-investment-agency-input'
              style={{ width: '100%' }} value={investmentAgencyInfo.value}
              onChange={changeName} />
          </Col>
          <Col span={24} >
            <div className='edit-bank-info-label'>
              性别
            </div>
            <Select
              placeholder={'请选择性别'}
              style={{ width: '100%' }}
              value={investmentAgencyInfo.sex}
              suffixIcon={<CaretDownOutlined />}
            >
              {
                sexList.map(item => {
                  return (
                    <Option key={item.id}>
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col span={24} >
            <div className='edit-bank-info-label'>
              手机号
            </div>
            <Input
              placeholder={'请输入手机号'} className='create-investment-agency-input'
              style={{ width: '100%' }} value={investmentAgencyInfo.china_mobile_phone_number}
              onChange={changeTel} />
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>
            取消
          </Button>
          <Button
            type='primary'
            onClick={saveChange}
          >
            保存
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default CreateParkStaff;