import React, { useState, useEffect, createRef } from 'react';
import E from 'wangeditor'

import { Button, Input, Select, Space, Divider, message, Modal, Typography } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';

import { paasql } from '@api'
import { uploadImg } from '@api/paasql'


const sexList = [{
  id: 'man',
  name: '男'
},
{
  id: 'woman',
  name: '女'
}
]
const editorRef = createRef()

const { Option } = Select;
const ParkStaffDetail = (props) => {
  const { staffId, reflushData, parkId, isEdit,  changeEdit } = props
  const [staffInfo, setVirtualParkInfo] = useState({
    name: null,

  })
  const [name, setName] = useState('')

  //富文本的内容
  const [content, setContent] = useState(null)
  useEffect(() => {
    if (staffId == null) {
      return
    }
    const cmd_data = {
      id: staffId
    }
    paasql('eip.cp_staff.query_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        const {
          name,
        } = result
        setName(name)


        setVirtualParkInfo(result)
      } else {
        message.error(data.error.message)
      }
    })

    //获得招商中介的列表


  }, [staffId, parkId, isEdit])


  const cancelEdit = () => {
    changeEdit(false)
  }


  const changeName = (e) => {
    const value = e.target.value
    setVirtualParkInfo({
      ...staffInfo,
      name: value
    })
  }

  const changeSex = (v, option) => {
    const { key, value } = option
    setVirtualParkInfo({
      ...staffInfo,
      sex: key,
    })

  }

  const saveEdit = () => {
    const cmd_data = {
      object: {
        ...staffInfo,
      }
    }
    paasql('eip.cp_staff.update', cmd_data).then(data => {
      
      if (data.error == null) {
        message.success('保存成功')
        changeEdit(false)
        reflushData(staffInfo.id)
      } else {
        message.error(data.error.message)
      }
    })
  }


  const changeTel = (e) => {
    const value = e.target.value
    setVirtualParkInfo({
      ...staffInfo,
      china_mobile_phone_number: value
    })
  }


  const editList = [
    {
      label: '名称',
      component: <Input
        className='input'
        onChange={changeName} value={staffInfo.name} />
    },
    {
      label: '性别',
      component: 
      <Select
        suffixIcon={<CaretDownOutlined />}
        className='select'
        onChange={changeSex}
        value={staffInfo.sex}
      >
        {
          sexList.map(item => {
            return (
              <Option
                style={{ zIndex: '999999' }}
                value={item.id}
                key={item.name}
              >
                {item.name}
              </Option>
            )
          })
        }
      </Select>
    },
    {
      label: '联系电话',
      component:
        <Input
          className='input'
          onChange={changeTel}
          value={staffInfo.china_mobile_phone_number}
        />
    }
  ]
  return (
    <div>

      <div className='park-staff-edit-nav' style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
        <div className='park-staff-edit-title'>
          编辑园区工作人员
        </div>
        <Space>
          <Button
            onClick={cancelEdit}
          >
            取消编辑
          </Button>
          <Button
            type='primary'
            onClick={saveEdit}
          >
            保存
          </Button>
        </Space>

      </div>
      <div className='park-staff-edit-info-list'>
        {
          editList.map(item => {
            return (
              <div key={item.label} className='info-item'>
                <div className="label" >
                  {item.label}
                </div>
                <div className='value'>
                  {item.component}
                </div>
              </div>
            )
          })
        }
      </div>
    </div >
  );
}

export default ParkStaffDetail;