import React, { useEffect, useState } from 'react';
import { Table } from 'antd'
import { moneyFormat } from '@utils/index'
const changePageSize = (page, pageSize) => {

}
const TotalTable = (props) => {
  const { totalRecord } = props
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableRowData, setTableRowData] = useState([])
  const [tableLength, setTableLength] = useState(1)
  const fistColums = {
    title: '单位（元）',
    key: "title"
  }

  const tmp_list = [
    { title: "应税销售额", value: "taxable_income", },
    { title: "税收合计", value: "tax_payable", },
    { title: "消费税", value: "consumption_tax", },
    { title: "增值税", value: "value_added_tax", },
    { title: "营业税", value: "business_tax", },
    { title: "企业所得税", value: "corporate_income_tax", },
    { title: "所得税", value: "individual_income_tax", },
    { title: "城市维护建设税", value: "city_maintenance_and_construction_tax", },
    { title: "车船使用税", value: "vehicle_and_vessel_usage_tax", },
    { title: "房产税", value: "property_tax", },
    { title: "印花税", value: "stamp_tax", },
    { title: "土地增值税", value: "land_appreciation_tax", },
    { title: "土地使用税", value: "land_use_tax", },
    { title: "环保税", value: "environmental_tax", }
  ]


  const data = [];
  for (let i = 0; i < 20; i++) {
    data.push({
      key: i,
      value: `Edrward ${i}`,
      age: 32,
      address: `London Park no. ${i}`,
    });
  }
  const handerData = () => {
    //处理表格数据
    const tableLength = totalRecord.length
    setTableLength(tableLength)
    const columsGroup = []
    const firstColums = {
      title: '单位(元)',
      width: '200px',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left'
    }


    columsGroup.push(firstColums)

    //处理表头
    for (let i = 0; i < totalRecord.length; i++) {
      columsGroup.push(
        {
          title: `${totalRecord[i].statistical_month}月`,
          dataIndex: i,
          key: i,
          align: 'right',
          render: (text, record) => {
            return moneyFormat(text)
          }
        }
      )
    }
    setTableRowData(columsGroup)

    //处理行数据


    const rowData = []
    //这里的处理待定

    for (let j = 0; j < tmp_list.length; j++) {
      var tmp_obj = {}
      //这里是第一列
      tmp_obj.title = tmp_list[j].title
      for (let i = 0; i < totalRecord.length; i++) {
        //这里需要处理第二列以后的数据
        for (const key in totalRecord[i]) {
          if (key == tmp_list[j].value) {
            const index = String(i)
            tmp_obj[`${index}`] = totalRecord[i][key]
          }
        }
      }
      rowData.push(tmp_obj)
    }
    setTableHeaderData(rowData)
  }

  useEffect(() => {
    handerData()

  }, [totalRecord])
  return (
    <>
      <Table
        columns={tableRowData}
        bordered
        rowKey={"title"}
        pagination={{
          pageSize: 20,
          hideOnSinglePage: true
        }}
        dataSource={tableHeaderData}
        scroll={{ x: `calc(${tableLength * 200}px  + 100px)`, y: 700 }}
      />
    </>

  );
}

export default TotalTable;