import React, { useState, useEffect } from 'react';

import { paasql } from '@api'
import {
  Button, Space, message, Table, Modal,
  Input, Select, Popconfirm
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
const ButtonGroup = Button.Group
const { Option } = Select;







const EditStaff = (props) => {
  const {
    enterpriseId,
    hideStaffModal,
    enterpriseName
  } = props
  const [tableData, setTableData] = useState([])
  const [positionList, setPositionList] = useState([])

  const [editingKey, setEditingKey] = useState('');
  const [editStaffObj, setEditStaffObj] = useState({
    id: null,
    email: null,
    name: null,
    position: null,
    telephone_number: null,
    cellphone_number: null
  })

  const [nowHaveEditItem, setNowHaveEditItem] = useState(false)
  const isEditing = record => record.id === editingKey;

  useEffect(() => {
    getData()
  }, [enterpriseId])

  const getData = () => {
    var cmd_data = {
      "eip_enterprise_id": enterpriseId,
    }
    paasql("eip.eip_enterprise_contact.query_by_eip_enterprise_id", cmd_data).then(data => {
      if (data.error == null) {
        const tableData = data.result
        paasql('eip.eip_enterprise_contact.cp_standard_data.list_of_position', cmd_data).then(positionList => {
          if (positionList.error == null) {
            const result = positionList.result[0].position_list
            const tableDataWithPosition = []
            for (let i = 0; i < tableData.length; i++) {
              let attrObj = tableData[i]
              attrObj.index = i + 1
              const positionNameList = tableData[i].position_name == null ? [] : tableData[i].position_name.split(',')
              //处理职位
              attrObj.positionNameList = positionNameList
              attrObj.positionName = positionNameList.join(',')
              tableDataWithPosition.push(attrObj)
            }


            setTableData(tableDataWithPosition)
            setPositionList(result)
          } else {
            message.error(positionList.error.message)
          }
        })
      } else {
        message.error(data.error.message)
      }
    })
  }


  //检查除了索引之外有没有法人
  const checkSelectFaren = (index) => {
    var haveFaren = false

    for (let i = 0; i < tableData.length; i++) {
      //处理看当前是否有法人
      if (index !== i) {
        haveFaren = tableData[i].position_name.search('法人') !== -1
        if (haveFaren) {
          break
        }
      }

    }
    return haveFaren
  }
  const EditableCell = (
    {
      editing,
      dataIndex,
      inputType,
      record,
      index,
      children,
      ...restProps
    }
  ) => {
    const value = dataIndex === undefined ? '' : record[dataIndex]
    const inputNode = inputType === 'select' ?
      <Select
        suffixIcon={<CaretDownOutlined />}
        style={{ width: '100%' }}
        mode='multiple'
        onChange={(value, option) => changePosition(value, option, record, dataIndex)}
        defaultValue={record.positionNameList}
      >
        {
          positionList.map(item => {
            return (
              <Option
                value={item.name}
                key={item.id}
              >
                {item.name}
              </Option>
            )
          })
        }
      </Select>
      :
      <Input onChange={(e) => changeTableValue(e, record, index, dataIndex)} defaultValue={value} />;
    return (
      <td {...restProps}>
        {
          editing ? (
            <div>
              {inputNode}
            </div>
          ) : (
              children
            )}
      </td>
    );
  };
  const tableHeaders = [{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 70,
    fixed: 'left',
    align: 'center',
  }, {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    editable: true,
  }, {
    title: '职位',
    dataIndex: 'positionName',
    key: 'positionName',
    width: 150,
    editable: true,

  }, {
    title: '固定电话',
    dataIndex: 'telephone_number',
    key: 'telephone_number',
    width: 100,
    editable: true,
  }, {
    title: '手机号',
    dataIndex: 'cellphone_number',
    key: 'cellphone_number',
    width: 100,
    editable: true,
  }, {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    width: 130,
    editable: true,
  }, {
    title: '操作',
    key: 'email',
    width: 120,

    render: (text, record, index) => {
      const editable = isEditing(record);
      return editable ? (
        <ButtonGroup>
          <Button

          >
          </Button>
          <Button
            type='link'
            onClick={() => saveEdit(record, index)}

          >
            保存
          </Button>
          <Popconfirm title="确定取消编辑吗?" onConfirm={() => cancelEdit(record, index)}>
            <Button
              type='link'
            >
              取消
              </Button>
          </Popconfirm>
        </ButtonGroup>
      ) : (
          <ButtonGroup>
            <Button

            >
            </Button>
            <Button
              type='link'
              onClick={() => editStaff(record, index)}
            >
              编辑
          </Button>
            <Button
              type='link'
              onClick={() => delStaff(record)}
            >删除
          </Button>
          </ButtonGroup>
        );
    },


  }]

  const changeTableValue = (e, record, index, dataIndex) => {

    const value = e.target.value
    const tmpEditStaffObj = Object.assign(editStaffObj, record)
    tmpEditStaffObj[dataIndex] = value

    // 这里还是有问题，执行下面的代码，页面不会更新，还会失焦
    setEditStaffObj(tmpEditStaffObj)
  }

  const changePosition = (v, option, record, dataIndex) => {

    //设置position_id 
    const positionIdList = []
    option.forEach(element => {
      positionIdList.push(element.key)
    });
    const positionId = positionIdList.join(',')
    const tmpEditStaffObj = Object.assign(editStaffObj, record)
    tmpEditStaffObj.position_id = positionId
    tmpEditStaffObj.position_name = v.join(',')

    // 这里还是有问题，执行下面的代码，页面不会更新，还会失焦
    setEditStaffObj(tmpEditStaffObj)

  }

  const cancelEdit = (record, index) => {

    if (record.isNew) {
      const _tableData = []
      const tmpTableData = tableData.filter(item => item.id !== record.id)
      for (let i = 0; i < tmpTableData.length; i++) {

        let attrObj = tmpTableData[i]
        attrObj.index = i + 1
        _tableData.push(tmpTableData[i])

      }
      setEditingKey('')
      setTableData([..._tableData])
    } else {
      setEditingKey('');
    }
    setNowHaveEditItem(false)
  }
  const saveEdit = (record, index) => {

    if (record.isNew) {
      //说明是创建新的
      const {
        email,
        name,
        position_id,
        position_name,
        telephone_number,
        cellphone_number,
      } = editStaffObj
      const cmd_data = {
        object: {
          email,
          name,
          position_id,
          position_name,
          telephone_number,
          cellphone_number,
          eip_enterprise_id: enterpriseId,
          eip_enterprise_name: enterpriseName
        }
      }

      if (name == '' || name == null) {
        message.error("名字是必填项！")
        return
      }

      //处理更新企业的法人
      var selectFaren = position_name.search('法人') !== -1
      const haveLegalRepresentative = checkSelectFaren(index)
      if (selectFaren) {
        //目前的法人列表中包含了法人，让用户更改后在处理
        if (haveLegalRepresentative) {
          message.error('其他联系人中选择了法人，不能选择多个，请确认后在更改')
          return
        } else {
          //需要更新企业的法人信息
          const cmd_data = {
            cp_object_id: enterpriseId,
            cp_schema_id: "eip_enterprise",
            object: {
              legal_representative: name
            }
          }
          paasql('eip.eip_enterprise.update', cmd_data).then(date => {

          })
        }
      } else {


        if (haveLegalRepresentative) {
          //其他的企业已经有法人信息了，不做处理
        } else {
          message.error('联系人中必须要包含法人,请返回选择！')
        }
      }
      paasql('eip.eip_enterprise_contact.create', cmd_data).then(data => {
        if (data.error == null) {
          message.success('新建成功')
          setNowHaveEditItem(false)

          setEditingKey('')
          getData()
        } else {
          message.error(data.error.message)
        }
      })

    } else {
      //说明是更新

      const {
        id,
        email,
        name,
        position_id,
        position_name,
        telephone_number,
        cellphone_number
      } = editStaffObj
      const cmd_data = {
        id,
        email,
        name,
        position_id,
        position_name,
        telephone_number,
        cellphone_number
      }
      if (name == '' || name == null) {
        message.error("名字是必填项！")
        return
      }


      //处理更新企业的法人
      var selectFaren = position_name.search('法人') !== -1
      const haveLegalRepresentative = checkSelectFaren(index)
      if (selectFaren) {
        //目前的法人列表中包含了法人，让用户更改后在处理

        if (haveLegalRepresentative) {
          message.error('其他联系人中选择了法人，不能选择多个，请确认后在更改')
          return
        } else {
          //需要更新企业的法人信息
          const cmd_data = {
            cp_object_id: enterpriseId,
            cp_schema_id: "eip_enterprise",
            object: {
              legal_representative: name
            }
          }
          paasql('eip.eip_enterprise.update', cmd_data).then(date => {

          })
        }
      } else {
        if (haveLegalRepresentative) {
          //不做处理
        } else {
          //需要更新企业的法人信息
          message.error('联系人中必须要包含法人,请返回选择！')
          return
        }
      }

      paasql('eip.eip_enterprise_contact.update', cmd_data).then(data => {
        if (data.error == null) {
          message.success('保存成功')
          setEditingKey('')
          getData()
          setNowHaveEditItem(false)
        } else {
          message.error(data.error.message)
        }
      })

    }
  }
  const back = () => {
    hideStaffModal()
  }
  const delStaff = (record) => {
    Modal.confirm({
      title: '删除工作人员',
      content: '确认要删除该员工吗？',
      onOk() {
        confirmDel(record)
      }
    })
  }

  const editStaff = (record) => {
    setEditStaffObj(record)
    setEditingKey(record.id)
  }
  const confirmDel = (record) => {

    const { id,
      position_name,
      name
    } = record


    const isFaren = position_name.search('法人') !== -1

    if (isFaren) {
      //说明是法人，需要更新法人的信息
      const cmd_data = {
        cp_object_id: enterpriseId,
        cp_schema_id: "eip_enterprise",
        object: {
          legal_representative: ''
        }
      }

      //更新企业数据
      paasql('eip.eip_enterprise.update', cmd_data).then(date => {

      })
    }
    const cmd_data = {
      id
    }
    paasql('eip.eip_enterprise_contact.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        getData()
      } else {
        message.error(data.error.message)
      }
    })
  }

  const createStaff = () => {
    if (nowHaveEditItem) {
      message.error("当前已经有一个待保存项了，请先保存后再新增")
      return
    }
    setNowHaveEditItem(true)
    const newStaff = {
      isNew: true,
      id: tableData.length + 1
    }
    const tmpTableData = [newStaff, ...tableData]
    for (let i = 0; i < tmpTableData.length; i++) {
      let attrObj = tmpTableData[i]
      attrObj.index = i + 1
      if (i !== 1) {
        attrObj.editing = true
      } else {
        attrObj.isNew = false
        attrObj.editing = false
      }
    }
    setEditStaffObj({
      position_name: ''
    })
    setEditingKey(tmpTableData[0].id)
    setTableData([...tmpTableData])
  }




  const mergedColumns = tableHeaders.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'positionName' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });



  return (
    <div>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={tableData}
        bordered
        rowKey={
          record => record.index
        }
        pagination={{
          pageSize: 20,
          hideOnSinglePage: true
        }}
        //设置宽度
        scroll={{ x: '1200px', y: 750 }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button onClick={createStaff}>
          新增工作人员
        </Button>
        <Space>
          <Button onClick={back}>
            返回
            </Button>

        </Space>
      </div>
    </div>
  );
}

export default EditStaff;