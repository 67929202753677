import React, { useState, useEffect } from 'react';
import { paasql } from '@api'
import { InputNumber, Input, Button, Row, Col, message, Space } from 'antd'
import number from '@svg/number-range.svg'
import './index.less'
const cssPrefix = "park-setting"

const ParkDashboardSetting = () => {
  const [yearlyTaxPlanInYuan, setYearlyTaxPlanInYuan] = useState(0)
  const [keyEnterpriseRange, setKeyEnterpriseRange] = useState(0)
  const [taxBandList, setTaxBandList] = useState([])
  const [parkId, setParkId] = useState(null)
  const [lastRank, setLastRank] = useState(0)
  useEffect(() => {
    handleRequest()
  }, [])
  const handleRequest = () => {

    paasql("eip.park.list").then(eip_park_list => {
      if (eip_park_list.error == null) {
        var cmd_data = {
          "id": eip_park_list.result[0].id
        }
        paasql("eip.eip_park.query_yuan_and_range", cmd_data).then(yuan_and_range => {
          if (yuan_and_range.error == null) {
            setParkId(eip_park_list.result[0].id)
            var cmd_data = {
              "eip_park_id": eip_park_list.result[0].id
            }

            const res = yuan_and_range.result
            const { key_enterprise_range, yearly_tax_plan_in_yuan } = res
            setKeyEnterpriseRange(Number(key_enterprise_range))
            const yearlyTaxPlanInYuan = yearly_tax_plan_in_yuan / 100000000
            setYearlyTaxPlanInYuan(yearlyTaxPlanInYuan)
            paasql("eip.eip_tax_band.list", cmd_data).then(band_list => {
              if (band_list.error == null) {
                var eip_tax_band_list = band_list.result
                var eip_tax_band_list_with_sort = eip_tax_band_list.sort(function (first_item, last_item) {
                  return Number(first_item.name) - Number(last_item.name)
                })
                const firstValue = eip_tax_band_list_with_sort[0].tax_band_value
                const firstId = eip_tax_band_list_with_sort[0].id
                var eip_tax_band_list_label = [{
                  "display_name": "0",
                  "rank": "第1档",
                  id: firstId,
                  value: firstValue
                }]
                for (var i = 0; i < eip_tax_band_list_with_sort.length - 1; i++) {

                  var attr = new Object()
                  attr.id = eip_tax_band_list_with_sort[i + 1].id
                  attr.value = eip_tax_band_list_with_sort[i + 1].tax_band_value
                  attr.display_name = eip_tax_band_list_with_sort[i].tax_band_value
                  var rank = `第${(i + 2)}档`
                  attr.rank = rank
                  eip_tax_band_list_label.push(attr)
                }
                setLastRank(eip_tax_band_list_label[eip_tax_band_list_label.length - 1].value)
                setTaxBandList(eip_tax_band_list_label)
              }
              else {
                message.error(band_list.error.mesage)
              }
            })

          } else {
            message.error(yuan_and_range.error.mesage)
          }
        })
      } else {
        message.error(eip_park_list.error.mesage)
      }
    })
  }

  const changeYearlyTaxPlanInYuan = (value) => {
    setYearlyTaxPlanInYuan(value)
  }
  const changeKeyEnterpriseRange = (value) => {
    setKeyEnterpriseRange(value)
  }
  const changeRank = (value, index) => {
    var _eip_tax_band_list_label = []
    var tmpValue = Number(taxBandList[index].display_name)


    if (tmpValue < value) {
      for (var i = 0; i < taxBandList.length; i++) {
        var attr = taxBandList[i]
        if (i - 1 == index) {
          attr.display_name = value
        }
        if (i == index) {

          attr.value = value
        }
        if (index == 4) {
          setLastRank(value)
        }
        _eip_tax_band_list_label.push(attr)
      }
      setTaxBandList(_eip_tax_band_list_label)
    }
  }


  //保存
  const saveEdit = () => {

    var update_objects = []

    for (var i = 0; i < taxBandList.length; i++) {

      var attr_obj = new Object()
      attr_obj.id = taxBandList[i].id
      attr_obj.tax_band_value = taxBandList[i].value
      update_objects.push(attr_obj)
    }

    const cmd_data = {
      "id": parkId,
      "yearly_tax_plan_in_yuan": yearlyTaxPlanInYuan * 100000000,
      "key_enterprise_range": keyEnterpriseRange
    }

    return paasql("eip.eip_park.update_yuan_and_range", cmd_data).then(update_yuan_and_range => {
      if (update_yuan_and_range.error == null) {
        const request = {
          "input_update_objects": update_objects
        }
        return paasql("eip.eip_tax_band.update", request)
          .then(update_eip_tax_band_list => {
            if (update_eip_tax_band_list.error == null) {
              message.success("保存成功")
            }
            else {
              message.error(update_eip_tax_band_list.error.message)
            }
          })
      } else {
        message.error(update_yuan_and_range.error.message)
      }
    })
  }
  return (
    <>
      <div>
        <Row   >
          <Col span={12} >
            <div className={`${cssPrefix}-tax-plan-container`}>
              <div className={`${cssPrefix}-tax-plan-title`}>
                年度纳税计划：
              </div>
              <div className={`${cssPrefix}-tax-plan-input-container`}>
                <InputNumber
                  className={`${cssPrefix}-tax-plan-input`}
                  onChange={changeYearlyTaxPlanInYuan}
                  value={yearlyTaxPlanInYuan}
                  precision={2}
                  style={{ width: '90%' }}>
                </InputNumber>
                <span
                  className={`${cssPrefix}-tax-plan-suffix`}
                >
                  亿元
              </span>
              </div>


            </div>
          </Col>
          <Col span={12} >
            <div className={`${cssPrefix}-key-ent-container`}>
              <div className={`${cssPrefix}-key-ent-title`}>
                重点企业范围：
              </div>
              <div className={`${cssPrefix}-key-ent-input-container`}>
                <InputNumber
                  className={`${cssPrefix}-key-ent-input`}
                  onChange={changeKeyEnterpriseRange}
                  value={keyEnterpriseRange}
                  precision={0}
                  style={{ width: '90%' }}>
                </InputNumber>

                <span
                  className={`${cssPrefix}-key-ent-suffix`}
                >
                  家
              </span>
              </div>

            </div>

          </Col>
        </Row>
      </div>
      <div>
        <Row className={`${cssPrefix}-rank-list-container`} >
          <Col
            className={`${cssPrefix}-rank-list-title`}
            span={24}
          >
            季度纳税档位：
          </Col>
          {
            taxBandList.map((item, index) => {
              return (
                <Col
                  key={index}
                  span={12}
                >
                  <div className={`${cssPrefix}-rank-list-input-title`}>
                    {item.rank}
                  </div>
                  <div>

                    <Input.Group className={`${cssPrefix}-rank-list-input-group`}>
                      <InputNumber
                        className={`${cssPrefix}-rank-list-input-item`}
                        disabled={true}
                        value={item.display_name}
                      />
                      <div
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'rgba(245, 245, 245, 1)',
                          minWidth: '30px',
                          lineHeight: '40px',
                          minHeight: '40px',
                        }}>
                        <img src={number} alt=""
                          style={{
                            border: 0,
                          }}
                        />
                      </div>


                      <InputNumber
                        className={`${cssPrefix}-rank-list-input-item1`}
                        onChange={
                          (e) => changeRank(e, index)
                        }
                        value={item.value}
                      />
                      <span style={{
                        border: 0,
                        width: '10%',
                        paddingLeft: 10,
                        minWidth: '30px',
                        height: '30px',
                      }}
                      >
                        元
                      </span>
                    </Input.Group>
                  </div>
                </Col>
              )
            })
          }
          <Col span={12}>
            <div className={`${cssPrefix}-rank-list-input-title`}>
              {
                `第${taxBandList.length + 1}档`
              }
            </div>
            <div>
              <InputNumber
                className={`${cssPrefix}-rank-list-last-rank-input`}
                disabled={true}
                value={lastRank}
              />
              <span
                style={{
                  border: 0,
                  width: '10%',
                  paddingLeft: 10,
                  minWidth: '30px',
                  height: '30px',
                }} >
                元以上
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'center', margin: '50px 0px' }}>
        <Space>
          <Button type="primary" onClick={saveEdit} >
            确认保存
        </Button>
        </Space>

      </div>
    </>
  );
}

export default ParkDashboardSetting;