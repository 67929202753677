import React, { useState, useEffect } from 'react';

import {
  Button, Col, Row, Space, Input,
  Divider, message, Modal, Typography, Select, Empty
} from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'

import { paasql } from '@api'
import ButtonLabel from '@component/ButtonLabel'

import { CreateIcon } from '@icon'


const { Option } = Select;
const ParkStaffEdit = (props) => {
  const { reflushData,
    investmentAgencyList, setActiveId,
    centralDepartment,
    cpStaffList,
    setIsBenbu,
    parkId,
    parkName,
    setCreateInvestmentAgencyVisable
  } = props

  //员工列表
  const [staffList, setStaffList] = useState([])


  //编辑的对象
  const [editStaffObj, setEditStaffObj] = useState({})
  const [createStaffObj, setCreateStaffObj] = useState(null)



  useEffect(() => {
    //获取staffList

    setStaffList(cpStaffList)
    //获得招商中介的列表
  }, [cpStaffList])



  //模糊查询
  const selectSatff = (v, item) => {
    if (v == 'park') {
      setActiveId(null)
      setIsBenbu(true)
    } else {
      setActiveId(v)
      setIsBenbu(false)
    }

  }

  const changeStaffName = (e) => {
    const value = e.target.value
    setEditStaffObj({
      ...editStaffObj,
      name: value
    })
  }

  const changeStaffTelNumber = (e) => {
    const value = e.target.value
    setEditStaffObj({
      ...editStaffObj,
      china_mobile_phone_number: value
    })
  }

  const saveEditStaff = (item) => {
    if (item.isCreate) {
      //说明是新建
      const {
        name, china_mobile_phone_number
      } = editStaffObj
      if (name == undefined || name == '' || name == null) {
        message.error('名字是必填项，请先填写再保存')
        return
      }
      const cmd_data = {
        object: {
          eip_park_id: parkId,
          eip_park_name: parkName,
          name,
          china_mobile_phone_number
        }
      }

      paasql('eip.cp_staff.create', cmd_data).then(data => {
        if (data.error == null) {
          message.success('保存成功')
          setCreateStaffObj(null)
          setEditStaffObj({})
        } else {
          message.error(data.error.message)
        }
      })

      return
    }
    const {
      id, name,
      china_mobile_phone_number
    } = editStaffObj
    const cmd_data = {
      object: {
        id, name,
        china_mobile_phone_number
      }
    }
    paasql('eip.cp_staff.update', cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        setEditStaffObj({})
      } else {
        message.error(data.error.message)
      }
    })

  }

  const cancelEditStaff = (item, index) => {
    if (item.isCreate) {
      //说明是新建
      //删除掉一个
      const tmpStaffList = staffList
      const [first, ...rest] = tmpStaffList
      setStaffList([...rest])
      setCreateStaffObj(null)
      return
    }

    const tmpStaffList = staffList
    tmpStaffList.forEach(element => {
      element.isEditing = false
    });
    setStaffList([...tmpStaffList])
  }

  const editStaff = (e, item, index) => {

    if (editStaffObj == null) {
      message.error('有一个未保存的项')
      return
    }
    const tmpStaffList = staffList
    tmpStaffList.forEach((item, i) => {
      if (i == index) {
        item.isEditing = true
      } else {
        item.isEditing = false
      }
    });
    setStaffList([...tmpStaffList])
    setEditStaffObj(item)
  }
  const delStaff = (item, index) => {
    Modal.confirm({
      title: '删除园区工作人员',
      onOk() {
        confrimDelStaff(item)
      },
      content: <span>
        将删除所选本部招商员(<Typography.Text type="danger" >{item.name}</Typography.Text>)，不可恢复，是否继续？
    </span>
    })
  }
  const confrimDelStaff = (item) => {
    const cmd_data = {
      id: item.id
    }
    paasql('eip.cp_staff.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        reflushData()
      } else {
        message.error(data.error.message)
      }
    })
  }

  const createInvestmentAgency = () => {
    setCreateInvestmentAgencyVisable(true)
  }


  //创建工作人员
  const createStaff = () => {
    if (createStaffObj !== null) {
      message.error('有一个未保存的项')
      return
    }
    const createStaffOb = {
      isEditing: true,
      isCreate: true
    }
    setCreateStaffObj(
      {
        ...createStaffOb
      }
    )

    const tmpStaffList = staffList
    for (let i = 0; i < tmpStaffList.length; i++) {
      tmpStaffList[i].isEditing = false
    }

    setStaffList([createStaffOb, ...tmpStaffList])
  }
  return (
    <div >
      <div >
        <div className='investment-agency-staff-nav' >
          <div
            className='investment-agency-title'
          >
            招商人员
            </div>

          <div>
            <Space>
              <Button
                onClick={createInvestmentAgency}
              >新增招商部门</Button>
              <Button
                onClick={createStaff}
              >新增本部招商员
            </Button>
            </Space>

          </div>

        </div>
        <div className='investment-agency-staff-detail-container' style={{ height: 'calc(100vh  - 300px)' }}>
          {
            staffList.length === 0 ? <Empty /> :
              staffList.map((item, index) => {
                return (
                  <React.Fragment key={item.id} >
                    <Row style={{ marginTop: '10px' }} >
                      <Col
                        span={1}
                        style={{ textAlign: 'right', lineHeight: '28px' }}
                      >
                        {`${index + 1}、`}
                      </Col>
                      <Col span={8} className='investment-agency-satff-detail-item'>
                        <Row className='inv-container' >
                          <Col className='inv-label'>
                            姓名:
                        </Col>
                          <Col className='inv-value'>
                            {
                              item.isEditing ?
                                <Input className="inv-input" value={editStaffObj.name}
                                  onChange={(e) =>
                                    changeStaffName(e, item)}
                                /> :
                                <div className="inv-show">{item.name}</div>
                            }
                          </Col>
                        </Row>


                      </Col>

                      <Col span={8} className='investment-agency-satff-detail-item'>

                        <Row className='inv-container'>
                          <Col className='inv-label'>
                            手机号:
                        </Col>
                          <Col className='inv-value'>
                            {
                              item.isEditing ?
                                <Input className="inv-input"
                                  value={editStaffObj.china_mobile_phone_number}
                                  onChange={(e) => changeStaffTelNumber(e)}
                                />
                                :
                                <div className="inv-show">{item.china_mobile_phone_number}</div>
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7} style={{ textAlign: 'right', lineHeight: '28px' }}>
                        <Space>
                          {
                            item.isEditing ?
                              <>
                                <Button
                                  className='investment-agency-edit-button'
                                  onClick={() =>
                                    cancelEditStaff(
                                      item, index
                                    )}
                                >
                                  取消
                            </Button>
                                <Button
                                  className='investment-agency-edit-button'
                                  type="primary" onClick={() =>
                                    saveEditStaff(item, index)}>
                                  保存
                            </Button>
                              </>
                              :
                              <>
                                <Button
                                  className='investment-agency-edit-button'
                                  onClick={() =>
                                    delStaff(
                                      item, index
                                    )}
                                >
                                  删除
                            </Button>
                                <Button type="primary"
                                  className='investment-agency-edit-button'
                                  onClick={
                                    (e) => editStaff(e, item, index)}
                                >
                                  编辑
                            </Button>
                              </>
                          }
                        </Space>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '10px 0 2px  0' }} />
                  </React.Fragment>
                )
              })
          }
        </div>
      </div>
    </div>
  );
}

export default ParkStaffEdit;