import React, { useContext, useEffect, useState } from 'react';
import { message, Row, Col, Select, Space, Button, Modal } from 'antd'
import { ReachableContext, } from '../UploadModal'
import { CaretDownOutlined } from '@ant-design/icons';
import { paasql } from '@api'

const { Option } = Select;


const FieldMapping = (props) => {
  const { tableHeaderData, backToSelectHeader, importData, importLoading, current } = useContext(ReachableContext)
  const [header, setHeaders] = useState([])
  const [fieldList, setFieldList] = useState([])
  const [relationMap, setRelationMap] = useState([])
  const [unMapList, setUnMapList] = useState([])
  const [visiblable, setVisiblable] = useState(false)
  useEffect(() => {
    if (tableHeaderData.length == 0) {
      message.error("未选择表头，请返回选择", 1)
      // 设置延时效果
      setTimeout(() => {
        handleBackToSelectHeader()
      }, 1000);
      return
    }
    //这里还是处理字段
    setHeaders(tableHeaderData)

  }, [])
  useEffect(() => {
    paasql('eip.eip_monthly_accumulated_tax_record.attrs').then(data => {
      const config = data.result.body.fields
      for (let i = 0; i < config.length; i++) {
        for (let j = 0; j < header.length; j++) {
          if (config[i].display_name == header[j].name) {
            config[i].value = header[j].column
          }
        }
      }
      setFieldList(config)
    })
  }, [header])


  const list = [1, 2, 3, 4]
  //这是确定上传按钮
  const handleBackToSelectHeader = () => {
    backToSelectHeader()
  }
  const handleSelectListChange = (value, index) => {
    const tmpfieldList = fieldList
    tmpfieldList[index].value = value
    setFieldList(tmpfieldList)
  }


  //处理映射关系
  const handelRelationMap = () => {
    const relationMap = {}
    //判断是否进行完全映射
    for (let i = 0; i < fieldList.length; i++) {
      if (i != 2) {
        if (fieldList[i].value == undefined) {
          message.error("所有总库表字段都应被映射")
          return
        }
      }
    }
    for (let i = 0; i < fieldList.length; i++) {
      if (i !== 2) {//去除掉taxpayer_registration_number
        var NewObjValue = null;
        NewObjValue = fieldList[i].name
        var attr_name = String(fieldList[i].value)
        relationMap[attr_name] = NewObjValue
      } else {

      }
    }
    //这是映射的字段
    setRelationMap(relationMap)
    const exception_field_list = []
    for (var i = 0; i < header.length; i++) {
      var is_same = null
      for (var j = 0; j < fieldList.length; j++) {
        if (j == 2) {//去除taxpayer_registration_number这个字段
        } else {
          if (header[i].column == fieldList[j].value) {
            is_same = true
            break
          }
          else {
            is_same = false
          }
        }

      }
      if (is_same != true) {
        exception_field_list.push(header[i])
      }
    }
    setUnMapList(exception_field_list)
    setVisiblable(true)
  }



  const importDataTo = () => {
    importData(relationMap)
  }

  //隐藏模态框
  const hideModal = () => {
    setVisiblable(false)
  }
  return (
    <div >
      <div className="subModalHeader">
        映射Excel表的列与税收总库字段
      </div>

      <Row style={{ fontFamily: 'HiraginoSansGB-W6,HiraginoSansGB', lineHeight: '21px', paddingBottom: 16 }}>
        <Col span={6} style={{ textAlign: 'right', paddingRight: 80 }} >
          税收总库表字段
        </Col>
        <Col span={18}>
          Excel的列
        </Col>
      </Row>

      <div style={{ maxHeight: '500px', overflow: 'auto' }} >
        {
          fieldList.map((item, index) => {
            return (
              <Row key={item.name} style={{ paddingBottom: 16 }} >
                <Col span={6} style={{ textAlign: 'right', padding: '13px 80px 13px 0px', fontSize: '14px', lineHeight: '14px' }} >
                  {item.display_name}:
                </Col>
                <Col span={18}>
                  <Select
                    placeholder="请选择"
                    value={item.value}
                    suffixIcon={<CaretDownOutlined />}
                    size='large'
                    style={{ width: 640 }}
                    onChange={(value) => handleSelectListChange(value, index)}
                  >
                    {
                      header.map(item => {
                        return (
                          <Option value={item.column} key={item.column}>{item.name}</Option>
                        )
                      })
                    }

                  </Select>
                </Col>
              </Row>
            )
          })
        }
      </div>

      <div style={{ textAlign: 'right', paddingTop: 20 }} >
        <Space>
          <Button onClick={handleBackToSelectHeader}>
            上一步
          </Button>
          <Button type="primary" onClick={handelRelationMap}>
            下一步
          </Button>
        </Space>
      </div>
      <Modal
        title='导入字段异常提醒'
        okText={importLoading ? "上传中,请稍后..." : "确定上传"}
        cancelText="返回上一步"
        cancelButtonProps={{
          disabled: importLoading
        }}
        closable={false}
        centered={true}
        confirmLoading={importLoading}
        visible={visiblable}
        onOk={importDataTo}
        onCancel={hideModal}
      >
        <div>
          <div className="subModalHeader">
            以下Excel表的列未与税收总库字段建立映射
        </div>
          {
            unMapList.map(item => {
              return (
                <div
                  key={item.column}
                >
                  {item.name}
                </div>
              )
            })
          }
        </div>
      </Modal>
    </div >

  );
}

export default FieldMapping;