// 要对应的表格列(所有)
export const multiHeader = [
  ['', '', '申报单位（盖章）:', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '企业名称', '税务管理码', '企业扶持金额按税种分', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '增值税', '', '', '企业所得税', '', '', '所得税', '', '', '房产税', '', '', '扶持金额合计', '上年余留', '累计补差', '累计管理费', '累计代扣', '累计预付款', '累计应支付扶持金额', '累计实际支付金额', '所选时段未支付扶持金额', '招商点扶持金额合计', '招商点上年余留', '招商点累计补差', '招商点累计管理费', '招商点累计代扣', '招商点累计预付款', '招商点累计应支付金额', '招商点累计已支付金额', '招商点所选时间段未支付金额']
]


export const header = ['', '', '', '', '总税额', '扶持金额', '招商点扶持金额', '总税额', '扶持金额', '招商点扶持金额', '总税额', '扶持金额', '招商点扶持金额', '总税额', '扶持金额', '招商点扶持金额', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']



export const filterVal = [
  'index',
  'eip_investment_agency_name',
  'name',
  'tax_file_number',
  'total_value_added_tax',
  'total_value_added_tax_refund',
  'total_agency_value_added_tax_refund',
  'total_corporate_income_tax',
  'total_corporate_income_tax_refund',
  'total_agency_corporate_income_tax_refund',
  'total_individual_income_tax',
  'total_individual_income_tax_refund',
  'total_agency_individual_income_tax_refund',
  'total_property_tax',
  'total_property_tax_refund',
  'total_agency_property_tax_refund',
  'total_tax_refund',
  'last_year_statement_balance',
  'total_payment_difference',
  'total_management_fee',
  'total_payment_on_behalf',
  'total_prepayment',
  'total_accounts_payable',
  'total_actual_amount_paid',
  'unpaid_amount',
  'total_agency_tax_refund',
  'last_agency_year_statement_balance',
  'total_agency_payment_difference',
  'total_agency_management_fee',
  'total_agency_payment_on_behalf',
  'total_agency_prepayment',
  'total_agency_accounts_payable',
  'total_agency_actual_amount_paid',
  'agency_unpaid_amount'
]

export const merges = [
  'E1:U1',
  'C2:D2',
  'A3:A5',
  'B3:B5',
  'C3:C5',
  'D3:D5',
  'E3:U3',
  'E4:G4',
  'H4:J4',
  'K4:M4',
  'N4:O4',
  'Q4:Q5',
  'R4:R5',
  'S4:S5',
  'T4:T5',
  'U4:U5',
  'V4:V5', 'W4:W5', 'X4:X5', 'Y4:Y5', 'Z4:Z5', 'AA4:AA5', 'AB4:AB5', 'AC4:AC5', 'AD4:AD5', 'AE4:AE5', 'AF4:AF5', 'AG4:AG5', 'AH4:AH5',
]

// 只有企业部分　
export const enterpriseMultiHeader = [
  ['', '', '申报单位（盖章）:', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '企业名称', '税务管理码', '企业扶持金额按税种分', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '增值税', '', '企业所得税', '', '所得税', '', '房产税', '', '扶持金额合计', '上年余留', '累计补差', '累计管理费', '累计代扣', '累计预付款', '累计应支付扶持金额', '累计实际支付金额', '所选时段未支付扶持金额']
]
export const enterpriseHeader = ['', '', '', '', '税额', '扶持金额', '税额', '扶持金额', '税额', '扶持金额', '税额', '扶持金额', '', '', '', '', '', '', '', '', '']
export const enterpriseFilterVal = ['index', 'eip_investment_agency_name', 'name', 'tax_file_number', 'total_value_added_tax', 'total_value_added_tax_refund', 'total_corporate_income_tax', 'total_corporate_income_tax_refund', 'total_individual_income_tax', 'total_individual_income_tax_refund', 'total_property_tax', 'total_property_tax_refund', 'total_tax_refund', 'last_year_statement_balance', 'total_payment_difference', 'total_management_fee', 'total_payment_on_behalf', 'total_prepayment', 'total_accounts_payable', 'total_actual_amount_paid', 'unpaid_amount']

export const enterpriseMerges = ['E1:U1', 'C2:D2', 'A3:A5', 'B3:B5', 'C3:C5', 'D3:D5', 'E3:U3', 'E4:F4', 'G4:H4', 'I4:J4', 'K4:L4', 'M4:M5', 'N4:N5', 'O4:O5', 'P4:P5', 'Q4:Q5', 'R4:R5', 'S4:S5', 'T4:T5', 'U4:U5']

// 只有中介部分
export const agencyMultiHeader = [
  ['', '', '申报单位（盖章）:', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['序号', '招商点', '企业名称', '税务管理码', '企业扶持金额按税种分', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '增值税', '', '企业所得税', '', '所得税', '', '房产税', '', '招商点扶持金额合计', '招商点上年余留', '招商点累计补差', '招商点累计管理费', '招商点累计代扣', '招商点累计预付款', '招商点累计应支付金额', '招商点累计已支付金额', '招商点所选时间段未支付金额']
]
export const agencyHeader = ['', '', '', '', '总税额', '招商点扶持金额', '总税额', '招商点扶持金额', '总税额', '招商点扶持金额', '总税额', '招商点扶持金额', '', '', '', '', '', '', '', '', '']
export const agencyFilterVal = ['index', 'eip_investment_agency_name', 'name', 'tax_file_number', 'total_value_added_tax', 'total_value_added_tax_refund', 'total_corporate_income_tax', 'total_agency_corporate_income_tax_refund', 'total_individual_income_tax', 'total_agency_individual_income_tax_refund', 'total_property_tax', 'total_agency_property_tax_refund', 'total_agency_tax_refund', 'last_agency_year_statement_balance', 'total_agency_payment_difference', 'total_agency_management_fee', 'total_agency_payment_on_behalf', 'total_agency_prepayment', 'total_agency_accounts_payable', 'total_agency_actual_amount_paid', 'agency_unpaid_amount']
export const agencyMerges = ['E1:U1', 'C2:D2', 'A3:A5', 'B3:B5', 'C3:C5', 'D3:D5', 'E3:U3', 'E4:F4', 'G4:H4', 'I4:J4', 'K4:L4', 'M4:M5', 'N4:N5', 'O4:O5', 'P4:P5', 'Q4:Q5', 'R4:R5', 'S4:S5', 'T4:T5', 'U4:U5']