import React, { useState, useEffect } from 'react';
import { paasql } from '@api'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import {
  Button, Input, DatePicker, Select,
  Col, Row, Space, InputNumber, message, Collapse
} from 'antd'
import { connect } from 'react-redux'

import { CancelEditIcon, CreateDocIcon } from '@icon'
import moment from 'moment'


const inputClassName = 'edit-enterprise-info-input-form'
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse

const isInPark = [{
  "id": "1",
  "name": "是"
},
{
  "id": "0",
  "name": "否"
}]
const enterpriseSourceList = [
  {
    id: '0',
    name: '园区注册'
  }, {
    id: '1',
    name: '迁入'
  }, {
    id: '2',
    name: '迁入(区外)'
  }
]


const EnterpriseEdit = (props) => {
  const enterpriseId = props.match.params.id
  const { Frame, dispatch } = props

  const [pageChange, setPageChange] = useState(false)
  const [eipBusinessYearList, setEipBusinessYearList] = useState([])
  var [cpStaffList, setCpStaffList] = useState([])
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])
  const [investmentAgencyStaffList, setInvestmentAgencyStaffList] = useState([])
  var [investmentAgencyStaffListAll, setInvestmentAgencyStaffListAll] = useState([])

  //下拉列表相关配置
  const [investmentDepartment, setInvestmentDepartment] = useState([])
  const [settledByList, setSettledByList] = useState([])
  const [settledStatusList, setSettledStatusList] = useState([])
  const [recordingGenreList, setRecordingGenreList] = useState([])

  const [taxStatusList, setTaxStatusList] = useState([])

  const [licenseStatusList, setLicenseStatusList] = useState([])
  //内外资
  const [registeredGenreList, setRegisteredGenreList] = useState([])

  //企业类型
  const [enterpriseGenreList, setEnterpriseGenreList] = useState([])

  const [enterpriseInfo, setEnterpriseInfo] = useState({
    registered_genre: '注册型企业'
  })



  const [businessTermStartedAt, setBusinessTermStartedAt] = useState(null)
  const [businessTermEndedAt, setBusinessTermEndedAt] = useState(null)
  const [establishedIn, setEstablishedIn] = useState(null)
  const [exitedAt, setExitedAt] = useState(null)
  //用来判读是否更改了离园
  const [defaultExitedAt, setDefaultExitedAt] = useState(null)
  //用来判读是否更改了离园
  const [defaultSettleAt, setDefaultSettleAt] = useState(null)
  const [settledAt, setSettledAt] = useState(null)
  const [firstTaxPayoutAt, setFirstTaxPayoutAt] = useState(null)

  //判断是否为中介招入的判断，用来前端判断展示
  const [isSettledByPark, setIsSettledByPark] = useState(true)
  //招商员的展示
  const [staff, setStaff] = useState(null)

  const [staffList, setStaffList] = useState([])
  //招商部门

  const [department, setDepartment] = useState(null)
  useEffect(() => {
    const cmd_data = {
      eip_enterprise_id: enterpriseId
    }
    handelEnterpriseInfo(cmd_data)
    //获得财务年数据
    getEipBusinessYearList()
    return () => {
      setPageChange(!pageChange)
    }
  }, [enterpriseId])


  //获取财务年的数据
  const getEipBusinessYearList = () => {
    paasql('eip.eip_business_year.list').then(data => {
      const result = data.result
      //排序
      const resultWithSort = result.sort(function (first, last) {
        return Number(first.name) - Number(last.name)
      })
      setEipBusinessYearList(resultWithSort)
    })
  }

  //处理企业的信息
  const handelEnterpriseInfo = (cmd_data) => {
    paasql('eip.eip_enterprise.get_eip_park_cp_staff_eip_investment_agency_eip_investment_agency_staff.list').then(data => {
      const [l, cp_staff_list, investment_agency_list, investment_agency_staff_list] = data.result.parallel_list_object
      //中介列表
      const cpStaffList1 = cp_staff_list.cp_staff_name
      const investmentAgencyList = investment_agency_list.eip_investment_agency_name
      const investmentAgencyStaffList = investment_agency_staff_list.eip_investment_agency_staff_name
      setCpStaffList(cpStaffList1)
      setInvestmentAgencyList(investmentAgencyList)
      cpStaffList = cpStaffList1
      //所有的招商中介
      investmentAgencyStaffListAll = investmentAgencyStaffList
      setInvestmentAgencyStaffListAll(investmentAgencyStaffList)
      setInvestmentAgencyStaffList(investmentAgencyStaffList)


      paasql('eip.eip_enterprise.get_cp_standard_data_from_cp_standard_collection').then(data => {
        const result = data.result.parallel_list_object

        //处理下拉列表的数据问题
        const [
          settled_by,//招商类别
          investment_department,//招商部门
          settled_status,//企业情况
          recording_genre,//注册类型
          tax_status, //税务状态
          registered_genre,//内外资
          enterprise_genre,//企业类型
          license_status //工商状态
        ] = result

        //招商类别
        const settledBy = settled_by.settled_by[0].result

        //入驻状态
        const settledStatus = settled_status.settled_status[0].result

        //园区登记类型
        const recordingGenre = recording_genre.recording_genre[0].result

        //税务状态
        const taxStatus = tax_status.tax_status[0].result

        //招商部门
        const investmentDepartment = investment_department.investment_department[0].result

        //内外资
        const registeredGenre = registered_genre.registered_genre[0].result

        //企业类型
        const enterpriseGenre = enterprise_genre.enterprise_genre[0].result


        const licenseStatus = license_status.license_status[0].result

        setLicenseStatusList(licenseStatus)
        setInvestmentDepartment(investmentDepartment)
        setSettledByList(settledBy)
        setSettledStatusList(settledStatus)
        setRecordingGenreList(recordingGenre)
        setTaxStatusList(taxStatus)
        setRegisteredGenreList(registeredGenre)
        setEnterpriseGenreList(enterpriseGenre)

        paasql('eip.eip_enterprise.enterprise_and_standard_data_by_enterprise_id', cmd_data).then(data => {
          const result = data.result
          if (result.length !== 0) {


            //查到的时候
            const {
              name,
              business_term_started_at,
              business_term_ended_at, established_in, settled_at,
              exited_at, first_tax_payout_at,
              eip_investment_agency_id,
              cp_staff_id,
              eip_investment_agency_staff_id,
              settled_by_code
            } = result[0]

            //处理面包屑的问题
            const arr = ['基本信息管理', '企业信息管理', name]

            dispatch({
              type: "SET_BREADCRUMB_LIST",
              value: arr
            })

            //处理招商类别

            if (settled_by_code == '02') {
              setIsSettledByPark(false)
              setDepartment(eip_investment_agency_id)
              setStaff(eip_investment_agency_staff_id)
              const tmp_list = investmentAgencyStaffList.filter(
                item => item.eip_investment_agency_id == eip_investment_agency_id
              )
              setStaffList(tmp_list)
            } else {
              setIsSettledByPark(true)
              setDepartment('park')
              setStaff(cp_staff_id)
              setStaffList(cpStaffList)
            }
            //营业日期自
            const businessTermStartedAt =
              business_term_started_at === null ||
                business_term_started_at === ''
                ? null
                : moment(business_term_started_at).format('YYYY-MM-DD')

            //营业日期至
            const businessTermEndedAt =
              business_term_ended_at === null ||
                business_term_ended_at === '' ||
                business_term_ended_at === undefined
                ? null
                : moment(business_term_ended_at).format('YYYY-MM-DD')

            //成立日期
            const establishedIn =
              established_in === null ||
                established_in === '' ||
                established_in === undefined
                ? null
                : moment(established_in).format('YYYY-MM-DD')

            //入园时间    
            const settledAt =
              settled_at === null ||
                settled_at === '' ||
                settled_at === undefined
                ? null
                : moment(settled_at).format('YYYY-MM-DD')
            //离园时间    
            const exitedAt =
              exited_at === null ||
                exited_at === '' ||
                exited_at === undefined
                ? null
                : moment(exited_at).format('YYYY-MM-DD')

            //首次纳税月
            if (
              first_tax_payout_at === null ||
              first_tax_payout_at === '' ||
              first_tax_payout_at === undefined
            ) {

            } else {
              const firstTaxPayoutAtYear = first_tax_payout_at.slice(0, 4)
              const firstTaxPayoutAtMonth = first_tax_payout_at.slice(4, 6)
              const firstTaxPayoutAt = `${firstTaxPayoutAtYear}-${firstTaxPayoutAtMonth}`
              setFirstTaxPayoutAt(firstTaxPayoutAt)
            }

            setExitedAt(exitedAt)
            setDefaultExitedAt(exitedAt)

            setDefaultSettleAt(settledAt)
            setSettledAt(settledAt)


            setEstablishedIn(establishedIn)
            setBusinessTermStartedAt(businessTermStartedAt)
            setBusinessTermEndedAt(businessTermEndedAt)
            setEnterpriseInfo(result[0])
          } else {
            message.error('未找到该企业，请重新选择企业')
            props.history.push(`/enterprise-database/detail/${enterpriseId}`)
          }
        })
      })
    })


  }

  const cancelEdit = () => {
    props.history.push(`/enterprise-database/detail/${enterpriseId}`)
  }
  const saveEdit = () => {
    delete enterpriseInfo.paasql_parallel
    const { id } = enterpriseInfo
    const cmd_data = {
      cp_object_id: enterpriseId,
      cp_schema_id: "eip_enterprise",
      object: {
        ...enterpriseInfo
      }
    }
    paasql('eip.eip_enterprise.update', cmd_data).then(data => {
      if (data.error == null) {
        //离园改变
        const exitedAtChange = defaultExitedAt === exitedAt ? false : true
        //进园改变
        const settledAtChange = defaultSettleAt === settledAt ? false : true
        //更新离进园信息
        const {
          eip_park_name,
          eip_park_id,
          id,
          eip_investment_agency_id,
          settled_at
        } = data.result


        if (settledAtChange) {
          //进园信息改变了，需要增加一条进园信息
          const settledAtYear = moment(settledAt).year()
          const settledAtMonth = ('0' + String(moment(settledAt).month() + 1)).slice(-2)
          const settledAtDay = ('0' + String(moment(settledAt).date())).slice(-2)
          const create_settled_at_record = {
            object: {
              eip_park_id,
              eip_park_name,
              eip_enterprise_id: id,
              eip_investment_agency_id,
              settled_status: 'eaf5b81dcea241aa83d6172998194cdc',
              settled_status_code: '01',
              "year": settledAtYear,
              "month": settledAtMonth,
              "day": settledAtDay
            }

          }
          paasql('eip.eip_enterprise_settled_or_exited_record.create', create_settled_at_record).then(
            create_settled_at_record_result => {
              if (create_settled_at_record_result.error == null) {
                //判断是否更新离园信息
                if (exitedAtChange) {
                  const exitedAtYear = moment(exitedAt).year()
                  const exitedAtMonth = ('0' + String(moment(exitedAt).month() + 1)).slice(-2)
                  const exitedAtDay = ('0' + String(moment(exitedAt).date())).slice(-2)
                  const create_exited_at_record = {
                    object: {
                      eip_park_id,
                      eip_park_name,
                      eip_enterprise_id: id,
                      eip_investment_agency_id,
                      settled_status: '0ecdc3865bb544479e17abae5a041cb4',
                      settled_status_code: '02',
                      "year": exitedAtYear,
                      "month": exitedAtMonth,
                      "day": exitedAtDay
                    }
                  }
                  paasql('eip.eip_enterprise_settled_or_exited_record.create', create_exited_at_record).then(
                    create_exited_at_record => {
                      if (create_exited_at_record.error == null) {
                        message.success('更新企业信息成功，创建了离进园记录成功')
                        props.history.push(`/enterprise-database/detail/${enterpriseId}`)

                      } else {
                        message.success("更新企业信息成功，创建了一条进园记录,但创建离园记录失败", 4)
                        props.history.push(`/enterprise-database/detail/${enterpriseId}`)

                        message.error(create_exited_at_record.error.message, 5)
                      }
                    })
                } else {
                  message.success('更新企业信息成功，创建了一条进园记录')
                  props.history.push(`/enterprise-database/detail/${enterpriseId}`)

                }
              } else {
                message.error(create_settled_at_record_result.error.message)
              }
            })
        } else {
          if (exitedAtChange) {
            const exitedAtYear = moment(exitedAt).year()
            const exitedAtMonth = ('0' + String(moment(exitedAt).month() + 1)).slice(-2)
            const exitedAtDay = ('0' + String(moment(exitedAt).date())).slice(-2)
            const create_exited_at_record = {
              object: {
                eip_park_id,
                eip_park_name,
                eip_enterprise_id: id,
                eip_investment_agency_id,
                settled_status: '0ecdc3865bb544479e17abae5a041cb4',
                settled_status_code: '02',
                "year": exitedAtYear,
                "month": exitedAtMonth,
                "day": exitedAtDay
              }
            }
            paasql('eip.eip_enterprise_settled_or_exited_record.create', create_exited_at_record).then(
              create_exited_at_record => {
                if (create_exited_at_record.error == null) {
                  props.history.push(`/enterprise-database/detail/${enterpriseId}`)

                  message.success('更新企业信息成功，创建了一条离园记录', 4)
                } else {
                  props.history.push(`/enterprise-database/detail/${enterpriseId}`)

                  message.success("更新企业信息成功，创建了一条进园记录,但创建离园记录失败", 4)
                  message.error(create_exited_at_record.error.message, 5)
                }
              })
          } else {
            message.success("更新企业信息成功", 4)
            props.history.push(`/enterprise-database/detail/${enterpriseId}`)
          }
        }
        // props.history.push(`/enterprise-database/detail/${enterpriseId}`)
      } else {
        message.error(data.error.message)
      }
    })
  }




  //大部分情况
  const changeFilelds = ({ fields, value }) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      [fields]: value
    })
  }

  //改变企业名
  const changeName = (e) => {
    const value = e.target.value

    setEnterpriseInfo({
      ...enterpriseInfo,
      name: value
    })
  }

  //改变档案号
  const changeFileNumber = (e) => {
    const value = e.target.value

    setEnterpriseInfo({
      ...enterpriseInfo,
      file_number: value
    })
  }


  //更改工商状态
  const changeLicenseStatus = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      license_status: value
    })
  }

  //更改注册号
  const changeRegistrationNumber = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      registration_number: value
    })
  }

  //更改法定代表人
  const changeLegalRepresentative = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      legal_representative: value
    })
  }

  //更改注册资本
  const changeRegisteredCapital = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_capital: value
    })
  }

  //更改实收资本
  const changePaidupCapital = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      paidup_capital: value
    })
  }


  //更改营业年限
  const changeBusinessTerm = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term: value
    })
  }

  //营业期限自
  const changeBusinessTermStartAt = (data, dateString) => {

    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term_started_at: time
    })
    setBusinessTermStartedAt(dateString)
  }
  //营业期限至
  const changeBusinessTermEndAt = (date, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      business_term_ended_at: time
    })
    setBusinessTermEndedAt(dateString)
  }

  //更改登记机关
  const changeRegisteredBy = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_by: value
    })
  }

  //更改成立日期
  const changeEstablishedIn = (date, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEstablishedIn(dateString)
    setEnterpriseInfo({
      ...enterpriseInfo,
      established_in: time
    })
  }

  //更改公司类型
  const changeEnterpriseGenre = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      enterprise_genre: value
    })
  }

  //更改行业分类
  const changeIndustry = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      industry: value
    })
  }


  //更改曾用名
  const changeFormerName = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      former_name: value
    })
  }

  //更改注册类型
  const changeRegisteredGenre = (value, option) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      registered_genre: value
    })
  }

  //更改经营范围
  const changeScopeOfBusiness = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'scope_of_business',
      value
    })
  }


  //大多数修改的情况

  //更改注册地址
  const changeRegisteredAddress = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'registered_address',
      value
    })
  }


  //更改经营地址
  const changeBusinessAddress = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'business_address',
      value
    })
  }
  //公司邮编
  const changeZip = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'zip_code',
      value
    })
  }
  //更改固定电话
  const changeTelephoneNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'telephone_number',
      value
    })
  }

  //更改备注
  const changeRemarks = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'remarks',
      value
    })
  }

  //更改注册类型
  const changeRecordingGenre = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      recording_genre: value
    })
  }

  const changeUscc = (e) => {
    const value = e.target.value
    setEnterpriseInfo({
      ...enterpriseInfo,
      uscc: value
    })
  }
  const enterpriseBaseInfoList = [

    {
      id: "uscc",
      span: 12,
      label: '统一信用代码',
      component:
        <Input
          style={{ width: '100%' }}
          className={inputClassName}
          value={enterpriseInfo.uscc} onChange={changeUscc} />
    },
    {
      id: "enterprise_genre",
      span: 12,
      label: '公司类型',
      component:
        <Select

          style={{ width: '100%' }}
          className={inputClassName}
          suffixIcon={<CaretDownOutlined />}
          value={enterpriseInfo.enterprise_genre}
          onChange={changeEnterpriseGenre} >
          {
            enterpriseGenreList.map(item => {
              return (
                <Option key={item.id} value={item.name} >{item.name}</Option>
              )
            })
          }
        </Select>

    }, {
      id: "registered_capital",
      span: 6,
      label: '注册资本(万元)',
      component:
        <InputNumber
          style={{ width: '100%' }}
          className={inputClassName} min={0} precision={2} value={enterpriseInfo.registered_capital} onChange={changeRegisteredCapital} />
    }, {
      id: "established_in",
      span: 6,
      label: '成立日期',
      component:
        <DatePicker
          allowClear={false}
          style={{ width: '100%' }}
          className={inputClassName}
          value={
            establishedIn == null ? undefined :
              moment(establishedIn, 'YYYY-MM-DD')
          }

          onChange={changeEstablishedIn} />
    }, {
      id: "business_term_started_at",
      span: 6,
      label: '营业期限自',
      component:
        <DatePicker
          style={{ width: '100%' }}
          className={inputClassName}
          allowClear={false}
          value={
            businessTermStartedAt == null
              ? undefined :
              moment(businessTermStartedAt, 'YYYY-MM-DD')
          }
          // value={enterpriseInfo.business_term_started_at}
          onChange={changeBusinessTermStartAt} />
    }, {
      id: "business_term_ended_at",
      span: 6,
      label: '营业期限至',
      component:
        <DatePicker
          style={{ width: '100%' }}
          allowClear={false}
          className={inputClassName}
          value={
            businessTermEndedAt == null ? undefined :
              moment(businessTermEndedAt, 'YYYY-MM-DD')
          }

          onChange={changeBusinessTermEndAt} />
    }, {
      id: "paidup_capital",
      span: 6,
      label: '实收资本(万元)',
      component:
        <InputNumber
          style={{ width: '100%' }}
          className={inputClassName} min={0} value={enterpriseInfo.paidup_capital} onChange={changePaidupCapital} />
    },
    {
      id: "business_term",
      span: 6,
      label: '营业年限',
      component:
        <InputNumber
          style={{ width: '100%' }}
          className={inputClassName} precision={0} min={0} value={enterpriseInfo.business_term} onChange={changeBusinessTerm} />
    },
    {
      id: "licensx_status",
      label: '工商状态',
      span: 6,

      //
      component:
        <Select
          style={{ width: '100%' }}
          className={inputClassName}
          suffixIcon={<CaretDownOutlined />}
          value={enterpriseInfo.license_status}
          onChange={changeLicenseStatus} >
          {
            licenseStatusList.map(item => {
              return (
                <Option key={item.id} value={item.name} >{item.name}</Option>
              )
            })
          }
        </Select>
    },
    {
      id: "registered_address",
      span: 18,
      label: '注册地址',
      component:
        <Input className={inputClassName} value={enterpriseInfo.registered_address} onChange={changeRegisteredAddress} />
    },
    {
      id: "telephone_number",
      span: 6,
      label: '固定电话',
      component:
        <Input className={inputClassName} type='tel' value={enterpriseInfo.telephone_number} onChange={changeTelephoneNumber} />
    }, {
      id: "business_address",
      span: 18,
      label: '经营地址',
      component:
        <Input className={inputClassName} value={enterpriseInfo.business_address} onChange={changeBusinessAddress} />
    }, {
      id: "zip_code",
      span: 6,
      label: '公司邮编',
      component:
        <Input className={inputClassName} value={enterpriseInfo.zip_code} onChange={changeZip} />
    },

    {
      id: "scope_of_business",
      span: 24,
      label: '经营范围',
      component:
        <TextArea
          className={inputClassName}
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={enterpriseInfo.scope_of_business}
          onChange={changeScopeOfBusiness} />
    },


    //原来是输入型的
    //现在改为下拉选项框





  ]


  //更改 税管码
  const changeTaxFileNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'tax_file_number',
      value
    })
  }
  const changeTaxpayerRegistrationNumber = (e) => {
    const value = e.target.value
    changeFilelds({
      fields: 'taxpayer_registration_number',
      value
    })
  }
  const changeTaxStatus = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      tax_status: value
    })
  }
  const taxInfoList = [
    {
      id: "taxpayer_registration_number",
      span: 8,
      label: '税号',
      component:
        <Input
          className={inputClassName} value={enterpriseInfo.taxpayer_registration_number}
          onChange={changeTaxpayerRegistrationNumber} />,
    },
    {
      id: "tax_file_number",
      label: '税管码',
      span: 8,
      component:
        <Input className={inputClassName}
          value={enterpriseInfo.tax_file_number} onChange={changeTaxFileNumber} />,
    },
    {
      id: "tax_status",
      span: 8,
      label: '税务状况',
      component:
        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.tax_status}
          onChange={changeTaxStatus} >
          {
            taxStatusList.map(item => {
              return (
                <Option key={item.id} >{item.name}</Option>
              )
            })
          }
        </Select>

    }
  ]


  const bankInfoList = [
    // {
    //   id: "bank_account_name",
    //   label: '户名',
    //   span: 12,
    //   component:
    //     <Input value={enterpriseInfo.bank_account_name} onChange={changeName} />,
    // }, {
    //   id: "bank_name",
    //   span: 12,
    //   label: '开户行',
    //   component:
    //     <Input value={enterpriseInfo.bank_name} onChange={changeName} />,
    // }, {
    //   id: "bank_branch",
    //   span: 12,
    //   label: '银行网点',
    //   component:
    //     <Input value={enterpriseInfo.bank_branch} onChange={changeName} />,
    // }, {
    //   id: "bank_account_number",
    //   span: 12,
    //   label: '银行账号',
    //   component:
    //     <Input value={enterpriseInfo.bank_account_number} onChange={changeName} />,
    // }
  ]


  const changeSettledAt = (data, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      settled_at: time
    })
    setSettledAt(dateString)
  }

  const changeExitedAt = (data, dateString) => {
    const time = dateString.replace(/-/g, '')
    setEnterpriseInfo({
      ...enterpriseInfo,
      exited_at: time
    })

    setExitedAt(dateString)
  }


  const changeRegisteredInPark = (value) => {
    setEnterpriseInfo({
      ...enterpriseInfo,
      is_registered_in_this_park: value
    })
  }


  const changeSettledStatus = (v, option) => {
    const { key, value } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      settled_status: value,
      settled_status_code: key
    })
  }

  const changeEnterpriseSource = (e, option) => {
    const { key, value } = option

    setEnterpriseInfo({
      ...enterpriseInfo,
      enterprise_source: value
    })
  }


  //更改园区招商类别
  const changeSettledBy = (v, option) => {
    const { key, value, children } = option
    if (children == '中介') {
      setIsSettledByPark(false)
    } else {
      setIsSettledByPark(true)
    }
    setEnterpriseInfo({
      ...enterpriseInfo,
      settled_by: value,
      settled_by_code: key
    })
  }


  //本部
  const park = [
    {
      name: '本部',
      id: 'park'
    }
  ]
  //更改园区招商部门
  const changeInvestmentDepartment = (v, option) => {
    setStaff(undefined)

    const { key, value } = option
    setDepartment(value)
    if (value === 'park') {
      //说明改的是本部招入  
      setStaffList(cpStaffList)
      //处理是招商中介的id

      //清除员工的选中状态
      const index = settledByList.findIndex(item => item.name === '本部')
      setIsSettledByPark(true)
      setEnterpriseInfo({
        ...enterpriseInfo,
        investment_department_name: null,
        investment_department_id: null,
        eip_investment_agency_name: null,
        eip_investment_agency_id: null,
        settled_by: settledByList[index].id,
        settled_by_code: settledByList[index].code

      })
    } else {
      const tmp_list = investmentAgencyStaffListAll.filter(
        item => item.eip_investment_agency_id == value
      )
      const index = settledByList.findIndex(item => item.name === '中介')
      setIsSettledByPark(false)
      setStaffList(tmp_list)
      setEnterpriseInfo({
        ...enterpriseInfo,
        eip_investment_agency_name: key,
        eip_investment_agency_id: value,
        settled_by: settledByList[index].id,
        settled_by_code: settledByList[index].code
      })
    }

  }
  //更改本部招商员名 
  const changeStaffName = (v, option) => {
    const { key, value, children } = option
    setStaff(value)
    if (isSettledByPark) {
      //本部招入
      setEnterpriseInfo({
        ...enterpriseInfo,
        eip_investment_agency_staff_id: null,
        eip_investment_agency_staff_name: null,
        cp_staff_name: children,
        cp_staff_id: value,
      })
    } else {
      //中介招入
      setEnterpriseInfo({
        ...enterpriseInfo,
        eip_investment_agency_staff_id: value,
        eip_investment_agency_staff_name: children,
        cp_staff_name: null,
        cp_staff_id: null,

      })
    }
  }

  //

  const changeInvestmentAgency = (value, option) => {
    const { children } = option

    const tmp_list = investmentAgencyStaffListAll.filter(
      item => item.eip_investment_agency_id == value
    )
    setInvestmentAgencyStaffList(tmp_list)
    setEnterpriseInfo({
      ...enterpriseInfo,
      eip_investment_agency_id: value,
      eip_investment_agency_name: children,
    })
  }
  const changeInvestmentAgencyStaff = (value, option) => {
    const { children } = option
    setEnterpriseInfo({
      ...enterpriseInfo,
      eip_investment_agency_staff_id: value,
      eip_investment_agency_staff_name: children
    })
  }

  const basicInfoList = [
    {
      id: "file_number",
      label: '档案号:',
      span: 6,
      component:
        <Input className={inputClassName} value={enterpriseInfo.file_number} onChange={changeFileNumber} />
    },
    {
      id: "name",
      label: '企业名称:',
      span: 12,
      component:
        <Input className={inputClassName} placeholder="" value={enterpriseInfo.name} onChange={changeName} />
    },
    {
      id: "1",
      label: '',
      span: 6,
    },
    {
      id: "settled_at",
      label: '入园时间:',
      span: 6,
      component:
        <DatePicker
          allowClear={false}

          style={{ width: '100%' }}
          className={inputClassName}
          value={
            settledAt == null ? undefined :
              moment(settledAt, 'YYYY-MM-DD')
          }
          onChange={changeSettledAt}
        />,
    }, {
      id: "enterprise_source",
      span: 6,

      label: '企业来源:',
      component:

        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.enterprise_source} onChange={changeEnterpriseSource}
        >
          {
            enterpriseSourceList.map(item => {
              return (
                <Option
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    }, {
      id: "investment_department",
      span: 6,
      label: '招商部门:',
      component:
        <Select

          placeholder={'招商部门'}
          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={department}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={changeInvestmentDepartment}
        >
          {
            (park.concat(investmentAgencyList)).map(item => {
              return (
                <Option key={item.name} value={item.id}>
                  {item.name}
                </Option>
              )
            })
          }
        </Select>

    }, {
      id: "settled_status",
      span: 6,

      //原字段：‘入驻状态’
      label: '企业情况:',
      component:

        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.settled_status} onChange={changeSettledStatus}
        >
          {
            settledStatusList.map(item => {
              return (
                <Option key={item.code} value={item.id}>
                  {item.name}
                </Option>
              )
            })
          }
        </Select>,
    }, {
      id: "recording_genre",
      span: 6,
      label: '注册类型:',
      component:
        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.recording_genre} onChange={changeRecordingGenre}
        >
          {
            recordingGenreList.map(item => {
              return (
                <Option key={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: "industry",
      span: 6,
      label: '行业分类:',
      component:
        <Input className={inputClassName} value={enterpriseInfo.industry} onChange={changeIndustry} />
    }, {
      id: "cp_staff_name",
      span: 6,
      label: '招商员:',
      component:

        <Select

          placeholder={'招商员'}
          className={inputClassName}
          style={{ width: '100%' }}
          suffixIcon={<CaretDownOutlined />}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          value={staff}
          onChange={changeStaffName}
        >
          {
            staffList.map(item => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    },
    // {
    //   id: "exited_at",
    //   span: 6,
    //   label: '离园时间',
    //   component:
    //     <DatePicker
    //       allowClear={false}

    //       style={{ width: '100%' }}
    //       className={inputClassName}
    //       value={
    //         exitedAt == null ? undefined :
    //           moment(exitedAt, 'YYYY-MM-DD')
    //       }
    //       onChange={changeExitedAt} />,
    // },
    {
      id: "registered_genre",
      span: 6,
      label: '内外资:',
      component:
        <Select

          style={{ width: '100%' }}
          className={inputClassName}
          suffixIcon={<CaretDownOutlined />}
          value={enterpriseInfo.registered_genre}
          onChange={changeRegisteredGenre} >
          {
            registeredGenreList.map(item => {
              return (
                <Option key={item.id} >{item.name}</Option>
              )
            })
          }
        </Select>
    }, {
      id: "former_name",
      span: 6,
      label: '曾用名:',
      component:
        <Input className={inputClassName} value={enterpriseInfo.former_name} onChange={changeFormerName} />
    },

    {
      id: "remarks",
      span: 12,
      label: '备注:',
      component:
        <Input className={inputClassName} value={enterpriseInfo.remarks} onChange={changeRemarks} />
    },

  ]
  const parkInvestmentList = [
    {
      // investmentDepartment
      id: "settled_by",
      span: 6,
      label: '招商类别',
      component:

        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.settled_by} onChange={changeSettledBy}
        >
          {
            settledByList.map(item => {
              return (
                <Option key={item.code} value={item.id}>
                  {item.name}
                </Option>
              )
            })
          }
        </Select>

    },

  ]
  const investmentList = [

    {
      // investmentDepartment
      id: "settled_by",
      span: 6,
      label: '招商类别',
      component:

        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          value={enterpriseInfo.settled_by} onChange={changeSettledBy}
        >
          {
            settledByList.map(item => {
              return (
                <Option key={item.code} value={item.id}>
                  {item.name}
                </Option>
              )
            })
          }
        </Select>

    }, {
      id: "eip_investment_agency_name",
      span: 6,
      label: '招商中介名称',
      component:

        <Select

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          value={enterpriseInfo.eip_investment_agency_name} onChange={changeInvestmentAgency}
        >
          {
            investmentAgencyList.map(item => {
              return (
                <Option key={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }, {
      id: "eip_investment_agency_staff_name",
      span: 6,
      label: '招商中介人员姓名',
      component:
        <Select
          showArrow={false}

          suffixIcon={<CaretDownOutlined />}
          className={inputClassName}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          value={enterpriseInfo.eip_investment_agency_staff_name} onChange={changeInvestmentAgencyStaff}
        >
          {
            investmentAgencyStaffList.map(item => {
              return (
                <Option key={item.id} >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    }
  ]

  const rateList = [
    // {
    //   id: "eip_enterprise_tax_refund_rate",
    //   span: 6,
    //   label: '企业增值税扶持点',
    //   component:
    //     <Input value={enterpriseInfo.settled_by} onChange={changeName} />,
    // }, {
    //   id: "cp_staff_name",
    //   span: 6,
    //   label: '企业所得税扶持点',
    //   component:
    //     <Input value={enterpriseInfo.cp_staff_name} onChange={changeName} />,
    // }, {
    //   id: "eip_investment_agency_name",
    //   span: 6,
    //   label: '招商点增值税扶持点',
    //   component:
    //     <Input value={enterpriseInfo.eip_investment_agency_name} onChange={changeName} />,
    // }, {
    //   id: "eip_investment_agency_staff_name",
    //   span: 6,
    //   label: '招商点所得税扶持点',
    //   component:
    //     <Input value={enterpriseInfo.eip_investment_agency_staff_name} onChange={changeName} />,
    // }
  ]
  return (
    <div className='edit-enterprise-info-container' >
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <Button.Group>
          <Button
            icon={<CancelEditIcon />}
            onClick={cancelEdit}
          >
            取消编辑
          </Button>
          <Button
            icon={<CreateDocIcon />}
            onClick={saveEdit}
          >
            保存编辑
          </Button>
        </Button.Group>
      </div>

      <div className='show-enterprise-info-container'>
        <Collapse
          defaultActiveKey={['1', '2', '3']}
          className="site-collapse-custom-collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >

          <Panel
            header={

              <div className="show-enterprise-info-title">
                企业基本信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="1">
            <Row>
              {
                (basicInfoList).map(item => {
                  return (
                    <Col className="edit-enterprise-info-item" key={item.id} span={item.span}>
                      <Row >
                        <Col
                          className="edit-enterprise-info-label"
                          style={{ textAlign: 'right', width: '100px' }}>
                          {`${item.label}`}
                        </Col>
                        <Col className='edit-enterprise-info-box' style={{ width: 'calc(100% - 100px)' }}>
                          {item.component}
                        </Col>
                      </Row>
                    </Col>
                  )
                })
              }
            </Row>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                营业执照信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="2">
            <Row>
              {
                enterpriseBaseInfoList.map(item => {
                  return (
                    <Col className="edit-enterprise-info-item" key={item.id} span={item.span}>
                      <Row >
                        <Col
                          className="edit-enterprise-info-label"
                          style={{ textAlign: 'right', width: '120px' }}>
                          {`${item.label}:`}
                        </Col>
                        <Col className='edit-enterprise-info-box' style={{ width: 'calc(100% - 120px)' }}>
                          {item.component}
                        </Col>
                      </Row>
                    </Col>
                  )
                })
              }
            </Row>
          </Panel>
          <Panel
            header={

              <div className="show-enterprise-info-title">
                税务证照信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="3">
            <Row >
              {taxInfoList.map(item => {
                return (
                  <Col className="edit-enterprise-info-item" key={item.id} span={item.span}>
                    <Row >
                      <Col
                        className="edit-enterprise-info-label"
                        style={{ textAlign: 'right', width: '100px' }}>
                        {`${item.label}:`}
                      </Col>
                      <Col className='edit-enterprise-info-box' style={{ width: 'calc(100% - 100px)' }}>
                        {item.component}
                      </Col>
                    </Row>
                  </Col>)
              })}
            </Row>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseEdit);