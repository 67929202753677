import React, { useState, useEffect } from 'react';

import {
  Button, Input, Col, Row,
  Empty,
  Space, Select, Divider, message, Modal, Typography
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'


import ButtonLabel from '@component/ButtonLabel'

import { DeleteDocIcon, CreateIcon } from '@icon'
import { paasql } from '@api'


const { Text } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group

const InvestmentAgencyDetail = (props) => {
  const {
    investmentAgencyId,
    centralDepartment,
    reflushData,
    setIsBenbu,
    setCreateInvestmentAgencyVisable,
    investmentAgencyList,
    setActiveId } = props
  const [investmentAgencyInfo, setInvestmentAgencyInfo] = useState({})

  const [investmentAgencyStaff, setInvestmentAgencyStaff] = useState([])

  const [investmentAgencyName, setInvestmentAgencyName] = useState(null)
  const [isEditing, setIsEditing] = useState(true)
  const [editInvestmentObj, setEditInvestmentObj] = useState({})
  const [editStaffObj, setEditStaffObj] = useState({})
  const [createStaffObj, setCreateStaffObj] = useState(null)
  useEffect(() => {
    getDataById()
    getStaffDataByAgencyId()
    setIsEditing(true)
  }, [investmentAgencyId])

  const getDataById = () => {
    const cmd_data = {
      id: investmentAgencyId
    }
    //获取中介的name

    paasql('eip.eip_investment_agency.query_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        const { name } = result
        setInvestmentAgencyName(name)
        setInvestmentAgencyInfo(result)
        setEditInvestmentObj(result)
      } else {
      }
    })
  }

  //通过id查找工作人员
  const getStaffDataByAgencyId = () => {
    const cmd_data = {
      eip_investment_agency_id: investmentAgencyId
    }
    paasql('eip.eip_investment_agency_staff.list_by_eip_investment_agency_id', cmd_data).then(data => {
      const result = data.result
      setInvestmentAgencyStaff(result)
    })
  }


  const selectSatff = (value, option) => {
    if (value == 'park') {
      setIsBenbu(true)
      setActiveId(null)
    } else {
      setIsBenbu(false)
      setActiveId(value)
    }
  }




  //删除招商中介
  const delInvestmentAgency = () => {
    //删除招商中介
    Modal.confirm({
      title: '删除中介',
      content: <span>
        将删除所选招商中介(<Text type="danger" >
          {investmentAgencyName}
        </Text>)和关联招商中介人员，不可恢复，是否继续？
          </span>,
      onOk() {
        confirmDelInvestmentAgency()
      }
    })
  }

  //确认删除
  const confirmDelInvestmentAgency = () => {
    const cmd_data = {
      id: investmentAgencyId
    }
    paasql('eip.eip_investment_agency.destroy_eip_investment_agency_and_staff', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        reflushData()
      } else {
        message.error(data.error.message)
      }
    })
  }

  //改变
  const changeValue = (e, item) => {
    const value = e.target.value
    setEditInvestmentObj({
      ...editInvestmentObj,
      [item.field]: value
    })
  }

  //编辑中介
  const editInvestment = () => {
    setEditInvestmentObj(investmentAgencyInfo)
    setIsEditing(true)
  }

  //取消编辑中介
  const cancelEdit = () => {
    setIsEditing(false)
  }


  //保存编辑的中介信息
  const saveEdit = () => {
    const {
      id, name, eip_park_id, eip_park_name,
      phone_number, bank_account_name,
      bank_account_number, bank_branch,
      bank_name, uscc, bank_code
    } = editInvestmentObj
    const cmd_data = {
      id, name, eip_park_id, eip_park_name,
      phone_number, bank_account_name,
      bank_account_number,
      bank_branch,
      bank_name, uscc, bank_code
    }
    paasql('eip.eip_investment_agency.update', cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        getDataById()
      } else {
        message.error(data.error.message)
      }
    })
    setIsEditing(false)

  }


  //编辑员工
  const editStaff = (e, item, index) => {
    if (editStaffObj == null) {
      message.error('有一个未保存的项')
      return
    }
    setEditStaffObj(item)
    const tmpAgencyStaffList = investmentAgencyStaff
    for (let i = 0; i < tmpAgencyStaffList.length; i++) {
      if (i == index) {
        tmpAgencyStaffList[i].isEditing = true
      } else {
        tmpAgencyStaffList[i].isEditing = false
      }
    }
    setInvestmentAgencyStaff([...tmpAgencyStaffList])
  }


  //删除中介人员
  const delStaff = (item) => {
    Modal.confirm({
      title: '删除中介人员',
      content: <span>
        将删除所选中介招商人员(<Text type="danger" >{item.name}</Text>)，不可恢复，是否继续？
    </span>,
      onOk() {
        confirmDelStaff(item)
      }
    })
  }

  //确认删除
  const confirmDelStaff = (item) => {
    const { id } = item
    const cmd_data = {
      id
    }
    paasql('eip.eip_investment_agency_staff.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success("删除成功")
        getStaffDataByAgencyId()
      } else {
        message.error(data.error.message)
      }
    })
  }

  //取消编辑
  const cancelEditStaff = (item, index) => {
    if (item.isCreate) {
      //说明是新建
      //删除掉一个
      const tmpAgencyStaffList = investmentAgencyStaff
      const [first, ...reast] = tmpAgencyStaffList
      setInvestmentAgencyStaff([...reast])
      setCreateStaffObj(null)
      return
    }

    const tmpAgencyStaffList = investmentAgencyStaff
    for (let i = 0; i < tmpAgencyStaffList.length; i++) {
      tmpAgencyStaffList[i].isEditing = false
    }
    setInvestmentAgencyStaff([...tmpAgencyStaffList])

  }

  //改变员工的手机号
  const changeStaffTelNumber = (e) => {
    const value = e.target.value
    setEditStaffObj({
      ...editStaffObj,
      telephone_number: value
    })
  }

  //改变员工的姓名
  const changeStaffName = (e) => {
    const value = e.target.value
    setEditStaffObj({
      ...editStaffObj,
      name: value
    })
  }

  //保存编辑的信息
  const saveEditStaff = (item) => {
    if (item.isCreate) {
      //说明是新建
      const {
        name, telephone_number
      } = editStaffObj
      if (name == undefined || name == '' || name == null) {
        message.error('名字是必填项，请先填写再保存')
        return
      }
      const cmd_data = {
        object: {
          eip_investment_agency_id: investmentAgencyId,
          eip_investment_agency_name: investmentAgencyName,
          name,
          telephone_number
        }
      }

      paasql('eip.eip_investment_agency_staff.create', cmd_data).then(data => {
        if (data.error == null) {
          message.success('保存成功')
          getStaffDataByAgencyId()
          setCreateStaffObj(null)
          setEditStaffObj({})
        } else {
          message.error(data.error.message)
        }
      })

      return
    }
    const {
      id, name,
      telephone_number
    } = editStaffObj
    const cmd_data = {
      object: {
        id, name,
        telephone_number
      }
    }
    paasql('eip.eip_investment_agency_staff.update', cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        getStaffDataByAgencyId()
        setEditStaffObj({})
      } else {
        message.error(data.error.message)
      }
    })

  }

  const addStaff = () => {
    if (createStaffObj !== null) {
      message.error('有一个未保存的项')
      return
    }
    const createStaffOb = {
      isEditing: true,
      isCreate: true
    }
    setCreateStaffObj(
      {
        ...createStaffOb
      }
    )

    const tmpAgencyStaffList = investmentAgencyStaff
    for (let i = 0; i < tmpAgencyStaffList.length; i++) {
      tmpAgencyStaffList[i].isEditing = false
    }

    setInvestmentAgencyStaff([createStaffOb, ...tmpAgencyStaffList])
  }

  const createInvestmentAgency = () => {
    setCreateInvestmentAgencyVisable(true)
  }
  const showList = [
    {
      label: "名称",
      value: investmentAgencyInfo.name,
      field: 'name'
    }, {
      label: "统一征信代码",
      value: investmentAgencyInfo.uscc,
      field: "uscc"
    }, {
      label: "户名",
      value: investmentAgencyInfo.bank_account_name,
      field: "bank_account_name"
    }, {
      label: "开户行",
      value: investmentAgencyInfo.bank_name,
      field: "bank_name"

    }, {
      label: "银行网点",
      value: investmentAgencyInfo.bank_branch,
      field: "bank_branch"

    }, {
      label: "银行账号",
      value: investmentAgencyInfo.bank_account_number,
      field: "bank_account_number"

    }, {
      label: "银行行联号",
      value: investmentAgencyInfo.bank_code,
      field: "bank_code"

    }, {
      label: "联系电话",
      value: investmentAgencyInfo.phone_number,
      field: "phone_number"
    }]
  return (
    <div>
      <div>
        <div className='investment-agency-staff-nav'>
          <div
            className='investment-agency-title'
          >
            招商部门详情

          </div>

          <div>

            {
              isEditing ?
                <Space>
                  <Button
                    onClick={delInvestmentAgency}>
                    删除
                  </Button>
                  <Button
                    onClick={createInvestmentAgency}
                  >新增招商部门</Button>
                  <Button onClick={cancelEdit} >
                    取消编辑
                </Button>
                  <Button onClick={saveEdit} type="primary">
                    保存
                </Button>
                </Space>
                :
                <Space>
                  <Button
                    onClick={delInvestmentAgency}>
                    删除
                  </Button>
                  <Button
                    onClick={createInvestmentAgency}
                  >新增招商部门</Button>
                  <Button onClick={editInvestment} type="primary">
                    编辑
                </Button>
                </Space>
            }
          </div>
        </div>
        <Row className="investment-detail-info-list">
          {
            showList.map((item, index) => {
              return (
                <Col span={6} key={item.label} className='investment-detail-info-item'>
                  <Row className='inv-container'>
                    <Col className='inv-label'>
                      {`${item.label}:`}
                    </Col>
                    <Col className='inv-value'>
                      {
                        isEditing ?
                          <Input className='inv-input' value={editInvestmentObj[item.field]} onChange={(e) => changeValue(e, item)} /> :
                          <div className='inv-show' >{item.value}</div>
                      }
                    </Col>
                  </Row>


                </Col>
              )
            })
          }
        </Row>

      </div>

      <Divider style={{ margin: '10px 0' }} />
      <div >
        <div className='investment-agency-staff-nav' >
          <div
            className='investment-agency-title'
          >
            招商人员
          </div>
          <Space
            style={{ textAlign: 'right', lineHeight: '28px' }}
          >
            <Button
              onClick={addStaff}
            >
              新增中介招商员
          </Button>
          </Space>

        </div>
        <div className='investment-agency-staff-detail-container'>
          {
            investmentAgencyStaff.length === 0 ? <Empty /> :
              investmentAgencyStaff.map((item, index) => {
                return (
                  <React.Fragment key={item.id} >
                    <Row style={{ marginTop: '10px' }} >
                      <Col
                        span={1}
                        style={{ textAlign: 'right', lineHeight: '28px' }}
                      >
                        {`${index + 1}、`}
                      </Col>
                      <Col span={8} className='investment-agency-satff-detail-item'>
                        <Row className='inv-container' >
                          <Col className='inv-label'>
                            姓名:
                        </Col>
                          <Col className='inv-value'>
                            {
                              item.isEditing ?
                                <Input className="inv-input" value={editStaffObj.name} onChange={(e) =>
                                  changeStaffName(e, item)}
                                /> :
                                <div className="inv-show">{item.name}</div>
                            }
                          </Col>
                        </Row>


                      </Col>

                      <Col span={8} className='investment-agency-satff-detail-item'>

                        <Row className='inv-container'>
                          <Col className='inv-label'>
                            手机号:
                        </Col>
                          <Col className='inv-value'>
                            {
                              item.isEditing ?
                                <Input className="inv-input" value={editStaffObj.telephone_number} onChange={(e) => changeStaffTelNumber(e)} /> :
                                <div className="inv-show">{item.telephone_number}</div>
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7} style={{ textAlign: 'right', lineHeight: '28px' }}>
                        <Space>
                          {
                            item.isEditing ?
                              <>
                                <Button
                                  className='investment-agency-edit-button'
                                  onClick={() =>
                                    cancelEditStaff(
                                      item, index
                                    )}
                                >
                                  取消
                            </Button>
                                <Button
                                  className='investment-agency-edit-button'
                                  type="primary" onClick={() =>
                                    saveEditStaff(item, index)}>
                                  保存
                            </Button>
                              </>
                              :
                              <>
                                <Button
                                  className='investment-agency-edit-button'
                                  onClick={() =>
                                    delStaff(
                                      item, index
                                    )}
                                >
                                  删除
                            </Button>
                                <Button
                                  className='investment-agency-edit-button'
                                  type="primary" onClick={
                                    (e) => editStaff(e, item, index)}
                                >
                                  编辑
                            </Button>
                              </>
                          }
                        </Space>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '10px 0 2px  0' }} />
                  </React.Fragment>
                )
              })
          }
        </div>
      </div>
    </div>
  );
}

export default InvestmentAgencyDetail;