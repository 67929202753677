import React, { useState, useEffect, useContext } from 'react';
import moment, { now } from 'moment'
import {
  Form,
  Select,
  DatePicker,
  Upload,
  Button,
  message,
  Modal, Space
} from 'antd';
import { UploadOutlined, CaretDownOutlined } from '@ant-design/icons';

import { paasql, createObjectWithBinary } from '@api'
import { ReachableContext } from '../UploadModal'


const { RangePicker } = DatePicker;

const monthFormat = 'YYYY-MM';
const currentYear = moment().year();
const currentStartTime = String(currentYear) + '-01'
//上传文件的处理
const SelectInfo = (props) => {

  const { goSelectHeader, hide, parkId } = useContext(ReachableContext)//使用上下文的模态框

  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [nextDisabled, setNextDisable] = useState(true)
  const [datePickerDisable, setDatePickerDisable] = useState(false)
  const [Btn, setBtn] = useState(true)
  const [visiblable, setVisiblable] = useState(false)
  const [canUpload, setCanUpload] = useState(false)
  const [defaultStartTime, setDefaultStartTime] = useState(currentStartTime)
  const [eipMonthlyAccumulatedTax, setEipMonthlyAccumulatedTax] = useState([])

  const [eipTaxBureau, setEipTaxBureau] = useState([])

  //这个是税务局的id
  const [selectBureau, setSelectBureau] = useState(null)
  const [fileList, setFileList] = useState([])

  //总库表id
  const [eipMonthlyAccumulatedTaxId, setEipMonthlyAccumulatedTaxId] = useState(null)
  const [fileId, setFileId] = useState(null)
  const [startedAt, setStartAt] = useState(null)
  const [endedAt, setEndAt] = useState(null)
  const [eipMonthlyAccumulatedTaxName, setEipMonthlyAccumulatedTaxName] = useState(null)



  useEffect(() => {
    paasql('eip.tax_bureau.list').then(data => {
      if (data.error == null) {
        const result = data.result
        setEipTaxBureau(result)
      } else {
        message.error(data.error.message)
      }
    })


    //税收总库表
    var cmd_data = {
      eip_park_id: parkId
    }
    paasql('eip.eip_monthly_accumulated_tax.list', cmd_data).then(list => {
      if (list.error == null) {
        const result = list.result
        const resultWithSort = result.sort(function (first, last) {
          return Number(last.ended_at) - Number(first.ended_at)
        })
        setEipMonthlyAccumulatedTax(resultWithSort)
      } else {
        message.error(list.error.message)
      }
    })
  }, [])




  const onValuesChange = (changedFields, allFields) => {
    //这里需要处理选中框的设置
  }

  const changedBureau = (value) => {
    setSelectBureau(value)
  }






  //这里的判断还有问题

  const dateChange = (dates, dateStrings) => {
    //判断是否选中税务局
    if (selectBureau == null) {
      message.error('请先选择税务局')
      return
    }
    //带-的开始和结束
    const [startTime, endTime] = dateStrings
    const startYear = startTime.slice(0, 4)
    const endYear = endTime.slice(0, 4)
    const startMonth = startTime.slice(5, 7)
    const endMonth = endTime.slice(5, 7)


    const pureStartTime = startTime.replace('-', '')
    const pureEndTime = endTime.replace('-', '')
    const nameForTax = `${startYear}年${startMonth}月-${endMonth}月`
    setEipMonthlyAccumulatedTaxName(nameForTax)
    //选择了第二个月



    //如果当前的总库表数为0
    if (eipMonthlyAccumulatedTax.length == 0) {

      //设置开始时间和结束时间
      setStartAt(pureStartTime)
      setEndAt(pureEndTime)
      setEipMonthlyAccumulatedTaxName(nameForTax)
      //解锁上传文件按钮
      setUploadDisabled(false)

      return
    }
    if (startYear != endYear) {
      message.warn("请正确选择起止时间", 2)
      return
    }
    //

    //1、判断结束月是否为1月
    if (endTime != undefined) {
      const endMonth = endTime.slice(5, 7)
      const endTimeStr = endTime.replace('-', '')

      //处理重复月的覆盖
      for (let i = 0; i < eipMonthlyAccumulatedTax.length; i++) {
        if (eipMonthlyAccumulatedTax[i].ended_at == endTimeStr) {
          setEndAt(pureEndTime)
          setStartAt(pureStartTime)
          setVisiblable(true)
          return
        } else {
          //判断是否跨月上传
          setUploadDisabled(true)
        }
      }

      const currentTime = eipMonthlyAccumulatedTax[0].ended_at
      if (endMonth == '01') {
        //设置上传的日期
        setStartAt(pureStartTime)
        setEndAt(pureEndTime)

        //可以上传
        setUploadDisabled(false)
        return
      }
      if (endTimeStr - currentTime > 1) {
        message.error("请先上传上个月的总库表")
      } else {
        //设置上传的日期
        setStartAt(pureStartTime)
        setEndAt(pureEndTime)
        setEipMonthlyAccumulatedTaxName(nameForTax)

        //可以上传
        setUploadDisabled(false)
      }
      //当前为一月份可以上传，前面已经判断过是否同年

      //判断是否跨月上传
    } else {
      return
    }
  }

  //拦截默认上传
  const handleBeforeUpload = (file) => {
    const list = [file]
    setCanUpload(false)
    setBtn(false)
    setFileList(list)
    return false;

  }
  //处理上传文件
  const handleUpload = () => {
    const formData = new FormData();
    setCanUpload(true)
    fileList.forEach((file) => {   // fileList 是要上传的文件数组
      formData.append('file', file);
    })

    const request = {
      cp_schema_id: "eip_monthly_accumulated_tax",
      cp_object_attribute: "original_raw_data_id",
      file_object: formData
    }
    createObjectWithBinary(request).then(data => {
        //下一步
        setNextDisable(false)
        //设置id
        const id = data.result.id
        const fileId = data.result.original_raw_data_id
        setFileId(fileId)
        setEipMonthlyAccumulatedTaxId(id)
      })
      .catch(error=>{
      })
  }


  //重新选择文件
  const reselectFile = () => {
    if (!Btn) {
      // eip.eip_monthly_accumulated_tax.del_file/
      let cmd_data = {
        "cp_file_id": fileId,//上传的文件id
        "eip_monthly_accumulated_tax_id": eipMonthlyAccumulatedTaxId//
      }

      paasql("eip.eip_monthly_accumulated_tax.del_file", cmd_data).then(data => {
        if (data.error == null) {
          // message.error("已删除文件")
        } else {
          message.error(data.error.message, 5)
        }
      })
    }
  }
  //覆盖操作
  const confirmDeletion = () => {
    const statistical_year = endedAt.slice(0, 4)
    const statistical_month = endedAt.slice(4, 6)

    if (statistical_month == 12) {
      var next_eip_monthly_accumulated_tax_ended_at = String(Number(endedAt) + 89)
    }
    else {
      var next_eip_monthly_accumulated_tax_ended_at = String(Number(endedAt) + 1)
    }



    var cmd_data = {
      "statistical_year": statistical_year,
      "statistical_month": statistical_month,
      "eip_monthly_accumulated_tax_eip_park_id": parkId,
      "eip_monthly_accumulated_tax_started_at": startedAt,
      "eip_monthly_accumulated_tax_ended_at": endedAt,
      "next_eip_monthly_accumulated_tax_ended_at": next_eip_monthly_accumulated_tax_ended_at
    }


    paasql("eip.tax_library.destroy_by_month", cmd_data).then(data => {
      if (data.error == null) {
        message.success("成功覆盖")
        setVisiblable(false)

        //可以上传文件了
        setUploadDisabled(false)
      } else {
        message.error(data.error.message)
      }
    })

  }
  const cancelUplaod = () => {
    //这里需要删除上传的文件
    hide()
  }
  const hideModal = () => {
    setVisiblable(false)
  }
  return (
    <div>
      <div className="subModalHeader">
        填写核算表信息
      </div>
      <Form
        size="large"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="tax_bureau"
          label="税务局"
          rules={[
            {
              required: true,
              message: '还未选择税务局',
            },
          ]}
        >
          <Select
            size="large"
            onChange={changedBureau}
            placeholder="请选择税务局"
            suffixIcon={<CaretDownOutlined />}
          >
            {
              eipTaxBureau.map(item => {
                return (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item label="时间跨度" name='time'>
          <RangePicker
            disabled={datePickerDisable}
            onChange={dateChange}
            defaultValue={[moment(defaultStartTime, monthFormat)]}
            picker="month"
          />
          {
            //Todo，重新选择日期
            datePickerDisable ?
              <Button>
                重新选择日期
            </Button> :
              <></>
          }
        </Form.Item>
        <Form.Item
          name="upload"
          label="上传文件"
          valuePropName="fileList"
          extra=""
        >
          <Upload
            beforeUpload={handleBeforeUpload}
            disabled={uploadDisabled}
            showUploadList={{
              showRemoveIcon: false
            }}
            fileList={fileList}
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          >

            <Button
              onClick={reselectFile}
            >
              <UploadOutlined
              />
              {
                Btn ? "选择文件" : "重新选择文件"
              }

            </Button>

          </Upload>
          {
            Btn ? "" :
              <Button
                disabled={canUpload}
                onClick={handleUpload}
              >
                确认上传文件
              </Button>
          }

        </Form.Item>
      </Form>
      <div style={{ textAlign: 'right', paddingTop: 20 }}>
        <Space>

          {
            Btn ? "" :
              <Button onClick={cancelUplaod}>
                取消上传
              </Button>
          }
          <Button type="primary"
            onClick={
              //这里需要处理总库表的更新状态
              () => goSelectHeader(
                {
                  "name": eipMonthlyAccumulatedTaxName,
                  "eip_monthly_accumulated_tax_id": eipMonthlyAccumulatedTaxId,//总库表id
                  "eip_tax_bureau_id": selectBureau,
                  "eip_park_id": parkId,
                  "started_at": startedAt,
                  "ended_at": endedAt,
                }
              )
            }
            disabled={nextDisabled}
          >
            下一步
          </Button>
        </Space>

      </div>
      <Modal
        title='数据覆盖提醒'
        centered={true}
        visible={visiblable}
        onOk={confirmDeletion}
        onCancel={hideModal}
      >
        <div>
          已上传过该月总库表，继续上传将覆盖旧的数据
      </div>
      </Modal>
    </div >
  )
}

export default SelectInfo;