import React, { useState, useEffect } from 'react';
import { Table, Popover } from 'antd'
import { paasql } from '@api'
import { moneyFormat } from '@utils/index'
import EnterpriseInfoPopup from './component/EnterpriseInfoPopup'
import TaxInfoPopup from './component/TaxInfoPopup'
const config = [
  {
    display_name: "法人代表",
    field: "legal_representative"
  }, {
    display_name: "工商状态",
    field: "license_status"
  }, {
    display_name: "成立时间",
    field: "established_in"
  }, {
    display_name: "招商中介",
    field: "eip_investment_agency_name"
  }, {
    display_name: "税务状态",
    field: "tax_status"
  }, {
    display_name: "注册类型",
    field: "registered_genre"
  }, {
    display_name: "入驻状态",
    field: "settled_status_code"
  },
  {
    display_name: "入园时间",
    field: "settled_at"
  },
  {
    display_name: "注册地址",
    field: "registered_address"
  }, {
    display_name: "迁入前地址",
    field: "former_registered_at"
  }
]

const taxInfoConfig = [
  { display_name: "消费税(元)", name: "consumption_tax", },
  { display_name: "增值税(元)", name: "value_added_tax" },
  { display_name: "营业税(元)", name: "business_tax" },
  { display_name: "企业所得税(元)", name: "corporate_income_tax" },
  { display_name: "所得税(元)", name: "individual_income_tax" },
  { display_name: "城市维护建设税(元)", name: "city_maintenance_and_construction_tax" },
  { display_name: "车船使用税(元)", name: "vehicle_and_vessel_usage_tax" },
  { display_name: "房产税(元)", name: "property_tax" },
  { display_name: "印花税(元)", name: "stamp_tax" },
  { display_name: "土地增值税(元)", name: "land_appreciation_tax" },
  { display_name: "土地使用税(元)", name: "land_use_tax" },
  { display_name: "环保税(元)", name: "environmental_tax" },
]
const MonthlyAccumulatedTaxRecord = (props) => {
  const { Data, totalPage, loading, pageSize, currentPage } = props

  const [tableData, setTableData] = useState([])
  const [enterpriseInfo, setEnterpriseInfo] = useState([])
  const [taxInfo, setTaxInfo] = useState([])
  const [
    enterpriseId,
    setEnterpriseId] = useState(null)
  useEffect(() => {
    setTableData(Data)
  }, [Data, totalPage])

  const clickEnterprise = (text, record, index) => {
    setEnterpriseId(record.eip_enterprise_id)
  }


  const handleTaxInfo = (text, record, index) => {
    const enterpriseTaxInfo = {
      ...record
    }
    for (let i = 0; i < taxInfoConfig.length; i++) {
      for (const key in enterpriseTaxInfo) {
        if (key == taxInfoConfig[i].name) {
          taxInfoConfig[i].value = enterpriseTaxInfo[key]
        }
      }
    }
    setTaxInfo(taxInfoConfig)
  }
  const columns = [
    {
      title: '序号',
      width: '5%',
      align: 'center',
      render: (text, record, index) => {
        return (
          `${(currentPage - 1) * pageSize + index + 1}`
        )
      }
    },
    {
      title: '企业名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) =>
        <Popover key={index}
          placement='leftTop'
          content={
            <EnterpriseInfoPopup
              enterpriseId={enterpriseId}
              enterpriseInfo={enterpriseInfo}
            />
          } title={text} trigger="click">
          <a onClick={() => clickEnterprise(text, record, index)}>{text}</a>
        </Popover>,
    },
    {
      title: '税号',
      dataIndex: 'taxpayer_registration_number',
      key: 'taxpayer_registration_number',
    },
    {
      title: '应税销售额（元）',
      dataIndex: 'taxable_income',
      key: 'taxable_income',
      align: 'right',
      render: (text, record, index) =>{
        return moneyFormat(text)
      }
    },
    {
      title: '税收合计(元)',
      dataIndex: 'tax_payable',
      key: 'tax_payable',
      align:'right',
      render: (text, record, index) => (
        <Popover
          placement='rightTop'
          style={{ paddingRight: 20 }}
          key={index}
          content={
            <TaxInfoPopup
              taxInfo={taxInfo}
            />
          }
          title='企业纳税情况'
          trigger="click">
          <a onClick={() => handleTaxInfo(text, record, index)}>
            {moneyFormat(text)}
          </a>
        </Popover>
      ),
    },
  ];


  return (
    <>
      <div style={{ width: '100%' }}>
        <Table
          size='small'
          loading={loading}
          pagination={{
            pageSize: 20,
            total: 20,
            hideOnSinglePage: true,
            defaultCurrent: 1,
          }}
          scroll={{ y: 'calc(100vh  - 310px)' }}
          columns={columns}
          bordered
          dataSource={tableData}
          rowKey={record => record.name} />
      </div>
    </>
  )
}



export default MonthlyAccumulatedTaxRecord;
