import React, { useEffect, useState } from 'react';
import { Table, } from 'antd'
import { moneyFormat } from '@utils/index'
//配置表头
const table_col_header_data = [
  { display_name: "招商点", width: 100, name: "eip_investment_agency_name", weight: 20 },
  { display_name: "企业名称", width: 300, name: "name", weight: 20 },
  { display_name: "税务管理码", width: 150, name: "tax_file_number", weight: 30 },
  { display_name: "增值税总税额", width: 150, name: "total_value_added_tax", weight: 40 },
  { display_name: "增值税扶持金额", width: 150, name: "total_value_added_tax_refund", weight: 40 },
  { display_name: "企业所得税总税额", width: 150, name: "total_corporate_income_tax", weight: 50 },
  { display_name: "企业所得税扶持金额", width: 150, name: "total_corporate_income_tax_refund", weight: 50 },
  { display_name: "扶持金额合计", width: 150, name: "total_tax_refund", weight: 50 },
  { display_name: "上季余留", width: 150, name: "last_year_statement_balance", weight: 50 },
  { display_name: "累计补差", width: 150, name: "total_payment_difference", weight: 50 },
  { display_name: "累计管理费", width: 150, name: "total_management_fee", weight: 50 },
  { display_name: "累计代扣", width: 150, name: "total_payment_on_behalf", weight: 50 },
  { display_name: "累计预付款", width: 150, name: "total_prepayment", weight: 50 },
  { display_name: "累计应支付金额", width: 150, name: "total_accounts_payable", weight: 50 },
  { display_name: "累计已支付金额", width: 150, name: "total_actual_amount_paid", weight: 50 },
  { display_name: "所选时段未支付金额", width: 150, name: "unpaid_amount", weight: 50 },
  { display_name: "招商点扶持金额合计", width: 150, name: "total_agency_tax_refund", weight: 50 },
  { display_name: "招商点上季余留", width: 150, name: "last_agency_year_statement_balance", weight: 50 },
  { display_name: "招商点累计补差", width: 150, name: "total_agency_payment_difference	", weight: 50 },
  { display_name: "招商点累计代扣", width: 150, name: "total_agency_payment_on_behalf", weight: 50 },
  { display_name: "招商点累计预付款", width: 150, name: "total_agency_prepayment", weight: 50 },
  { display_name: "招商点累计应支付金额", width: 150, name: "total_agency_accounts_payable", weight: 50 },
  { display_name: "招商点累计已支付扶持金额", width: 150, name: "total_agency_actual_amount_paid", weight: 50 },
  { display_name: "招商点所选时间段未支付金额", width: 150, name: "agency_unpaid_amount", weight: 50 },
]
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 60,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '招商点',
    dataIndex: 'eip_investment_agency_name',
    key: 'eip_investment_agency_name',
    width: 100,
    fixed: 'left',
  }, {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    fixed: 'left',
  }, {
    title: '税号',
    dataIndex: 'tax_file_number',
    width: 250,
    key: 'tax_file_number',
  }, {
    title: '增值税总金额（元）',
    dataIndex: 'total_value_added_tax',
    key: 'total_value_added_tax',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '增值税扶持金额（元）',
    dataIndex: 'total_value_added_tax_refund',
    key: 'total_value_added_tax_refund',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '企业所得税总金额（元）',
    dataIndex: 'total_corporate_income_tax',
    key: 'total_corporate_income_tax',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '企业所得税扶持金额（元）',
    dataIndex: 'total_corporate_income_tax_refund',
    key: 'total_corporate_income_tax_refund',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '扶持金额合计（元）',
    dataIndex: 'total_tax_refund',
    key: 'total_tax_refund',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '上季余额（元）',
    dataIndex: 'last_year_statement_balance',
    key: 'last_year_statement_balance',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '累计补差（元）',
    dataIndex: 'total_payment_difference',
    key: 'total_payment_difference',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '累计管理费（元）',
    dataIndex: 'total_management_fee',
    key: 'total_management_fee',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '累计代扣（元）',
    dataIndex: 'total_payment_on_behalf',
    key: 'total_payment_on_behalf',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '累计预付款（元）',
    dataIndex: 'total_prepayment',
    key: 'total_prepayment',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '累计应支付金额（元）',
    dataIndex: 'total_accounts_payable',
    key: 'total_accounts_payable',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '累计已支付金额（元）',
    dataIndex: 'total_actual_amount_paid',
    key: 'total_actual_amount_paid',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '本期未支付金额（元）',
    dataIndex: 'unpaid_amount',
    key: 'unpaid_amount',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '招商点扶持金额合计(元)',

    dataIndex: 'total_agency_tax_refund',
    key: 'total_agency_tax_refund',
    width: 200,
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },
  {
    title: '招商点上季余留(元)',
    dataIndex: 'last_agency_year_statement_balance',
    key: 'last_agency_year_statement_balance',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  },

  {
    title: '招商点累计补差(元)',
    dataIndex: 'total_agency_payment_difference',
    key: 'total_agency_payment_difference',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    }
  }, {
    title: '招商点累计代扣(元)',
    dataIndex: 'total_agency_payment_on_behalf',
    key: 'total_agency_payment_on_behalf',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    },
  }, {
    title: '招商点累计预付款(元)',
    dataIndex: 'total_agency_prepayment',
    key: 'total_agency_prepayment',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    },
  },
  {
    title: '招商点累计应支付金额(元)',
    dataIndex: 'total_agency_accounts_payable',
    key: 'total_agency_accounts_payable',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    },
    width: 230
  },
  {
    title: '招商点累计已支付扶持金额(元)',
    dataIndex: 'total_agency_actual_amount_paid',
    width: 230,
    key: 'total_agency_actual_amount_paid',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    },
  },
  {
    title: '招商点所选时间段未支付金额(元)',
    dataIndex: 'agency_unpaid_amount',
    key: 'agency_unpaid_amount',
    align: 'right',
    render: (text, record) => {
      return (moneyFormat(text))
    },
    width: 250

  },
];
const TaxRefundStatisticsTable = (props) => {
  const { tableData, pageSize, currentPage } = props
  const [data, setData] = useState([])

  useEffect(() => {
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].index = (currentPage - 1) * pageSize + i + 1
      //增加是否支付成功
      tableData[i].is_payment_succeeded_name = tableData[i].is_payment_succeeded == '1' ? '是' : '否'
    }

    setData(tableData)
  }, [tableData])


  return (
    <>
      <Table
        size='small'
        columns={columns}
        dataSource={data}
        bordered
        rowKey={
          record => record.id
        }
        pagination={{
          pageSize: 20,
          hideOnSinglePage: true
        }}
        //设置宽度
        scroll={{ x: 'calc(4600px)', y: 'calc(100vh - 580px)' }}
      />
    </>
  );
}

export default TaxRefundStatisticsTable;