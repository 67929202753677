//菜单的左边栏的数据配置

import React from 'react'

import {
  TaxWarningIcon, OnlinePoliceIcon, TaxInfoIcon, TaxRefundIcon,
  EnterpriseBiosphereIcon, UserManagementIcon, InfoManagementIcon, InvestmentManagementIcon
} from '@icon'
import { gpi } from '@api'
import { getSession } from '@api/paasql'





export const getMenuList = () => {


  const { roles } = getSession()
  const cmd_data = {
    "role_id_list": roles
  }
  const method = 'query_role_menu'

  return gpi(method, cmd_data).then(data => {
    data.list.forEach(element => {
      const key = element.icon
      element.icon = iconMap[key]
    });
    return data
  })
}



const iconMap = {
  TaxInfo: <TaxInfoIcon />,
  TaxRefund: <TaxRefundIcon />,
  OnlinePolice: <OnlinePoliceIcon />,
  InvestmentManagement: <InvestmentManagementIcon />,
  TaxWarning: < TaxWarningIcon />,
  InfoManagement: <InfoManagementIcon />,
  UserManagement: <UserManagementIcon />,
  EnterpriseBiosphere: <EnterpriseBiosphereIcon />,
}



//数据结构。只做参考，这里的key-title以数据库为主
const menuList = [
  {
    key: "tax-info-management",
    title: "纳税信息管理",
    iconComponent:
      <TaxInfoIcon />,
    subMenu: [{
      parentMenuTitle: "纳税信息管理",
      key: "tax-management",
      title: "纳税一览",
      route: "/tax-management",
    }, {
      parentMenuTitle: "纳税信息管理",
      key: "import-data",
      title: "数据入库",
      route: "/import-data",
    }, {
      parentMenuTitle: "纳税信息管理",
      key: "data-seach",
      title: "数据查询",
      route: "/data-seach",
    }]
  },
  {
    key: "tax-refuned",
    title: "退税",
    iconComponent:
      <TaxRefundIcon />,
    subMenu: [{
      parentMenuTitle: "退税",
      key: "park-tax-refund",
      title: "园区本部核算",
      route: "/park-tax-refund",

    }, {
      parentMenuTitle: "退税",
      key: "agency-tax-refund",
      title: "中介退税核算",
      route: "/agency-tax-refund",
    }, {
      parentMenuTitle: "退税",
      key: "park-tax-refund-statistics",
      title: "园区本部统计",
      route: "/park-tax-refund-statistics",
    }, {
      parentMenuTitle: "退税",
      key: "agency-tax-refund-statistics",
      title: "中介招入统计",
      route: "/agency-tax-refund-statistics",
    }]
  },
  {
    key: "online-police",
    title: "在线政策",
    iconComponent:
      <OnlinePoliceIcon />,
    subMenu: [{
      parentMenuTitle: "在线政策",
      key: "police-management",
      title: "政策管理",
      route: "/police-management",

    },
    {
      parentMenuTitle: "在线政策",
      key: "policy-online",
      title: "政策内容",
      route: "/policy-online",
    }]
  },



  {
    key: "investment-online-management",
    title: "招商管理",
    iconComponent:
      <InvestmentManagementIcon />,
    subMenu: [{
      parentMenuTitle: "招商管理",
      key: "park-introduction",
      title: "开发区介绍",
      route: "/park-introduction",

    }, {
      parentMenuTitle: "招商管理",
      key: "famous-enterprise",
      title: "明星企业",
      route: "/famous-enterprise",
    }, {
      parentMenuTitle: "招商管理",
      key: "contact-us",
      title: "联系我们",
      route: "/contact-us",
    }, {
      parentMenuTitle: "招商管理",
      key: "faq",
      title: "常见问题解答",
      route: "/faq",
    }, {
      parentMenuTitle: "招商管理",
      key: "business-name-rules",
      title: "企业核名规则",
      route: "/business-name-rules",
    }]
  },
  {
    key: "tax-warning",
    title: "纳税预警",
    iconComponent:
      <TaxWarningIcon />,
    subMenu: [{
      parentMenuTitle: "纳税预警",
      key: "park-dashboard",
      title: "园区总览",
      route: "/park-dashboard",
    }, {
      parentMenuTitle: "纳税预警",
      key: "park-dashboard-setting",
      title: "园区总览设置",
      route: "/park-dashboard-setting",
    },
    {
      parentMenuTitle: "纳税预警",
      key: "virtual-park",
      title: "园中园概况",
      route: "/virtual-park",
    },
    {
      parentMenuTitle: "纳税预警",
      key: "tax-new-get-on",
      title: "重点企业排行榜",
      route: "/tax-new-get-on",
    }
    ]
  },
  {
    key: "information-management",
    title: "基本信息管理",
    iconComponent:
      <InfoManagementIcon />,
    subMenu: [{
      parentMenuTitle: "基本信息管理",
      key: "enterprise-database",
      title: "企业信息管理",
      route: "/enterprise-database",
    }, {
      parentMenuTitle: "基本信息管理",
      key: "investment-agency",
      title: "招商中介管理",
      route: "/investment-agency",
    },
    {
      parentMenuTitle: "基本信息管理",
      key: "virtual-park-management",
      title: "园中园管理",
      route: "/virtual-park-management",
    },
    {
      parentMenuTitle: "基本信息管理",
      key: "park-staff-management",
      title: "园区工作人员管理",
      route: "/park-staff-management",
    }
    ]
  },
  {
    key: "user-management",
    title: "用户管理",
    iconComponent:
      <UserManagementIcon />,
    subMenu: [{
      parentMenuTitle: "用户管理",
      key: "account-management",
      title: "账号管理",
      route: "/account-management",
    }]
  },
  {
    key: "enterprise-biosphere",
    title: "企业生态圈",
    iconComponent:
      <EnterpriseBiosphereIcon />,
    subMenu: [{
      parentMenuTitle: "企业生态圈",
      key: "enterprise-biosphere",
      title: "企业生态圈",
      route: "/enterprise-biosphere",
    }]
  }

]

export default menuList
