import React, { useState, useEffect } from 'react';
import echarts from 'echarts'


//y轴和x轴
const xAxisMapToyAxis = [
  { x: "税收合计", y: "tax_payable" },
  { x: "消费税", y: "consumption_tax" },
  { x: "增值税", y: "value_added_tax" },
  { x: "营业税", y: "business_tax" },
  { x: "企业所得税", y: "corporate_income_tax" },
  { x: "所得税", y: "individual_income_tax" },
  { x: "城市维护建设税", y: "city_maintenance_and_construction_tax" },
  { x: "车船使用税", y: "vehicle_and_vessel_usage_tax" },
  { x: "房产税", y: "property_tax" },
  { x: "印花税", y: "stamp_tax" },
  { x: "土地增值税", y: "land_appreciation_tax" },
  { x: "土地使用税", y: "land_use_tax" },
  { x: "环保税", y: "environmental_tax" },
]

const MonthlyTaxChart = (props) => {
  const { totalRecord, year, tableData, month } = props
  const [legendData, setLegendData] = useState([])
  const [XData, setXdata] = useState([])
  const [chartTitle, setChartTitle] = useState('')
  useEffect(() => {
    handerData()
  }, [tableData, year])
  useEffect(() => {
    var myChart = echarts.init(document.getElementById('month-container'));
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    var option = {
      title: {
        text: chartTitle,
        top: 20,
        left: 20
      },
      grid: {
        left: '10%',
        bottom: '20%'
      },
      tooltip: {
        trigger: 'axis',
      },

      legend: {
        data: ['销量']
      },
      xAxis: {
        boundaryGap: false,
        data: XData,
        axisTick: {       //y轴刻度线
          show: false
        },
        axisLabel: {
          interval: -1,
          rotate: 40
        }
      },
      yAxis: {
        offset: 10,
        axisLine: {
          show: false,
          onZero: false
        },
        axisTick: {       //y轴刻度线
          show: false
        },
        splitLine: {     //网格线
          "show": false
        },
        axisLabel: {
          show: false
        }
      },
      series: [{
        y: '纳税总额',
        type: 'bar',
        barWidth: 20,
        data: legendData,
        symbolSize: 8,
        symbol: 'circle',

        itemStyle: {
          color: '#3A63FF',
          barBorderRadius: [10, 10, 0, 0],

        },
        label: {
          show: true, //开启显示
          position: 'top', //在上方显示
        },
        lineStyle: {
          color: '#3A63FF'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#3A63FF'
          }, {
            offset: 1,
            color: '#ffffff'
          }])
        },
      }],
    };
    myChart.setOption(option);
  }, [totalRecord, XData, legendData, tableData])
  const handerData = () => {
    const tmpTableData = tableData
    const yData = []
    const XData = []
    var totalTax = 0
    for (let i = 0; i < xAxisMapToyAxis.length; i++) {
      for (const key in tmpTableData) {
        if (key == xAxisMapToyAxis[i].y) {
          totalTax = totalTax + Number(tmpTableData[key])
          yData.push((tmpTableData[key] / 10000).toFixed(2))
          XData.push(xAxisMapToyAxis[i].x)
        }
      }
    }
    var tmp_title = null
    totalTax = (totalTax/10000).toFixed(2)//精度问题
    if (month != null) {
      const title = month == 'all' ? year : `${year}年${month.month}月`
      var tmp_title = `${title}年税收情况构成总额(${totalTax}万元)`
    } else {
      var tmp_title = `${year}年税收情况构成总额(${totalTax}万元)`

    }
    setChartTitle(tmp_title)
    setLegendData(yData)
    setXdata(XData)
  }
  return (
    <>
      <div id='month-container' style={{ overflow: "auto", width: '100%', height: '400px', backgroundColor: '#FFFFFF', marginTop: '20px', marginBottom: '20px', borderRadius: '14px' }} />
    </>
  );
}

export default MonthlyTaxChart;