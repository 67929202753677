import React, { useContext, useEffect, useState } from 'react';
import { ReachableContext } from '../UploadModal'
import { message, Row, Col, Select, Divider, Table, Button, Space } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import { paasql } from '@api'
const lineList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const { Option } = Select;




const SelectHeader = (props) => {
  const { taxID, choseHeader, backToSelectInfo } = useContext(ReachableContext)
  const [workSheet, setWorkSheet] = useState([])//工作表的下拉列表配置
  const [defaultWorkSheet, setDefaultWorkSheet] = useState()


  const [startLine, setStartLine] = useState('1')
  const [endLine, setEndLine] = useState('1')
  const [page, setPage] = useState("0")
  const [originalRawDataId, setOriginalRawDataId] = useState(null)
  const [tableLoading, setTableLoading] = useState(true)//表格加载状态

  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    async function fetchData() {
      var cmd_data = {
        "id": taxID
      }
      paasql("eip.monthly_accumulated_tax.list_by_id", cmd_data).then(data => {
        if (data.error == null) {
          var monthly_accumulated_tax_handler = data.result[0].original_raw_data_id
          var cmd_data = {
            "id": monthly_accumulated_tax_handler
          }
          return paasql("eip.eip_cp_file_meta.query_by_id", cmd_data).then(file_info => {
            if (file_info.error == null) {
              return fetch("/cpx/office/excel/read",
                {
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                  },
                  method: "POST",
                  body: JSON.stringify({
                    "id": "0",
                    "domain": "eip",
                    "params": {
                      "define": {
                        "original_raw_data_id": monthly_accumulated_tax_handler
                      }
                    }
                  })
                })
                .then(res => res.json()).then(res => {
                  if (res.error == null) {
                    const workSheet = res.result.data
                    setOriginalRawDataId(monthly_accumulated_tax_handler)
                    setDefaultWorkSheet(workSheet[0].name)
                    setWorkSheet(workSheet)
                    handleTableData(workSheet, 0)
                  }
                  else {
                    alert(res.error.message)
                  }
                })
            } else {
              alert(file_info.error.message)
            }
          })
        } else {
          alert(data.error.message)
        }
      })
    }
    //ToDo
    // if (originalFileId == null || taxID == null) {
    //   message.error('没有上传文件,请先传文件')
    //   setTimeout(() => {
    //     backToSelectInfo()

    //   }, 1000);
    //   return
    // }
    fetchData()
  }, [])
  const handleTableData = (data, index) => {
    const workSheet = data
    var tmp_table_data = []
    var default_sheet_data = workSheet[index].raw_data
    var x = default_sheet_data[0].x


    var table_index = 0
    var NewObjectValue = default_sheet_data[0].text
    var attr_object = {}
    attr_object[default_sheet_data[0].y] = NewObjectValue
    attr_object.index = 0
    tmp_table_data.push(attr_object);
    for (let i = 0; i < default_sheet_data.length; i++) {
      if (default_sheet_data[i].x == x) {//如果x相同，说明要往对象里面增加属性
        var NewObjectValue = null
        NewObjectValue = default_sheet_data[i].text
        tmp_table_data[table_index][default_sheet_data[i].y] = NewObjectValue
      }
      else {
        //  如果x相同，说明要新建对象，
        var x = default_sheet_data[i].x
        table_index = table_index + 1
        var attr_object = {}
        var NewObjectValue = null;
        NewObjectValue = default_sheet_data[i].text
        attr_object[default_sheet_data[i].y] = NewObjectValue
        tmp_table_data.push(attr_object);
      }
    }
    for (let i = 0; i < tmp_table_data.length; i++) {
      tmp_table_data[i]["index"] = i + 1
    }

    var _col_headers = [{
      key: 'index',
      title: 'index',
      dataIndex: 'index',
      width: 40,
      fixed: 'left',
    }]

    Object.keys(tmp_table_data[1]).forEach(function (key) {
      var header_obj = {}
      header_obj.key = key
      header_obj.title = key
      header_obj.dataIndex = key
      header_obj.width = 200
      _col_headers.push(header_obj)
    });

    setTableHeaderData(_col_headers)
    setTableData(tmp_table_data)
    setTableLoading(false)
  }
  const handleWorkSheetChange = (value) => {
    const tmpPage = value.key
    setPage(tmpPage)
    const data = workSheet
    handleTableData(data, Number(tmpPage))
  }
  const handleStartLineChange = (value) => {
    const tmpStartLine = value
    if (tmpStartLine > endLine) {
      message.warn("开始行不能大于结束行")
    } else {
      setStartLine(value)
    }
  }
  const handleEndLineChange = (value) => {
    const tmpEndLine = value
    if (startLine > tmpEndLine) {
      message.warn("开始行不能大于结束行")
    } else {
      setEndLine(value)
    }
  }
  const nextStep = (value) => {
    choseHeader({ startLine, endLine, page, originalRawDataId })
  }
  return (
    <>
      <div className="subModalHeader">
        确认Excel文件的表头信息
    </div>

      <div>
        <div>
          <Row style={{ paddingBottom: 16 }} >
            <Col span={4} style={{ textAlign: 'right', lineHeight: '30px', paddingRight: 20 }} >
              工作表:
            </Col>
            <Col span={20}>
              <Select
                suffixIcon={<CaretDownOutlined />}
                labelInValue value={{ key: defaultWorkSheet }} style={{ width: 720 }} onChange={handleWorkSheetChange}>
                {
                  workSheet.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={item.id}>
                        {item.name}
                      </Option>
                    )
                  })
                }
              </Select>
            </Col>
          </Row>

          <Row>
            <Col span={24} >
              <Row>
                <Col span={4} style={{ textAlign: 'right', lineHeight: '30px', paddingRight: 20 }} >
                  表格开始序号:
                   </Col>
                <Col span={7}   >
                  <Select  suffixIcon={<CaretDownOutlined />} value={startLine} style={{ width: 290 }} onChange={handleStartLineChange} >
                    {
                      lineList.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item}>
                            {item}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
                <Col span={4} style={{ textAlign: 'right', lineHeight: '30px', paddingRight: 20 }} >
                  表格结束序号:
                   </Col>
                <Col span={8}   >
                  <Select  suffixIcon={<CaretDownOutlined />} value={endLine} style={{ width: 290 }} onChange={handleEndLineChange} >
                    {
                      lineList.map((item, index) => {
                        return (
                          <Option key={index} value={item}>{item}</Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Divider style={{ margin: '10 10px' }} />


        <div flex="3" style={{ overflow: "auto" }}>
          <Table
            rowKey={record => record.index}
            loading={tableLoading}
            pagination={{
              hideOnSinglePage: true
            }} pageSize={10} showHeader={false} bordered size='small' columns={tableHeaderData} dataSource={tableData} scroll={{ x: 1500, y: 300 }} />
        </div>
        <div style={{ textAlign: 'right', paddingTop: 20 }}>
          <Space>
            <Button onClick={backToSelectInfo}>
              上一步
          </Button>
            <Button type="primary" onClick={nextStep}>
              下一步
          </Button>
          </Space>
        </div>
      </div>

    </>
  );
}

export default SelectHeader;