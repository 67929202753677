import React, { useState, useEffect } from 'react';

import { Button, message, Row, Col, Space, Empty, List, Modal } from 'antd'

import { paasql } from '@api'
import ButtonLabel from '@component/ButtonLabel'
import VirtualParkDetail from './VirtualParkDetail'
import VirtualParkEdit from './VirtualParkEdit'
import CreateVirtualPark from './CreateVirtualPark'
import { CreateIcon, RightOutlinedIcon } from '@icon'

import './index.less'
const ButtonGroup = Button.Group

const VirtualParkManagement = () => {
  const [parkId, setParkId] = useState(null)
  const [parkName, setParkName] = useState(null)
  const [virtualParkList, setVirtualParkList] = useState([])
  const [empty, setEmpty] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [activeId, setActiveId] = useState(null)

  const [createVirtualParkVisable, setCreateVirtualParkVisable] = useState(false)
  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const parkId = data.result[0].id
        setParkId(parkId)
        setParkName(data.result[0].name)
        const cmd_data = {
          eip_park_id: parkId
        }
        paasql('eip.eip_virtual_park.list', cmd_data).then(virtualParkList => {
          if (virtualParkList.error == null) {
            const result = virtualParkList.result

            if (result.length == 0) {
              setEmpty(true)
            } else {
              result[0].isActive = true
              setVirtualParkList(result)

              setActiveId(result[0].id)
            }
          } else {
            message.error(virtualParkList.error.message)
          }
        })
      } else {
        message.error(data.error.message)
      }
    })

    return () => {

    }
  }, [])

  const createVirtualPark = () => {
    setCreateVirtualParkVisable(true)
  }

  const reflushData = (id) => {
    const cmd_data = {
      eip_park_id: parkId
    }
    paasql('eip.eip_virtual_park.list', cmd_data).then(virtualParkList => {
      if (virtualParkList.error == null) {
        const result = virtualParkList.result

        if (result.length == 0) {
          setEmpty(true)
        } else {
          result[0].isActive = true
          setVirtualParkList(result)
          if (id === undefined) {
            setActiveId(result[0].id)
            return
          }
          setActiveId(id)
        }
      } else {
        message.error(virtualParkList.error.message)
      }
    })
  }

  const changeListClick = (item, index) => {
    setActiveId(item.id)
    setIsEdit(false)
    const tmpVirtualParkList = virtualParkList
    for (let i = 0; i < tmpVirtualParkList.length; i++) {
      if (i === index) {
        tmpVirtualParkList[i].isActive = true

      } else {
        tmpVirtualParkList[i].isActive = false
      }
    }
    setVirtualParkList([...tmpVirtualParkList])
  }

  const changeEdit = (status) => {
    setIsEdit(status)
  }

  const hideModal = () => {
    setCreateVirtualParkVisable(false)
  }
  return (
    <div>
      <div>
        <ButtonGroup>
          <ButtonLabel
            size='large'
          >

          </ButtonLabel>
          <Button
            size='large'
            icon={<CreateIcon />}
            onClick={createVirtualPark}
          >
            新增园中园
          </Button>
        </ButtonGroup>

      </div>
      <div className='virtual-park-container' style={{
        maxHeight: 'calc(100vh  - 230px)',
        overflow: 'auto',
      }}>
        {
          empty ? <Empty
          /> :
            <Row >
              <Col span={4}
                style={{ borderRight: '1px solid #E0E0E0' }}
              >
                <List
                  className='virtual-park-list'
                  dataSource={virtualParkList}
                  pagination={{
                    simple: true,
                    pageSize: 15,
                    hideOnSinglePage: true
                  }}
                  renderItem={(item, index) => (
                    <List.Item
                      className={`virtual-park-list-item ${activeId === item.id ? 'active-virtual-item' : ''}`}
                      key={item.id}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                      onClick={() => changeListClick(item, index)}
                    >
                      <span
                      // className={`virtual-item ${activeId === item.id ? 'active-virtual-item' : ''}`}
                      >
                        {item.name}
                      </span>


                      <div>
                        {
                          item.id === activeId ? <>
                            <RightOutlinedIcon />
                          </> : <> </>
                        }
                      </div>

                    </List.Item>
                  )}
                />
              </Col>
              <Col span={20}
                className="virtual-park-detail-edit-container"
              >
                {
                  isEdit ?
                    <VirtualParkEdit
                      parkId={parkId}
                      isEdit={isEdit}
                      changeEdit={changeEdit}
                      reflushData={reflushData}
                      VirtualParkId={activeId}
                    />

                    : <VirtualParkDetail
                      changeEdit={changeEdit}
                      reflushData={reflushData}
                      VirtualParkId={activeId}
                    />
                }
              </Col>
            </Row>

        }
        <div>
        </div>
      </div>
      <Modal
        width={850}
        title='新增中介'
        onCancel={() => {
          setCreateVirtualParkVisable(false)
        }}
        destroyOnClose={true}
        footer={null}
        visible={createVirtualParkVisable}
      >
        <CreateVirtualPark
          hideModal={hideModal}
          parkId={parkId}
          parkName={parkName}
          reflushData={reflushData}
        />
      </Modal>
    </div>
  );
}

export default VirtualParkManagement;