import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux'
import { Button, Divider, message, Space, Modal, Row, Col, List } from 'antd'
import { paasql } from '@api'
import { ExclamationCircleOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment'
import actionType from '@actions/actionType'
import ImplementingEnterpriseList from './ImplementingEnterpriseList'
import {
  DeleteDocIcon,
  BackToIcon,
  ArchivedDocIcon, SendListIcon, EditIcon

} from '@icon'
const ImplementingPoliceDetail = (props) => {
  const { PoliceManagementReducer, dispatch } = props
  const { alreadyGet, nextPoliceId, previousPoliceId, previousDisabled, nextDisabled } = PoliceManagementReducer
  const [content, setContent] = useState('')
  const policeId = props.match.params.id
  const [parkId, setParkId] = useState(null)
  const [parkName, setParkName] = useState(null)

  const [title, setTitle] = useState('')
  const [time, setTime] = useState('')
  const [archived, setArchived] = useState(null)
  const [policeList, setPoliceList] = useState([])
  const [enterpriseList, setEnterpriseList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  useEffect(
    () => {
      const cmd_data = {
        id: policeId
      }


      if (!alreadyGet) {
      }

      paasql("eip.eip_cms_doc.query_by_id", cmd_data).then(data => {
        const result = data.result
        //处理
        if (result.length != 0) {
          const { content, name, cp_timestamp, archived, eip_park_id, eip_park_name } = result[0]
          //处理页面显示


          //开始处理面包屑导航

          const navArr = ['在线政策', '未归档政策', name]
          dispatch({
            type: 'SET_BREADCRUMB_LIST',
            value: navArr
          })
          setParkId(eip_park_id)
          setParkName(eip_park_name)

          setArchived(archived)
          if (archived === 1) {
            message.error('这是归档的政策')
            props.history.push(`/police-management/archived/${policeId}`)
            return
          }

          const cmd_data = {
            id: policeId,
            archived,
            eip_park_id,
            name: "在线政策",
          }
          paasql("eip.eip_cms_doc.list_by_space_id", cmd_data).then(policeList => {
            if (policeList.error == null) {
              const result = policeList.result[0].content
              const policeListWithSort = result.sort(function (first, last) {
                return Number(last.cp_timestamp) - Number(first.cp_timestamp)
              })
              dispatch({
                type: actionType.SET_POLICE_LIST,
                value: policeListWithSort
              })
              dispatch({
                type: actionType.SET_NEXT_POLICEID,
                value: policeId
              })
              setPoliceList(policeListWithSort)
            } else {
              message.error(policeList.error.message)
            }
          })
          //判断archived
          setContent(content)
          setTitle(name)
          //处理日期
          const time = moment(cp_timestamp / 1000).format('YYYY-MM-DD HH:mm')
          setTime(time)
          const request = {
            eip_cms_doc_id: policeId
          }
          paasql('eip.eip_enterprise_o2m_eip_cms_doc.enterprise_list', request).then(enterprise_list => {
            if (enterprise_list.error == null) {
              setEnterpriseList(enterprise_list.result)
            } else {
              message.error(enterprise_list.error.message)
            }
          })
        }

      })
    }, [policeId])

  const handleRequest = () => {
    const request = {
      eip_cms_doc_id: policeId
    }
    paasql('eip.eip_enterprise_o2m_eip_cms_doc.enterprise_list', request).then(enterprise_list => {
      if (enterprise_list.error == null) {
        setEnterpriseList(enterprise_list.result)
      } else {
        message.error(enterprise_list.error.message)
      }
    })
  }
  const confirmArchivedPolice = () => {
    const cmd_data = {
      archived: '1',
      id: policeId
    }
    paasql('eip.cms_doc.update_archived', cmd_data).then(data => {
      if (data.error == null) {
        message.success('归档成功')
        props.history.push(`/police-management/archived/${policeId}`)
      } else {
        message.error(data.error.message)
      }
    })
  }
  //归档政策
  const changeArchived = () => {
    Modal.confirm({
      onOk() {
        confirmArchivedPolice()
      },
      width: 600,
      content: '将移动该政策到归档的政策中，归档的政策不能再次编辑和发送，是否归档？',
      icon: <ExclamationCircleOutlined />,
      title: `归档政策`
    })

  }

  //编辑政策
  const eidtPolice = () => {
    props.history.push(`/police-management/edit/${policeId}`)
  }

  //显示发送名单
  const showEnterpriseList = () => {
    setModalVisible(true)
  }

  //隐藏模态框
  const hideModal = () => {
    setModalVisible(false)
  }

  const sendMessageSuccess = () => {
    setModalVisible(false)
    handleRequest()
  }
  const confirmDestroyPolice = () => {
    const cmd_data = {
      id: policeId
    }
    return paasql("eip.eip_enterprise_o2m_eip_cms_doc.policy_delete", cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        props.history.push(`/police-management`)
      } else {
        message.error(data.error.message)
      }
    })
  }
  //删除政策
  const destroyPolice = () => {
    Modal.confirm({
      onOk() {
        confirmDestroyPolice()
      },
      width: 600,
      content: '确认要删除该政策，删除后不可恢复！',
      icon: <ExclamationCircleOutlined />,
      title: `删除政策`
    })

  }

  const goPreviousPolice = () => {
    //previousPoliceId
    props.history.push(`/police-management/implementing/${previousPoliceId}`)

  }
  const goNextPolice = () => {
    props.history.push(`/police-management/implementing/${nextPoliceId}`)
  }


  const backPoliceList = () => {
    props.history.push('/police-management')
  }
  return (
    <div className="police-detail-container" >
      <div
        className='police-detail-header'
      >
        <div>
          <span style={{ paddingRight: 20 }}  >
            <LeftOutlined /> <a target="_blank" disabled={previousDisabled} onClick={goPreviousPolice} rel="noopener noreferrer"> 上一篇</a>
          </span>
          <span >
            <a target="_blank" disabled={nextDisabled} onClick={goNextPolice} rel="noopener noreferrer">下一篇 </a><RightOutlined />
          </span>
        </div>
        <div>
          <Button.Group>
            <Button
              icon={<BackToIcon />}
              size='large'
              onClick={backPoliceList}>
              返回
            </Button>
            <Button
              size='large'

              icon={<DeleteDocIcon />}
              onClick={destroyPolice}>
              删除政策
            </Button>
            <Button
              size='large'
              icon={<SendListIcon />}
              onClick={showEnterpriseList}
            >
              发送名单   {enterpriseList.length}
            </Button>
            <Button
              size='large'
              icon={<ArchivedDocIcon />}

              onClick={changeArchived}
            >
              立即归档
            </Button>
            <Button
              size='large'
              icon={<EditIcon />}
              onClick={eidtPolice}

            >
              编辑政策
            </Button>
          </Button.Group>

        </div>
      </div>


      <div className='police-detail-container'>
        <div className='police-detail-container-title'>
          <div>
            {title}
          </div>
          <div className='police-detail-container-time'>
            {time}
          </div>
        </div>
        <Divider>
          <div
            className='divider-container'
          >

          </div>
        </Divider>

        <div
          dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </div>

      <Modal
        closable={false}
        title={
          <div className='police-detail-modal-title'>
            发送名单
          </div>

        }
        width={800}
        visible={modalVisible}
        footer={null}
        destroyOnClose={true}
      >
        <ImplementingEnterpriseList
          sendMessageSuccess={sendMessageSuccess}
          policeId={policeId}
          parkName={parkName}
          handleRequest={handleRequest}
          parkId={parkId}
          hideModal={hideModal}
          enterpriseList={enterpriseList}
        >

        </ImplementingEnterpriseList>
      </Modal>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    PoliceManagementReducer: state.PoliceManagement,
    Frame: state.Frame
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ImplementingPoliceDetail);
