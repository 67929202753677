
const defaultState = {
  "monthly_accumulated_tax_info": {
    'eip_tax_bureau': { 'id': '', 'name': '上海市奉贤区庄行税务局' },
    'eip_park': { 'id': '', 'name': '华严工业园区' },
    'eip_business_year': { 'id': '', 'name': '2019' },
    'start_month': { 'id': '', 'name': '01月' },
    'end_month': { 'id': '', 'name': '' },
    'started_at': null,
    'ended_at': 3,
    'name': ''
  }
}


export default (state = defaultState, action) => {
  switch (action.type) {
    case "CHANGE_INFO":
      const tmpState = state
      var { monthly_accumulated_tax_info } = tmpState
      monthly_accumulated_tax_info.ended_at= action.values.ended_at
      return {
        monthly_accumulated_tax_info
      }
    default:
      return state
  }
}
