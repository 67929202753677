import React, { useEffect, useState, useContext } from 'react';
import { Select, Row, Col, message, Input, Space, Button } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import { ReachableContext, } from '../UploadModal'
const theader = [{
  column: 0,
  name: "税管码"
}, {
  column: 1,
  name: "企业名称"
}, {
  column: 2,
  name: "支付金额"
}, {
  column: 3,
  name: "支付日期"
}, {
  column: 4,
  name: "中介支付金额"
}, {
  column: 5,
  name: "中介支付日期"
}]
const relationMap = {
  1: "name",
  2: "actual_amount_paid",
  3: "actual_amount_paid",
}

const { Option } = Select;
const FieldPurpose = () => {

  const { backToSelectHeader, tableHeaderData,
    //  relationMap, 
    backToFieldMapping,
    importLoading,
    fieldList,
    relationMap,
    importData
  } = useContext(ReachableContext)
  const [match, setMatch] = useState({
    key: 'name',
    name: 'tax_file_number',
    write_str:['paid_at','actual_amount_paid']
  })



  const [alreadyMappingList, setAlreadyMappingList] = useState([])
  const [info, setInfo] = useState({})
  const [keyAndNameSelect, setKeyAndNameSelectList] = useState([])
  useEffect(() => {
    if (tableHeaderData.length == 0) {
      message.error("未选择表头，请返回选择", 1)
      // 设置延时效果
      setTimeout(() => {
        backToSelectHeader()
      }, 1000);
      return
    }

    const unMapList = []
    //处理支付日期
    const alreadyMapping = fieldList.filter(item => item.value != undefined)

    const info = {}


    const write_str = []//待处理


    //页面的显示实际支付和支付日期这两个的值
    for (let i = 0; i < tableHeaderData.length; i++) {
      for (let j = 0; j < alreadyMapping.length; j++) {
        if (alreadyMapping[j].value == tableHeaderData[i].column) {
          const obj = {
          }
          const name = alreadyMapping[j].name
          info[name] = tableHeaderData[i].name
          break
        }
      }
    }
    setInfo(info)

    //处理key和name的
    const alreadyMappingList = []
    for (const key in relationMap) {
      for (let i = 0; i < tableHeaderData.length; i++) {
        const attr_obj = tableHeaderData[i]
        if ( relationMap[key]== tableHeaderData[i].column) {
          attr_obj.field = key
          alreadyMappingList.push(attr_obj)
        }
      }
    }
    setAlreadyMappingList(alreadyMappingList)
    //这里怎么确定的
  }, [tableHeaderData, relationMap])
  const labelList = [{
    name: "key",
    display_name: '主键'
  },
  {
    name: "name",
    display_name: '匹配字段'
  },
  ]

  const handleSelectListChange = (value, item, index) => {

    if (item.name == "name") {
      setMatch({
        ...match,
        name: value
      })
    } else {
      setMatch({
        ...match,
        key: value
      })
    }
  }
  const handleImport = () => {
    importData({match})
  }
  return (
    <>
      <div className="subModalHeader">
        选择excel表的列的用途
      </div>
      <Row style={{ fontFamily: 'HiraginoSansGB-W6,HiraginoSansGB', lineHeight: '21px', paddingBottom: 16 }}>
        <Col span={6} style={{ textAlign: 'right', paddingRight: 60 }} >
          字段用途
        </Col>
        <Col span={18}>
          Excel表的列
        </Col>
      </Row>
      <div style={{ maxHeight: '500px', overflow: 'auto' }} >
        {
          labelList.map((item, index) => {
            return (
              <Row key={item.name} style={{ paddingBottom: 16 }} >
                <Col span={6} style={{ textAlign: 'right', padding: '13px 60px 13px 0px', fontSize: '14px', lineHeight: '14px' }} >
                  {item.display_name}:
                </Col>
                <Col span={18}>
                  <Select
                    placeholder={`请选择${item.display_name}`}
                    suffixIcon={<CaretDownOutlined />}
                    size='large'
                    style={{ width: 640 }}
                    onChange={(value) => handleSelectListChange(value, item, index)}
                  >
                    {
                      alreadyMappingList.map(optionItem => {
                        return (
                          <Option value={optionItem.field} key={optionItem.field}>{optionItem.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </Row>
            )
          })
        }
        <Row style={{ paddingBottom: 16 }}>
          <Col span={6} style={{ textAlign: 'right', padding: '13px 60px 13px 0px', fontSize: '14px', lineHeight: '14px' }}>
            实际支付:
            </Col>
          <Col span={18} >
            <div style={{ lineHeight: '40px' }}>
              {info.actual_amount_paid}
            </div>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 16 }}>
          <Col span={6} style={{ textAlign: 'right', padding: '13px 60px 13px 0px', fontSize: '14px', lineHeight: '14px' }}>
            支付日期:
           </Col>
          <Col span={18} >
            <div style={{ lineHeight: '40px' }}>
              {
                info.paid_at
              }
            </div>
          </Col>
        </Row>

      </div>

      <div style={{ textAlign: 'right', paddingTop: 20 }} >
        <Space>
          <Button onClick={backToFieldMapping}>
            上一步
          </Button>
          <Button type="primary" onClick={handleImport}>
            下一步
          </Button>
        </Space>
      </div>
    </>
  );
}

export default FieldPurpose;