import React, { useState, useEffect } from 'react';

import { Button, Space, Divider, Modal, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'

import { paasql } from '@api'
import { EditIcon, DeleteDocIcon, BackToIcon } from '@icon'


const FamousEnterpriseDetail = (props) => {
  const { dispatch } = props

  const docId = props.match.params.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState("")
  useEffect(() => {
    const cmd_data = {
      id: docId
    }
    paasql('eip.eip_cms_doc.query_by_id', cmd_data).then(data => {
      const result = data.result
      if (result.length === 0) {
        message.error("没有这家企业")
        props.history.push('/famous-enterprise')
        return
      }
      const { name, content } = result[0]
      const navArr = ['招商管理', '明星企业', name]

      dispatch({
        type: 'SET_BREADCRUMB_LIST',
        value: navArr
      })
      setTitle(name)
      setContent(content)

    })
  }, [])
  const eidtDoc = () => {
    props.history.push(`/famous-enterprise/edit/${docId}`)
  }
  function delDoc() {
    Modal.confirm({
      onOk() {
        confirmDestroyDoc()
      },
      width: 600,
      content: '确认要删除该明星企业，删除后不可恢复！',
      icon: <ExclamationCircleOutlined />,
      title: `删除明星企业`
    })
  }
  const confirmDestroyDoc = () => {
    const cmd_data = {
      id: docId
    }
    paasql('eip.eip_cms_doc.delete', cmd_data).then(data => {
      if (data.error == null) {
        message.success("删除成功")
        props.history.push('/famous-enterprise')
      } else {
        message.error(data.error.message)
      }
    })
  }
  const backToFamousEnterprise = () => {
    props.history.push('/famous-enterprise')

  }
  return (
    <div className="police-detail-container" >
      <div style={{ textAlign: 'right' }}>
        <Button.Group>


          <Button
            icon={<BackToIcon />}
            onClick={backToFamousEnterprise}
          >
            返回
          </Button>
          <Button
            icon={<DeleteDocIcon />}
            onClick={delDoc}
          >
            删除
          </Button>
          <Button

            icon={<EditIcon />}
            onClick={eidtDoc}
          >
            编辑文档
          </Button>
        </Button.Group>
      </div>
      <div className='police-detail-container'>
        <div className='police-detail-container-title'>
          <div>
            {title}
          </div>
        </div>
        <Divider>
          <div
            className='divider-container'
          >

          </div>
        </Divider>

        <div
          dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FamousEnterpriseDetail);