import React, { useState, useEffect, createRef } from 'react';
import E from 'wangeditor'

import { Button, Input, Select, Space, Divider, message, Modal, Typography } from 'antd'


import { paasql } from '@api'
import { uploadImg } from '@api/paasql'


const editorRef = createRef()

const { Option } = Select;
const VirtualParkDetail = (props) => {
  const { VirtualParkId, reflushData, parkId, isEdit, pageChange, changeEdit } = props
  const [virtualParkInfo, setVirtualParkInfo] = useState({
    name: null,

  })
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])

  const [name, setName] = useState('')
  const [investmentAgencyStaffId, setInvestmentAgencyStaffId] = useState(null)
  const [investmentAgencyStaffName, setInvestmentAgencyStaffName] = useState(null)
  //富文本的内容
  const [content, setContent] = useState(null)
  useEffect(() => {
    if (VirtualParkId == null) {
      return
    }
    const cmd_data = {
      id: VirtualParkId
    }
    paasql('eip.eip_virtual_park.query_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        const {
          name, brief_introduction,
          eip_investment_agency_name,
          eip_investment_agency_id
        } = result
        setName(name)
        setInvestmentAgencyStaffName(eip_investment_agency_name)
        setInvestmentAgencyStaffId(eip_investment_agency_id)
        initEditor(brief_introduction)
        setContent(brief_introduction)
        setVirtualParkInfo(result)
      } else {
        message.error(data.error.message)
      }
    })

    //获得招商中介的列表
    const cmd_data1 = {
      eip_park_id: parkId
    }
    paasql('eip.eip_investment_agency.list', cmd_data1).then(data => {
      if (data.error == null) {
        const result = data.result
        setInvestmentAgencyList(result)
      } else {
        message.error(data.error.message)
      }
    })

  }, [VirtualParkId, parkId, isEdit])


  const initEditor = (content) => {

    const editor = new E(editorRef.current)

    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      setContent(html)
    }
    editor.customConfig.menus = [
      'head',
      'bold',
      'italic',
      'underline',
      'justify',
      'image',
    ]
    editor.customConfig.zIndex = 100
    editor.customConfig.customUploadImg = function (files, insert) {
      //eip_cms_doc_file
      const formData = new FormData();
      files.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file);
      })

      const request = {
        cp_schema_id: "cp_file_meta",
        cp_object_attribute: "id",
        file_object: formData
      }
      uploadImg(request).then(data => {
        if (data.error == null) {
          const fileId = data.result.id
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`
          insert(url)
        } else {
          message.error(data.error.message)
        }
      })
    }

    editor.create()
    editor.txt.html(content)
  }
  const editVirtualPark = () => {
    changeEdit(false)
  }


  const changeName = (e) => {
    const value = e.target.value
    setVirtualParkInfo({
      ...virtualParkInfo,
      name: value
    })
  }

  const changeInvestmentAgency = (v, option) => {
    const { key, value } = option
    setVirtualParkInfo({
      ...virtualParkInfo,
      eip_investment_agency_name: key,
      eip_investment_agency_id: value,
    })

  }

  const saveEdit = () => {
    const cmd_data = {
      object: {
        ...virtualParkInfo,
        brief_introduction: content
      }
    }
    paasql('eip.eip_virtual_park.update', cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        changeEdit(false)
      } else {
        message.error(data.error.message)
      }
    })
  }

  const editList = [
    {
      label: '名称',
      component:
        <Input
          className='input'
          onChange={changeName} value={virtualParkInfo.name} />
    },
    {
      label: '招商中介名称',
      component:
        <Select
          className='select'
          style={{ width: '100%', }}
          onChange={changeInvestmentAgency}
          value={virtualParkInfo.eip_investment_agency_name}
        >
          {
            investmentAgencyList.map(item => {
              return (
                <Option
                  style={{ zIndex: '999999' }}
                  value={item.id}
                  key={item.name}
                >
                  {item.name}
                </Option>
              )
            })
          }
        </Select>
    },
    {
      label: '简介',
      component:
        <div ref={editorRef} />

    }
  ]
  return (
    <div>

      <div className='virtual-park-edit-nav'>
        <div className='virtual-park-edit-title'>
          编辑园中园
        </div>
        <Space>
          <Button
            onClick={editVirtualPark}
          >
            取消编辑
          </Button>
          <Button
            type='primary'
            onClick={saveEdit}
          >
            保存
      </Button>
        </Space>

      </div>
      <div className='virtual-park-edit-info-list'>
        {
          editList.map(item => {
            return (
              <div key={item.label} className='info-item'>
                <div className="label" >
                  {item.label}
                </div>
                <div className='value'>
                  {item.component}
                </div>
              </div>
            )
          })
        }
      </div>


    </div >
  );
}

export default VirtualParkDetail;