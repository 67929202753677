import React, { useState, useContext, useEffect } from 'react';
import { Select, Row, Col, Space, Button, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import { ReachableContext, } from '../UploadModal'


const { Option } = Select;

const FieldPurpose = (props) => {
  const {
    tableHeaderData,
    backToFieldMapping,
    importData,
    relationMap
  } = useContext(ReachableContext)
  const [match, setMatch] = useState({})

  //已经支付的列表
  const [info, setInfo] = useState({
    paid_at: "支付日期",
    actual_amount_paid: '实际支付'
  })

  //这里应该是要除去支付日期/实际支付/招商点实际支付/招商点支付日期这四个
  const [keyAndNameSelectList, setKeyAndNameSelectList] = useState([
  ])


  useEffect(() => {
    if (relationMap === null) {
      message.error('还未选择表头')
      backToFieldMapping()
      return
    }
    const list = [{
      name: "key",
      display_name: '主键',
      type: 'select'
    }]
    const alreadyMappingList = []
    for (let i = 0; i < tableHeaderData.length; i++) {

      for (const key in relationMap) {
        if (Number(relationMap[key]) === tableHeaderData[i].column) {
          const attr_obj = Object.assign(tableHeaderData[i])
          attr_obj.field = key
          alreadyMappingList.push(attr_obj)
        }
      }
    }
    const keyAndNameSelectList = []
    const info = []
    const write_str = []

    for (let i = 0; i < alreadyMappingList.length; i++) {
      if (
        alreadyMappingList[i].field === "actual_amount_paid"
        || alreadyMappingList[i].field === "actual_agency_amount_paid"
        || alreadyMappingList[i].field === "agency_paid_at"
        || alreadyMappingList[i].field === "paid_at"
      ) {
        info[alreadyMappingList[i].field] = alreadyMappingList[i].name//这是页面显示使用的
        write_str.push(alreadyMappingList[i].field)
      } else {
        keyAndNameSelectList.push(alreadyMappingList[i])
      }
    }
    setInfo(info)
    setMatch({
      ...match,
      write_str
    })
    setKeyAndNameSelectList(keyAndNameSelectList)
  }, [])
  const labelList = [{
    name: "key",
    display_name: '主键',
    type: 'select'
  },
  {
    name: "name",
    display_name: '匹配字段',
    type: 'select'
  },
  {
    name: "paid_at",
    display_name: '支付日期',
    type: 'string'
  },
  {
    name: "actual_amount_paid",
    display_name: '实际支付',
    type: 'string'
  },
  {
    name: "actual_agency_amount_paid",
    display_name: '招商点实际支付',
    type: 'string'
  },
  {
    name: "agency_paid_at",
    display_name: '招商点支付日期',
    type: 'string'
  }
  ]

  const handleSelectListChange = (value, item, index) => {

    if (item.name === 'key') {
      setMatch({
        ...match,
        key: value
      })
      return
    }
    if (item.name === 'name') {
      setMatch({
        ...match,
        name: value
      })
    }
  }
  const handleImport = () => {
    //拿到了match
    const { key, name } = match
    if (key == undefined || name == undefined) {
      message.error("请先选择主键和匹配字段")
    } else {
      importData({ match })
    }

  }
  return (
    <>
      <div className="subModalHeader">
        选择excel表的列的用途
      </div>
      <Row style={{ fontFamily: 'HiraginoSansGB-W6,HiraginoSansGB', lineHeight: '21px', paddingBottom: 16 }}>
        <Col span={6} style={{ textAlign: 'right', paddingRight: 60 }} >
          字段用途
        </Col>
        <Col span={18}>
          Excel表的列
        </Col>
      </Row>
      <div style={{ maxHeight: '500px', overflow: 'auto' }} >
        {
          labelList.map((item, index) => {
            return (
              <Row key={item.name} style={{ paddingBottom: 16 }} >
                <Col span={6} style={{ textAlign: 'right', padding: '13px 60px 13px 0px', fontSize: '14px', lineHeight: '14px' }} >
                  {item.display_name}:
                </Col>
                <Col span={18}>
                  {
                    item.type == "select" ?
                      <Select
                        placeholder={`请选择${item.display_name}`}
                        suffixIcon={<CaretDownOutlined />}
                        size='large'
                        style={{ width: 640 }}
                        onChange={(value) => handleSelectListChange(value, item, index)}
                      >
                        {
                          keyAndNameSelectList.map((optionItem, index) => {
                            return (
                              <Option value={optionItem.field} key={index}>{optionItem.name}</Option>
                            )
                          })
                        }
                      </Select>
                      :
                      <div key={item.name} style={{ lineHeight: '40px' }}>
                        {info[item.name]}
                      </div>
                  }

                </Col>
              </Row>
            )
          })
        }
      </div>

      <div style={{ textAlign: 'right', paddingTop: 20 }} >
        <Space>
          <Button onClick={backToFieldMapping}>
            上一步
          </Button>
          <Button type="primary" onClick={handleImport}>
            下一步
          </Button>
        </Space>
      </div>
    </>
  );
}

export default FieldPurpose;