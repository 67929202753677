import React, { useState, useEffect } from "react";

import { Col, Row } from "antd";

import YearAmount from "./ParkDashboard/YearAmount";
import ParkEnterpriseLevelCount from "./ParkDashboard/ParkEnterpriseLevelCount";
import ParkTaxTrend from "./ParkDashboard/ParkTaxTrend";
import ParkSettleExitedRecord from "./ParkDashboard/ParkSettleExitedRecord";
import "./ParkDashboard/index.less";

import FirstPaidAt from "./ParkDashboard/FirstPaidAt";
// var src = "" + '?session=' + session + '&year=' + year + '&api_server=' + api_server
const aliyunSrc =
  "http://datav.aliyuncs.com/share/2675be913be746752e17ba3d7a47d21a";
const ParkDashboard = () => {
  const [parkDashboardDataVSrc, setParkDashboardDataVSrc] = useState(null);
  return (
    <div className="park-dashboard-container">
      <Row
        className="first-row"
        style={{
          marginTop: "20px",
        }}
      >
        <Col className={"first-col"} span={12}>
          <YearAmount />
        </Col>
        <Col
          className={"secound-col"}
          span={12}
          style={{
            paddingLeft: "20px",
          }}
        >
          <ParkEnterpriseLevelCount />
        </Col>
      </Row>

      <Row
        className="secound-row"
        style={{
          marginTop: "20px",
        }}
      >
        <Col className="first-col" span={12}>
          <ParkTaxTrend />
        </Col>
        <Col
          className="secound-col"
          span={12}
          style={{
            paddingLeft: "20px",
          }}
        >
          <ParkSettleExitedRecord />
        </Col>
      </Row>
      <Row className="third-row">
        <FirstPaidAt />
      </Row>
    </div>
  );
};

export default ParkDashboard;
