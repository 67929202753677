import React, { useState, useEffect } from 'react';


import {
  Button, message, Spin,
  Pagination, Modal
} from 'antd'

import moment from 'moment'

import EnterpriseTable from './EnterpriseTable'
import UploadModal from './ImportTaxRefund/UploadModal'
import ImportManagementFee from './ImportManagement/UploadModal'
import SelectFieldModal from '../component/SelectFieldModal'
import ButtonLabel from '@component/ButtonLabel'
import FilterData from '../component/FilterData'


import { tableHeaderForExport } from './tableConfig'
import { paasql, queryEnterpriseByPage } from '@api'
import { ButtonGroupLable, ExportDataIcon, CreateIcon, FilterIcon, ImportDataIcon } from '@icon'
import './EnterpriseDatabase.less'



const ButtonGroup = Button.Group
const EnterpriseDatabase = (props) => {
  const [tableData, setTableData] = useState([])
  const [tableDataAll, setTableDataAll] = useState([])
  const [parkId, setParkId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalPage, setTotalPage] = useState(0)
  const [attributeFilter, setAttributeFilter] = useState([])
  const [enterpriseSize, setEnterpriseSize] = useState(0)

  const [loading, setLoading] = useState(false)


  const [selectFieldModalVisiable, setSelectFieldModalVisiable] = useState(false)
  const [filterDataModalVisiable, setFilterDataModalVisiable] = useState(false)

  //导入扶持点
  const [uploadFileModalChange, setUploadFileModalChange] = useState(false)
  const [uploadFileModalVisable, setUploadFileModalVisable] = useState(false)



  //导入管理费
  const [
    importManagementFeeModalVisable,
    setImportManagementFeeModalVisable] = useState(false)


  const [
    importManagementFeeModalChange,
    setImportManagementFeeModalChange] = useState(false)
  useEffect(() => {
    paasql('eip.park.list').then(data => {
      const result = data.result
      setParkId(result[0].id)
      const cmd_data = {
        eip_park_id: result[0].id,
        page: currentPage,
        size: pageSize,
        attribute_filter: attributeFilter
      }
      getData(cmd_data)
    })
  }, [])
  const getData = (cmd_data, ) => {
    queryEnterpriseByPage(cmd_data).then(data => {
      const { list, total_page, total } = data
      paasql('eip.eip_enterprise_database.get_cp_standard_data_from_cp_standard_collection').then(data => {
        const standData = data.result.parallel_list_object
        const tableData = handleTableData(list, standData)
        setEnterpriseSize(total)
        setTableData(tableData)
        setTotalPage(total_page)
      })

    })
  }

  const getAllData = async function () {
    const cmd_data = {
      eip_park_id: parkId,
      page: 1,
      size: pageSize * totalPage,
      attribute_filter: attributeFilter
    }
    setLoading(true)
    return queryEnterpriseByPage(cmd_data).then(data => {
      const { list } = data

      return paasql('eip.eip_enterprise_database.get_cp_standard_data_from_cp_standard_collection').then(data => {
        const standData = data.result.parallel_list_object
        const tableDataAll = handleTableData(list, standData)
        setLoading(false)
        //处理招商部门
        tableDataAll.forEach(element => {
          //处理时间格式
          const { settled_at, established_in,
            business_term_started_at, business_term_ended_at,
            currency
          } = element
          const settledAt =
            settled_at === null ||
              settled_at === '' ? null
              : moment(settled_at).format('YYYY-MM-DD')

          const establishedIn =
            established_in === null ||
              established_in === '' ? null
              : moment(established_in).format('YYYY-MM-DD')

          const businessTermStartedAt =
            business_term_started_at === null ||
              business_term_started_at === '' ? null
              : moment(business_term_started_at).format('YYYY-MM-DD')

          const businessTermEndedAt =
            business_term_ended_at === null ||
              business_term_ended_at === '' ? null
              : moment(business_term_ended_at).format('YYYY-MM-DD')


          element.settled_at = settledAt
          element.established_in = establishedIn
          element.business_term_started_at = businessTermStartedAt
          element.business_term_ended_at = businessTermEndedAt

          element.currency = currency === null || currency === '' ? '人民币' : currency
          if (element.settled_by_code == '01') {
            element.zhaoshangbumen = '本部'
            element.zhaoshangyuan = element.cp_staff_name
          } else {
            element.zhaoshangbumen = element.eip_investment_agency_name
            element.zhaoshangyuan = element.eip_investment_agency_staff_name
          }
        });


        setTableDataAll(tableDataAll)
        return tableDataAll
      })

    })
  }
  const handleTableData = (data, standData) => {
    var standardDataObj = new Object()
    for (var i = 0; i < standData.length; i++) {
      for (const key in standData[i]) {
        standardDataObj[key] = standData[i][key][0].result
      }
    }
    const _data = []
    for (var i = 0; i < data.length; i++) {
      var attr_obj = data[i]
      for (const tb_attr in data[i]) {
        for (const key in standardDataObj) {
          if (key == tb_attr) {
            for (var k = 0; k < standardDataObj[key].length; k++) {
              if (data[i][tb_attr] == standardDataObj[key][k].id) {
                attr_obj[tb_attr] = standardDataObj[key][k].name
              }
            }
          }
        }
      }

      _data.push(attr_obj)
    }
    const tmp_table_data_with_judge = []
    for (var i = 0; i < _data.length; i++) {
      var attr_obj = _data[i]
      if (attr_obj.is_registered_in_this_park == '1') {
        attr_obj.is_registered_in_this_park = "是"
      } else {
        attr_obj.is_registered_in_this_park = "否"
      }
      tmp_table_data_with_judge.push(attr_obj)
    }
    return tmp_table_data_with_judge
  }
  const createEnterprise = () => {
    props.history.push('enterprise-database/create')
  }

  const importTaxRefund = () => {
    setUploadFileModalChange(!uploadFileModalChange)
    setUploadFileModalVisable(true)
  }

  const importManagementFee = () => {
    setImportManagementFeeModalChange(!importManagementFeeModalChange)
    setImportManagementFeeModalVisable(true)
  }

  const filterEnterprise = () => {
    setFilterDataModalVisiable(true)
  }


  //筛选条件
  const filterData = (condition) => {
    setAttributeFilter(condition)
    setCurrentPage(1)
    const cmd_data = {
      eip_park_id: parkId,
      page: 1,
      size: pageSize,
      attribute_filter: condition
    }


    queryEnterpriseByPage(cmd_data).then(data => {
      const { list, total_page, total } = data
      setEnterpriseSize(total)
      paasql('eip.eip_enterprise_database.get_cp_standard_data_from_cp_standard_collection').then(data => {
        const standData = data.result.parallel_list_object
        const tableData = handleTableData(list, standData)
        setTableData(tableData)
        setTotalPage(total_page)
        setFilterDataModalVisiable(false)
      })

    })
  }


  //隐藏模态框
  const hideFilterModal = () => {
    setFilterDataModalVisiable(false)

  }
  //打开选择字段导出
  const exportData = () => {
    setSelectFieldModalVisiable(true)
  }

  //关闭选择字段导出
  const hideSelectFieldModal = () => {
    setLoading(false)
    setSelectFieldModalVisiable(false)
  }
  const pageChange = (page) => {
    setCurrentPage(page)
    const cmd_data = {
      eip_park_id: parkId,
      page: page,
      size: pageSize,
      attribute_filter: attributeFilter
    }
    getData(cmd_data)
  }

  //删除后刷新数据
  const reflushData = () => {
    const cmd_data = {
      eip_park_id: parkId,
      page: currentPage,
      size: pageSize,
      attribute_filter: attributeFilter
    }
    getData(cmd_data)
  }

  const clearCondition = () => {
    setAttributeFilter([])
  }


  return (
    <Spin spinning={loading}>
      <div  >
        <ButtonGroup>
          <ButtonLabel
            size="large"
          />
          <Button
            size="large"
            icon={<CreateIcon />}
            onClick={createEnterprise}>
            新增
          </Button>
          <Button
            size='large'
            icon={<ImportDataIcon />}
            size="large" onClick={importTaxRefund}>
            导入扶持点
          </Button>
          <Button
            size='large'
            icon={<ImportDataIcon />}
            size="large" onClick={importManagementFee}>
            导入管理费
          </Button>

          <Button
            size='large'
            icon={<FilterIcon />}
            size="large" onClick={filterEnterprise} >
            筛选
          </Button>

          <Button
            size='large'
            icon={<ExportDataIcon />}
            size="large" onClick={exportData}  >
            导出
          </Button>
        </ButtonGroup>

      </div>
      <div style={{ marginTop: '20px' }}>
        <EnterpriseTable
          {
          ...props
          }
          reflushData={reflushData}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPage={totalPage}
          tableData={tableData}
        >
        </EnterpriseTable>
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <Pagination
            showQuickJumper={true}
            current={currentPage}
            showSizeChanger={false}
            total={totalPage * pageSize}
            showTotal={(total, range) => `共 ${enterpriseSize} 条`}
            pageSize={pageSize}
            onChange={pageChange}
          >
          </Pagination>
        </div>

      </div>
      <UploadModal
        parkId={parkId}
        reflushData={reflushData}
        uploadFileModalChange={uploadFileModalChange}
        visible={uploadFileModalVisable}
      >
      </UploadModal>

      <ImportManagementFee
        parkId={parkId}
        reflushData={reflushData}
        uploadFileModalChange=
        {importManagementFeeModalChange}
        visible={importManagementFeeModalVisable}
      >
      </ImportManagementFee>
      <Modal
        title="请选择要导出的列"
        footer={null}
        onCancel={() => {
          setSelectFieldModalVisiable(false)
        }}
        visible={selectFieldModalVisiable}
      >
        <SelectFieldModal
          getAllData={getAllData}
          hideModal={hideSelectFieldModal}
          totalPage={totalPage}
          pageSize={pageSize}
          tableData={tableDataAll}
        >
        </SelectFieldModal>
      </Modal>
      <Modal
        title="筛选"

        footer={null}
        width={1000}
        onCancel={() => {
          setFilterDataModalVisiable(false)
        }}
        visible={filterDataModalVisiable}
      >
        <FilterData
          clearCondition={clearCondition}
          hideModal={hideFilterModal}
          filterData={filterData}
          totalPage={totalPage}
          pageSize={pageSize}
        >
        </FilterData>
      </Modal>
    </Spin>
  );
}

export default EnterpriseDatabase;