import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Button, Radio, message, List, Checkbox, Space, Modal } from 'antd'
import { ExclamationCircleOutlined, StarTwoTone } from '@ant-design/icons';
import moment from 'moment'


import './index.less'
import { paasql } from '@api'
import actionType from '@actions/actionType'
import ButtonLabel from '@component/ButtonLabel'

import { CreateIcon } from '@icon'


const ButtonGroup = Button.Group
const PoliceManagement = (props) => {
  const { PoliceManagementReducer, dispatch } = props
  const { alreadyGet } = PoliceManagementReducer
  const [parkId, setParkId] = useState(null)
  const [parkName, setParkName] = useState(null)
  const [currentArchived, setCurrentArchived] = useState('0')
  const [isArchived, setIsArchived] = useState("1")
  const [notArchived, setNotArchived] = useState("0")
  const [policeList, setPoliceList] = useState([])
  const [selectNotArichivedList, setSelectNotArichivedList] = useState([])
  const [selectArichivedList, setSelectArichivedList] = useState([])

  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        setParkId(data.result[0].id)
        const cmd_data = {
          archived: '0',
          eip_park_id: data.result[0].id
        }
        getPoliceByArchived(cmd_data)
      } else {
      }
    })
  }, [])

  const getPoliceByArchived = (request) => {
    const {
      archived,
      eip_park_id
    } = request
    let cmd_data = {
      archived,
      eip_park_id,
      name: "在线政策",
    }
    paasql("eip.eip_cms_doc.list_by_space_id", cmd_data).then(policeList => {
      if (policeList.error == null) {
        const result = policeList.result[0].content

        const policeListWithSort = result.sort(function (first, last) {
          return Number(last.cp_timestamp) - Number(first.cp_timestamp)
        })
        dispatch({
          type: actionType.SET_POLICE_LIST,
          value: policeListWithSort
        })
        for (let i = 0; i < policeListWithSort.length; i++) {
          policeListWithSort[i].checked = false
        }
        setPoliceList(policeListWithSort)
      } else {
        message.error(policeList.error.message)
      }
    })
  }


  //归档/取消归档政策
  const changeArchived = (e) => {

    const cmd_data = {

      archived: e.target.value,
      eip_park_id: parkId
    }
    setCurrentArchived(e.target.value)
    getPoliceByArchived(cmd_data)
  }

  //创建政策
  const createPolice = () => {
    props.history.push('/police-management/create')
  }

  //显示政策详情
  const showPoliceDetail = (item) => {
    if (currentArchived == '1') {
      props.history.push(`/police-management/archived/${item.id}`)
    } else {
      props.history.push(`/police-management/implementing/${item.id}`)
    }
  }

  //编辑政策
  const EditPolice = (item) => {
    props.history.push(`/police-management/edit/${item.id}`)
  }

  const destroyPolice = () => {
    Modal.confirm({
      onOk() {
        confirmDestroyPolice()
      },
      width: 600,
      content: '是否要删除该政策',
      icon: <ExclamationCircleOutlined />,
      title: `删除政策`
    })
  }

  //确认删除
  const confirmDestroyPolice = (item) => {

  }


  //选择要删除的政策
  const selectPolice = (e, item, index) => {
    const ckd = e.target.checked

    const pli = policeList

    pli[index].checked = ckd

    if (currentArchived === '0') {//归档
      const checked = e.target.checked
      //判断是多加还是删除
      if (checked) {
        //追加
        let selectList = selectNotArichivedList
        selectList.push(item)
        setSelectNotArichivedList(selectList)
      } else {
        //删除
        let selectList = selectNotArichivedList

        const id = item.id
        const delPoliceList = selectList.filter(function (item) {
          return item.id != id
        });
        setSelectNotArichivedList(delPoliceList)
      }
    } else {
      const checked = e.target.checked
      //判断是多加还是删除
      if (checked) {
        //追加
        let selectList = selectArichivedList
        selectList.push(item)
        setSelectArichivedList(selectList)
      } else {
        //删除
        let selectList = selectArichivedList

        const id = item.id
        const delPoliceList = selectList.filter(function (item) {
          return item.id != id
        });
        setSelectArichivedList(delPoliceList)
      }
    }
    setPoliceList(pli)

  }

  //确认更改是否归档
  const confirmChangeArchived = (item) => {
    const archived = currentArchived === '0' ? '1' : '0'
    const cmd_data = {
      archived: archived,
      id: item.id
    }
    paasql('eip.cms_doc.update_archived', cmd_data).then(data => {
      if (data.error == null) {
        const cmd_data = {
          archived: currentArchived,
          eip_park_id: parkId
        }
        getPoliceByArchived(cmd_data)
      } else {

      }

    })
  }

  //处理归档和取消归档的操作
  const HandleArchived = (item) => {
    Modal.confirm({
      onOk() {
        confirmChangeArchived(item)
      },
      width: 600,
      content:
        currentArchived === '1' ?
          '将移动该政策到进行中的政策中，进行中的政策可以编辑和发送，是否取消归档？' :
          '将移动该政策到归档的政策中，归档的政策不能再次编辑和发送，是否归档？'
      ,
      icon: <ExclamationCircleOutlined />,
      title: `请选择是否归档`
    })
  }


  //一键归档
  const batchArchived = () => {

  }


  //一键取消归档
  const batchCancelArchived = () => {

  }
  return (
    <>
      <div>

        < ButtonGroup>
          <ButtonLabel
            size='large'
          />

          <Button
            size='large'
            icon={<CreateIcon />}
            onClick={createPolice}
          >
            新增政策
        </Button>
        </ButtonGroup>

      </div>
      <div className='police-container' >
        <div className='operator-button-group'>
          <Radio.Group
            onChange={changeArchived}
            defaultValue={currentArchived} size="large">
            <Radio.Button value={notArchived} className="police-radio-left-button">
              未归档
          </Radio.Button>
            <Radio.Button value={isArchived} className="police-radio-right-button">
              已归档
          </Radio.Button>
          </Radio.Group>
          <div>
            {/* <Space>
              {
                currentArchived === '1' ?
                  <>
                    <Button onClick={batchCancelArchived} >
                      一键取消归档
                    </Button>
                  </> :
                  <>
                    <Button onClick={batchArchived} >
                      一键归档
                    </Button>
                  </>
              }
            </Space> */}

          </div>
        </div>

        <div className='police-list-container' >
          <List
            itemLayout="horizontal"
            dataSource={policeList}
            renderItem={(item, index) => (
              <>
                <List.Item
                  actions={[
                    <div>
                      {
                        currentArchived === '1' ? <></> :
                          <Button type="primary"
                            onClick={() => EditPolice(item)}
                          >
                            编辑
                          </Button>
                      }
                    </div>,
                    <div>
                      <Button
                        key="archeved"
                        onClick={() => HandleArchived(item)}
                      >
                        {
                          currentArchived === '1' ? "取消归档" : "归档政策"
                        }
                      </Button>
                    </div>
                  ]}
                >
                  <List.Item.Meta
                    onClick={() => showPoliceDetail(item)}
                    description={
                      <Content item={item} />
                    }
                  />
                </List.Item>
              </>
            )}
          />
        </div>
      </div>

    </>

  );
}

const mapStateToProps = (state) => {
  return {
    PoliceManagementReducer: state.PoliceManagement
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setPoliceLista: dispatch.setPoliceList
//   }
// }

export default connect(mapStateToProps)(PoliceManagement);

const Content = (props) => {
  const { item } = props
  const [time, setTime] = useState()
  const { cp_timestamp } = item

  useEffect(() => {
    const time = moment(cp_timestamp / 1000).format('YYYY-MM-DD')
    setTime(time)
  }, [])
  return (
    <>
      <span className='police-list-item-time'>
        {time}
      </span>
      <span   style={{cursor:'pointer'}}  className='police-list-item-title'>
        {item.name}
      </span>
    </>

  )
}