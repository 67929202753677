import React, { useState, useEffect } from "react";
import { message, List, Card, Avatar, Button, Row, Col } from "antd";
import { paasql, queryCmsDoc } from "@api";
import "./index.less";
import { EditIcon, CreateIcon } from "@icon";

const defaultImgUrl =
  "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png";
const FamousEnterprise = (props) => {
  const [docList, setDocList] = useState([]);
  const [change, setChange] = useState(false);
  useEffect(() => {
    paasql("eip.park.list").then((data) => {
      if (data.error == null) {
        const result = data.result;
        const { id } = result[0];
        const cmd_data = {
          eip_park_id: id,
          name: "明星企业简介",
        };

        paasql("eip.cms_doc.list", cmd_data).then((policeList) => {
          if (policeList.error == null) {
            const result = policeList.result[0];
            const sapce_id = result.id;
            queryCmsDoc(sapce_id, id).then((res) => {

              res.forEach((element) => {

                if (element.file_list === null) {
                  element.imgUrl = defaultImgUrl;
                } else {
                  const fileId = element.file_list[0].cp_file_id;
                  element.imgUrl = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
                }
              });
              const sortList = res.sort(function (first, last) {
                return Number(last.cp_timestamp) - Number(first.cp_timestamp);
              });
              setDocList(sortList);
            });
          } else {
            message.error(policeList.error.message);
          }
        });
      } else {
        message.error(data.error.message);
      }
    });
    return () => {
      window.location.reload();
    };
  }, [change]);
  //处理图片路径
  const handleFileImg = (docList) => {
    const requesList = [];
    for (let i = 0; i < docList.length; i++) {
      let attrObj = new Object();
      attrObj.id = docList[i].id;
      attrObj.level = "0";
      requesList.push(attrObj);
    }
    const cmd_data = {
      cms_doc_list: requesList,
    };
    paasql("eip.eip_cms_doc_file.cp_file_id.list", cmd_data).then((data) => {
      const result = data.result;
      const seachResult = [];
      for (let i = 0; i < result.length; i++) {
        if (result[i].length != 0) {
          seachResult.push(result[i][0]);
        }
      }
      const titleStr = [];
      for (let i = 0; i < docList.length; i++) {
        const attrObj = docList[i];
        for (let j = 0; j < seachResult.length; j++) {
          if (docList[i].id == seachResult[j].eip_cms_doc_id) {
            const fileId = seachResult[j].cp_file_id;
            attrObj.imgUrl = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
            break;
          } else {
            docList[i].imgUrl = defaultImgUrl;
          }
        }
        titleStr.push(attrObj);
      }
      const sortList = titleStr.sort(function (first, last) {
        return Number(last.cp_timestamp) - Number(first.cp_timestamp);
      });
      setDocList(sortList);
    });
  };

  const editDoc = (item) => {
    const id = item.id;
    props.history.push(`/famous-enterprise/edit/${id}`);
  };

  const createEnter = () => {
    props.history.push("/famous-enterprise/create");
  };

  const showDocDetail = (item) => {
    const id = item.id;
    props.history.push(`/famous-enterprise/detail/${id}`);
  };
  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Button icon={<CreateIcon />} onClick={createEnter}>
          新增明星企业
        </Button>
      </div>
      <List
        pagination={{
          onChange: (page) => {},
          pageSize: 12,
          hideOnSinglePage: true,
        }}
        grid={{
          gutter: 16,
          column: 4,
        }}
        dataSource={docList}
        renderItem={(item) => (
          <List.Item>
            <Card
              className="card-container"
              cover={
                <>
                  <div
                    onClick={() => showDocDetail(item)}
                    style={{
                      margin: "0 auto",
                      borderRadius: "14px",
                      width: 200,
                      height: 200,
                      display: "flex",
                      position: "relative",
                      backgroundImage: `url(${item.imgUrl})`,
                      backgroundClip: "content-box",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </>
              }
            >
              <div onClick={() => showDocDetail(item)} className="card-title">
                {item.name}
              </div>
              <div className="card-btn">
                <Button icon={<EditIcon />} onClick={() => editDoc(item)}>
                  编辑文档
                </Button>
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default FamousEnterprise;
