import React, { useState, useEffect } from 'react';

import {
  Button, Col, Row, Space,
  Divider, message, Modal, Typography
} from 'antd'


import { paasql } from '@api'


const ParkStaffEdit = (props) => {
  const { staffId, reflushData, parkId, isEdit, changeEdit } = props
  const [staffInfo, setStaffInfo] = useState({})
  //富文本的内容
  useEffect(() => {
    if (staffId == null) {
      return
    }
    const cmd_data = {
      id: staffId
    }
    paasql('eip.cp_staff.query_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result

        setStaffInfo(result)
      } else {
        message.error(data.error.message)
      }
    })

    return (() => {

    })
    //获得招商中介的列表
  }, [staffId])


  const editStaffInfo = () => {
    changeEdit(true)
  }
  const delStaff = () => {
    Modal.confirm({
      title: '删除园区工作人员',
      onOk() {
        confrimDelStaff()
      },
      content: <span>
        将删除所选招商中介(<Typography.Text type="danger" >{staffInfo.name}</Typography.Text>)和关联招商中介人员，不可恢复，是否继续？
    </span>
    })
  }
  const confrimDelStaff = () => {
    const cmd_data = {
      id: staffId
    }
    paasql('eip.cp_staff.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        reflushData()
      } else {
        message.error(data.error.message)
      }
    })
  }

  const showList = [
    {
      label: '名称',
      value: staffInfo.name
    },
    {
      label: '性别',
      value: staffInfo.sex
    },
    {
      label: '联系电话',
      value: staffInfo.china_mobile_phone_number
    }
  ]
  return (
    <div >

      <div className='park-staff-detail-nav' >
        <div className='park-staff-detail-title'>
          园区工作人员详情
        </div>
        <Space>
          <Button
            onClick={delStaff}
          >
            删除
        </Button>
          <Button
            type='primary'
            onClick={editStaffInfo}
          >
            编辑
        </Button>
        </Space>

      </div>
      <div className="park-staff-detail-info-list">
        {
          showList.map(item => {
            return (
              <div key={item.label} className='park-staff-detail-info-item'>
                <div className="label" >
                  {item.label}
                  </div>
                <div className="value">
                  {item.value}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default ParkStaffEdit;