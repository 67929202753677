import React, { useEffect, useState, createRef, useUpdate } from "react";

import E from "wangeditor";
import { Button, Input, Space, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import { paasql } from "@api";
import { uploadImg, updateUploadImg as updateUploadImgApi } from "@api/paasql";
import { CancelEditIcon, CreateDocIcon } from "@icon";

const editorRef = createRef();

const FamousEnterpriseEdit = (props) => {
  const { dispatch } = props;
  const useUpdate = () => useState(0)[1];
  const update = useUpdate();
  const docId = props.match.params.id;
  const [stringLength, setStringLength] = useState(1);
  const [htmlContent, setHtmlContent] = useState("");
  const [title, setTitle] = useState("");

  //头像的
  const [imgUrl, setImageUrl] = useState(null);
  const [noFileId, setNoFileId] = useState(true);
  const [cmsDocFileId, setCmsDocFileId] = useState(null);
  const [file, setFile] = useState(null);
  const [hadUploadImg, setHadUploadImg] = useState(false);

  //封面的
  const [noBannerFileId, setNoBannerFileId] = useState(true);
  const [bannerImgUrl, setBannerImageUrl] = useState(null);
  const [cmsBannerFileId, setCmsBannerFileId] = useState(null);

  const [hadUploadBannerImg, setHadUploadBannerImg] = useState(false);
  const [bannerFile, setBannerFile] = useState(null);
  //判断是否上传新图片的标志位

  useEffect(() => {
    const cmd_data = {
      id: docId,
    };
    paasql("eip.eip_cms_doc.query_by_id", cmd_data).then((data) => {
      const result = data.result;
      //处理
      if (result.length != 0) {
        const { content, name, id, cp_timestamp, eip_park_id } = result[0];

        //处理页面显示
        const cmd_data = {
          cms_doc_list: [
            {
              id,
            },
          ],
        };
        paasql("eip.eip_cms_doc_file.cp_file_id.list", cmd_data).then(
          (data) => {
            const result = data.result;
            if (result[0].length != 0) {
              result[0].forEach((element) => {
                if (element.level == 0) {
                  const fileId = element.cp_file_id;
                  setNoFileId(false);
                  const docFileId = element.id;
                  setCmsDocFileId(docFileId);
                  const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
                  setImageUrl(imgUrl);
                }

                if (element.level == 1) {
                  const fileId = element.cp_file_id;
                  setNoBannerFileId(false);
                  const docFileId = element.id;
                  setCmsBannerFileId(docFileId);
                  const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
                  setBannerImageUrl(imgUrl);
                }
              });
            }

            //处理面包屑
            const navArr = ["招商管理", "编辑明星企业", name];
            dispatch({
              type: "SET_BREADCRUMB_LIST",
              value: navArr,
            });
          }
        );
        //判断archived
        setHtmlContent(content);
        initEditor(content);
        setTitle(name);
        const length = name.length;
        setStringLength(length);
      }
    });
    return () => {
      // window.location.reload();
    };
  }, [docId]);

  const initEditor = (content) => {
    const editor = new E(editorRef.current);
    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      setHtmlContent(html);
    };
    editor.customConfig.menus = [
      "head",
      "bold",
      "italic",
      "underline",
      "justify",
      "image",
    ];
    editor.customConfig.customUploadImg = function (files, insert) {
      //eip_cms_doc_file
      const formData = new FormData();
      files.forEach((file) => {
        // fileList 是要上传的文件数组
        formData.append("file", file);
      });
      const request = {
        cp_schema_id: "eip_cms_doc_file",
        cp_object_attribute: "cp_file_id",
        file_object: formData,
      };
      uploadImg(request).then((data) => {
        if (data.error == null) {
          const fileId = data.result.cp_file_id;
          if (fileId == null) {
            setHadUploadImg(true);
          }
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
          insert(url);
        } else {
          message.error(data.error.message);
        }
      });
    };
    editor.create();
    editor.txt.html(content);
  };

  const cancelEdit = () => {
    //回到之前的路径
    props.history.push(`/famous-enterprise/detail/${docId}`);
  };
  const saveEdit = async () => {
    //判断是否上传文件
    if (noFileId) {
      //只保存文件，不保存上传文件
      //但是上传了问价
      if (hadUploadImg) {
        //上传图片，更新文件
        await handleUploadImg();
      } else {
      }
    } else {
      if (hadUploadImg) {
        await updateUploadImg();
      } else {
      }
    }

    //处理上传的封面图
    if (noBannerFileId) {
      //说明是新建
      if (hadUploadBannerImg) {
        await createBanner();
      }
    } else {
      if (hadUploadBannerImg) {
        await uploadBannerImg();
      }
    }

    //最终的处理
    handleSaveDoc();
  };

  const createBanner = () => {
    const formData = new FormData();
    formData.append("file", bannerFile);
    const request = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_object: formData,
    };
    //更新docid
    uploadImg(request).then((data) => {
      if (data.error == null) {
        const result = data.result;
        const { cp_file_id, id } = result;
        const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${cp_file_id}/${cp_file_id}`;
        const cmd_data = {
          object: {
            id,
            eip_cms_doc_id: docId,
            level: "1",
          },
        };
        paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
          if (data.error == null) {
            // props.history.push(`/famous-enterprise/detail/${docId}`);
          } else {
            message.error(data.error.message);
          }
        });
      } else {
        // message.error("保存图片失败");
        message.error(data.error.message);
      }
    });
  };



  //上传封面图,覆盖
  const uploadBannerImg = () => {
    const formData = new FormData();
    formData.append("file", bannerFile);
    const request = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_list: formData,
      cp_object_id: cmsBannerFileId,
    };
    //更新docid
    updateUploadImgApi(request).then((data) => {
      if (data.error == null) {
        const result = data.result;
        const { cp_file_id, id } = result;
        const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${cp_file_id}/${cp_file_id}`;
        // const cmd_data = {
        //   object: {
        //     id,
        //     eip_cms_doc_id: docId,
        //     level: "1",
        //   },
        // };
        // paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
        //   if (data.error == null) {
        //     // props.history.push(`/famous-enterprise/detail/${docId}`);
        //   } else {
        //     message.error(data.error.message);
        //   }
        // });
      } else {
        // message.error("保存图片失败");
        message.error(data.error.message);
      }
    });
  };


  //处理上传文件（新建）
  const handleUploadImg = () => {
    const formData = new FormData();
    formData.append("file", file);
    const request = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_object: formData,
    };
    //更新docid
    uploadImg(request).then((data) => {
      if (data.error == null) {
        const result = data.result;
        const { cp_file_id, id } = result;
        const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${cp_file_id}/${cp_file_id}`;
        const cmd_data = {
          object: {
            id,
            eip_cms_doc_id: docId,
            level: "0",
          },
        };
        paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
          if (data.error == null) {
            props.history.push(`/famous-enterprise/detail/${docId}`);
          } else {
            message.error(data.error.message);
          }
        });
      } else {
        // message.error("保存图片失败");
        message.error(data.error.message);
      }
    });
  };

  //处理上传文件（更新）
  const updateUploadImg = () => {
    //cmsDocFileId
    const formData = new FormData();
    formData.append("file", file);
    const request = {
      cp_schema_id: "eip_cms_doc_file",
      cp_object_attribute: "cp_file_id",
      file_list: formData,
      cp_object_id: cmsDocFileId,
    };

    updateUploadImgApi(request).then((data) => {
      if (data.error == null) {
        const fileId = data.result.cp_file_id;
        const doc_file_id = data.result.id;

        //拿到文件的ID后，再更新doc的id
        // const cmd_data = {
        //   object: {
        //     id: doc_file_id,
        //     eip_cms_doc_id: docId,
        //     level: "0",
        //   },
        // };
        // paasql("eip.eip_cms_doc_file.update", cmd_data).then((data) => {
        //   if (data.error == null) {
        //     const imgUrl = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`;
        //     setImageUrl(imgUrl);
        //     props.history.push(`/famous-enterprise/detail/${docId}`);
        //   } else {
        //     message.error(data.error.message);
        //   }
        // });
      } else {
        message.error(data.error.message);
      }
    });
  };

  //处理保存文件
  const handleSaveDoc = () => {
    const cmd_data = {
      content: htmlContent,
      id: docId,
      name: title,
    };
    paasql("eip.cms_doc.update_content", cmd_data).then((data) => {
      if (data.error == null) {
        message.success("保存文档成功");
        props.history.push(`/famous-enterprise/detail/${docId}`);
      } else {
        message.error(data.error.message);
      }
    });
  };

  const changeTitle = (e) => {
    const value = e.target.value;
    const length = value.length;
    setStringLength(length);
    setTitle(value);
  };

  //头像
  const handleChange = (info) => {
    getBase64(info.file, (imageUrl) => {

      setFile(info.file);
      setHadUploadImg(true);
      setImageUrl(imageUrl);
    });
  };

  const handleBeforeUpload = () => {
    return false;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  //封面图改变了
  function handleBannerChange(info) {
    getBase64(info.file, (imageUrl) => {
      setBannerFile(info.file);
      setHadUploadBannerImg(true);
      setBannerImageUrl(imageUrl);
    });
  }

  function handleBannerBeforeUpload() {
    return false;
  }
  return (
    <div className="edit-police-container">
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button icon={<CancelEditIcon />} onClick={cancelEdit}>
            取消编辑
          </Button>
          <Button icon={<CreateDocIcon />} onClick={saveEdit}>
            保存内容
          </Button>
        </Space>
      </div>
      <div>
        <span className="edit-police-info-label">明星企业名</span>
        <Input
          size="large"
          onChange={changeTitle}
          placeholder={"请输入标题"}
          maxLength={50}
          value={title}
          suffix={`${stringLength}/50`}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <span className="edit-police-info-label">上传企业头像</span>
          <Upload
            onChange={handleChange}
            showUploadList={false}
            listType="picture-card"
            beforeUpload={handleBeforeUpload}
          >
            {imgUrl ? (
              <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              <PlusOutlined />
            )}
          </Upload>
        </div>

        <div>
          <span className="edit-police-info-label">上传企业封面</span>
          <Upload
            onChange={handleBannerChange}
            showUploadList={false}
            listType="picture-card"
            beforeUpload={handleBannerBeforeUpload}
          >
            {bannerImgUrl ? (
              <img src={bannerImgUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              <PlusOutlined />
            )}
          </Upload>
        </div>
      </div>
      <div>
        <span className="edit-police-info-label">编辑文档内容</span>

        <div className="police-edit-eidtor" ref={editorRef} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamousEnterpriseEdit);
