import React, { useEffect } from 'react';
import echarts from 'echarts'

const MonthlyChart = (props) => {
  useEffect(() => {
    var myChart = echarts.init(document.getElementById('container'));
    // 指定图表的配置项和数据
    window.addEventListener("resize", () => {
      myChart.resize();
    });
    const Title = `${year}年税收增长情况（万元）`
    var option = {
      title: {
        text: Title,
        top: 20,
        left: 20
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['销量']
      },
      xAxis: {
        boundaryGap: false,
        data: XData,
        axisLine: {
          show: false,
        },
        axisTick: {       //y轴刻度线
          show: false
        },
      },
      yAxis: {
        offset: 10,
        axisLine: {
          show: false,
          onZero: false
        },
        axisTick: {       //y轴刻度线
          show: false
        },
      },
      series: [{
        name: '纳税总额',
        type: 'line',
        data: legendData,
        symbolSize: 8,
        symbol: 'circle',

        itemStyle: {
          color: '#3A63FF'
        },
        lineStyle: {
          color: '#3A63FF'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#3A63FF'
          }, {
            offset: 1,
            color: '#ffffff'
          }])
        },
      }],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  })
  const { XData, year, legendData } = props

  return (
    <>
      <div id='container' style={{ width: '100%', height: '400px',marginTop: '20px',backgroundColor: '#FFFFFF',borderRadius:'14px' }}>
      </div>
    </>
  );
}

export default MonthlyChart;