import React, { useEffect, useState, createRef } from 'react';
import E from 'wangeditor'
import { Input, Button, Space, message } from 'antd'
import { connect } from 'react-redux'
import { uploadImg, getSession } from '@api/paasql'
import { paasql } from '@api'
const editorRef = createRef()

const PoliceCreate = (props) => {
  const { PoliceManagementReducer,dispatch } = props
  const [parkId, setParkId] = useState(null)
  const [spaceId, setSpaceId] = useState(null)
  const [stringLength, setStringLength] = useState(0)
  const [htmlContent, setHtmlContent] = useState(null)
  const [title, setTitle] = useState('')
  useEffect(() => {
  
    //初始化
    initEditor()
    //处理园区id
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const result = data.result
        const parkId = result[0].id
        setParkId(parkId)
      } else {
        message.error(data.error.message)
      }
    })

    const { domain } = getSession()
    const request = {
      eip_cms_space_domain: domain,
      eip_cms_space_name: "在线政策"
    }
    //处理spaceid
    paasql('eip.eip_cms_space.list', request).then(data => {
      if (data.error == null) {
        const result = data.result
        const spaceId = result[0].id
        setSpaceId(spaceId)
    
      } else {
        message.error(data.error.message)
      }
    }).finally(() => {
      dispatch({
        //设置面包屑        \
        type:'SET_BREADCRUMB_LIST',
        value: ['在线政策','新增政策']
      })
    })
  }, [])


  //初始化编辑器
  const initEditor = () => {
    const editor = new E(editorRef.current)
    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      setHtmlContent(html)
    }
    editor.customConfig.menus = [
      'head',
      'bold',
      'italic',
      'underline',
      'justify',
      'image',
    ]
    editor.customConfig.customUploadImg = function (files, insert) {
      //eip_cms_doc_file
      const formData = new FormData();
      files.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file);
      })

      const request = {
        cp_schema_id: "eip_cms_doc_file",
        cp_object_attribute: "cp_file_id",
        file_object: formData
      }
      uploadImg(request).then(data => {
        if (data.error == null) {
          const fileId = data.result.cp_file_id
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`
          insert(url)
        } else {
          message.error(data.error.message)
        }
      })
    }
    editor.create()
  }
  const changeTitle = (e) => {
    setStringLength(e.target.value.length)
    setTitle(e.target.value)
  }

  const cancelCreate = () => {
    props.history.push('/police-management')
  }
  const createPolice = () => {
    if (title === '') {
      message.error('标题是必须的')
      return
    }
    const cmd_data = {
      object: {
        eip_cms_space_id: spaceId,
        eip_park_id: parkId,
        name: title,
        content: htmlContent
      }
    }
    paasql('eip.eip_cms_doc.create', cmd_data).then(data => {
      if (data.error == null) {
        message.success('创建政策成功')
        const result = data.result
        const { id } = result
        props.history.push(`/police-management/implementing/${id}`)
      } else {
        message.error(data.error.message)
      }
    })

  }
  return (
    <div className='create-police-container'>
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button onClick={cancelCreate} >
            取消编辑
        </Button>
          <Button type="primary" onClick={createPolice} >
            确认新增
        </Button>
        </Space>

      </div>
      <div>
        <span className='create-police-info-label'>
          编辑标题内容
        </span>

        <Input
          size='large'
          onChange={changeTitle}
          placeholder={'请输入标题'}
          maxLength={50}
          suffix={`${stringLength}/50`}
        />
      </div>
      <div>
        <span className='create-police-info-label'>
          编辑政策内容
        </span>
        <div className="police-create-eidtor" ref={editorRef} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    PoliceManagementReducer: state.PoliceManagement
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PoliceCreate);