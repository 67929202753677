import React from 'react';

import exportdata from '@svg/export-data.svg'
import exportDataWhite from '@svg/export.svg'
import importData from '@svg/import-data.svg'
import backTo from '@svg/back-to.svg'
import rightOutlined from '@svg/right-out-lined.svg'
import label from '@svg/button-group-label.svg'
import create from '@svg/create.svg'
import filter from '@svg/filter.svg'
import edit from '@svg/edit-doc.svg'
import cancelEdit from '@svg/cancel-edit.svg'
import alreadyImport from '@svg/already_import.svg'
import createDoc from '@svg/create-doc.svg'

import deleteDoc from '@svg/delete-doc.svg'

import archived from '@svg/archived.svg'
import notArchived from '@svg/not-archived.svg'
import sendList from '@svg/send-list.svg'
import lastBalance from '@svg/last-balance.svg'

import bankCard from '@svg/bank-card.svg'
import fuChiDian from '@svg/fuchidian.svg'

import staff from '@svg/staff.svg'
import taxRecord from '@svg/tax-record.svg'

//nav的icon

import TaxRefund from '@svg/退税.svg'
import TaxInfo from '@svg/纳税信息.svg'
import onlinePolice from '@svg/在线政策.svg'
import TaxWarning from '@svg/纳税预警.svg'
import Zsgl from '@svg/招商管理.svg'
import Jbxxgl from '@svg/cb_title_icon_jbxxgl.svg'
import Yhxx from '@svg/cb_title_icon_yhxx.svg'
import Stq from '@svg/cb_title_icon_qysty.svg'


//radio的button
import shangBang from '@svg/shangbang.svg'
import shangBangSelected from '@svg/shang_bang_selected.svg'
import luoBang from '@svg/luo_bang.svg'
import luoBangSelect from '@svg/luo_bang_select.svg'
import totalTax from '@svg/total_tax.svg'
import totalTaxSelect from '@svg/total_tax_select.svg'
import shengXu from '@svg/sheng_xu.svg'
import shengXuSelect from '@svg/sheng_xu_select.svg'
import jiangXu from '@svg/jiang_xu.svg'
import jiangXuSelect from '@svg/jiang_xu_select.svg'


//企业生态圈
import activeEnterprise from '@svg/active_enterprise.svg'
import activeEnterpriseSelect from '@svg/active_enterprise_select.svg'
import keyEnterprise from '@svg/key_enterprise.svg'
import keyEnterpriseSelect from '@svg/key_enterprise_select.svg'
import sameIndustry from '@svg/same_industry.svg'
import sameIndustrySelect from '@svg/same_industry_select.svg'

//退税
export const TaxRefundIcon = () => {
  return (
    <img

      className="nav-icon"

      style={{ marginRight: "16px", fontSize: "14px" }} src={TaxRefund} alt='退税' ></img>
  )
}

//纳税
export const TaxInfoIcon = () => {
  return (
    <img
      className="nav-icon"

      style={{ marginRight: "16px", fontSize: "14px" }} src={TaxInfo} alt='纳税信息管理' ></img>
  )
}
//在线政策
export const OnlinePoliceIcon = () => {
  return (
    <img
      className="nav-icon"
      style={{ marginRight: "16px", fontSize: "14px" }} src={onlinePolice} alt='在线政策' ></img>
  )
}

//纳税预警
export const TaxWarningIcon = () => {
  return (
    <img
      className="nav-icon"

      style={{ marginRight: "16px", fontSize: "14px" }} src={TaxWarning} alt='纳税预警' ></img>
  )
}

//纳税预警
export const InvestmentManagementIcon = () => {
  return (
    <img
      className="nav-icon"
      style={{ marginRight: "16px", fontSize: "14px" }}
      src={Zsgl}
      alt='招商管理'
    >

    </img>
  )
}


//基本信息管理
export const InfoManagementIcon = () => {
  return (
    <img
      className="nav-icon"
      style={{ marginRight: "16px", fontSize: "14px" }}
      src={Jbxxgl} alt='基本信息管理' >

    </img>
  )
}

//基本信息管理
export const UserManagementIcon = () => {
  return (
    <img
      className="nav-icon"

      style={{ marginRight: "16px", fontSize: "14px" }} src={Yhxx} alt='用户管理' ></img>
  )
}

//基本信息管理
export const EnterpriseBiosphereIcon = () => {
  return (
    <img
      className="nav-icon"

      style={{ marginRight: "16px", fontSize: "14px" }} src={Stq} alt='企业生态圈' >

    </img>
  )
}






//按钮的前缀
export const ButtonGroupLable = () => {
  return (
    <img
      className="nav-icon"

      src={label} alt='按钮前缀' ></img>

  )
}


//导出数据
export const ExportDataIcon = (props) => {
  return (
    <img
      {...props}
      className="nav-icon"
      style={{ marginRight: 6 }}
      src={exportdata} alt="导出数据" ></img>
  )
}

//导入数据
export const ImportDataIcon = (props) => {
  return (
    <img
      {...props}
      className="nav-icon"
      src={importData} alt="导入数据"></img>
  )
}

//返回
export const BackToIcon = (props) => {
  return (
    <img
      {...props}
      className="nav-icon"
      src={backTo} alt='返回' ></img>
  )
}


//向右的icon 或者是更多
export const RightOutlinedIcon = (props) => {
  return (
    <img
      {...props}

      src={rightOutlined}
      alt='右'
      className="nav-icon"
    >

    </img>
  )
}


//创建
export const CreateIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props} src={create}

      role="img"
      alt='创建' ></img>
  )
}

//筛选

export const FilterIcon = (props) => {
  return (
    <img
      className="nav-icon"

      {...props} src={filter}
      role="img" alt='筛选' >

    </img>
  )
}





//编辑
export const EditIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props} src={edit} role="img" alt='编辑' ></img>
  )
}

//取消编辑
export const CancelEditIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props} src={cancelEdit
      } role="img" alt='取消编辑' ></img>
  )
}




//保存文档
export const CreateDocIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props} src={createDoc
      } role="img" alt='保存文档' ></img>
  )
}



//删除
export const DeleteDocIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={deleteDoc}
      role="img" alt='删除' ></img>
  )
}


//归档
export const ArchivedDocIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={archived}
      role="img" alt='归档' ></img>
  )
}

//未归档
export const NotArchivedDocIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={notArchived}
      role="img" alt='未归档' ></img>
  )
}


//发送名单
export const SendListIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={sendList}
      role="img" alt='发送名单' ></img>
  )
}


//上次余留
export const LastBalanceIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={lastBalance}
      role="img" alt='结算上次余留' ></img>
  )
}

//银行账号
export const BankCardIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={bankCard}
      role="img" alt='银行信息' ></img>
  )
}


//扶持点
export const FuChiDianIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={fuChiDian}
      role="img" alt='扶持点' ></img>
  )
}




//工作人员
export const StaffIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={staff}
      role="img" alt='工作人员' ></img>
  )
}



//纳税记录
export const TaxRecordIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={taxRecord}
      role="img" alt='纳税记录' ></img>
  )
}

//白色的导出按钮的icon
export const ExportDataWhiteIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={exportDataWhite}
      role="img" alt='纳税记录' ></img>
  )
}

//纳税预警的重点企业排行榜

//累计纳税金额
export const TotalIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={totalTax}
      role="img" alt='累计纳税金额' ></img>
  )
}

//选中状态
export const TotalIconSelect = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={totalTaxSelect}
      role="img" alt='累计纳税金额' ></img>
  )
}

//今年入榜
export const InIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={shangBang}
      role="img" alt='今年上榜' ></img>
  )
}

//选中状态下
export const InIconSelected = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={shangBangSelected}
      role="img" alt='今年上榜' ></img>
  )
}

//今年落榜
export const OutIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={luoBang}
      role="img" alt='今年落榜' ></img>
  )
}

//选中状态下
export const OutIconSelect = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={luoBangSelect}
      role="img" alt='今年落榜' ></img>
  )
}

//增长率升序

export const DecriceIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={shengXu}
      role="img" alt='升序' ></img>
  )
}
//选中状态下
export const DecriceIconSelect = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={shengXuSelect}
      role="img" alt='升序' ></img>
  )
}
//增长率降序
export const IncreseIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={jiangXu}
      role="img" alt='纳税记录' ></img>
  )
}


//选中状态
export const IncreseIconSelect = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={jiangXuSelect}
      role="img" alt='纳税记录' ></img>
  )
}

//活跃企业


export const ActiveEnterpriseIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={activeEnterprise}
      role="img" alt='当前活跃企业' ></img>
  )
}


//选中状态
export const ActiveEnterpriseSelectIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={activeEnterpriseSelect}
      role="img" alt='当前活跃企业' ></img>
  )
}


//重点企业
export const KeyEnterpriseIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={keyEnterprise}
      role="img" alt='重点企业' ></img>
  )
}


//选中的重点企业
export const KeyEnterpriseSelectIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={keyEnterpriseSelect}
      role="img" alt='重点企业' ></img>
  )
}


//同行业
export const SameIndustryIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={sameIndustry}
      role="img" alt='重点企业' ></img>
  )
}


//选中的同行业
export const SameIndustrySelectIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={sameIndustrySelect}
      role="img" alt='重点企业' ></img>
  )
}


//

//选中的同行业
export const AlreadyImportIcon = (props) => {
  return (
    <img
      className="nav-icon"
      {...props}
      src={alreadyImport}
      role="img" alt='已导入总库表' ></img>
  )
}