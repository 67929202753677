
import React, { useState, useEffect } from 'react';
import { Space, Button, Select, Col, Row, Divider, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons';
import { agencySeasonTaxRecordUpdate } from '@api'

const { Option } = Select;
const selectList = [{
  id: 'yes',
  name: "是",
  value: true
}, {
  id: 'no',
  name: "否",
  value: false
}]
const SetLastStatementBalance = (props) => {
  const { selectTaxRefundStatement, handleLoading, hideSetLastStatementBalance, reflushData } = props

  const [isLastAgencyAdded, setIsLastAgencyAdded] = useState(null)
  const [isLastStatementBalanceAdded, setIsLastStatementBalanceAdded] = useState(null)


  const handleRequest = () => {
    const { eip_park_id, year, quarter } = selectTaxRefundStatement
    if (isLastAgencyAdded == null || isLastStatementBalanceAdded == null) {
      message.error('请先选择是否计算上次余留和招商点上次余留')
      return
    }
    const cmd_data = {
      eip_business_year: year,
      eip_park_id: eip_park_id,
      is_agency: true,
      is_last_agency_added: isLastAgencyAdded,
      is_last_statement_balance_added: isLastStatementBalanceAdded,
      quarter: quarter,
    }
    handleLoading(true)
    hideSetLastStatementBalance(false)
    agencySeasonTaxRecordUpdate(cmd_data).then(data => {
      if (data.success) {
        //刷新当前的数据
        message.success('更新成功')
      }
    }).finally(() => {
      reflushData()
      handleLoading(false)
    })
  }
  //处理上次余留
  const handleLastStatementBalance = (value) => {
    setIsLastAgencyAdded(value)
  }
  //处理招商点上次余留
  const handleAgencyLastStatementBalance = (value) => {
    setIsLastStatementBalanceAdded(value)
  }


  return (
    <>
      <div>
        <div>
          <Row>
            <Col span={10} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
              <span>
                是否计算上次余留:
            </span>
            </Col>
            <Col span={14} style={{ paddingLeft: 20 }}>
              <Select
                size='large'
                suffixIcon={<CaretDownOutlined />}
                onChange={handleLastStatementBalance}
                style={{ width: 250 }}
              >
                {
                  selectList.map((item, index) => {
                    return (
                      <Option
                        key={item.id}
                        value={item.value}
                      >
                        {item.name}
                      </Option>
                    )
                  })
                }
              </Select>
            </Col>
            <div style={{ fontWeight: 700, paddingTop: 20, whiteSpace: 'pre-line' }}>
              注意：更改后，将重新计算本表所有记录的“管理费”、“本季应扶持金额”、“本次余留”、
              “本季待付款金额”数据项。
          </div>
          </Row>
        </div>
        <Divider />
        <Row>
          <Col span={10} style={{ height: 40, lineHeight: '40px', textAlign: 'right' }}>
            <span>
              是否计算招商点上次余留:
          </span>
          </Col>
          <Col span={14} style={{ paddingLeft: 20 }} >
            <Select
              size='large'
              suffixIcon={<CaretDownOutlined />}
              onChange={handleAgencyLastStatementBalance}
              style={{ width: 250 }}
            >
              {
                selectList.map((item, index) => {
                  return (
                    <Option
                      key={item.id}
                      value={item.value}
                    >

                      {item.name}
                    </Option>
                  )
                })

              }
            </Select>
          </Col>
          <div style={{ fontWeight: 700, paddingTop: 20, whiteSpace: 'pre-line' }}>
            注意：更改后， 将重新计算本表所有记录的“招商点本季应扶持金额”、“招商点本次余留”、“招商点本季待
            付款金额”数据项。
          </div>
        </Row>

      </div>

      <div style={{ textAlign: 'right', paddingTop: 20 }}>
        <Space>
          <Button onClick={hideSetLastStatementBalance}>
            取消
            </Button>
          <Button type="primary" onClick={handleRequest}>
            确认
            </Button>
        </Space>
      </div>
    </>
  );
}

export default SetLastStatementBalance;