import React, { useEffect, useState } from 'react';
import { message, Space, Button, Divider } from 'antd'
import { paasql } from '@api'
import { getSession } from '@api/paasql'
import { EditIcon } from '@icon'

const BusinessNameRules = (props) => {
  const [docId, setDocId] = useState(null)
  const [title, setTitle] = useState(null)
  const [content, setContent] = useState(null)
  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const result = data.result
        const parkId = result[0].id
        const cmd_data = {
          eip_park_id: parkId,
          name: "企业核名规则",
        }
        paasql("eip.cms_doc.list", cmd_data).then(policeList => {
          if (policeList.error == null) {
            const result = policeList.result[0].list[0]
            const { name, content, id } = result
            setContent(content)
            setTitle(name)
            setDocId(id)
          } else {
            message.error(policeList.error.message)
          }
        })

      } else {
        message.error(data.error.message)
      }
    })
  }, [])

  const eidtDoc = () => {
    props.history.push(`/business-name-rules/edit/${docId}`)
  }
  return (
    <div className="police-detail-container" >
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button 
            icon={<EditIcon />}
          onClick={eidtDoc} 
          >
            编辑文档
          </Button>
        </Space>
      </div>
      <div className='police-detail-container'>
        <div className='police-detail-container-title'>
          <div>
            {title}
          </div>
        </div>
        <Divider>
          <div
            className='divider-container'
          >

          </div>
        </Divider>

        <div
          dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </div>
    </div>
  );
}
 
export default BusinessNameRules;