import React, { useState, useEffect } from 'react';
import {
  Button, Tabs, List, Space,
  Row, Col, Input, Checkbox, InputNumber, Modal, Divider, message
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';


import { paasql, queryEnterpriseInfo, postGpi, rankTop } from '@api'

import { DeleteDocIcon } from '@icon'



const { TabPane } = Tabs;


const ImplementingEnterpriseList = (props) => {



  const {
    hideModal, sendMessageSuccess, handleRequest,//actions
    enterpriseList, parkId, parkName, policeId
  } = props
  const [actionKey, setActiveKey] = useState('1')
  const [content, setContent] = useState([])
  const [contentWithSelect, setContentWithSelect] = useState([])

  const [seachEnterprise, serSeachEnterprise] = useState([])
  const [enterpriseWithSelect, setEnterpriseWithSelect] = useState([])
  const [showKeyEnterprise, setKeyEnterprise] = useState(true)
  //排名
  const [compareChange, setCompareChange] = useState('0')
  const [industry, setIndustry] = useState('')
  const [keyEnterpriseListAll, setkeyEnterpriseListAll] = useState([])
  const [keyEnterpriseList, setkeyEnterpriseList] = useState([])

  useEffect(() => {
    const cmd_data = {
      "eip_park_id": parkId,
    }
    rankTop(cmd_data).then(data => {
      setkeyEnterpriseListAll(data.keyEnterpriseList)
    })
  }, [parkId])


  const changAcitiveKey = (key) => {
    setActiveKey(key)
  }
  const changeTel = (e) => {
    const value = e
    if (value === '') {
      setContent([])
      return
    }
    const cmd_data = {
      "eip_park_id": parkId,
      "attribute_filter": [
        {
          "attribute": "cellphone_number",
          "operator": "contain",
          "value": [
            String(value)
          ]
        }
      ]
    }
    const method = 'query_enterprise_contact_info'
    postGpi(cmd_data, method).then(data => {
      const { list } = data
      setContent(list)
    })
  }
  const delPolice = (item) => {
    Modal.confirm({
      title: '删除政策',
      onOk() {
        confirmDel(item)
      },
      content: <>
        确认将
        <span style={{ color: 'red' }}>
          {item.eip_enterprise_name}
        </span>
        从发送名单中移除吗？
      </>
    })
  }
  const confirmDel = (item) => {
    const cmd_data = {
      id: item.id
    }
    paasql('eip.eip_enterprise_o2m_eip_cms_doc.delete', cmd_data).then(data => {
      handleRequest()
    })
  }

  //点击了新添加的企业联系人名单
  const addEntepriseContact = (item) => {
    const tmp_list = contentWithSelect
    tmp_list.push(item)
    setContentWithSelect(tmp_list)
    setContent([])
  }

  //删除联系人
  const delChooseContact = (index) => {
    const list = []
    for (var i = 0; i < contentWithSelect.length; i++) {
      if (i == index) {

      } else {
        list.pushObject(contentWithSelect[i])
      }
    }
    setContentWithSelect(list)
  }

  //改变输入的企业名
  const changeEnterprise = (e) => {
    const value = e.target.value
    if (value === '') {
      serSeachEnterprise([])
      return
    }
    var cmd_data = {
      "eip_park_id": parkId,
      "attribute_filter": [
        {
          "attribute": "name",
          "operator": "contain",
          "value": [
            value
          ]
        }
      ]
    }
    queryEnterpriseInfo(cmd_data).then(data => {
      const { enterpriseList } = data
      serSeachEnterprise(enterpriseList)
    })
  }
  //增加一条选中的企业
  const addEnteprise = (item) => {
    const tmp_list = enterpriseWithSelect
    tmp_list.push(item)
    setEnterpriseWithSelect(tmp_list)
    serSeachEnterprise([])
  }

  //删除一条选中的企业
  const delChooseEnterprise = (index) => {
    const list = []
    for (var i = 0; i < enterpriseWithSelect.length; i++) {
      if (i == index) {

      } else {
        list.pushObject(enterpriseWithSelect[i])
      }
    }
    setEnterpriseWithSelect(list)
  }


  //改变是否发送给重点企业
  const changeShowKeyEnterprise = (e) => {
    setKeyEnterprise(e.target.checked)
  }


  const changeIndustry = (e) => {

    const value = e.target.value
    setIndustry(value)
    var cmd_data = {
      "eip_park_id": parkId,
      "attribute_filter": [
        {
          "attribute": "industry",
          "operator": "contain",
          "value": [value]
        }],
      "other_filter": [{
        "attribute": "compare_change",
        "operator": "<>",
        "value": ["0", compareChange]
      }]
    }
    rankTop(cmd_data).then(data => {
      setkeyEnterpriseList(data.keyEnterpriseList)
    })
  }

  const changeCompareChange = (value) => {
    const strValue = String(value)
    setCompareChange(strValue)
    var cmd_data = {
      "eip_park_id": parkId,
      "attribute_filter": [
        {
          "attribute": "industry",
          "operator": "contain",
          "value": [industry]
        }],
      "other_filter": [{
        "attribute": "compare_change",
        "operator": "<>",
        "value": ["0", strValue]
      }]
    }
    rankTop(cmd_data).then(data => {
      setkeyEnterpriseList(data.keyEnterpriseList)
    })
  }
  const confirmSend = () => {
    //处理搜索的企业名
    var tmp_enterprise_list = enterpriseWithSelect
    const enterprise_list = []
    for (let i = 0; i < tmp_enterprise_list.length; i++) {
      const attr_obj = tmp_enterprise_list[i]
      attr_obj.eip_enterprise_id = attr_obj.id
      attr_obj.eip_enterprise_name = attr_obj.name
      enterprise_list.push(attr_obj)
    }

    //判断是否要批量发送给重点企业

    var key_enterprise_list = []

    if (showKeyEnterprise) {
      var key_enterprise_list = keyEnterpriseListAll
    } else {
      var key_enterprise_list = keyEnterpriseList
    }

    for (const obj of key_enterprise_list) {
      obj.eip_enterprise_name = obj.name
    }

    //开始去重
    const filter_list =
      contentWithSelect
        .concat(enterprise_list)
        .concat(key_enterprise_list)

    var eip_enterprise_without_duplication = [];

    var obj = {};

    //去重
    for (var i = 0; i < filter_list.length; i++) {
      if (!obj[filter_list[i].eip_enterprise_id]) {
        eip_enterprise_without_duplication.push(filter_list[i]);
        obj[filter_list[i].eip_enterprise_id] = true;
      }

    }


    //整理请求数据（each数组）
    var cmd_data_attr = []
    for (let i = 0; i < eip_enterprise_without_duplication.length; i++) {
      const tmp_obj = eip_enterprise_without_duplication[i]//赋值方便一点
      const attr_obj = new Object()
      attr_obj.eip_park_id = parkId
      attr_obj.eip_enterprise_name = tmp_obj.eip_enterprise_name
      attr_obj.eip_enterprise_id = tmp_obj.eip_enterprise_id
      attr_obj.eip_cms_doc_id = policeId
      // attr_obj.set("eip_cms_doc_name", this.get("eip_cms_doc_name"))
      cmd_data_attr.push(attr_obj)
    }

    const cmd_data = {
      "input_create_objects": cmd_data_attr
    }

    return paasql("eip.eip_enterprise_o2m_eip_cms_doc.batch_create", cmd_data).then(data => {
      if (data.error == null) {
        message.success('发送成功')
        sendMessageSuccess()
      } else {
        message.error(data.error.message)
      }
    })
  }
  return (
    <div>
      <Tabs
        onChange={changAcitiveKey}
        activeKey={actionKey} tabPosition={"left"}>
        <TabPane tab={<div>已发送名单 {enterpriseList.length} </div>} key="1">
          <List
            className="implementing-enterprise-list"
            itemLayout="horizontal"
            dataSource={enterpriseList}
            renderItem={item => (
              <List.Item
                actions={[
                  <span onClick={
                    () => delPolice(item)}
                  >
                    <DeleteDocIcon />
                    <span
                      style={{
                        fontSize: '14px',
                        fontfamily: 'HiraginoSansGB-W6,HiraginoSansGB',
                        fontWeight: 'normal',
                        color: 'rgba(32,34,36,1)'
                      }}

                    >
                      删除名单
                    </span>
                  </span>
                ]}>
                <List.Item.Meta
                  description={
                    item.eip_enterprise_name
                  }
                />
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane
          tab={
            <div>新增发送企业</div>
          }
          key="2"
        >
          {/*搜素企业的页面 */}
          <div>
            <div className="send-enterprise-add-contact-title">
              输入企业联系人手机号，添加将收到政策的企业：
            </div>
            <InputNumber
              onChange={changeTel}
              className='send-enterprise-add-contact-input'
              style={{}}
              placeholder="请输入企业联系人手机号"
              size='large'
            />
            {/* 搜索的联系人 */}
            <ul className='send-enterprise-seach-contact-list'>
              {
                content.map(item => {
                  return (
                    <li
                      key={item.eip_enterprise_id}
                      onClick={() => addEntepriseContact(item)}
                    >
                      {item.name}
                    </li>
                  )
                })
              }
            </ul>

            {/* 选择的结果 */}
            <ul className='send-enterprise-choosed-contact-list'>
              {
                contentWithSelect.map((item, index) => {
                  return (
                    <li
                      key={item.eip_enterprise_id}
                    >
                      <div >
                        <span>
                          {item.name}
                        </span>
                        <span onClick={
                          () => delChooseContact(index)}
                        >
                          <DeleteDocIcon />
                        </span>
                      </div>

                    </li>
                  )
                })
              }
            </ul>
            <Divider style={{ margin: '10px 0px 0px 0px' }} />
            {/* contentWithSelect */}
            <div className="send-enterprise-add-name-title">
              输入企业名称，添加将收到政策的企业：
            </div>
            <Input
              onChange={changeEnterprise}
              className='send-enterprise-add-name-input'
              style={{ background: 'rgba(245,245,245,1)', border: '0px solid rgba(245,245,245,1)' }}
              placeholder="请输入企业名称"
              size='large'
            />
            {/* 搜索的企业 */}
            <ul className='send-enterprise-seach-contact-list'>
              {
                seachEnterprise.map(item => {
                  return (
                    <li
                      onClick={() => addEnteprise(item)}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  )
                })
              }
            </ul>

            {/* 选择的结果 */}
            <ul className='send-enterprise-choosed-contact-list'>
              {
                enterpriseWithSelect.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                    >
                      <div >
                        <span>
                          {item.name}
                        </span>
                        <span onClick={
                          () => delChooseEnterprise(index)
                        }
                        >
                          <DeleteDocIcon />
                        </span>
                      </div>

                    </li>
                  )
                })
              }
            </ul>
            <Divider style={{ margin: '10px 0px 0px 0px' }} />

            <div>
              <Checkbox
                checked={showKeyEnterprise}
                onChange={changeShowKeyEnterprise}
              >

              </Checkbox>
              <span className="send-enterprise-show-key-enterprise-title">
                批量添加纳税重点企业：（不填写以下筛选条件时，将发送给全部纳税重点企业）
              </span>

              <Row className="send-police-row">
                <Col span={8} className="send-polic-industry-label"  >行业包含：</Col>
                <Col span={16}>
                  <Input
                    className='send-police-industry-input'
                    onChange={changeIndustry}
                    placeholder="请输入"
                    size='large'
                  />
                </Col>

              </Row>
              <Row className="send-police-row">
                <Col span={8} className='send-polic-compare-change-label' >纳税排名变动不大于：</Col>
                <Col span={16}>
                  <InputNumber
                    className='send-police-compare-change-input'
                    onChange={changeCompareChange}
                    style={{ width: '100%', border: '0px solid rgba(245,245,245,1)' }}
                    placeholder="请输入"
                    size='large'
                  />
                </Col>

              </Row>
            </div>
          </div>

        </TabPane>
      </Tabs>

      <div style={{ textAlign: 'right', marginTop: 20 }}>
        {
          actionKey === '1' ?
            <Button type="primary" onClick={hideModal}>
              关闭
            </Button>
            :
            <Space>
              <Button onClick={hideModal}>
                取消
              </Button>
              <Button type="primary" onClick={confirmSend}>
                确定发送
              </Button>
            </Space>
        }

      </div>
    </div>
  );
}

export default ImplementingEnterpriseList;