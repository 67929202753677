import React, { useEffect, useState } from "react";
import echarts from "echarts";

import { parkEnterpriseFirstPayout } from "@api";
function FirstPaidAt() {
  const [total, setTotal] = useState(0);
  const year = new Date().getFullYear();
  useEffect(() => {
    parkEnterpriseFirstPayout().then((res) => {
      const data = res.result;
      var total = 0;
      data.forEach((element) => {
        total += Number(element);
      });
      setTotal(total);
      const option = {
        color: ["rgb(42,107,255)"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        title: {
          text: "每月第一年度产税户新增数",
          subtext: "家",
          top: "10",
          left: "30",
        },
        grid: {
          top: "40",
          left: "50",
          right: "80",
        },

        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
          },
        ],
        grid: {
          y: 70,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        legend: {
          orient: "vertical",
          top: "1%",
          data: ["中介", "本部", "合计"],
          right: 10,
        },
        series: [
          {
            name: "",
            type: "line",
            data: data,
          },
          {
            name: "",
            type: "bar",
            data: data,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgb(65,116,245)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(118,207,255)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      };
      var myEchart = echarts.init(document.getElementById("first-paid-at"));
      myEchart.setOption(option);
      window.addEventListener("resize", () => {
        myEchart.resize();
      });
    });
  }, []);
  return (
    <div className="first-paid-at-container">
      <div
        style={{
          height: "calc(100% -  20px)",
          width: "100%",
        }}
        id="first-paid-at"
      ></div>

      <div className="text">
        {year}年新增第一年度产税户累计{total}户
      </div>
    </div>
  );
}

export default FirstPaidAt;
