import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { sign_in_with_cp_passport } from '../../api/paasql'
import './login.less'
import Bg from '@img/login-bg-small.png'
import logo from './img/huayanlogo.png'
const Login = (props) => {
  const onFinish = values => {

    const { username, password } = values

    //目前默认的domain是华严园区
    const defaultDomain = "eip"
    sign_in_with_cp_passport(defaultDomain, username, password).then(res => {
      if (res.error == null) {
        message.success("登录成功")
        props.history.push("/")
      } else {
        message.error(res.error.message)

      }
    })
  };
  const onFinishFailed = errorInfo => {
    message.error("请输入账号和密码")
  };

  return (
    <div className="login-main"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container" >
        <div className='left-container'>
          <div className="logo" >
            <img src={logo} alt="华严logo" />
          </div>
          <div className="intro" >
            <div className="intro-text">
              Hi,欢迎来到
          </div>
            <div className="intro-text">
              华严智慧园区管理平台
          </div>
          </div>
        </div>
        <div className='right-container'>
          <div className="form-container">
            <div className="form-header">
              <div className="header-title" >
                欢迎使用
            </div>
              <div className="header-warn"  >
                <p style={{ fontSize: "12px" }}>
                  请正确输入您的相关信息以继续
                </p>

              </div>
            </div>
            <Form
              layout="vertical"
              className="form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="用户名称"
                className="form-item"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '请输入用户名',
                  },
                ]}
              >

                <Input className="form-input" />
              </Form.Item>

              <Form.Item
                label="登录密码"
                className="form-item"
                name="password"
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]}
              >
                <Input.Password className="form-input" />
              </Form.Item>

              <Form.Item >
                <Button type="primary" htmlType="submit" className="form-button">
                  登录
            </Button>
              </Form.Item>
            </Form>
          </div>
        </div>


      </div>
      <div className="copyright-container">
        ™ 和 © 2008-2020 致千科技. 保留一切权利。软件许可 隐私条款
      </div>
    </div>
  );
}

export default Login;