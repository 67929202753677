import React, { useState, useEffect } from 'react';
import {
  Button, Select,
  Col, Row, message, Modal, Typography, Collapse
} from 'antd'
import { connect } from 'react-redux'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment'
import debounce from 'lodash/debounce';


import EditBankInfo from '../component/EditBankInfo'
import EditTaxRefundRate from '../component/EditTaxRefundRate'
import EditStaff from '../component/EditStaff'
import {
  StaffIcon, FuChiDianIcon, BankCardIcon,
  BackToIcon, DeleteDocIcon, TaxRecordIcon, EditIcon
} from '@icon'

import { paasql, queryEnterpriseInfo } from '@api'
import { ButtonLabel } from '@component'
const { Option } = Select;
const { Text } = Typography
const { Panel } = Collapse
const isInPark = [{
  "id": "1",
  "name": "是"
},
{
  "id": "0",
  "name": "否"
}]
const EnterpriseDetail = (props) => {
  const enterpriseId = props.match.params.id
  const { Frame, dispatch } = props
  const [parkId, setParkId] = useState(null)
  const [EnterpriseId, setEnterpriseId] = useState(props.match.params.id)
  const [cpStaffList, setCpStaff] = useState([])
  const [investmentAgencyList, setInvestmentAgency] = useState([])
  const [investmentAgencyStaffList, setInvestmentAgencyStaff] = useState([])
  const [settledBy, setSettledBy] = useState([])
  const [settledStatus, setSettledStatus] = useState([])
  const [recordingGenre, setRecordingGenre] = useState([])
  const [taxStatus, setTaxStatus] = useState([])
  const [registeredGenre, setRegisteredGenre] = useState(null)

  //是否本园区注册
  const [isRegisteredInThisPark, setIsRegisteredInThisPark] = useState('')

  //是否本园区招商
  const [isSettledInPark, setIsSettledInPark] = useState(false)

  const [enterpriseInfo, setEnterpriseInfo] = useState({})

  const [bankInfoModal, setBankInfoModal] = useState(false)
  const [rateInfoModal, setrateInfoModal] = useState(false)
  const [staffModal, setStaffModal] = useState(false)
  const [delLoading, setDelLoading] = useState(false)
  const [delModalConfirmVisable, setDelModalConfirmVisable] = useState(false)

  const [businessTermStartedAt, setBusinessTermStartedAt] = useState(null)
  const [businessTermEndedAt, setBusinessTermEndedAt] = useState(null)
  const [establishedIn, setEstablishedIn] = useState(null)
  const [exitedAt, setExitedAt] = useState(null)
  const [settledAt, setSettledAt] = useState(null)
  const [firstTaxPayoutAt, setFirstTaxPayoutAt] = useState(null)

  const [seachEnterpriseList, setSeachEnterprise] = useState([])
  const [cacheEnterprise, setCacheEnterprise] = useState({})

  useEffect(() => {
    const cmd_data = {
      eip_enterprise_id: enterpriseId
    }
    handelEnterpriseInfo(cmd_data)
    //获得财务年数据
    getParkId()


    const historyEnterprise = JSON.parse(localStorage.getItem('history_enterprise'))
    //去重
    if (historyEnterprise == null) {
      return
    } else {
      for (let i = 0; i < historyEnterprise.length; i++) {
        historyEnterprise[i] = JSON.parse(historyEnterprise[i])
      }
      var resultWithFilter = historyEnterprise.filter(function (element, index, self) {
        return self.findIndex(el => el.id == element.id) === index
      })

      //排序
      const enterpriseListWithSort = resultWithFilter.sort(function (first, last) {
        return Number(last.timestamp) - Number(first.timestamp)
      })

      //只显示前十个
      enterpriseListWithSort.length = 10

      setSeachEnterprise(enterpriseListWithSort)
    }

  }, [enterpriseId])


  const getParkId = () => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        setParkId(data.result[0].id)
      } else {
        message.error(data.error.message)
      }
    })
  }

  //处理企业的信息
  const handelEnterpriseInfo = (cmd_data) => {
    paasql('eip.eip_enterprise.enterprise_and_standard_data_by_enterprise_id', cmd_data).then(data => {
      const result = data.result

      const arr = ['基本信息管理', '企业信息管理']
      if (result.length != 0) {
        //查到的时候
        const {
          name,
          business_term_started_at,
          business_term_ended_at, established_in,
          settled_by,
          settled_status,
          is_registered_in_this_park,
          tax_status,
          settled_at,
          exited_at, recording_genre,
          eip_investment_agency_staff_name,
          investment_department_name,
          first_tax_payout_at, registered_genre } = result[0]

        //设置面包屑的导航
        arr.push(name)
        dispatch({
          type: "SET_BREADCRUMB_LIST",
          value: arr
        })
        //营业期限自
        const businessTermStartedAt =
          business_term_started_at === null ||
            business_term_started_at === '' ? null :
            moment(business_term_started_at).format('YYYY-MM-DD')

        //营业期限至
        const businessTermEndedAt =
          business_term_ended_at === null ||
            business_term_ended_at === ''
            ? null :
            moment(business_term_ended_at).format('YYYY-MM-DD')

        //成立日期
        const establishedIn =
          established_in === null ||
            established_in === '' ? null
            : moment(established_in).format('YYYY-MM-DD')


        //入园时间
        const settledAt =
          settled_at === null ||
            settled_at === '' ? null :
            moment(settled_at).format('YYYY-MM-DD')

        //离园时间
        const exitedAt =
          exited_at === null ||
            exited_at === '' ? null :
            moment(exited_at).format('YYYY-MM-DD')



        //首次纳税月
        const firstTaxPayoutAt =
          first_tax_payout_at === null ||
            first_tax_payout_at === '' ? null :
            `${first_tax_payout_at.slice(0, 4)}-${first_tax_payout_at.slice(4, 6)}`

        setFirstTaxPayoutAt(firstTaxPayoutAt)
        setExitedAt(exitedAt)
        setSettledAt(settledAt)
        setEstablishedIn(establishedIn)
        setBusinessTermStartedAt(businessTermStartedAt)
        setBusinessTermEndedAt(businessTermEndedAt)
        var enterpriseInfo = result[0]

        paasql('eip.eip_enterprise.get_eip_park_cp_staff_eip_investment_agency_eip_investment_agency_staff.list').then(data => {
          const [l, cp_staff_list, investment_agency_list, investment_agency_staff_list] = data.result.parallel_list_object
          //中介列表
          const cpStaffList = cp_staff_list.cp_staff_name
          const investmentAgencyList = investment_agency_list.eip_investment_agency_name
          const investmentAgencyStaffList = investment_agency_staff_list.eip_investment_agency_staff_name
          setCpStaff(cpStaffList)
          setInvestmentAgency(investmentAgencyList)
          setInvestmentAgencyStaff(investmentAgencyStaffList)
          paasql('eip.eip_enterprise.get_cp_standard_data_from_cp_standard_collection').then(data => {
            const result = data.result.parallel_list_object

            //处理下拉列表的数据问题
            const [settled_bys, investment_department, settled_statu, recording_genres, tax_statu, registeredGenres] = result
            //招商部门
            const settledBy = settled_bys.settled_by[0].result
            //入驻状态
            const settledStatus = settled_statu.settled_status[0].result
            //园区登记类型
            const recordingGenre = recording_genres.recording_genre[0].result
            //税务状态
            const taxStatus = tax_statu.tax_status[0].result

            //注册类型
            const registeredGenre = registeredGenres.registered_genre[0].result


            //处理招商部门
            const selectSettledByIndex = settledBy.findIndex(item => item.id === settled_by)
            const selectSettledBy =
              selectSettledByIndex === -1 ?
                '' : settledBy[selectSettledByIndex].name

            //判断是否为中介招入

            //处理招商类别

            ///SettledByName
            var SettledByName = null
            if (selectSettledBy !== '中介') {
              setIsSettledInPark(true)
              SettledByName = investment_department_name
            } else {
              setIsSettledInPark(false)
              SettledByName = eip_investment_agency_staff_name
            }

            setEnterpriseInfo({
              ...enterpriseInfo,
              SettledByName
            })

            //处理入驻状态
            const selectsettledStatusIndex = settledStatus.findIndex(item => item.id === settled_status)
            const selectsettledStatus =
              selectsettledStatusIndex === -1 ?
                '' : settledStatus[selectsettledStatusIndex].name


            //处理注册类型
            const selectrecordingGenreIndex = recordingGenre.findIndex(item => item.id === recording_genre)
            const selectrecordingGenre =
              selectrecordingGenreIndex === -1 ?
                '' : recordingGenre[selectrecordingGenreIndex].name

            //处理税务状态
            const selectTaxStatusIndex = taxStatus.findIndex(item => item.id === tax_status)
            const selectTaxStatus =
              selectTaxStatusIndex === -1 ?
                '' : taxStatus[selectTaxStatusIndex].name

            //处理注册类型
            const selectRegisteredGenreIndex = registeredGenre.findIndex(item => item.id === registered_genre)
            const selectRegisteredGenre =
              selectRegisteredGenreIndex === -1 ?
                '' : registeredGenre[selectRegisteredGenreIndex].name


            //处理是否本园区注册
            const selectIsInParkIndex = isInPark.findIndex(item => item.id === is_registered_in_this_park)
            const selectIsInPark = selectIsInParkIndex === -1 ? '否' : isInPark[selectIsInParkIndex].name



            setSettledBy(selectSettledBy)

            setIsRegisteredInThisPark(selectIsInPark)
            setSettledStatus(selectsettledStatus)
            setRecordingGenre(selectrecordingGenre)
            setTaxStatus(selectTaxStatus)
            setRegisteredGenre(selectRegisteredGenre)

          })
        })
      } else {
        message.error('未找到该企业，请重新选择企业')
        props.history.push('/enterprise-database')
      }
    })


  }

  const backToList = () => {
    props.history.push('/enterprise-database')
  }
  const edit = () => {
    props.history.push(`/enterprise-database/edit/${enterpriseId}`)
  }

  const editBankInfo = () => {
    setBankInfoModal(true)
  }

  const hideBankInfoModal = () => {
    setBankInfoModal(false)
  }
  const editRate = () => {
    setrateInfoModal(true)
  }
  const hideRateInfoModal = () => {
    setrateInfoModal(false)
  }
  const editStaff = () => {
    setStaffModal(true)
  }
  const hideStaffModal = () => {
    setStaffModal(false)
  }
  const taxInfo = () => {
    props.history.push(`/enterprise-database/tax-record/${enterpriseId}`)
  }


  const deleteEnterprise = () => {
    Modal.confirm({
      title: '删除企业',
      visible: delModalConfirmVisable,
      centered: true,
      content:
        <span>
          本操作将删除所选企业(<Text type="danger" >{enterpriseInfo.name}</Text>)，删除后不可恢复，是否继续？
      </span>,

      confirmLoading: delLoading,
      onOk() {
        confirmDel()
      }
    })
  }
  const confirmDel = () => {
    const cmd_data = {
      id: enterpriseId
    }
    paasql('eip.eip_enterprise.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        props.history.push('/enterprise-database')
      } else {
        message.error(data.error.message)
      }
    })

    setDelLoading(true)
  }
  const ShowCompont = ({ value }) => {
    return (
      <div className='show-enterprise-info-box'>
        {value}
      </div>
    )
  }
  const enterpriseBaseInfoList = [
    {
      id: "uscc",
      label: '统一征信代码:',
      span: 12,
      component:
        <ShowCompont value={enterpriseInfo.uscc} />
    }, {
      id: "enterprise_genre",
      span: 12,
      label: '公司类型:',
      component:
        <ShowCompont value={enterpriseInfo.enterprise_genre} />
    },
    //预留位置
    {
      id: "registered_capital",
      span: 6,
      label: '注册资本(万元):',
      component:
        <ShowCompont value={enterpriseInfo.registered_capital} />
    }, {
      id: "established_in",
      span: 6,
      label: '成立日期',
      component:
        <ShowCompont value={establishedIn} />
    }, {
      id: "business_term_started_at",
      span: 6,
      label: '营业期限自:',
      component:
        <ShowCompont
          value={businessTermStartedAt}
        />
    }, {
      id: "business_term_ended_at",
      span: 6,
      label: '营业期限至:',
      component:
        <ShowCompont value={businessTermEndedAt} />
    }, {
      id: "paidup_capital",
      span: 6,
      label: '实收资本(万元):',
      component:
        <ShowCompont value={enterpriseInfo.paidup_capital} />
    },
    // {
    //   id: "business_term",
    //   span: 6,
    //   label: '营业年限:',
    //   component:
    //     <ShowCompont value={enterpriseInfo.business_term} />
    // },
    {
      id: "currency",
      label: '币种:',
      span: 6,
      component:
        <ShowCompont value={enterpriseInfo.currency} />
    }, {
      id: "license_status",
      label: '工商状态:',
      span: 6,
      component:
        <ShowCompont value={enterpriseInfo.license_status} />
    }, {
      id: "registered_address",
      span: 18,
      label: '注册地址:',
      component:
        <ShowCompont value={enterpriseInfo.registered_address} />

    }, {
      id: "telephone_number",
      span: 6,
      label: '固定电话:',
      component:
        <ShowCompont value={enterpriseInfo.telephone_number} />

    }, {
      id: "business_address",
      span: 18,
      label: '经营地址:',
      component:
        <ShowCompont value={enterpriseInfo.business_address} />
    }, {
      id: "zip_code",
      span: 6,
      label: '公司邮编:',
      component:
        <ShowCompont value={enterpriseInfo.zip_code} />
    },
    {
      id: "scope_of_business",
      span: 24,
      label: '经营范围:',
      component:
        <ShowCompont value={enterpriseInfo.scope_of_business} />
    },
  ]


  //更改 税管码
  const taxInfoList = [
    {
      id: "taxpayer_registration_number",
      label: '税号:',
      span: 8,
      component:
        <ShowCompont value={enterpriseInfo.taxpayer_registration_number} />
    },
    {
      id: "tax_file_number",
      label: '税管码:',
      span: 8,
      component:
        <ShowCompont value={enterpriseInfo.tax_file_number} />

    }, {
      id: "tax_status",
      span: 8,
      label: '税务状况:',
      component:
        <ShowCompont value={taxStatus} />

    }
  ]




  const settleList = [
    {
      id: "file_number",
      label: '档案号:',
      span: 6,
      component:
        <ShowCompont value={enterpriseInfo.file_number} />
    }, {
      id: "name",
      span: 12,
      label: '企业名称:',
      component:
        <ShowCompont value={enterpriseInfo.name} />,
    },
    {
      id: "1",
      span: 6,
      label: '',
    },
    {
      id: "settled_at",
      label: '入园时间:',
      span: 6,
      component:
        <ShowCompont value={settledAt} />
    },
    {
      id: "enterprise_source",
      span: 6,
      label: '企业来源:',
      component:
        <ShowCompont value={enterpriseInfo.enterprise_source} />,
    },
    {
      id: "investment_department_name",
      span: 6,
      label: '招商部门:',
      component:
        <ShowCompont value={
          isSettledInPark ? '本部' : enterpriseInfo.eip_investment_agency_name
        } />,
    },
    {
      id: "settled_status",
      span: 6,
      label: '企业情况:',
      component:
        <ShowCompont value={settledStatus} />,
    },
    {
      id: "recording_genre",
      span: 6,
      label: '注册类型:',
      component:
        <ShowCompont value={recordingGenre} />
    }, {
      id: "industry",
      span: 6,
      label: '行业分类:',
      component:
        <ShowCompont value={enterpriseInfo.industry} />
    },
    {
      id: "cp_staff_name",
      span: 6,
      label: '招商员:',
      component:
        <ShowCompont value={
          isSettledInPark ?
            enterpriseInfo.cp_staff_name : enterpriseInfo.eip_investment_agency_staff_name} />,
    }, {
      id: "registered_genre",
      span: 6,
      label: '内外资:',
      component:
        <ShowCompont value={registeredGenre} />
    },
    // {
    //   id: "registration_number",
    //   span: 6,
    //   label: '注册号:',
    //   component:
    //     <ShowCompont value={enterpriseInfo.registration_number} />
    // }, 
    // {
    //   id: "legal_representative",
    //   span: 6,
    //   label: '法定代表人:',
    //   component:
    //     <ShowCompont value={enterpriseInfo.legal_representative} />
    // }, {
    //   id: "registered_by",
    //   span: 6,
    //   label: '登记机关:',
    //   component:
    //     <ShowCompont value={enterpriseInfo.registered_by} />
    // }, 
    {
      id: "former_name",
      span: 6,
      label: '曾用名:',
      component:
        <ShowCompont value={enterpriseInfo.former_name} />
    },
    {
      id: "remarks",
      span: 12,
      label: '备注:',
      component:
        <ShowCompont value={enterpriseInfo.remarks} />
    }
  ]


  const enterpriseInvestmentList = [

  ]

  const investmentList = [
    {
      id: "name",
      span: 6,
      label: '企业名称:',
      component:
        <ShowCompont value={enterpriseInfo.name} />,
    }, {
      id: "eip_investment_agency_name",
      span: 6,
      label: '招商部门:',
      component:
        <ShowCompont value={enterpriseInfo.eip_investment_agency_name} />,
    },
    {
      id: "eip_investment_agency_staff_name",
      span: 6,
      label: '招商员:',
      component:
        <ShowCompont value={enterpriseInfo.eip_investment_agency_staff_name} />,
    }
  ]

  const debounceQueryEnterpriseInfo = debounce(function (cmd_data) {
    queryEnterpriseInfo(cmd_data).then(data => {
      const { enterpriseList } = data
      setSeachEnterprise(enterpriseList)
    })

  }, 500)

  const seachEnterprise = (value) => {

    if (value === '') {
      setSeachEnterprise([])
      return
    }
    var cmd_data = {
      'eip_park_id': parkId,
      "attribute_filter": [
        {
          "attribute": "name",
          "operator": "contain",
          "value": [
            value
          ]
        }
      ]
    }

    debounceQueryEnterpriseInfo(cmd_data)

  }

  const selectEnterprise = (v, option) => {

    const { key, value } = option


    //设置缓存的值
    const cacheEnterprise = {
      'id': key,
      name: value,
      timestamp: new Date().getTime()
    }

    setCacheEnterprise(cacheEnterprise)

    setEnterpriseId(key)
  }


  const confirmToOther = () => {

    var historyEnterprise = JSON.parse(localStorage.getItem('history_enterprise'))
    historyEnterprise = historyEnterprise === null ? [] : historyEnterprise
    historyEnterprise[historyEnterprise.length] = JSON.stringify(cacheEnterprise)
    const obj = JSON.stringify(historyEnterprise)

    window.localStorage.setItem('history_enterprise', obj)
    const id = EnterpriseId
    props.history.push(`/enterprise-database/detail/${id}`)
  }
  return (
    <div>
      <div className="show-enterprise-info-btn-group"
        style={{ display: 'flex', justifyContent: 'space-between' }}  >
        <div>
          <Button.Group>

            <ButtonLabel
              size='large'
            />
            <Button
              size='large'
              icon={<BackToIcon />}
              onClick={backToList}
            >
              返回列表
            </Button>
            <Button
              size='large'
              icon={<BankCardIcon />}
              onClick={editBankInfo}
            >
              银行账户
            </Button>
            <Button
              size='large'
              icon={<FuChiDianIcon />}
              onClick={editRate}
            >
              扶持点
            </Button>
            <Button
              size='large'
              icon={<StaffIcon />}

              onClick={editStaff}
            >
              人员信息
            </Button>
            <Button
              size='large'
              icon={<TaxRecordIcon />}

              onClick={taxInfo}
            >
              纳税记录
            </Button>
            <Button
              size='large'
              icon={<DeleteDocIcon />}
              onClick={deleteEnterprise}
            >
              删除
            </Button>
            <Button
              size='large'
              icon={<EditIcon />}
              onClick={edit}
            >
              编辑
          </Button>
          </Button.Group>
        </div>
        <div
        className='right-button-group'
        >
          <Select
            placeholder="请输入企业名称"
            showSearch
            suffixIcon={<CaretDownOutlined />}
            onChange={selectEnterprise}
            style={{ width: '250px' }}
            filterOption={false}
            onSearch={seachEnterprise}
          >
            {
              seachEnterpriseList.map(item => {
                return (
                  <Option
                    key={item.id}
                    value={item.name}
                  >
                    {item.name}
                  </Option>
                )
              })
            }
          </Select>
          <Button
            style={{ marginLeft: '20px' }}
            type="primary"
            onClick={confirmToOther}
          >
            确定
            </Button>
        </div>

      </div>
      <div className='show-enterprise-info-container' >
        <Collapse
          defaultActiveKey={['1', '2', '3']}
          className="site-collapse-custom-collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <Panel
            header={

              <div className="show-enterprise-info-title">
                企业基本信息
            </div>
            }
            className="site-collapse-custom-panel"
            key="1">
            <div className='basic-info'>
              <Row
              >
                {
                  settleList.map(item => {
                    return (
                      <Col className="show-enterprise-info-item" key={item.id} span={item.span}>
                        <Row >
                          <Col className="show-enterprise-info-label" style={{ textAlign: 'right', width: '100px' }}>
                            {item.label}
                          </Col>
                          <Col style={{ width: 'calc(100% - 100px)' }}>
                            {item.component}
                          </Col>
                        </Row>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Panel>
          <Panel
            className="site-collapse-custom-panel"
            header={
              <div className="show-enterprise-info-title">
                营业执照信息
                  </div>
            }
            key="2">
            <Row>
              {
                enterpriseBaseInfoList.map(item => {
                  return (
                    <Col className="show-enterprise-info-item" key={item.id} span={item.span}>
                      <Row >
                        <Col className="show-enterprise-info-label" style={{ textAlign: 'right', width: '120px' }}>
                          {item.label}
                        </Col>
                        <Col style={{ width: 'calc(100% - 130px)' }}>
                          {item.component}
                        </Col>
                      </Row>
                    </Col>
                  )
                })
              }
            </Row>
          </Panel>
          <Panel
            header={
              <div className="show-enterprise-info-title">
                税务证照信息
                  </div>
            }
            key='3'
            className="site-collapse-custom-panel"
          >
            <Row>
              {taxInfoList.map(item => {
                return (
                  <Col className="show-enterprise-info-item" key={item.id} span={item.span}>
                    <Row>
                      <Col className="show-enterprise-info-label" style={{ textAlign: 'right', width: '120px' }}>
                        {item.label}
                      </Col>
                      <Col style={{ width: 'calc(100% - 120px)' }}>
                        {item.component}
                      </Col>
                    </Row>
                  </Col>)
              })}
            </Row>
          </Panel>
        </Collapse>


        {/* <div>
            <div className="show-enterprise-info-title">
              园区入驻信息
          </div>
            <Row>
              {
                settleList.map(item => {
                  return (
                    <Col className="show-enterprise-info-item" key={item.id} span={item.span}>
                      <div className='show-enterprise-info-label'>
                        {item.label}
                      </div>
                      <div>
                        {item.component}
                      </div>

                    </Col>
                  )
                })
              }
            </Row>
          </div> */}



      </div>
      <Modal
        centered={true}
        destroyOnClose={true}
        visible={bankInfoModal}
        width={400}
        title={<div style={{ textAlign: 'center' }}>
          银行账号信息
        </div>}
        footer={null}
        closable={false}
      >
        <EditBankInfo
          hideBankInfoModal={hideBankInfoModal}
          enterpriseId={enterpriseId}
          enterpriseInfo={enterpriseInfo}
        >

        </EditBankInfo>
      </Modal>
      <Modal
        centered={true}
        destroyOnClose={true}
        width={400}
        visible={rateInfoModal}
        title={<div style={{ textAlign: 'center' }}>
          扶持点信息
        </div>}
        footer={null}
        closable={false}
      >
        <EditTaxRefundRate
          hideRateInfoModal={hideRateInfoModal}
          enterpriseId={enterpriseId}
        >

        </EditTaxRefundRate>
      </Modal>
      <Modal
        centered={true}
        destroyOnClose={true}
        visible={staffModal}
        width={1300}

        title={<div style={{ textAlign: 'center' }}>
          工作人员
        </div>}
        footer={null}
        closable={false}
      >
        <EditStaff
          hideStaffModal={hideStaffModal}
          enterpriseId={enterpriseId}
          enterpriseName={enterpriseInfo.name}
        >

        </EditStaff>

      </Modal>

    </div >

  );
}

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseDetail);