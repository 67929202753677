import React, { useEffect, useState } from 'react';
import { List, Button, message } from 'antd'
import moment from 'moment'
import { paasql } from '@api'

import { getSession } from '@api/paasql'

const PolicyOnline = (props) => {

  const [policeList, setPoliceList] = useState([])
  useEffect(() => {
    const { primary_identity } = getSession()
    //当前登陆者的ID，身份为企业时有数据
    const { id } = primary_identity
    const cmd_data = {
      // eip_enterprise_id: 'fb387b7b180f47b99f3f22feaad5946a'
      eip_enterprise_id: id
    }
    paasql('eip.eip_cms_doc.list_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        var resultWithFilter = result.filter(function (element, index, self) {
          return self.findIndex(el => el.id == element.id) === index
        })

        //排序
        const policeListWithSort = resultWithFilter.sort(function (first, last) {
          return Number(last.cp_timestamp) - Number(first.cp_timestamp)
        })
        setPoliceList(policeListWithSort)

      } else {
        message.error(data.error.message)
      }
    })
  }, [])

  const showPoliceDetail = (item) => {
    const id = item.id
    props.history.push(`/policy-online/detail/${id}`)
  }

  return (

    <div className='police-online-container'>
      <div className='police-list-container' >
        <List
          itemLayout="horizontal"
          dataSource={policeList}
          renderItem={(item, index) => (
            <>
              <List.Item
              >
                <List.Item.Meta
                  onClick={() => showPoliceDetail(item)}
                  description={
                    <Content item={item} />
                  }
                />
              </List.Item>
            </>
          )}
        />
      </div>
    </div>

  );
}

export default PolicyOnline;
const Content = (props) => {
  const { item } = props
  const [time, setTime] = useState()
  const { cp_timestamp } = item

  useEffect(() => {
    const time = moment(cp_timestamp / 1000).format('YYYY-MM-DD')
    setTime(time)
  }, [])
  return (
    <>
      <span className='police-list-item-time'>
        {time}
      </span>
      <span style={{ cursor: 'pointer' }} className='police-list-item-title'>
        {item.name}
      </span>
    </>

  )
}