import React, { useEffect, useState } from "react";

import echarts from "echarts";
import { parkSettleExitedRecord } from "@api";

function ParkSettleExitedRecord() {
  const [inNumber, setInNumber] = useState(0);
  const [outNumber, setOutNumber] = useState(0);

  const [total, setTotal] = useState(0);

  const [option, setOption] = useState({});
  useEffect(() => {
    parkSettleExitedRecord().then((res) => {
      const data = res.result.list;

      const { In, Out, Total } = res.result.data;
      setInNumber(In);
      setOutNumber(Out);
      setTotal(Total);

      const inList = [];
      const outList = [];
      data.forEach((element) => {
        if (element.type === 1) {
          inList.push(element.amount);
        } else {
          outList.push(element.amount);
        }
      });
      const option = {
        color: ["#333FFF", "red"],
        title: {
          text: "每月离园/进园企业数",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          right: 20,
          top: 10,
          orient: "vertical",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          minInterval: 1,
          axisTick: {
            //y轴刻度线
            show: false,
          },
        },
        yAxis: {
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
          axisLine: {
            show: false,
            onZero: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          type: "category",
        },
        series: [
          {
            name: "进园",
            type: "bar",
            data: inList,
          },
          {
            name: "离园",
            type: "bar",
            data: outList,
          },
        ],
      };

      const dom = document.getElementById("park-settle-exited-record");

      if (dom !== null) {
        var myEchart = echarts.init(dom);
        myEchart.setOption(option);
        window.addEventListener("resize", () => {
          myEchart.resize();
        });
      }
    });
  }, []);

  return (
    <div className="park-settle-exited-record-container">
      <div
        style={{
          height: "calc(100% - 20px)",
          width: "100%",
        }}
        id="park-settle-exited-record"
      ></div>
      <div
        className='text'
      >
        2020年度离园企业数量累计：{outNumber}户；新入驻企业数量累计：{inNumber}
        户 ；当前园区企业总数：{total}户
      </div>
    </div>
  );
}
export default ParkSettleExitedRecord;
