
import React, { useState, useEffect, useContext } from 'react';
import {
  Upload,
  Button,
  message,
  Space,

} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ReachableContext } from '../UploadModal'
import { updateObjectWithBinary } from '@api/paasql'

const UploadFile = (props) => {

  const { goSelectHeader, taxRefundId } = useContext(ReachableContext)//使用上下文的模态框
  const [fileList, setFileList] = useState([])
  const [nextDisabled, setNextDisabled] = useState(true)
  const [hideBtn, setHideBtn] = useState(true)

  useEffect(() => {

  }, [taxRefundId])
  const handleBeforeUpload = (file) => {
    const list = [file]
    setHideBtn(false)
    setFileList(list)
    return false;
  }
  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {   // fileList 是要上传的文件数组
      formData.append('file', file);
    })
    const cmd_data = {
      cp_schema_id: "eip_agency_administered_enterprises_tax_refund_statement",
      cp_object_attribute: 'original_raw_data_id',
      cp_object_id: taxRefundId,
      file_list: formData
    }
    updateObjectWithBinary(cmd_data).then(data => {
      if (data.error == null) {
        message.success("上传文件成功", 2)
        //上传文件成功，下一步按钮激活
        setNextDisabled(false)
      } else {
        message.error(data.error.message)
      }
    })
  }
  return (
    <div>
      <div>
        <div className="subModalHeader" style={{ margin: '20px 0px' }}>
          数据写入成功后，匹配企业将被标记为支付成功。
      </div>
        <Upload
          beforeUpload={handleBeforeUpload}
          showUploadList={{
            showRemoveIcon: false
          }}
          fileList={fileList}
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        >

          <Button
          >
            <UploadOutlined
            />
            选择文件
            </Button>

        </Upload>

        {
          hideBtn ? "" :
            <Button
              onClick={handleUpload}
              disabled={!nextDisabled}
            >
              确认上传文件
            </Button>
        }
        <div style={{ textAlign: 'right', paddingTop: 20 }}>
          <Space>
            <Button type="primary"
              onClick={
                //这里需要处理总库表的更新状态
                () => goSelectHeader()
              }
              disabled={nextDisabled}
            >
              下一步
          </Button>
          </Space>

        </div>
      </div >
    </div >
  )
}

export default UploadFile;