import React, { useState, useEffect } from 'react';


import { Button, DatePicker, Pagination, message, Modal, List } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { DownloadOutlined, SearchOutlined, } from '@ant-design/icons';



import MonthlyAccumulatedTaxRecord from './TaxRecordTable.jsx'//表格展示组
import { moneyFormat } from '@utils/index'
import './index.less'
import UploadModal from './UploadModal.jsx'
import { ExportDataIcon, ImportDataIcon, AlreadyImportIcon } from '@icon'
import { ButtonLabel } from '@component'
import SelectFieldModal from './component/SelectFieldmodal'
import { queryMonthlyPaymentRecordApi, queryTaxRecord, paasql } from '@api'


const { RangePicker } = DatePicker;
const ButtonGroup = Button.Group
const monthFormat = 'YYYY-MM';
const currentEndTime = moment(new Date()).format('YYYY-MM')

const ImportData = () => {
  const [selectDate, setSelectDate] = useState([])
  const [singleMonth, setSingleMonth] = useState(true)
  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modalChange, setModalChange] = useState(false)
  //defaultStartTime
  const [defaultStartTime, setDefaultStartTime] = useState(currentEndTime)
  const [defaultEndTime, setDefaultEndTime] = useState(currentEndTime)
  const [tableLoading, setTableLoading] = useState(false)

  const [modelVisible, setModelVisible] = useState(true)
  const [totalTaxRecord, setTotalTaxRecord] = useState([])
  const [taxTimeInfo, setTaxTimeInfo] = useState({})

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [parkId, setParkId] = useState("2b9312bccbf94297a5172f3277ac8fa8")
  const [taxPaidAt, setTaxPaidAt] = useState(null)

  const [monthlyAccumulatedTaxList, setMonthlyAccumulatedTaxList] = useState([])
  useEffect(() => {
    //获得月份的api
    //看是汇总还是单月数据
    const now = new Date()
    const time = moment(now).format('YYYY-MM').replace('-', '')
    //这里需要处理
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        setParkId(data.result[0].id)
        setSelectDate([time, time])
        setTaxPaidAt(time)
        const cmd_data = {
          "eip_park_id": data.result[0].id,
          "tax_paid_at": time,
          "page": 1,
          "size": pageSize
        }

        //这个是表格的数据
        queryMonthlyPaymentRecordApi(cmd_data).then(data => {
          const { table_row_data, total_page } = data
          setTableData(table_row_data)
          setTotalPage(total_page)
        })
        //处理已导入的总库表列表
        const cmd_data1 = {
          "eip_park_id": data.result[0].id,
        }
        paasql('eip.eip_monthly_accumulated_tax.list', cmd_data1).then(data => {
          const result = data.result
          var eip_monthly_accumulated_tax_list_sort = result.sort(function (first_attr_item, last_attr_item) {
            return Number(last_attr_item.ended_at) - Number(first_attr_item.ended_at);
          });
          setMonthlyAccumulatedTaxList(eip_monthly_accumulated_tax_list_sort)
        })
      } else {
        message.error(data.error.message)
      }
    })

  }, [])

  const importData = () => {
    setShowModal(true)
    setModalChange(!modalChange)
  }
  const exportData = () => {
    //单月，使用单月接口

    if (singleMonth) {//说明同月
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": taxPaidAt,
        "page": 1,
        "size": pageSize * totalPage
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const { table_row_data, total_page } = data
        //还需要处理税收期间

        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].index = String(i + 1)
          for (const key in table_row_data[i]) {
            const obj = table_row_data[i]
            if (key == 'taxable_income' || key == 'tax_payable' || key == 'consumption_tax' || key == 'value_added_tax' ||
              key == 'business_tax' || key == 'corporate_income_tax' || key == 'individual_income_tax' ||
              key == 'city_maintenance_and_construction_tax' || key == 'vehicle_and_vessel_usage_tax' ||
              key == 'property_tax' || key == 'stamp_tax' || key == 'land_appreciation_tax' ||
              key == 'land_use_tax' || key == 'environmental_tax'
            ) {
              table_row_data[i][key] = moneyFormat(table_row_data[i][key])
            } else {

            }
          }
        }

        setTotalTaxRecord(table_row_data)
      }).finally(() => {
        setTableLoading(false)
        setModelVisible(!modelVisible)
      })
    } else { //说明是汇总
      const cmd_data = {
        "eip_park_id": parkId,
        ...taxTimeInfo,
        "page": 1,
        "size": pageSize * totalPage,
      }
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        const { table_row_data, total_page } = data
        const { start_month, start_year, end_month, end_year } = taxTimeInfo
        const tmpTaxPaidAt = `${start_year} 年${start_month}-${end_month}月`
        for (let i = 0; i < table_row_data.length; i++) {
          table_row_data[i].tax_paid_at = tmpTaxPaidAt
          table_row_data[i].index = String(i + 1)
          for (const key in table_row_data[i]) {
            const obj = table_row_data[i]
            if (key == 'taxable_income' || key == 'tax_payable' || key == 'consumption_tax' || key == 'value_added_tax' ||
              key == 'business_tax' || key == 'corporate_income_tax' || key == 'individual_income_tax' ||
              key == 'city_maintenance_and_construction_tax' || key == 'vehicle_and_vessel_usage_tax' ||
              key == 'property_tax' || key == 'stamp_tax' || key == 'land_appreciation_tax' ||
              key == 'land_use_tax' || key == 'environmental_tax'
            ) {
              table_row_data[i][key] = moneyFormat(table_row_data[i][key])
            } else {

            }
          }
        }
        setTotalTaxRecord(table_row_data)
      }).finally(() => {
        setTableLoading(false)
        setModelVisible(!modelVisible)
      })
    }
  }
  const dateChange = (date, dateString) => {
    const [startTime, endTime] = dateString
    setDefaultStartTime(startTime)
    setDefaultEndTime(endTime)
    const isSingleMonth = dateString[0] === dateString[1]
    //设置
    setSingleMonth(isSingleMonth)
    setSelectDate(dateString)

    const taxPaidAt = endTime.replace('-', '')//这是单月数据的查询接口

    const startYear = startTime.slice(0, 4)
    const startMonth = startTime.slice(5, 7)
    const endYear = endTime.slice(0, 4)
    const endMonth = endTime.slice(5, 7)

    //设置跨月查询的api请求条件
    setTaxTimeInfo({
      start_year: String(startYear),
      start_month: String(startMonth),
      end_month: String(endMonth),
      end_year: String(endYear),
    })
    setTaxPaidAt(taxPaidAt)
  }
  const seachResult = () => {
    setCurrentPage(1)
    if (singleMonth) {
      const time = selectDate[0].replace('-', '')
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": time,
        "page": 1,
        "size": pageSize
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {
        const { table_row_data, total_page } = data
        setTableData(table_row_data)
        setTableLoading(false)
        setTotalPage(total_page)
      })
      //如果单月的话，就使用queryMonthlyPaymentRecord
    }
    else {
      const startTime = selectDate[0].split('-')
      const [start_year, start_month] = startTime

      const endTime = selectDate[1].split('-')
      const [end_year, end_month] = endTime


      const cmd_data = {
        "eip_park_id": parkId,
        "start_year": start_year,
        "start_month": start_month,
        "end_year": end_year,
        "end_month": end_month,
        "page": 1,
        "size": pageSize
      }
      //如果不是单月的话，就使用queryTaxRecord
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        //表格的数据和页码
        const { table_row_data, total_page } = data
        setTableData(table_row_data)
        setTableLoading(false)
        setTotalPage(total_page)
      })
    }
  }
  const pageChange = (page) => {
    setCurrentPage(page)
    if (singleMonth) {
      const time = selectDate[0].replace('-', '')
      const cmd_data = {
        "eip_park_id": parkId,
        "tax_paid_at": time,
        "page": page,
        "size": pageSize
      }
      setTableLoading(true)
      queryMonthlyPaymentRecordApi(cmd_data).then(data => {

        const { table_row_data, total_page } = data
        setTableData(table_row_data)
        setTableLoading(false)

        setTotalPage(total_page)
      })
      //如果单月的话，就使用queryMonthlyPaymentRecord
    }
    else {
      const startTime = selectDate[0].split('-')
      const [start_year, start_month] = startTime

      const endTime = selectDate[1].split('-')
      const [end_year, end_month] = endTime


      const cmd_data = {
        "eip_park_id": parkId,
        "start_year": start_year,
        "start_month": start_month,
        "end_year": end_year,
        "end_month": end_month,
        "page": page,
        "size": pageSize
      }
      //如果不是单月的话，就使用queryTaxRecord
      setTableLoading(true)
      queryTaxRecord(cmd_data).then(data => {
        //表格的数据和页码
        const { table_row_data, total_page } = data
        setTableData(table_row_data)
        setTableLoading(false)
        setTotalPage(total_page)
      })
    }
  }
  const alreadyImportList = () => {
    Modal.confirm({
      title: '已导入总库表',
      content: <>
        <List

          style={{ height: '300px', overflow: 'auto' }}
          dataSource={monthlyAccumulatedTaxList}
          renderItem={(item, index) => (
            <List.Item
              key={item.id}
            >
              {item.name}
            </List.Item>)}
        >

        </List>
      </>,

    })
  }
  return (
    <>
      <div>
        <div
          className="container"
        >
          <div className='left' >
            <ButtonGroup>
              <ButtonLabel
                size='large'
              />
              <Button
                size='large'
                icon={<ImportDataIcon />}
                onClick={importData}
              >
                导入数据
            </Button>
              <Button
                size='large'
                onClick={exportData}
                icon={<ExportDataIcon />}
              >
                导出数据
              </Button>
              <Button
                size='large'
                onClick={alreadyImportList}
                icon={<AlreadyImportIcon />}
              >
                已导入总库表
              </Button>
            </ButtonGroup>



          </div>
          <div className="data-seach-condition" >
            <RangePicker
              locale={locale}
              size='small'
              value={[moment(defaultStartTime, monthFormat), moment(defaultEndTime, monthFormat)]}
              style={{ marginRight: 20, }}
              format="YYYY-MM"
              onChange={dateChange}
              picker="month"
            />
            <Button
              onClick={seachResult}
              type='primary'
              icon={<SearchOutlined />}
            >
              开始搜索
            </Button>
          </div>
        </div>
        <div
          className="container"
          style={{ paddingTop: 10 }}
        >
          <MonthlyAccumulatedTaxRecord
            pageSize={pageSize}
            currentPage={currentPage}
            Data={tableData}
            loading={tableLoading}
            totalPage={totalPage}
          />
        </div>
        <Pagination
          showQuickJumper={true}
          style={{ textAlign: 'right', paddingTop: 20 }}
          size='large'
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={currentPage}
          pageSize={pageSize}
          total={totalPage * pageSize}
          onChange={pageChange}
        />

      </div>
      <>
        <UploadModal
          parkId={parkId}
          modalChange={modalChange}
          visible={showModal}
        />
      </>
      <>
        <SelectFieldModal
          totalRecord={totalTaxRecord}
          modelVisible={modelVisible}
        >
        </SelectFieldModal>
      </>
    </>
  );
}

export default ImportData;