import React, { useEffect, useState, createRef } from 'react';

import E from 'wangeditor'
import { Button, Input, Space, message } from 'antd'
import { connect } from 'react-redux'

import { paasql } from '@api'
import { uploadImg } from '@api/paasql'
import { CancelEditIcon, CreateDocIcon } from '@icon'

const editorRef = createRef()


const BusinessNameRulesEdit = (props) => {
  const { dispatch } = props
  const docId = props.match.params.id
  const [stringLength, setStringLength] = useState(1)
  const [htmlContent, setHtmlContent] = useState('')
  const [title, setTitle] = useState('')
  useEffect(() => {

    const cmd_data = {
      id: docId
    }
    paasql("eip.eip_cms_doc.query_by_id", cmd_data).then(data => {
      const result = data.result
      //处理
      if (result.length !== 0) {
        const { content, name, archived, eip_park_id } = result[0]
        //处理页面显示
        //判断archived
        setHtmlContent(content)
        initEditor(content)
        setTitle(name)
        const length = name.length
        setStringLength(length)
        //处理日期

      }
    })
      .finally(() => {
        const navArr = ['招商管理', '编辑企业核名规则']
        dispatch({
          type: 'SET_BREADCRUMB_LIST',
          value: navArr
        })
      })
  }, [docId])


  const initEditor = (content) => {
    const editor = new E(editorRef.current)

    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      setHtmlContent(html)
    }
    editor.customConfig.menus = [
      'head',
      'bold',
      'italic',
      'underline',
      'justify',
      'image',
    ]
    editor.customConfig.customUploadImg = function (files, insert) {
      //eip_cms_doc_file
      const formData = new FormData();
      files.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file);
      })

      const request = {
        cp_schema_id: "eip_cms_doc_file",
        cp_object_attribute: "cp_file_id",
        file_object: formData
      }
      uploadImg(request).then(data => {
        if (data.error == null) {
          const fileId = data.result.cp_file_id
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`
          insert(url)
        } else {
          message.error(data.error.message)
        }
      })
    }

    editor.create()
    editor.txt.html(content)
  }

  const cancelEdit = () => {
    //回到之前的路径
    props.history.push(`/business-name-rules`)
  }
  const saveEdit = () => {
    //保存内容
    //eip.cms_doc.update_content
    const cmd_data = {
      content: htmlContent,
      id: docId,
      name: title
    }
    paasql("eip.cms_doc.update_content", cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        props.history.push(`/business-name-rules`)
      } else {
        message.error(data.error.message)
      }
    })
  }
  const changeTitle = (e) => {
    const value = e.target.value
    const length = value.length
    setStringLength(length)
    setTitle(value)
  }
  return (
    <div className='edit-police-container'>
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button
            icon={<CancelEditIcon />}
            onClick={cancelEdit} >
            取消编辑
        </Button>
          <Button
            icon={<CreateDocIcon />}
            onClick={saveEdit}
          >
            保存内容
        </Button>
        </Space>

      </div>
      <div>
        <span className='edit-police-info-label'>
          编辑标题内容
        </span>
        <Input
          size='large'
          onChange={changeTitle}
          placeholder={'请输入标题'}
          maxLength={50}
          value={title}
          suffix={`${stringLength}/50`}
        />
      </div>
      <div>
        <span className='edit-police-info-label'>
          编辑文档内容
        </span>

        <div className="police-edit-eidtor" ref={editorRef} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BusinessNameRulesEdit);