import {
  Login,
  Index,
  NotFound,
  ImportData,
  TaxManagement,
  TaxWarning,
  DataSeach,


  ParkTaxRefund,
  ParkTaxRefundStatistics,
  AgencyTaxRefund,
  AgencyTaxRefundStatistics,

  PoliceManagement,
  PoliceEdit,
  ArchivedPoliceDetail,
  ImplementingPoliceDetail,
  PoliceCreate,
  PolicyOnline,
  PolicyOnlineDetail,

  ParkDashboard,
  ParkDashboardSetting,
  ParkIntroduction,
  ParkIntroductionEdit,
  Faq,
  FaqEdit,
  FamousEnterprise,
  FamousEnterpriseEdit,
  FamousEnterpriseDetail,
  FamousEnterpriseCreate,

  ContactUs,
  ContactUsEdit,

  BusinessNameRules,
  BusinessNameRulesEdit,


  TaxNewGetOn,
  VirtualPark,
  Dashboard,




  EnterpriseDatabase,
  EnterpriseCreate,
  EnterpriseEdit,
  EnterpriseDetail,
  ShowTaxPaymentRecord,

  VirtualParkManagement,
  ParkStaffManagement,
  InvestmentAgency,


  UserManagement,


  EnterpriseBiosphere
} from '../pages'

//配置登陆页和
export const mainRouter = [
  {
    pathname: '/dash-border',
    component: Dashboard,
    title: '华严智慧园区-招商展示'
  }, {
    pathname: '/login',
    component: Login,
    title: '华严智慧园区管理平台-登录'
  }, {
    pathname: '/',
    component: Index,
    // exact: true
  }

]

export const appRouter = [
  {
    pathname: '/tax-management',
    component: TaxManagement,
    title: '纳税信息管理-纳税一览'
  },
  {
    pathname: '/import-data',
    component: ImportData,
    title: '纳税信息管理-数据入库'
  },
  {
    pathname: '/data-seach',
    component: DataSeach,
    title: '纳税信息管理-数据查询'
  },

  //退税模块
  {
    pathname: '/park-tax-refund',
    component: ParkTaxRefund,
    title: '退税-园区本部退税'
  },
  {
    pathname: '/agency-tax-refund',
    component: AgencyTaxRefund,
    title: '退税-中介招入退税核算'
  },
  {
    pathname: '/park-tax-refund-statistics',
    component: ParkTaxRefundStatistics,
    title: '退税-园区本部退税统计'
  },
  {
    pathname: '/agency-tax-refund-statistics',
    component: AgencyTaxRefundStatistics,
    title: '退税-中介招入退税统计'
  },

  //政策管理
  {
    pathname: '/police-management',
    exact: true,
    component: PoliceManagement,
    title: '在线政策-政策管理'
  },

  {
    pathname: '/policy-online',
    exact: true,
    component: PolicyOnline,
    title: '在线政策-政策内容'
  }, {
    pathname: '/policy-online/detail/:id',
    component: PolicyOnlineDetail,
    title: '在线政策-政策内容详情'
  },
  {
    pathname: '/police-management/edit/:id',
    component: PoliceEdit,
    title: '在线政策-政策管理-编辑政策内容'
  },
  {
    pathname: '/police-management/archived/:id',
    component: ArchivedPoliceDetail,
    title: '在线政策-政策管理-已归档政策'
  },
  {
    pathname: '/police-management/implementing/:id',
    component: ImplementingPoliceDetail,
    title: '在线政策-政策管理-未归档政策'
  },
  {
    pathname: '/police-management/create',
    component: PoliceCreate,
    title: '在线政策-政策管理-创建政策'
  },

  //纳税预警模块
  {
    pathname: '/park-dashboard',
    component: ParkDashboard,
    title: '纳税预警-园区总览'
  },
  {
    pathname: '/park-dashboard-setting',
    component: ParkDashboardSetting,
    title: '纳税预警-园区总览设置'
  },
  {
    pathname: '/tax-new-get-on',
    component: TaxNewGetOn,
    title: '纳税预警-重点企业排行榜'

  },
  {
    pathname: '/virtual-park',
    component: VirtualPark,
    title: '纳税预警-园中园概况'

  },



  //招商管理模块
  {
    pathname: '/park-introduction',
    exact: true,
    component: ParkIntroduction,
    title: '招商管理-开发区介绍'

  },

  {
    pathname: '/park-introduction/edit/:id',
    component: ParkIntroductionEdit,
    title: '招商管理-编辑开发区介绍'
  },
  {
    pathname: '/famous-enterprise',
    exact: true,
    component: FamousEnterprise,
    title: '招商管理-明星企业'

  },

  //FamousEnterpriseEdit
  {
    pathname: '/famous-enterprise/edit/:id',
    component: FamousEnterpriseEdit,
    title: '招商管理-编辑明星企业'
  },
  {
    pathname: '/famous-enterprise/create',
    component: FamousEnterpriseCreate,
    title: '招商管理-创建明星企业'
  },
  {
    pathname: '/famous-enterprise/detail/:id',
    component: FamousEnterpriseDetail,
    title: '招商管理-明星企业详情'
  },
  {
    pathname: '/contact-us',
    exact: true,
    component: ContactUs,
    title: '招商管理-联系我们'
  },
  {
    pathname: '/contact-us/edit/:id',
    component: ContactUsEdit,
    title: '招商管理-联系我们-编辑'
  },
  {
    pathname: '/faq',
    exact: true,
    component: Faq,
    title: '招商管理-常见问题解答'
  },
  {
    pathname: '/faq/edit/:id',
    component: FaqEdit,
    title: '招商管理-编辑-常见问题解答'

  },
  {
    pathname: '/business-name-rules',
    exact: true,
    component: BusinessNameRules,
    title: '招商管理-企业核名规则'
  },
  {
    pathname: '/business-name-rules/edit/:id',
    component: BusinessNameRulesEdit,
    title: '招商管理-编辑企业核名规则'
  },




  //基本信息管理
  {
    pathname: '/virtual-park-management',
    component: VirtualParkManagement,
    title: '基本信息管理-园中园管理'
  },
  {
    pathname: '/park-staff-management',
    component: ParkStaffManagement,
    title: '基本信息管理-园区工作人员管理'
  },
  {
    pathname: '/investment-agency',
    component: InvestmentAgency,
    title: '基本信息管理-招商中介管理'
  },
  {
    pathname: '/enterprise-database',
    exact: true,
    component: EnterpriseDatabase,
    title: '基本信息管理-企业库'
  },
  {
    pathname: '/enterprise-database/create',
    component: EnterpriseCreate,
    title: '基本信息管理-企业库-创建企业'

  },
  {
    pathname: '/enterprise-database/detail/:id',
    component: EnterpriseDetail,
    title: '基本信息管理-企业库-企业详情'
  },
  {
    pathname: '/enterprise-database/tax-record/:id',
    component: ShowTaxPaymentRecord,
    title: '基本信息管理-企业库-企业详情-纳税记录'
  }, {
    pathname: '/enterprise-database/edit/:id',
    component: EnterpriseEdit,
    title: '基本信息管理-企业库-编辑企业'

  }, {
    pathname: '/account-management',
    component: UserManagement,
    title: '用户管理-账号管理'
  }, {
    pathname: '/enterprise-biosphere',
    component: EnterpriseBiosphere,
    title: '企业生态圈'
  }
]



