import React, { useState, useEffect } from 'react';
import { paasql } from '@api'
import { getSession } from '@api/paasql'
import { message,Button } from 'antd'

// var src = "" + '?session=' + session + '&year=' + year + '&api_server=' + api_server
const aliyunSrc = 'http://datav.aliyuncs.com/share/2bdf53675b9589081977accec3828a87'
const Dashboard = (props) => {
  const [parkDashboardDataVSrc, setParkDashboardDataVSrc] = useState(null)
  useEffect(() => {
  }, [])
  const goToLogin = () =>{
    props.history.push('/login')
  }
  return (
    <div style={{ position: "relative",width:'100%', height: '100%'  }}>
      <div  style={{ position: 'absolute', top:'20px', right: '20px',height:'50px',width:'50px',zIndex:'100' }}>
          <Button
            size='small'
            type="primary"
            width={50}
            style={{backgroundColor:'#005AC9'}}
            onClick={goToLogin}
          >
            登录
          </Button>
      </div>
      <iframe style={{ position: 'absolute', width:'100%', height: '100%' }} src={aliyunSrc} frameBorder="0"></iframe>
    </div>
  );
}

export default Dashboard;