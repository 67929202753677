import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  InputNumber,
  Divider,
  Checkbox,
  Input,
  Button,
  Space,
  DatePicker,
  Radio,
  Typography,
  message,
} from "antd";
import moment from "moment";

import { moneyFormat, isNil } from "@utils/index";
import { paasql } from "@api";
import "./EditEnterpriseInfo.less";

const { TextArea } = Input;
const { Text } = Typography;
const EditEnterpriseInfo = (props) => {
  const { handleCancel, handleOk, id, selectTaxRefundStatement } = props;
  const [info, setInfo] = useState({}); //
  const [showType, setShowType] = useState("enterprise");
  const [isPaymentSuccessed, setIsPaymentSuccessed] = useState(true); //是否支付成功
  const [isAgencyPaymentSuccessed, setIsAgencyPaymentSuccessed] = useState(
    false
  ); //招商点是否支付成功
  const [isLastStatement, setIsLastStatement] = useState(false); //是否计算上次余留
  const [isAgencyLastStatement, setIsAgencyLastStatement] = useState(false); //是否结算招商点上次余留
  var [
    isCalculatIndividualIncomeTax,
    setIsCalculatIndividualIncomeTax,
  ] = useState(false); //是否计算个人所得税
  var [isCalculatePropertytax, setIsCalculatePropertytax] = useState(false); //是否计算房产税

  var [
    isAgencyCalculatIndividualIncomeTax,
    setIsAgencyCalculatIndividualIncomeTax,
  ] = useState(false); //是否计算招商点个人所得税
  var [
    isAgencyCalculatePropertytax,
    setIsAgencyCalculatePropertytax,
  ] = useState(false); //是否计算招商点房产所得税

  const [paidAt, setPaidAt] = useState("");
  const [agencyPaidAt, setAgencyPaidAt] = useState("");
  const [enterpriseInfo, setEnterpriseInfo] = useState(null);
  const [defaultManagementFee, setDefaultManagementFee] = useState(2500);

  // 标定管理费的处理是从企业扣还是中介扣
  const [deduction, setDeduction] = useState("enterprise");

  //初始管理费集合
  const [managementFee, setManagementFee] = useState({
    enterpriseManagementFee: 0,
    agencyManagementFee: 0,
  });

  useEffect(() => {
    const cmd_data = {
      id: id,
    };
    paasql(
      "eip.eip_agency_administered_enterprises_tax_refund_statement_record.query_by_id",
      cmd_data
    ).then((data) => {
      setEnterpriseInfo(data.result[0]);
      handleData(data.result[0]);
    });
  }, [id]);

  //处理初始化进入的时候数据处理

  function handleData(enterpriseInfo) {
    const {
      is_last_statement_balance_added, //是否计算上次余留
      is_payment_succeeded, //是否支付成功
      paid_at, //支付日期
      is_agency_last_statement_balance_added, //招商点是否计算上次余留
      is_agency_payment_succeeded, //招商点款项是否支付成功
      agency_paid_at, //招商点支付日期

      calculate_enterprise_individual_income_tax_refund,
      calculate_agency_individual_income_tax_refund,

      calculate_enterprise_property_tax_refund,
      calculate_agency_property_tax_refund,
      remain_management_fee, //剩余管理费
      agency_remain_management_fee, //中介剩余管理费
    } = enterpriseInfo;

    //设置管理费
    setManagementFee({
      enterpriseManagementFee: remain_management_fee,
      agencyManagementFee: agency_remain_management_fee,
    });

    // 1、由于企业所有的rate不为0的时候，管理费一定会从中介扣，不会从企业扣
    // 设置disable

    // 2、如果

    // 是否支付成功
    const isPaymentSuccessed = is_payment_succeeded === "1" ? true : false;
    const isAgencyPaymentSuccessed =
      is_agency_payment_succeeded === "1" ? true : false;
    const isAgencyLastStatementBalanceAdded =
      is_agency_last_statement_balance_added === "1" ? true : false;
    const isLastStatement =
      is_last_statement_balance_added === "1" ? true : false;
    setIsAgencyLastStatement(isAgencyLastStatementBalanceAdded); //是否计算招商点的上次余留
    setIsLastStatement(isLastStatement); //是否计算上次余留
    setIsPaymentSuccessed(isPaymentSuccessed); //是否支付成功
    setIsAgencyPaymentSuccessed(isAgencyPaymentSuccessed); //招商点是否支付成功

    //是否计算招商点的
    const isCalculatIndividualIncomeTax =
      calculate_enterprise_individual_income_tax_refund == "1" ? true : false;
    const isCalculatPropertyTax =
      calculate_enterprise_property_tax_refund == "1" ? true : false;
    const isAgencyCalculatPropertyTax =
      calculate_agency_property_tax_refund == "1" ? true : false;
    const isAgencyCalculatIndividualIncomeTax =
      calculate_agency_individual_income_tax_refund == "1" ? true : false;

    setIsAgencyCalculatIndividualIncomeTax(isAgencyCalculatIndividualIncomeTax); //是否计算招商点个人所得税
    setIsAgencyCalculatePropertytax(isAgencyCalculatPropertyTax); //是否计算招商房产税
    setIsCalculatIndividualIncomeTax(isCalculatIndividualIncomeTax); //是否计算个人所得税
    setIsCalculatePropertytax(isCalculatPropertyTax); //是否计算房产税

    //处理招商点支付日期和支付日期
    const now = moment().format("YYYY-MM-DD");
    const paidAtStr = paid_at == null || paid_at == "" ? now : paid_at;

    const paidAt = moment(paidAtStr).format("YYYY-MM-DD");
    const agencyPaidAtStr =
      agency_paid_at == null || agency_paid_at == "" ? now : agency_paid_at;
    const agencyPaidAt = moment(agencyPaidAtStr).format("YYYY-MM-DD");
    setPaidAt(paidAt);
    setAgencyPaidAt(agencyPaidAt);

    //设置剩余管理费
    setDefaultManagementFee(remain_management_fee);

    // 处理是否可以修改企业的rate
    checkRate(enterpriseInfo);
    setInfo({
      ...enterpriseInfo,
    });
  }

  // 处理更改企业的扶持比的时候，判断管理费扣还是从企业还是招商点扣
  useEffect(() => {
    checkRate(info);
  }, [info]);

  // 初始化进入的时候，处理是否为企业扣管理费
  function checkRate(value) {
    var arr = Object.keys(value);
    if (arr.length !== 0) {
      const {
        enterprise_value_added_tax_refund_rate,
        enterprise_corporate_income_tax_refund_rate,
        agency_individual_income_tax_refund_rate,
        agency_property_tax_refund_rate,
      } = value;
      //如果这些都为0
      if (
        enterprise_value_added_tax_refund_rate == 0 &&
        enterprise_corporate_income_tax_refund_rate == 0 &&
        agency_individual_income_tax_refund_rate == 0 &&
        agency_property_tax_refund_rate == 0
      ) {
        setDeduction("agency");
      } else {
        setDeduction("enterprise");
      }
    } else {
      setDeduction("enterprise");
    }
  }
  //定义一个取整的函数
  const formatDecimal = function (num, decimal) {
    num = num.toFixed(4); //精度为小数点后4位数
    num = num.toString();
    let index = num.lastIndexOf(".");
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1);
    } else {
      num = num.substring(0);
    }
    return parseFloat(num).toFixed(decimal);
  };

  //更改是否计算上次余留
  const changeIsLastStatement = (e) => {
    const is_last_statement_balance_added = e.target.checked;
    setIsLastStatement(is_last_statement_balance_added);
    //这里也需要处理
    const value = is_last_statement_balance_added ? "1" : "0";
    info.is_last_statement_balance_added = value;
    setInfo({
      ...info,
      is_last_statement_balance_added: value,
    });
    handelEnterpriseTax();
  };

  //处理管理费和代付款，本次余留

  const handelEnterpriseTax = () => {
    const {
      payment_difference, //补差金额
      total_enterprise_tax_refund_in_quarter,
      is_last_statement_balance_added, //是否计算上次余留
      last_statement_balance, //
      payment_on_behalf,
      prepayment,
    } = info;
    var {
      management_fee,
      accounts_payable,
      current_balance,
      pending_payment,
    } = info;
    //计算管理费
    if (is_last_statement_balance_added === "1") {
      //如果计算上次余留
      const tmp_calculation_management_fee =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference);
      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );
      accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
      if (accounts_payable >= 0) {
        //如果本季应扶持金额 >=0
        var tmp_value = Number(accounts_payable % 100);
        current_balance = formatDecimal(tmp_value, 2);
      } else {
        current_balance = 0;
      }
      if (calculation_management_fee > Number(defaultManagementFee)) {
        //为默认管理费
        management_fee = defaultManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费
        management_fee = calculation_management_fee;
      } else {
        //为0
        management_fee = 0;
      }
      //计算上次余留情况下的本季应扶持金额
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //设置管理费和应扶持金额
    }
    //如果不计算上次余留
    else {
      const tmp_calculation_management_fee =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference);

      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );

      if (calculation_management_fee > Number(defaultManagementFee)) {
        //默认管理费
        management_fee = defaultManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费

        management_fee = calculation_management_fee;
      } else {
        //为0
        management_fee = 0;
      }

      //不计算上次余留情况下的本季应扶持金额
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      //应扶持金额

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);

      //设置管理费和应扶持金额
    }
    //计算本季应扶持金额

    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项
    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    //计算本次代付款项
    // ！！！！！这里有疑问
    info.pending_payment = String(pending_payment);
    info.management_fee = String(management_fee);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);

    //设置这里的数值页面未同步
    setInfo({
      ...info,
      management_fee,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };

  //计算代付和
  const handelPendingPayment = () => {
    //判断是否计算本季余留
    const {
      payment_difference, //补差金额
      total_enterprise_tax_refund_in_quarter,
      is_last_statement_balance_added, //是否计算上次余留
      last_statement_balance, //
      payment_on_behalf,
      prepayment,
    } = info;
    var {
      management_fee,
      accounts_payable,
      current_balance,
      pending_payment,
    } = info;

    if (is_last_statement_balance_added === "1") {
      //计算上次余留
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //设置管理费和应扶持金额
    } else {
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      //应扶持金额

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //应扶持金额
    }

    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项
    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    // ！！！！！这里有疑问
    info.pending_payment = String(pending_payment);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);

    setInfo({
      ...info,
      pending_payment,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };
  //更改补差
  const changePaymentDifference = (value) => {
    //更改指针的方向
    const tmp_info = info;
    const f_value = value === null ? "0" : value;
    tmp_info.payment_difference = f_value;

    setInfo({
      ...info,
      payment_difference: f_value,
    });
    handelEnterpriseTax();
  };
  //<>更改补差备注
  const changePaymentDifferenceRemark = (e) => {
    const value = e.target.value;
    setInfo({
      ...info,
      payment_difference_remark: value,
    });
  };
  //更改代扣
  const changePaymentOnBehalf = (value) => {
    const tmp_info = info;
    const f_value = value === null ? "0" : String(value);
    tmp_info.payment_on_behalf = f_value;
    setInfo({
      ...info,
      payment_on_behalf: f_value,
    });
    handelPendingPayment();
  };
  //更改预付
  const changePrepayment = (value) => {
    const tmp_info = info;
    const f_value = value === null ? "0" : String(value);
    tmp_info.prepayment = f_value;
    setInfo({
      ...info,
      prepayment: f_value,
    });
    handelPendingPayment();
  };

  //更改本次余留
  const changeCurrentBalance = (value) => {
    const { accounts_payable } = info;
    const f_value = value === null ? "0" : String(value);
    const tmp_pending_payment = Number(accounts_payable) - Number(f_value);
    const pending_payment = formatDecimal(tmp_pending_payment, 2);
    setInfo({
      ...info,
      pending_payment,
      current_balance: f_value,
    });
  };

  //更改管理费
  //需要更改应扶持金额
  const changeManagementFee = (value) => {
    var f_value = value === null ? 0 : value;

    if (f_value > Number(defaultManagementFee)) {
      message.error("输入的管理费不能大于剩余管理费");
      f_value = defaultManagementFee;
    }
    f_value = String(f_value);
    info.management_fee = f_value;
    const {
      is_last_statement_balance_added,
      total_enterprise_tax_refund_in_quarter,
      last_statement_balance,
      payment_difference,
      payment_on_behalf,
      prepayment,
    } = info;
    var { accounts_payable, current_balance, pending_payment } = info;
    //计算应扶持金额

    if (is_last_statement_balance_added === "1") {
      const tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(f_value) - //管理费
        Number(payment_on_behalf) -
        Number(prepayment);

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    } else {
      const tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(f_value) - //管理费
        Number(payment_on_behalf) -
        Number(prepayment);

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    }
    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项

    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    info.pending_payment = String(pending_payment);
    info.management_fee = String(f_value);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);
    //计算代付款
    setInfo({
      ...info,
      management_fee: f_value,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };

  //更改是否计算招商点上次余留
  const changeIsAgencyLastStatement = (e) => {
    const is_agency_last_statement_balance_added = e.target.checked;
    setIsAgencyLastStatement(is_agency_last_statement_balance_added);
    //这里也需要处理
    const value = is_agency_last_statement_balance_added ? "1" : "0";
    info.is_agency_last_statement_balance_added = value;
    setInfo({
      ...info,
      is_agency_last_statement_balance_added: value,
    });
    handelAgencyTax();
  };

  //是否支付成功
  const changeIsPaymentSuccessed = (e) => {
    const is_payment_succeeded = e.target.checked;
    setIsPaymentSuccessed(is_payment_succeeded);
    const value = is_payment_succeeded ? "1" : "0";

    setInfo({
      ...info,
      is_payment_succeeded: value,
    });
  };
  //更改实际支付
  const changeActualAmountPaid = (value) => {
    const f_value = value === null ? "0" : String(value);
    const actual_amount_paid = f_value;
    setInfo({
      ...info,
      actual_amount_paid,
    });
  };
  //更改支付日期
  const changePaidAt = (date, dateString) => {
    setPaidAt(dateString);
    const paid_at = dateString.replace(/-/g, "");
    setInfo({
      ...info,
      paid_at,
    });
  };
  //更改备注
  const changeRemark = (e) => {
    const remark = e.target.value;
    setInfo({
      ...info,
      remark,
    });
  };

  //更改开户行
  const changeBankName = (e) => {
    const value = e.target.value;
    setInfo({
      ...info,
      bank_name: value,
    });
  };

  //更改银行账户
  const changeBankAccountName = (e) => {
    const value = e.target.value;
    setInfo({
      ...info,
      bank_account_name: value,
    });
  };
  //更改银行账户名
  const changeBankAccountNumber = (e) => {
    const bank_account_number = e.target.value;
    setInfo({
      ...info,
      bank_account_number,
    });
  };

  //更改开户行
  const changeBankBranch = (e) => {
    const bank_branch = e.target.value;
    setInfo({
      ...info,
      bank_branch,
    });
  };

  //更改行号
  const changeBankCode = (e) => {
    const bank_code = e.target.value;
    setInfo({
      ...info,
      bank_code,
    });
  };

  const handelTotalTaxRefund = () => {
    const {
      enterprise_value_added_tax_refund,
      enterprise_corporate_income_tax_refund,
      enterprise_individual_income_tax_refund,
      enterprise_property_tax_refund,
    } = info;

    var tmp_total_enterprise_tax_refund_in_quarter = null;
    if (isCalculatIndividualIncomeTax) {
      if (isCalculatePropertytax) {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_individual_income_tax_refund) +
          Number(enterprise_property_tax_refund);
      } else {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_individual_income_tax_refund);
      }
    } else {
      if (isCalculatePropertytax) {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_property_tax_refund);
      } else {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund);
      }
    }

    const total_enterprise_tax_refund_in_quarter = formatDecimal(
      tmp_total_enterprise_tax_refund_in_quarter,
      2
    );
    info.total_enterprise_tax_refund_in_quarter = total_enterprise_tax_refund_in_quarter;
    //这里的扶持额发生了变化

    handelEnterpriseTax();
    setInfo({
      ...info,
      total_enterprise_tax_refund_in_quarter,
    });
  };

  //更改增值税比
  const changeValueAddedTaxRefundRate = (value) => {
    const _value = isNil(value) ? "0" : value;
    const enterprise_value_added_tax_refund_rate = _value / 100;

    const { total_value_added_tax_in_quarter } = info;
    const tmp_value =
      enterprise_value_added_tax_refund_rate * total_value_added_tax_in_quarter;
    const enterprise_value_added_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_value_added_tax_refund_rate = _value;
    info.enterprise_value_added_tax_refund = enterprise_value_added_tax_refund;
    setInfo({
      ...info,
      enterprise_value_added_tax_refund_rate: _value,
      enterprise_value_added_tax_refund,
    });
    handelTotalTaxRefund();
  };

  const changeCorporateIncomeTaxRefundRate = (value) => {
    const enterprise_corporate_income_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_corporate_income_tax_in_quarter } = info;
    const tmp_value =
      enterprise_corporate_income_tax_refund_rate *
      total_corporate_income_tax_in_quarter;
    const enterprise_corporate_income_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_corporate_income_tax_refund_rate = value;
    info.enterprise_corporate_income_tax_refund = enterprise_corporate_income_tax_refund;
    setInfo({
      ...info,
      enterprise_corporate_income_tax_refund_rate: value,
      enterprise_corporate_income_tax_refund,
    });
    handelTotalTaxRefund();
  };

  const changeCalculateIndividualIncomeTax = (e) => {
    const calculate_individual_income = e.target.checked;

    if (calculate_individual_income) {
      //设置显示的税额

      const {
        total_individual_income_tax,
        enterprise_individual_income_tax_refund_rate,
      } = info;

      const tmp_value =
        (enterprise_individual_income_tax_refund_rate / 100) *
        total_individual_income_tax;
      const enterprise_individual_income_tax_refund = formatDecimal(
        tmp_value,
        2
      );
      info.enterprise_individual_income_tax_refund = enterprise_individual_income_tax_refund;
      setInfo({
        ...info,
        enterprise_individual_income_tax_refund,
      });
    }
    isCalculatIndividualIncomeTax = calculate_individual_income;
    setIsCalculatIndividualIncomeTax(calculate_individual_income);
    const value = calculate_individual_income ? "1" : "0";
    info.calculate_enterprise_individual_income_tax_refund = value;
    setInfo({
      ...info,
      calculate_enterprise_individual_income_tax_refund: value,
    });
    handelTotalTaxRefund();
  };

  const changeIndividualIncomeTax = (value) => {
    const enterprise_individual_income_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_individual_income_tax } = info;
    const tmp_value =
      enterprise_individual_income_tax_refund_rate *
      total_individual_income_tax;
    const enterprise_individual_income_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_individual_income_tax_refund_rate = value;
    info.enterprise_individual_income_tax_refund = enterprise_individual_income_tax_refund;
    setInfo({
      ...info,
      enterprise_individual_income_tax_refund_rate: value,
      enterprise_individual_income_tax_refund,
    });
    handelTotalTaxRefund();
  };

  //是否计算房产税
  const changeCalculatePropertyTaxRefund = (e) => {
    const calculate_property_tax = e.target.checked;
    if (calculate_property_tax) {
      const { enterprise_property_tax_refund_rate, total_property_tax } = info;
      const tmp_value =
        (enterprise_property_tax_refund_rate / 100) * total_property_tax;
      const enterprise_property_tax_refund = formatDecimal(tmp_value, 2);
      info.enterprise_property_tax_refund = enterprise_property_tax_refund;
      setInfo({
        ...info,
        enterprise_property_tax_refund,
      });
    }
    isCalculatePropertytax = calculate_property_tax;
    setIsCalculatePropertytax(calculate_property_tax);
    const value = calculate_property_tax ? "1" : "0";
    info.calculate_enterprise_property_tax_refund = value;
    setInfo({
      ...info,
      calculate_enterprise_property_tax_refund: value,
    });
    handelTotalTaxRefund();
  };

  //改变房产税
  const changePropertyTax = (value) => {
    const enterprise_property_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_property_tax } = info;
    const tmp_value = enterprise_property_tax_refund_rate * total_property_tax;
    const enterprise_property_tax_refund = formatDecimal(tmp_value, 2);

    info.enterprise_property_tax_refund_rate = value;
    info.enterprise_property_tax_refund = enterprise_property_tax_refund;

    setInfo({
      ...info,
      enterprise_property_tax_refund_rate: value,
      enterprise_property_tax_refund,
    });
    handelTotalTaxRefund();
  };
  const enterpriseRateAndValueList = [
    {
      label: ":增值税扶持比例",
      field: "enterprise_value_added_tax_refund_rate",
      rate: info.enterprise_value_added_tax_refund_rate,
      value: info.enterprise_value_added_tax_refund,
      component: (
        <>
          <Col
            span={12}
            style={{ paddingRight: 20, borderBottom: "1px solid #F0F0F0" }}
          >
            <Row>
              <Col span={12}>
                <div style={{ lineHeight: "32px", textAlign: "right" }}>
                  增值税扶持比(企):
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    disabled={deduction === "agency"}
                    max={100}
                    style={{ width: 80 }}
                    onChange={changeValueAddedTaxRefundRate}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_value_added_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_value_added_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: "本季所得税扶持比例:",
      field: "enterprise_corporate_income_tax_refund_rate",
      rate: info.enterprise_corporate_income_tax_refund_rate,
      value: info.enterprise_corporate_income_tax_refund,
      component: (
        <>
          <Col
            span={12}
            style={{ paddingRight: 20, borderBottom: "1px solid #F0F0F0" }}
          >
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  企业所得税扶持比(企)：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    disabled={deduction === "agency"}
                    onChange={changeCorporateIncomeTaxRefundRate}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_corporate_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_corporate_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: "所得税扶持比例：",
      field: "enterprise_individual_income_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    style={{ paddingRight: 10 }}
                    checked={isCalculatIndividualIncomeTax}
                    onChange={changeCalculateIndividualIncomeTax}
                  ></Checkbox>
                  所得税扶持比(企)：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    disabled={deduction === "agency"}
                    onChange={changeIndividualIncomeTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_individual_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>

            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_individual_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: "房产税扶持比例：",
      field: "enterprise_property_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    style={{ paddingRight: 10 }}
                    checked={isCalculatePropertytax == "1" ? true : false}
                    onChange={changeCalculatePropertyTaxRefund}
                  ></Checkbox>
                  房产税扶持比(企)：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    disabled={deduction === "agency"}
                    onChange={changePropertyTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_property_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_property_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
  ];

  // 处理中介的数据

  const handelAgencyTotalTaxRefund = () => {
    const {
      agency_value_added_tax_refund,
      agency_corporate_income_tax_refund,
      agency_individual_income_tax_refund,
      agency_property_tax_refund,
    } = info;

    var tmp_total_agency_tax_refund_in_quarter = null;
    if (isAgencyCalculatIndividualIncomeTax) {
      if (isAgencyCalculatePropertytax) {
        tmp_total_agency_tax_refund_in_quarter =
          Number(agency_value_added_tax_refund) +
          Number(agency_corporate_income_tax_refund) +
          Number(agency_individual_income_tax_refund) +
          Number(agency_property_tax_refund);
      } else {
        tmp_total_agency_tax_refund_in_quarter =
          Number(agency_value_added_tax_refund) +
          Number(agency_corporate_income_tax_refund) +
          Number(agency_individual_income_tax_refund);
      }
    } else {
      if (isAgencyCalculatePropertytax) {
        tmp_total_agency_tax_refund_in_quarter =
          Number(agency_value_added_tax_refund) +
          Number(agency_corporate_income_tax_refund) +
          Number(agency_property_tax_refund);
      } else {
        tmp_total_agency_tax_refund_in_quarter =
          Number(agency_value_added_tax_refund) +
          Number(agency_corporate_income_tax_refund);
      }
    }

    const total_agency_tax_refund_in_quarter = formatDecimal(
      tmp_total_agency_tax_refund_in_quarter,
      2
    );
    info.total_agency_tax_refund_in_quarter = total_agency_tax_refund_in_quarter;
    //这里的扶持额发生了变化
    handelAgencyTax();
    setInfo({
      ...info,
      total_agency_tax_refund_in_quarter,
    });
  };

  const changeAgencyValueAddedTax = (value) => {
    const agency_value_added_tax_refund_rate = value / 100;

    const { total_value_added_tax_in_quarter } = info;
    const tmp_value =
      agency_value_added_tax_refund_rate * total_value_added_tax_in_quarter;
    const agency_value_added_tax_refund = formatDecimal(tmp_value, 2);
    info.agency_value_added_tax_refund_rate = value;
    info.agency_value_added_tax_refund = agency_value_added_tax_refund;
    setInfo({
      ...info,
      agency_value_added_tax_refund_rate: value,
      agency_value_added_tax_refund,
    });

    handelAgencyTotalTaxRefund();
  };

  const changeAgencyCorporateIncomeTax = (value) => {
    const agency_corporate_income_tax_refund_rate = value / 100;

    const { total_corporate_income_tax_in_quarter } = info;
    const tmp_value =
      agency_corporate_income_tax_refund_rate *
      total_corporate_income_tax_in_quarter;
    const agency_corporate_income_tax_refund = formatDecimal(tmp_value, 2);
    info.agency_corporate_income_tax_refund_rate = value;
    info.agency_corporate_income_tax_refund = agency_corporate_income_tax_refund;
    setInfo({
      ...info,
      agency_corporate_income_tax_refund_rate: value,
      agency_corporate_income_tax_refund,
    });
    handelAgencyTotalTaxRefund();
  };

  const changeAgencyIndividualIncomeTax = (value) => {
    const agency_individual_income_tax_refund_rate = value / 100;

    const { total_individual_income_tax } = info;
    const tmp_value =
      agency_individual_income_tax_refund_rate * total_individual_income_tax;
    const agency_individual_income_tax_refund = formatDecimal(tmp_value, 2);
    info.agency_individual_income_tax_refund_rate = value;
    info.agency_individual_income_tax_refund = agency_individual_income_tax_refund;
    setInfo({
      ...info,
      agency_individual_income_tax_refund_rate: value,
      agency_individual_income_tax_refund,
    });
    handelAgencyTotalTaxRefund();
  };

  const changeAgencyPropertyTax = (value) => {
    const agency_property_tax_refund_rate = value / 100;

    const { total_property_tax } = info;
    const tmp_value = agency_property_tax_refund_rate * total_property_tax;
    const agency_property_tax_refund = formatDecimal(tmp_value, 2);
    info.agency_property_tax_refund_rate = value;
    info.agency_property_tax_refund = agency_property_tax_refund;
    setInfo({
      ...info,
      agency_property_tax_refund_rate: value,
      agency_property_tax_refund,
    });
    handelAgencyTotalTaxRefund();
  };

  const changeAgencyCalculatePropertyTax = (e) => {
    const calculate_property_tax = e.target.checked;
    if (calculate_property_tax) {
      const { total_property_tax, agency_property_tax_refund_rate } = info;
      const tmp_value =
        (agency_property_tax_refund_rate / 100) * total_property_tax;
      const agency_property_tax_refund = formatDecimal(tmp_value, 2);

      info.agency_property_tax_refund = agency_property_tax_refund;

      setInfo({
        ...info,
        agency_property_tax_refund,
      });
    }
    isAgencyCalculatePropertytax = calculate_property_tax;
    setIsAgencyCalculatePropertytax(calculate_property_tax);
    const value = calculate_property_tax ? "1" : "0";
    info.calculate_agency_property_tax_refund = value;
    setInfo({
      ...info,
      calculate_agency_property_tax_refund: value,
    });
    handelAgencyTotalTaxRefund();
  };

  const changeAgencyCalculateIndividualIncomeTax = (e) => {
    const calculate_individual_income_tax = e.target.checked;
    if (calculate_individual_income_tax) {
      const {
        total_corporate_income_tax_in_quarter,
        agency_corporate_income_tax_refund_rate,
      } = info;
      const tmp_value =
        (agency_corporate_income_tax_refund_rate / 100) *
        total_corporate_income_tax_in_quarter;
      const agency_corporate_income_tax_refund = formatDecimal(tmp_value, 2);
      info.agency_corporate_income_tax_refund = agency_corporate_income_tax_refund;
      setInfo({
        ...info,
        agency_corporate_income_tax_refund,
      });
    }

    isAgencyCalculatIndividualIncomeTax = calculate_individual_income_tax;
    setIsAgencyCalculatIndividualIncomeTax(calculate_individual_income_tax);

    const value = calculate_individual_income_tax ? "1" : "0";
    info.calculate_agency_individual_income_tax_refund = value;

    setInfo({
      ...info,
      calculate_agency_individual_income_tax_refund: value,
    });
    handelAgencyTotalTaxRefund();
  };

  const agencyRateAndValueList = [
    {
      label: "增值税招商点扶持比例：",
      field: "agency_value_added_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20 ,borderBottom: "1px solid #F0F0F0"}}>
            <Row>
              <Col span={14}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  增值税扶持比(招)：
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeAgencyValueAddedTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.agency_value_added_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={14}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={10}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.agency_value_added_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: " 本季所得税招商点扶持比例：",
      field: "agency_corporate_income_tax_refund_rate",
      component: (
        <>
          <Col
            span={12}
            style={{ paddingRight: 20, borderBottom: "1px solid #F0F0F0" }}
          >
            <Row>
              <Col span={14}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  企业所得税扶持比(招)：
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeAgencyCorporateIncomeTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.agency_corporate_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={14}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={10}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.agency_corporate_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: " 个税招商点扶持比例：",
      field: "agency_individual_income_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={14}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    checked={
                      isAgencyCalculatIndividualIncomeTax == "1" ? true : false
                    }
                    onChange={changeAgencyCalculateIndividualIncomeTax}
                  ></Checkbox>
                  所得税扶持比(招)：
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeAgencyIndividualIncomeTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.agency_individual_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={14}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={10}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.agency_individual_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      label: " 本季房产税招商点扶持比例：",
      field: "agency_property_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={14}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    checked={isAgencyCalculatePropertytax == "1" ? true : false}
                    onChange={changeAgencyCalculatePropertyTax}
                  ></Checkbox>
                  房产税扶持比(招)：
                </div>
              </Col>
              <Col span={10}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeAgencyPropertyTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.agency_property_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={14}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={10}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.agency_property_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
  ];
  const taxList = [
    {
      label: "本季增值税:",
      value: moneyFormat(info.total_value_added_tax_in_quarter),
    },
    {
      label: "本季企业所得税:",
      value: moneyFormat(info.total_corporate_income_tax_in_quarter),
    },
    {
      label: "本季所得税:",
      value: moneyFormat(info.total_individual_income_tax),
    },
    {
      label: "本季房产税:",
      value: moneyFormat(info.total_property_tax),
    },
  ];

  const refundList = [
    {
      labelComponent: "补差:",
      id: "payment_difference",
      unit: "元",
      value: info.payment_difference,
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.payment_difference}
          onChange={changePaymentDifference}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "payment_difference_remark",
      labelComponent: "备注:",
      className: "",
      unit: "",
      value: info.payment_difference_remark,
      type: "string",
      component: (
        <Input
          placeholder={"请输入"}
          value={info.payment_difference_remark}
          onChange={changePaymentDifferenceRemark}
          className={`enterprise-info-input`}
        />
      ),
    },
    {
      id: "payment_on_behalf",
      labelComponent: <Text type="danger">代扣:</Text>,
      className: "reducer",
      value: info.payment_on_behalf,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.payment_on_behalf}
          onChange={changePaymentOnBehalf}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "prepayment",
      labelComponent: <Text type="danger">预付:</Text>,
      className: "reducer",
      value: info.prepayment,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.prepayment}
          onChange={changePrepayment}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "current_balance",
      labelComponent: <Text type="danger">本次预留:</Text>,
      value: info.current_balance,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.current_balance}
          onChange={changeCurrentBalance}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "management_fee",
      labelComponent: <Text type="danger">管理费:</Text>,
      value: info.management_fee,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.management_fee}
          onChange={changeManagementFee}
          className={`enterprise-info-input number`}
        />
      ),
    },
  ];

  //招商点的计算的处理
  const handelAgencyTax = () => {
    const {
      agency_payment_difference, //招商点补差金额
      total_agency_tax_refund_in_quarter, //本季招商点合计应扶持金额
      is_agency_last_statement_balance_added, //是否招商点计算上次余留
      agency_last_statement_balance, //招商点上次余留
      agency_payment_on_behalf, //招商点代扣其他
      agency_prepayment, //招商点预付
    } = info;

    var {
      agency_management_fee, //招商点管理费
      agency_accounts_payable, //招商点本季应扶持金额
      agency_pending_payment, //招商点代付款扶持金额
      agency_current_balance, //招商点本次余留
    } = info;

    let { agencyManagementFee } = managementFee;
    //计算招商点本季应扶持金额
    if (is_agency_last_statement_balance_added == "1") {
      // 计算上次余留

      const tmp_calculation_management_fee =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_last_statement_balance) +
        Number(agency_prepayment);
      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );
      agency_accounts_payable = Number(agency_accounts_payable); //拿到本季应扶持金额

      if (agency_accounts_payable >= 0) {
        //如果本季应扶持金额 >=0
        var tmp_value = Number(agency_accounts_payable % 100);
        agency_current_balance = formatDecimal(tmp_value, 2);
      } else {
        agency_current_balance = 0;
      }

      //这里的默认管理费是agency_management

      if (calculation_management_fee > Number(agencyManagementFee)) {
        //为默认管理费
        agency_management_fee = agencyManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费
        agency_management_fee = calculation_management_fee;
      } else {
        //为0
        agency_management_fee = 0;
      }

      var tmp_agency_accounts_payable =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_last_statement_balance) +
        Number(agency_payment_difference) -
        Number(agency_management_fee) -
        Number(agency_payment_on_behalf) -
        Number(agency_prepayment);
      agency_accounts_payable = formatDecimal(tmp_agency_accounts_payable, 2);

      // const tmp_agency_accounts_payable =
      //   Number(total_agency_tax_refund_in_quarter) +
      //   Number(agency_last_statement_balance) +
      //   Number(agency_payment_difference) -
      //   Number(agency_payment_on_behalf) -
      //   Number(agency_prepayment);
      // agency_accounts_payable = formatDecimal(tmp_agency_accounts_payable, 2);
    }
    //如果不计算上次余留
    else {
      const tmp_calculation_management_fee =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_payment_difference);

      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );

      if (calculation_management_fee > Number(agencyManagementFee)) {
        //默认管理费
        agency_management_fee = agencyManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费

        agency_management_fee = calculation_management_fee;
      } else {
        //为0
        agency_management_fee = 0;
      }

      const tmp_agency_accounts_payable =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_payment_difference) -
        Number(agency_management_fee) -
        Number(agency_payment_on_behalf) -
        Number(agency_prepayment);
      agency_accounts_payable = formatDecimal(tmp_agency_accounts_payable, 2);
    }
    //计算本次招商点本次余留

    var agency_accounts_payable = Number(agency_accounts_payable); //拿到本季招商点应扶持金额
    if (agency_accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(agency_accounts_payable % 100);
      agency_current_balance = formatDecimal(tmp_value, 2);
    } else {
      agency_current_balance = 0;
    }

    //计算招商点本次代付款项
    const tmp_agency_pending_payment =
      agency_accounts_payable - agency_current_balance;
    agency_pending_payment = formatDecimal(tmp_agency_pending_payment, 2);

    // ！！！！！这里有疑问
    info.agency_pending_payment = String(agency_pending_payment);
    info.agency_management_fee = String(agency_management_fee);
    info.agency_accounts_payable = String(agency_accounts_payable);
    info.agency_current_balance = String(agency_current_balance);

    //设置这里的数值页面未同步
    setInfo({
      ...info,
      agency_management_fee,
      agency_accounts_payable: String(agency_accounts_payable),
      agency_current_balance: String(agency_current_balance),
    });
  };
  //更改招商点补差的计算
  const changeAgencyPaymentDifference = (value) => {
    const f_value = value === null ? "0" : String(value);
    const tmp_info = info;
    tmp_info.agency_payment_difference = f_value;
    setInfo({
      ...info,
      agency_payment_difference: f_value,
    });
    handelAgencyTax();
  };
  //更改招商点补差备注 =>
  const changeAgencyPaymentDifferenceRemark = (e) => {
    const value = e.target.value;
    setInfo({
      ...info,
      agency_payment_difference_remark: value,
    });
  };
  //更改招商点代扣
  const changeAgencyPaymentOnBehalf = (value) => {
    const f_value = value === null ? "0" : String(value);
    const tmp_info = info;
    tmp_info.agency_payment_on_behalf = f_value;
    setInfo({
      ...info,
      agency_payment_on_behalf: f_value,
    });
    handelAgencyTax();
  };

  //更改招商点预付
  const changeAgencyPrepayment = (value) => {
    const f_value = value === null ? "0" : String(value);
    const tmp_info = info;
    tmp_info.agency_prepayment = f_value;
    setInfo({
      ...info,
      agency_prepayment: f_value,
    });
    handelAgencyTax();
  };

  //更改招商点预留
  const changeAgencyCurrentBalance = (value) => {
    //需要更改招商点的待付款项
    const { agency_accounts_payable } = info;
    const f_value = value === null ? "0" : String(value);

    const tmp_agency_pending_payment =
      Number(agency_accounts_payable) - Number(f_value);
    const agency_pending_payment = formatDecimal(tmp_agency_pending_payment, 2);
    setInfo({
      ...info,
      agency_pending_payment,
      agency_current_balance: f_value,
    });
  };

  //更改招商点管理费

  const changeTotalAgencyManagementFee = (value) => {
    var f_value = value === null ? 0 : value;

    const { agencyManagementFee } = managementFee;
    if (f_value > Number(agencyManagementFee)) {
      message.error("输入的管理费不能大于剩余管理费");
      f_value = agencyManagementFee;
    }
    f_value = String(f_value);
    info.management_fee = f_value;
    const {
      is_agency_last_statement_balance_added,
      total_agency_tax_refund_in_quarter,
      agency_last_statement_balance,
      agency_payment_difference,
      agency_payment_on_behalf,
      agency_prepayment,
    } = info;
    var {
      agency_accounts_payable,
      agency_current_balance,
      agency_pending_payment,
    } = info;
    //计算应扶持金额

    if (is_agency_last_statement_balance_added === "1") {
      const tmp_accounts_payable =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_last_statement_balance) +
        Number(agency_payment_difference) -
        Number(f_value) - //管理费
        Number(agency_payment_on_behalf) -
        Number(agency_prepayment);

      agency_accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    } else {
      const tmp_accounts_payable =
        Number(total_agency_tax_refund_in_quarter) +
        Number(agency_payment_difference) -
        Number(f_value) - //管理费
        Number(agency_payment_on_behalf) -
        Number(agency_prepayment);

      agency_accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    }
    //计算本次余留
    agency_accounts_payable = Number(agency_accounts_payable); //拿到本季应扶持金额
    if (agency_accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(agency_accounts_payable % 100);
      agency_current_balance = formatDecimal(tmp_value, 2);
    } else {
      agency_current_balance = 0;
    }

    //计算本次代付款项

    const tmp_pending_payment =
      agency_accounts_payable - agency_current_balance;
    agency_pending_payment = formatDecimal(tmp_pending_payment, 2);

    info.agency_pending_payment = String(agency_pending_payment);
    info.agency_management_fee = String(f_value);
    info.agency_accounts_payable = String(agency_accounts_payable);
    info.agency_current_balance = String(agency_current_balance);
    //计算代付款
    setInfo({
      ...info,
      agency_management_fee: f_value,
      agency_accounts_payable: String(agency_accounts_payable),
      agency_current_balance: String(agency_current_balance),
    });
  };
  const agencyRefundList = [
    {
      id: "agency_payment_difference",
      labelComponent: "招商点补差:",
      value: info.agency_payment_difference,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.agency_payment_difference}
          onChange={changeAgencyPaymentDifference}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "agency_payment_difference_remark",
      labelComponent: "招商点补差备注:",
      value: info.agency_payment_difference_remark,
      unit: "",
      component: (
        <Input
          placeholder={"请输入"}
          value={info.agency_payment_difference_remark}
          onChange={changeAgencyPaymentDifferenceRemark}
          className={`enterprise-info-input`}
        />
      ),
    },
    {
      id: "agency_payment_on_behalf",
      labelComponent: "招商点代扣：",
      value: info.agency_payment_on_behalf,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.agency_payment_on_behalf}
          onChange={changeAgencyPaymentOnBehalf}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "agency_prepayment",
      labelComponent: "招商点预付:",
      value: info.agency_prepayment,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.agency_prepayment}
          onChange={changeAgencyPrepayment}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "agency_current_balance",
      labelComponent: "招商点本次预留:",
      value: info.agency_current_balance,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.agency_current_balance}
          onChange={changeAgencyCurrentBalance}
          className={`enterprise-info-input number`}
        />
      ),
    },
    // 新增管理费
    {
      id: "total_agency_management_fee",
      labelComponent: "管理费(招):",
      value: info.agency_management_fee,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.agency_management_fee}
          onChange={changeTotalAgencyManagementFee}
          className={`enterprise-info-input number`}
        />
      ),
    },
  ];

  const changeShowType = (e) => {
    setShowType(e.target.value);
  };

  //更改招商点是否支付成功   =>
  const changeIsAgencyPaymentSuccessed = (e) => {
    const checked = e.target.checked;
    setIsAgencyPaymentSuccessed(checked);
    const value = checked ? "1" : "0";
    setInfo({
      ...info,
      is_agency_payment_succeeded: value,
    });
  };

  //更改招商点实际支付日期 =》
  const changeAgencyPaidAt = (date, dateString) => {
    setAgencyPaidAt(dateString);
    const agency_paid_at = dateString.replace(/-/g, "");
    setInfo({
      ...info,
      agency_paid_at,
    });
  };
  //改变招商点实际支付金额 =>
  const changeActualAgencyAmountPaid = (value) => {
    const f_value = value === null ? "0" : String(value);
    const actual_agency_amount_paid = f_value;
    setInfo({
      ...info,
      actual_agency_amount_paid,
    });
  };
  const confirmUpdata = () => {
    //eip.eip_agency_administered_enterprises_tax_refund_statement_record.update
    handleOk(info);
  };
  return (
    <>
      <div
        className="enterprise-button-group"
        style={{ textAlign: "right", marginTop: "10px" }}
      >
        <Radio.Group value={showType} onChange={changeShowType} size="small">
          <Radio.Button className="left-radio-button" value="enterprise">
            企业
          </Radio.Button>
          <Radio.Button className="right-radio-button" value="agency">
            招商点
          </Radio.Button>
        </Radio.Group>
      </div>

      <div>
        <div>
          <Row>
            <Col span={18}>
              <Row>
                {taxList.map((item, index) => {
                  return (
                    <Col span={12} key={index}>
                      <Row>
                        <Col span={12} style={{ textAlign: "right" }}>
                          {item.label}
                        </Col>
                        <Col
                          span={12}
                          style={{ textAlign: "right" }}
                          className="tax-refund-modal-header-item-value"
                        >
                          {item.value === null ? "0" : item.value}元
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={6}>
              <div>
                本季税额合计:
                <span className="tax-refund-modal-header-total-value">
                  {(
                    Number(info.total_value_added_tax_in_quarter) +
                    Number(info.total_corporate_income_tax_in_quarter)
                  ).toFixed(2)}
                  元
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="agency-enterprise-info-modal-container">
        <div style={{ display: showType == "enterprise" ? "" : "none" }}>
          <Row style={{ height: 120 }}>
            <Col span={18}>
              <Row>
                {enterpriseRateAndValueList.map((item) => {
                  return (
                    <React.Fragment key={item.field}>
                      {item.component}
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
            <Col
              className="tax-refund-total"
              span={6}
              style={{ height: 120, paddingLeft: 20, textAlign: "center" }}
            >
              <div>本季扶持额合计:</div>
              <span className="tax-refund-modal-header-item-value">
                {moneyFormat(info.total_enterprise_tax_refund_in_quarter)}元
              </span>
            </Col>
          </Row>
          <Divider style={{ margin: "20px 0 10px 0" }} />
          <div
            style={{
              height: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ height: 20, lineHeight: "20px" }}>
              上季余留：{moneyFormat(info.last_statement_balance)}
            </div>
            <div style={{ height: 20, lineHeight: "20px", textAlign: "right" }}>
              <div style={{ textAlign: "right" }}>
                <Checkbox
                  onChange={changeIsLastStatement}
                  checked={isLastStatement}
                >
                  计入上次余额
                </Checkbox>
              </div>
            </div>
          </div>
          <Row className="agency-refund-list" style={{ marginTop: "10px" }}>
            {refundList.map((item) => {
              return (
                <Col
                  className="agency-refund-list-item"
                  key={item.id}
                  span={12}
                  style={{
                    height: 28,
                    marginBottom: "10px",
                    lineHeight: "28px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="info-text">{item.labelComponent}</div>
                  <div className="info-input">
                    {item.component}
                    <span>{item.unit}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Divider style={{ margin: "10px 0" }} />
          <Row style={{ marginTop: "10px" }}>
            <Col
              span={8}
              style={{
                height: 28,
                lineHeight: "28px",
              }}
            >
              应扶持金额：{moneyFormat(info.accounts_payable)} 元
            </Col>
            <Col
              span={8}
              style={{ height: 28, lineHeight: "28px", textAlign: "center" }}
            >
              待付金额：{moneyFormat(info.pending_payment)}元
            </Col>
            <Col
              span={8}
              style={{ height: 28, lineHeight: "28px", textAlign: "right" }}
            >
              实付总额：{moneyFormat(info.actual_amount_paid)}元
            </Col>

            <Col
              span={12}
              style={{
                paddingRight: "20px",
                margin: "10px 0px 0px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="info-label">户名：</div>
              <Input
                disabled={true}
                value={info.bank_account_name}
                className="info-input"
                onChange={changeBankName}
              />
            </Col>
            <Col
              span={12}
              style={{
                paddingRight: "20px",
                margin: "10px 0px 0px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="info-label">银行网点：</div>
              <Input
                value={info.bank_branch}
                className="info-input"
                onChange={changeBankBranch}
              />
            </Col>

            {/*  */}

            <Col
              span={12}
              style={{
                paddingRight: "20px",
                margin: "10px 0px 0px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="info-label">开户行：</div>

              <Input
                value={info.bank_name}
                className="info-input"
                onChange={changeBankName}
              />
            </Col>
            <Col
              span={12}
              style={{
                paddingRight: "20px",
                margin: "10px 0px 0px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="info-label">银行账号：</div>
              <Input
                value={info.bank_account_number}
                className="info-input"
                onChange={changeBankAccountNumber}
              />
            </Col>

            <Col
              span={12}
              style={{
                paddingRight: "20px",
                margin: "10px 0px 0px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="info-label">银行行联号：</div>
              <Input
                value={info.bank_code}
                className="info-input"
                onChange={changeBankCode}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "10px 0" }} />

          <Row style={{ marginTop: "10px" }}>
            <Col span={24} style={{ height: 28, lineHeight: "28px" }}>
              <Checkbox
                checked={isPaymentSuccessed}
                onChange={changeIsPaymentSuccessed}
              >
                是否支付成功
              </Checkbox>
            </Col>
            <Col
              span={12}
              className="park-refund-tax-paid-info"
              style={{ height: 28, lineHeight: "28px" }}
            >
              <span className="info-text">支付日期：</span>

              <DatePicker
                size="middle"
                allowClear={false}
                disabled={!isPaymentSuccessed}
                value={moment(paidAt, "YYYY-MM-DD")}
                onChange={changePaidAt}
              />
            </Col>
            <Col
              className="park-refund-tax-paid-info"
              span={12}
              style={{ height: 28, lineHeight: "28px" }}
            >
              <span className="info-text">实际支付：</span>
              <InputNumber
                size="large"
                precision={2}
                disabled={!isPaymentSuccessed}
                value={info.actual_amount_paid}
                onChange={changeActualAmountPaid}
                className="info-input"
              />
            </Col>
          </Row>
        </div>

        <div style={{ display: showType == "agency" ? "" : "none" }}>
          <Row style={{ height: 125 }}>
            <Col span={18}>
              <Row>
                {agencyRateAndValueList.map((item) => {
                  return (
                    <React.Fragment key={item.field}>
                      {item.component}
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
            <Col
              className="tax-refund-total"
              span={6}
              style={{ height: 120, paddingLeft: 20, textAlign: "center" }}
            >
              <div>本季招商点扶持额合计:</div>
              <span className="tax-refund-modal-header-item-value">
                {moneyFormat(info.total_agency_tax_refund_in_quarter)}元
              </span>
            </Col>
          </Row>
          <Divider style={{ margin: "10px 0" }} />
          <div
            style={{
              height: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ height: 20, lineHeight: "20px" }}>
              上季招商点余留：{moneyFormat(info.agency_last_statement_balance)}
            </div>
            <div style={{ height: 20, lineHeight: "20px", textAlign: "right" }}>
              <div style={{ textAlign: "right" }}>
                <Checkbox
                  checked={isAgencyLastStatement}
                  onChange={changeIsAgencyLastStatement}
                >
                  招商点计入上次余留
                </Checkbox>
              </div>
            </div>
          </div>
          <Row className="agency-refund-list" style={{ marginTop: "10px" }}>
            {agencyRefundList.map((item) => {
              return (
                <Col
                  className="agency-refund-list-item"
                  key={item.id}
                  span={12}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="info-text">{item.labelComponent}</span>
                  <div className="info-input">
                    {item.component}
                    <span>{item.unit}</span>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Divider style={{ margin: "10px 0" }} />
          <Row style={{ marginTop: "10px" }}>
            <Col span={8} style={{ height: 40, lineHeight: "40px" }}>
              招商点应扶持金额：{moneyFormat(info.agency_accounts_payable)} 元
            </Col>
            <Col
              span={8}
              style={{ height: 40, lineHeight: "40px", textAlign: "center" }}
            >
              招商点待付金额：{moneyFormat(info.agency_pending_payment)}元
            </Col>
            <Col
              span={8}
              style={{ height: 40, lineHeight: "40px", textAlign: "right" }}
            >
              招商点实付总额：{moneyFormat(info.actual_agency_amount_paid)}元
            </Col>
          </Row>
          <Divider style={{ margin: "10px 0" }} />

          <Row style={{ margin: "10px 0" }}>
            <Col span={24} style={{ height: 28, lineHeight: "28px" }}>
              <Checkbox
                checked={isAgencyPaymentSuccessed}
                onChange={changeIsAgencyPaymentSuccessed}
              >
                是否支付成功
              </Checkbox>
            </Col>

            <Col
              span={12}
              className="park-refund-tax-paid-info"
              style={{ height: 28, lineHeight: "28px" }}
            >
              <span className="info-text">招商点支付日期：</span>

              <DatePicker
                allowClear={false}
                value={moment(agencyPaidAt, "YYYY-MM-DD")}
                disabled={!isAgencyPaymentSuccessed}
                onChange={changeAgencyPaidAt}
              />
            </Col>
            <Col
              className="park-refund-tax-paid-info"
              span={12}
              style={{ height: 28, lineHeight: "28px" }}
            >
              <span className="info-text">招商点实际支付：</span>
              <InputNumber
                precision={2}
                value={info.actual_agency_amount_paid}
                className="rate-info-input"
                disabled={!isAgencyPaymentSuccessed}
                onChange={changeActualAgencyAmountPaid}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="agency-enterprise-info-modal-container"
        style={{ padding: 10, width: 820 }}
      >
        <div>
          <TextArea
            onChange={changeRemark}
            placeholder="请填写备注"
            value={info.remark}
          />
        </div>
      </div>
      <Divider style={{ margin: "10px 0" }} />
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={confirmUpdata} type="primary">
            确定更改
          </Button>
        </Space>
      </div>
    </>
  );
};

export default EditEnterpriseInfo;
