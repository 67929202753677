import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux'
import { Button, Divider, message } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment'
import { paasql } from '@api'
import actionType from '@actions/actionType'
import {
  BackToIcon,
} from '@icon'
const PolicyOnlineDetail = (props) => {
  const { PolicyOnlineReducer, dispatch } = props
  const { alreadyGet, nextPoliceId, previousPoliceId, previousDisabled, nextDisabled } = PolicyOnlineReducer

  const [content, setContent] = useState('')
  const policeId = props.match.params.id


  const [title, setTitle] = useState('')
  const [time, setTime] = useState('')
  const [policeList, setPoliceList] = useState([])
  useEffect(
    () => {
      const cmd_data = {
        id: policeId
      }
      if (!alreadyGet) {
      }

      paasql("eip.eip_cms_doc.query_by_id", cmd_data).then(data => {
        const result = data.result
        //处理
        if (result.length != 0) {
          const { content, name, cp_timestamp } = result[0]
          //处理面包屑导航
          const navArr = ['在线政策', '政策内容', name]
          dispatch({
            type: 'SET_BREADCRUMB_LIST',
            value: navArr
          })

          //处理页面显示
          const cmd_data = {
            eip_enterprise_id: "fb387b7b180f47b99f3f22feaad5946a"
          }
          paasql("eip.eip_cms_doc.list_by_id", cmd_data).then(policeList => {
            if (policeList.error == null) {
              const result = policeList.result


              //去重
              var resultWithFilter = result.filter(function (element, index, self) {
                return self.findIndex(el => el.id == element.id) === index
              })

              //排序
              const policeListWithSort = resultWithFilter.sort(function (first, last) {
                return Number(last.cp_timestamp) - Number(first.cp_timestamp)
              })



              //设置redux的数据
              dispatch({
                type: actionType.SET_POLICE_ONLINE_LIST,
                value: policeListWithSort
              })
              dispatch({
                type: actionType.SET_NEXT_POLICE_ONLINE_ID,
                value: policeId
              })

              setPoliceList(policeListWithSort)
            } else {
              message.error(policeList.error.message)
            }
          })
          //判断archived
          setContent(content)
          setTitle(name)
          //处理日期
          const time = moment(cp_timestamp / 1000).format('YYYY-MM-DD HH:mm')
          setTime(time)
        }

      })
    }, [policeId])



  const goPreviousPolice = () => {
    props.history.push(`/policy-online/detail/${previousPoliceId}`)

  }
  const goNextPolice = () => {

    props.history.push(`/policy-online/detail/${nextPoliceId}`)
  }




  const backPoliceList = () => {
    props.history.push('/policy-online')
  }
  return (
    <div className="police-detail-container" >
      <div
        className='police-detail-header'
      >
        <div>
          <span style={{ paddingRight: 20 }}  >
            <LeftOutlined /> <a target="_blank" disabled={previousDisabled} onClick={goPreviousPolice} rel="noopener noreferrer"> 上一篇</a>
          </span>
          <span >
            <a target="_blank" disabled={nextDisabled} onClick={goNextPolice} rel="noopener noreferrer">下一篇 </a><RightOutlined />
          </span>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button.Group>
            <Button
              icon={<BackToIcon />}
              size='large'
              onClick={backPoliceList}>
              返回
            </Button>
          </Button.Group>

        </div>
      </div>

      <div className='police-detail-container'>
        <div className='police-detail-container-title'>
          <div>
            {title}
          </div>
          <div className='police-detail-container-time'>
            {time}
          </div>
        </div>
        <Divider>
          <div
            className='divider-container'
          >

          </div>
        </Divider>

        <div
          dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </div>

    </div>

  );
}



const mapStateToProps = (state) => {
  return {
    PolicyOnlineReducer: state.PolicyOnline
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PolicyOnlineDetail);