import React, { useState, useEffect, createContext } from 'react';
import { Modal, Steps, Space, Button } from 'antd'
import { readExcelHeader, enterpriseRefundRateUpdate } from '@api'
import UploadFile from './UploadFile'
import SelectHeader from './SelectHeader'
import FieldMapping from './FieldMapping'
import FieldPurpose from './FieldPurpose'
import ImportResult from './ImportResult'
export const ReachableContext = createContext();

const { Step } = Steps;



const UploadModel = (props) => {
  //配置
  const steps = [
    {
      title: '第1步',
      description: "上传文件",
      content: <UploadFile />
    },
    {
      title: '第2步',
      description: "选择表头",
      content: <SelectHeader />
    },
    {
      title: '第3步',
      description: "建立映射",
      content: <FieldMapping />
    },
    {
      title: '第4步',
      description: "选择字段用途",
      content: <FieldPurpose />
    },
    {
      title: '第5步',
      description: "导出结果",
      content: <ImportResult />
    },
  ];
  const {
    visible,
    uploadFileModalChange,
    taxRefundId,
    parkId,
    reflushData
  } = props
  const [modalVisable, setModalVisable] = useState(false)
  const [current, setCurrent] = useState(0)//当前页面
  const [importLoading, setImportLoading] = useState(false)
  const [importModalVisable, setImportModalVisable] = useState(false)
  //请求有关的数据
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [startLine, setStartLine] = useState('1')//这里的开始行是指数据行的开始行，不是表格的开始行
  const [originalRawDataId, setOriginalFileId] = useState(null)
  const [page, setPage] = useState('0')
  const [match, setMatch] = useState({})
  //映射字段
  const [relationMap, setRelationMap] = useState(null)



  //所有字段的列表
  const [fieldList, setFieldList] = useState([])
  const [importResultData, setImportResultData] = useState([])
  useEffect(() => {
    setModalVisable(visible)
  }, [visible, uploadFileModalChange])

  //去选择表头页面
  const goSelectHeader = () => {
    setCurrent(1)
  }

  //从选择表头页回到上传文件页
  const backToUploadFile = () => {
    setCurrent(0)
  }


  //处理选择表头的情况
  const choseHeader = (request) => {
    const { endLine, page, originalRawDataId } = request
    readExcelHeader(request).then(data => {
      const tableHeaderData = data.table_headers
      setTableHeaderData(tableHeaderData)
      //处理导入的所需参数
      setStartLine(Number(endLine))//结束行
      setOriginalFileId(originalRawDataId)
      setPage(page)
      setCurrent(2)
    })
  }

  //处理回到选择表头页面
  const backToSelectHeader = () => {
    setCurrent(1)
  }

  //去选择字段用途页面//这里需要
  const goToFieldPurpose = (request) => {
    const { relationMap, fieldList } = request
    setFieldList(fieldList)
    setRelationMap(relationMap)
    setCurrent(3)
    //这里需要处理字段用途页面所需的数据
    const write_str = []

    //整理好已建立映射的列表
    for (const key in relationMap) {
      for (let i = 0; i < tableHeaderData.length; i++) {
        if (tableHeaderData[i].column == Number(relationMap[key])) {
          const attr_obj = {}
          attr_obj.display_name = tableHeaderData[i].name
          attr_obj.field = key
          attr_obj.column = tableHeaderData[i].column
          write_str.push(attr_obj)
        }
      }
    }


  }

  //返回字段映射页
  const backToFieldMapping = () => {
    setCurrent(2)
  }

  //导入数据的模态框处理
  const importData = (request) => {
    const { match } = request
    setMatch(match)
    setImportModalVisable(true)

  }

  //隐藏导入的确定模态框
  const hideImportModal = () => {
    setImportModalVisable(false)
  }

  //这里是处理导入接口的地方
  const confirmImport = () => {
    const cmd_data = {
      "eip_park_id": parkId,
      "refund_statement_id": taxRefundId,
      "original_file_id": originalRawDataId,
      "related_map": relationMap,
      "start_line": startLine,
      "page": Number(page),
      "match": match
    }
    setImportLoading(true)
    enterpriseRefundRateUpdate(cmd_data).then(data => {

      const { importResult } = data
      setImportResultData(importResult)
      setImportModalVisable(false)
      setCurrent(4)
    }).finally(() => {
      setImportLoading(false)
    })
  }
  const closeModal = () => {
    setModalVisable(false)
    setCurrent(0)
    reflushData()
  }
  return (
    <>
      <ReachableContext.Provider
        value={{
          parkId,
          goSelectHeader,
          taxRefundId,
          backToUploadFile,
          choseHeader,
          tableHeaderData,
          backToSelectHeader,
          goToFieldPurpose,
          fieldList,
          backToFieldMapping,
          relationMap,
          importData,
          importResultData
        }}>

        <Modal
          title="导入扶持点"
          style={{ maxHeight: '800px', overflow: 'auto' }}
          maskClosable={false}
          width={1000}
          centered={true}
          footer={null}
          visible={modalVisable}
          onCancel={closeModal}
        >

          <Steps progressDot current={current}>
            {
              steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} />
              ))
            }
          </Steps>
          <div className="steps-content">
            {
              steps[current].content
            }
          </div>

        </Modal>

        <Modal
          closable={false}
          visible={importModalVisable}
          footer={null}
          title="确认导入支付核对表"
        >
          <div style={{ textAlign: 'right' }}>
            <Space>
              {
                importLoading ? <></> :
                  <Button onClick={hideImportModal}>
                    取消
              </Button>
              }

              <Button
                type='primary'
                loading={importLoading}
                onClick={confirmImport}
              >
                {
                  importLoading ? "正在上传中..." : '确认上传'
                }
              </Button>
            </Space>

          </div>
        </Modal>
      </ReachableContext.Provider>

    </>
  );
}
export default UploadModel;