import React, { useState, useEffect } from "react";

import {
  Button,
  Input,
  Col,
  Row,
  Space,
  Divider,
  message,
  Modal,
  Typography,
  Select,
} from "antd";
import { CaretDownOutlined, IssuesCloseOutlined } from "@ant-design/icons";

import { paasql, queryEnterpriseInfo } from "@api";

const { Text } = Typography;

const { Option } = Select;

const primaryIdSchemaList = [
  {
    schema: "cp_staff",
    name: "园区工作人员",
    method: "eip.cp_staff.list",
  },
  {
    schema: "eip_enterprise",
    name: "企业",
    method: "eip.cp_staff.list1",
  },
  {
    schema: "eip_investment_agency",
    name: "招商中介",
    method: "eip.cp_staff.list2",
  },
  {
    schema: "eip_investment_agency_staff",
    name: "招商工作人员",
    method: "eip.eip_investment_agency_staff.list",
  },
];

const AccountDetail = (props) => {
  const { accountId, reflushData } = props;
  const [accountInfo, setAccountInfo] = useState({});

  const [paasportList, setPassportList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editAccountObj, setEditAccountObj] = useState({});
  const [editPassportObj, setEditPassportObj] = useState({});
  const [createPassportObj, setCreatePassportObj] = useState(null);

  const [roleString, setRoleSrtring] = useState("");

  const [roleAttributeList, setRoleAttributeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleArr, setRoleArr] = useState([]);

  const [roleItemArr, setRoleItemArr] = useState([]);
  useEffect(() => {
    getDataById();
    getPassportDataById();
    setIsEditing(false);
    paasql("eip.cp_role.list").then((data) => {
      const result = data.result;
      setRoleList(result);
    });
  }, [accountId]);

  const getDataById = () => {
    if (accountId == null) {
      return;
    }
    const cmd_data = {
      cp_account_id: accountId,
    };
    paasql("eip.cp_account_and_role.list", cmd_data).then((data) => {
      if (data.error == null) {
        const result = data.result[0];
        setAccountInfo(result);
        //处理角色列表
        const { role_attribute } = result;
        const role_arr = [];
        const role_id_arr = [];
        for (let i = 0; i < role_attribute.length; i++) {
          role_id_arr.push(role_attribute[i].cp_role_id);
          role_arr.push(role_attribute[i].cp_role_name);
        }
        //显示页面的角色显示
        var role_string = role_arr.join();
        setRoleSrtring(role_string);
        setRoleArr(role_id_arr);
        //处理角色的选中状态
        setRoleAttributeList(role_attribute);
      } else {
      }
    });
  };

  //通过id查找工作人员
  const getPassportDataById = () => {
    const cmd_data = {
      cp_account_id: accountId,
    };
    paasql("eip.cp_passport.list", cmd_data).then((data) => {
      const result = data.result;
      setPassportList(result);
    });
  };

  //编辑账号
  const editAccount = () => {
    setEditAccountObj(accountInfo);
    setIsEditing(true);
  };

  //取消编辑账号
  const cancelEdit = () => {
    setEditAccountObj({});
    setIsEditing(false);
  };

  //todo 保存编辑的账户
  const saveEdit = () => {
    if (roleItemArr.length == 0) {
      // message.error('角色是必须')
      // return
    }

    const { id, name, nickname } = editAccountObj;

    if ((name == null || name == "", name == undefined)) {
      message.error("名称是必须的");
      return;
    }
    const cmd_data = {
      object: {
        id,
        name,
        nickname: nickname,
      },
    };
    paasql("eip.cp_account.update", cmd_data).then((data) => {
      if (data.error == null) {
        message.success("保存成功");
        //更新角色信息
        const tm = roleAttributeList;
        const tmpRoleItemArr = roleItemArr;
        const createArr = [];
        const delArr = [];

        //要拿到多的
        //要找到少的

        for (let j = 0; j < tm.length; j++) {
          //先筛选出少了的。
          var isExist = false;
          for (let i = 0; i < tmpRoleItemArr.length; i++) {
            if (tmpRoleItemArr[i].value === tm[j].cp_role_id) {
              isExist = true;
              break;
            } else {
            }
          }
          if (!isExist) {
            delArr.push(tm[j]);
          }
        }

        // 多出来的
        for (let i = 0; i < tmpRoleItemArr.length; i++) {
          var isExist = false;
          for (let j = 0; j < tm.length; j++) {
            if (tmpRoleItemArr[i].value == tm[j].cp_role_id) {
              isExist = true;
              break;
            } else {
            }
          }
          if (!isExist) {
            createArr.push(tmpRoleItemArr[i]);
          }
        }

        //筛出来当前和以前的不同
        //多的创建，

        const create_objects = [];
        for (let i = 0; i < createArr.length; i++) {
          const obj = new Object();
          obj.cp_role_name = createArr[i].key;
          obj.cp_role_id = createArr[i].value;
          obj.name = editAccountObj.name;
          obj.cp_account_id = editAccountObj.id;
          create_objects.push(obj);
        }

        const cmd_data = {
          new_objects: create_objects,
        };

        const del_objects = [];

        for (let i = 0; i < delArr.length; i++) {
          const obj = new Object();
          obj.id = delArr[i].id;
          del_objects.push(obj);
        }
        const cmd_data1 = {
          del_objects,
        };

        paasql("eip.cp_account_m2m_cp_role.batch_create", cmd_data).then(
          (data) => {
            if (data.error == null) {
              paasql("eip.cp_account_m2m_cp_role.batch_delete", cmd_data1).then(
                (data) => {
                  if (data.error == null) {
                    getDataById(editAccountObj.id);
                  } else {
                    message.error(data.error.message);
                  }
                }
              );
            } else {
              message.error(data.error.message);
            }
          }
        );
      } else {
        message.error(data.error.message);
      }
    });
    setIsEditing(false);
  };

  //done
  const delAccount = () => {
    Modal.confirm({
      title: "删除账号",
      content: (
        <span>
          本操作将删除所选账号(<Text type="danger">{accountInfo.name}</Text>
          )和关联钥匙串，删除后不可恢复，是否继续？
        </span>
      ),
      onOk() {
        confirmDelAccount();
      },
    });
  };
  const confirmDelAccount = () => {
    const cmd_data = {
      cp_account_id: accountId,
    };
    paasql("eip.cp_account.delete_account_and_cp_passport", cmd_data).then(
      (data) => {
        if (data.error == null) {
          message.success("删除成功");
          reflushData();
        } else {
          message.error(data.error.message);
        }
      }
    );
  };

  //编辑
  const editPassport = (e, item, index) => {
    if (editPassportObj == null) {
      message.error("有一个未保存的项");
      return;
    }
    setEditPassportObj({
      ...item,
      new_id: item.id,
    });

    const tmpPassportList = paasportList;
    for (let i = 0; i < tmpPassportList.length; i++) {
      if (i == index) {
        tmpPassportList[i].isEditing = true;
      } else {
        tmpPassportList[i].isEditing = false;
      }
    }
    setPassportList([...tmpPassportList]);
  };

  //删除钥匙串
  const delPassport = (item) => {
    Modal.confirm({
      title: "删除钥匙串",
      content: (
        <span>
          将删除所选钥匙串(<Text type="danger">{item.id}</Text>
          )，不可恢复，是否继续？
        </span>
      ),
      onOk() {
        confirmDelPassport(item);
      },
    });
  };

  //确认删除
  const confirmDelPassport = (item) => {
    const { id } = item;
    const cmd_data = {
      id,
    };
    paasql("eip.cp_passport.destroy", cmd_data).then((data) => {
      if (data.error == null) {
        message.success("删除成功");
        getPassportDataById();
      } else {
        message.error(data.error.message);
      }
    });
  };

  //取消编辑
  const cancelEditPassport = (item, index) => {
    if (item.isCreate) {
      //说明是新建
      //删除掉一个
      const tmpPassportList = paasportList;
      const [first, ...reast] = tmpPassportList;
      setPassportList([...reast]);
      setCreatePassportObj(null);
      return;
    }
    const tmpPassportList = paasportList;
    for (let i = 0; i < tmpPassportList.length; i++) {
      tmpPassportList[i].isEditing = false;
    }
    setPassportList([...tmpPassportList]);
  };

  //改变钥匙串密码
  const changeHashedPassport = (e) => {
    const value = e.target.value;
    setEditPassportObj({
      ...editPassportObj,
      hashed_password: value,
    });
  };

  //改变员工的姓名
  const changePassportId = (e) => {
    const value = e.target.value;
    setEditPassportObj({
      ...editPassportObj,
      new_id: value,
    });
  };

  //保存编辑的信息
  const saveEditPassport = (item) => {
    if (item.isCreate) {
      //说明是新建
      const { new_id, hashed_password } = editPassportObj;

      if (new_id == undefined || new_id == "" || new_id == null) {
        message.error("名字是必填项，请先填写再保存");
        return;
      }
      const cmd_data = {
        object: {
          cp_account_id: accountId,
          hold_id: new_id,
        },
        mechanism: "cp_passport",
      };

      paasql("eip.cp_passport.create", cmd_data).then((data) => {
        if (data.error == null) {
          const { id } = data.result;
          const cmd_data = {
            id,
            password: hashed_password,
          };
          paasql("cp.passport.admin_change_password", cmd_data).then((data) => {
            if (data.error == null) {
              getPassportDataById();
              setCreatePassportObj(null);
              setEditPassportObj({});
              message.success("保存成功");
            } else {
              message.error(data.error.message);
            }
          });
        } else {
          if (data.error.message === 500) {
            message.error("创建用户失败！");
          }
          message.error(data.error.message);
        }
      });
      return;
    }

    //cp.passport.admin.update_cp_passport_id
    const { id, new_id, hashed_password } = editPassportObj;

    const cmd_data = {
      id,
      new_id,
    };

    if (new_id === id) {
      const cmd_data = {
        id,
        password: hashed_password,
      };
      paasql("cp.passport.admin_change_password", cmd_data).then((data) => {
        if (data.error == null) {
          getPassportDataById();
          setEditPassportObj({});

          message.success("保存成功");
        } else {
          message.error(data.error.message);
        }
      });
      return;
    }
    paasql("cp.passport.admin.update_cp_passport_id", cmd_data).then((data) => {
      if (data.error == null) {
        // message.success('保存成功')
        getPassportDataById();
        setEditPassportObj({});
        //更新password

        const { id } = data.result;

        ///cp.passport.admin_change_password
      } else {
        const messageError = data.error.message;
        if (data.error.code == 500) {
          message.error("更新用户信息失败！");
        }
        message.error(data.error.message);
      }
    });
  };

  const addPassport = () => {
    if (createPassportObj !== null) {
      message.error("有一个未保存的项");
      return;
    }
    const createPassportOb = {
      isEditing: true,
      isCreate: true,
    };
    setCreatePassportObj({
      ...createPassportOb,
    });

    const tmpPassportList = paasportList;
    for (let i = 0; i < tmpPassportList.length; i++) {
      tmpPassportList[i].isEditing = false;
    }

    setPassportList([createPassportOb, ...tmpPassportList]);
  };

  const changeName = (e) => {
    const value = e.target.value;
    setEditAccountObj({
      ...editAccountObj,
      name: value,
    });
  };

  const changeNickName = (e) => {
    const value = e.target.value;
    setEditAccountObj({
      ...editAccountObj,
      nickname: value,
    });
  };
  const changeRole = (value, option) => {
    console.log(value, option);
    if (value.length >= 3) {
      message.error("最多只能选择两个角色");
      return;
    }
    setRoleArr(value);
    setRoleItemArr(option);
  };

  const showList = [
    {
      label: "名称",
      value: accountInfo.name,
      editComponent: (
        <Input
          className="input"
          size="large"
          value={editAccountObj.name}
          onChange={changeName}
        />
      ),
    },
    {
      label: "昵称",
      value: accountInfo.nickname,
      editComponent: (
        <Input
          className="input"
          size="large"
          value={editAccountObj.nickname}
          onChange={changeNickName}
        />
      ),
    },
    {
      label: "角色",
      value: roleString,
      editComponent: (
        <Select
          className="select"
          type="select"
          mode="multiple"
          value={roleArr}
          onChange={changeRole}
          style={{ width: "100%" }}
        >
          {roleList.map((item) => {
            return (
              <Option key={item.name} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
  ];

  return (
    <div>
      <div className="account-detail-nav">
        <div className="account-detail-title">基本信息</div>

        <div>
          {isEditing ? (
            <Space>
              <Button onClick={delAccount}>删除账号</Button>
              <Button onClick={cancelEdit}>取消编辑</Button>
              <Button onClick={saveEdit} type="primary">
                保存
              </Button>
            </Space>
          ) : (
            <Space>
              <Button onClick={delAccount}>删除账号</Button>
              <Button onClick={editAccount} type="primary">
                编辑
              </Button>
            </Space>
          )}
        </div>
      </div>
      <div>
        <Row className="account-detail-info-list">
          {showList.map((item, index) => {
            return (
              <Col
                span={12}
                key={item.label}
                className="account-detail-info-item"
              >
                <div className="label">{item.label}</div>
                {isEditing ? (
                  item.editComponent
                ) : (
                  <div className="value">{item.value}</div>
                )}
              </Col>
            );
          })}
        </Row>
      </div>

      <Divider style={{ margin: "10px 0" }} />
      <div>
        <div className="passport-nav-container">
          <div className="label">账号钥匙串</div>
          <div>
            <Button onClick={addPassport}>新增钥匙串</Button>
          </div>
        </div>
        <div className="passport-detail-container">
          {paasportList.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Row className="paasport-item">
                  <Col span={8} className="paasport-name">
                    <div className="paasport-name-label">登录名</div>
                    {item.isEditing ? (
                      <Input
                        className="paasport-name-input"
                        value={editPassportObj.new_id}
                        onChange={(e) => changePassportId(e, item)}
                      />
                    ) : (
                      <div className="paasport-name-value">{item.id}</div>
                    )}
                  </Col>

                  <Col span={8} className="paasport-password">
                    <div className="paasport-password-label">密码</div>
                    {item.isEditing ? (
                      <Input.Password
                        maxLength={16}
                        visibilityToggle={false}
                        className="paasport-password-input"
                        value={editPassportObj.hashed_password}
                        onChange={(e) => changeHashedPassport(e)}
                      />
                    ) : (
                      <div className="paasport-password-value">******</div>
                    )}
                  </Col>
                  <Col
                    span={8}
                    key={item.label}
                    style={{ textAlign: "right", lineHeight: "40px" }}
                  >
                    <Space>
                      {item.isEditing ? (
                        <>
                          <Button
                            onClick={() => cancelEditPassport(item, index)}
                          >
                            取消
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => saveEditPassport(item, index)}
                          >
                            保存
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => delPassport(item, index)}>
                            删除
                          </Button>
                          <Button
                            type="primary"
                            onClick={(e) => editPassport(e, item, index)}
                          >
                            编辑
                          </Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: "10px 0" }} />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccountDetail;
