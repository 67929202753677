export const multiHeader = [
  [
    "",
    "",
    "是否结转上次余留:是/否(默认是)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "",
    "",
    "申报单位盖章",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "序号",
    "招商点",
    "企业名称",
    "税管码",
    "银行户名",
    "开户行",
    "银行网点",
    "银行账号",
    "企业扶持资金按税种分",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "增值税(本季)",
    "",
    "",
    "企业所得税(本季)",
    "",
    "",
    "所得税(本季)",
    "",
    "",
    "房产税(本季)",
    "",
    "",
    "本季扶持金额合计",
    "加:上次余留",
    "加:补差金额",
    "补差备注",
    "减:管理费",
    "减:代扣其他",
    "减:预付金额",
    "本季应扶持金额",
    "减:本次余留",
    "本季待付款金额",
    "是否支付成功",
    "支付日期",
    "实际支付金额",
    "备注",
  ],
];

export const header = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "税额",
  "扶持比例",
  "扶持金额",
  "税额",
  "扶持比例",
  "扶持金额",
  "税额",
  "扶持比例",
  "扶持金额",
  "税额",
  "扶持比例",
  "扶持金额",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

export const filterVal = [
  "index",
  "settled_name",
  "name",
  "tax_file_number",
  "bank_account_name",
  "bank_name",
  "bank_branch",
  "bank_account_number",
  "total_value_added_tax_in_quarter",
  "enterprise_value_added_tax_refund_rate",
  "enterprise_value_added_tax_refund",
  "total_corporate_income_tax_in_quarter",
  "enterprise_corporate_income_tax_refund_rate",
  "enterprise_corporate_income_tax_refund",
  "total_individual_income_tax",
  "enterprise_individual_income_tax_refund_rate",
  "enterprise_individual_income_tax_refund",
  "total_property_tax",
  "enterprise_property_tax_refund_rate",
  "enterprise_property_tax_refund",
  "total_enterprise_tax_refund_in_quarter",
  "last_statement_balance",
  "payment_difference",
  "payment_difference_remark",
  "management_fee",
  "payment_on_behalf",
  "prepayment",
  "accounts_payable",
  "current_balance",
  "pending_payment",
  "is_payment_succeeded",
  "paid_at",
  "actual_amount_paid",
  "remark",
];

export const merges = [
  "C1:Z1",
  // "C2:E2",
  // "C3:E3",
  "A4:A6",
  "B4:B6",
  "C4:C6",
  "D4:D6",
  "E4:E6",
  "F4:F6",
  "G4:G6",
  "H4:H6",
  "I4:AH4",
  "I5:K5",
  "L5:N5",
  "O5:Q5",
  "R5:T5",
  "U5:U6",
  "V5:V6",
  "W5:W6",
  "X5:X6",
  "Y5:Y6",
  "Z5:Z6",
  "AA5:AA6",
  "AB5:AB6",
  "AC5:AC6",
  "AD5:AD6",
  "AE5:AE6",
  "AF5:AF6",
  "AG5:AG6",
  "AH5:AH6",
];
