import React, { useState, useEffect } from 'react';
import { Modal, message, Checkbox, Row, Col, Button, Space } from 'antd'
import { paasql, enterpriseExportExcel as exportExcelApi } from '@api'
import { tableHeaderForExport as table_col_header_data } from '..//EnterpriseDatabase/tableConfig.jsx'
const table_col_header_data1Î = [//表头数据的配置项
  { display_name: "企业名称", name: "name", },
  { display_name: "所属园区名称", name: "eip_park_name", },
  { display_name: "统一信用代码", name: "uscc", },
  { display_name: "档案号", name: "file_number", },
  { display_name: "入园日期", name: "settled_at", },
  { display_name: "离园日期", name: "exited_at", },
  { display_name: "招商部门", name: "settled_by", },
  { display_name: "本部招商员姓名", name: "cp_staff_name", },
  { display_name: "招商中介名称", name: "eip_investment_agency_name", },
  { display_name: "招商中介人员姓名", name: "eip_investment_agency_staff_name", },
  { display_name: "是否本园区注册", name: "is_registered_in_this_park", },
  { display_name: "入驻状态", name: "settled_status", },
  { display_name: "园区登记类型", name: "recording_genre", },
  { display_name: "税管码", name: "tax_file_number", },
  { display_name: "税务状态", name: "tax_status", },
  { display_name: "首次纳税月", name: "first_tax_payout_at", },
  { display_name: "工商状态", name: "license_status", },
  { display_name: "注册号", name: "registration_number", },
  { display_name: "法定代表人", name: "legal_representative", },
  { display_name: "注册资本(万元)", name: "registered_capital", },
  { display_name: "实收资本(万元)", name: "paidup_capital", },
  { display_name: "营业年限", name: "business_term", },
  { display_name: "营业期限自", name: "business_term_started_at", },
  { display_name: "营业期限至", name: "business_term_ended_at", },
  { display_name: "登记机关", name: "registered_by", },
  { display_name: "成立日期", name: "established_in", },
  { display_name: "公司类型", name: "enterprise_genre", },
  { display_name: "行业分类", name: "industry", },
  { display_name: "注册类型", name: "registered_genre", },
  { display_name: "曾用名", name: "former_name", },
  { display_name: "经营范围", name: "scope_of_business", },
  { display_name: "注册地址", name: "registered_address", },
  { display_name: "经营地址", name: "business_address", },
  { display_name: "工商同步时间", name: "synchronized_at", },
  { display_name: "公司邮编", name: "zip_code", },
  { display_name: "固定电话", name: "telephone_number", },
  { display_name: "企业来源", name: "enterprise_source" },
  { display_name: "招商部门", name: "investment_department_name" },
  { display_name: "备注", name: "remarks", },

]
const SelectFielsModal = (props) => {
  const { tableData, handleExportData, hideModal, getAllData } = props
  const [indeterminate, setIndeterminate] = useState(false)

  const [allCheckList, setAllCheckList] = useState([])
  const [checkAll, setCheckAll] = useState(true)
  const [exportDataLoading, setExportDataLoading] = useState(false)
  const [checkList, setCheckList] = useState([])
  const [options, setOptions] = useState([])

  const radioList = [
    {
      "id": "name",
      "name": "name",
      "display_name": "企业名称"
    },
    {
      "id": "file_number",
      "name": "file_number",
      "display_name": "档案号"
    }, {

      "id": "settled_at",
      "name": "settled_at",
      "display_name": "入园日期"
    }, {
      "id": "enterprise_source",
      "name": "enterprise_source",
      "display_name": "企业来源"
    },

    //todo
    {
      "id": "zhaoshangbumen",
      "name": "zhaoshangbumen",
      "display_name": "招商部门"
    },
    {
      "id": "zhaoshangyuan",
      "name": "zhaoshangyuan",
      "display_name": "招商员"
    },
    {
      "id": "recording_genre",
      "name": "recording_genre",
      "display_name": "注册类型"
    },

    {
      "id": "remarks",
      "name": "remarks",
      "display_name": "备注"
    },
    {
      "id": "settled_status",
      "name": "settled_status",
      "display_name": "企业情况"
    },
    {
      "id": "513edda5e9b1401eb620ac33ea2e0f35",
      "name": "uscc",
      "display_name": "统一信用代码"
    },
    {
      "id": "established_in",
      "name": "established_in",
      "display_name": "成立日期"
    },
    {
      "id": "registered_capital",
      "name": "registered_capital",
      "display_name": "注册资本(万元)"
    },
    {
      "id": "enterprise_genre",
      "name": "enterprise_genre",
      "display_name": "企业类型"
    },
    {
      "id": "business_term_started_at",
      "name": "business_term_started_at",
      "display_name": "营业期限自"
    },
    {
      "id": "business_term_ended_at",
      "name": "business_term_ended_at",
      "display_name": "营业期限至"
    },
    {
      "id": "paidup_capital",
      "name": "paidup_capital",
      "display_name": "实收资本(万元)"
    },
    {
      "id": "business_term",
      "name": "business_term",
      "display_name": "营业年限"
    },
    {
      id: 'currency',
      name: 'currency',
      display_name: '币种'
    },
    {
      "id": "license_status",
      "name": "license_status",
      "display_name": "工商状态"
    },

    {
      "id": "registered_address",
      "name": "registered_address",
      "display_name": "注册地址"
    },
    {
      "id": "business_address",
      "name": "business_address",
      "display_name": "经营地址"
    },
    {
      "id": "scope_of_business",
      "name": "scope_of_business",
      "display_name": "经营范围"
    },
    {
      "id": "taxpayer_registration_number",
      "name": "taxpayer_registration_number",
      "display_name": "税号"
    },
    {
      "id": "tax_file_number",
      "name": "tax_file_number",
      "display_name": "税管码"
    },
    {
      "id": "tax_status",
      "name": "tax_status",
      "display_name": "税务状况"
    },
    {
      "id": "telephone_number",
      "name": "telephone_number",
      "display_name": "固定电话"
    },

    //todo
    {
      "id": "bank_account_name",
      "name": "bank_account_name",
      "display_name": "户名"
    },
    {
      "id": "bank_name",
      "name": "bank_name",
      "display_name": "开户银行"
    },
    {
      "id": "bank_branch",
      "name": "bank_branch",
      "display_name": "银行网点"
    },
    {
      "id": "bank_account_number",
      "name": "bank_account_number",
      "display_name": "银行账号"
    },
    {
      "id": "bank_code",
      "name": "bank_code",
      "display_name": "银行行联号"
    },
    {
      "id": "legal_representative",
      "name": "legal_representative",
      "display_name": "法人姓名"
    },
    {
      "id": "fr_tel",
      "name": "fr_tel",
      "display_name": "法人固定电话"
    },
    {
      "id": "fr_phone",
      "name": "fr_phone",
      "display_name": "法人手机"
    },
    {
      "id": "fr_email",
      "name": "fr_email",
      "display_name": "法人邮箱"
    },
    {
      "id": "cw_name",
      "name": "cw_name",
      "display_name": "财务姓名"
    },
    {
      "id": "cw_tel",
      "name": "cw_tel",
      "display_name": "财务固定电话"
    },
    {
      "id": "cw_phone",
      "name": "cw_phone",
      "display_name": "财务手机"
    },
    {
      "id": "cw_email",
      "name": "cw_email",
      "display_name": "财务邮箱"
    },
    {
      "id": "lxr_name",
      "name": "lxr_name",
      "display_name": "联系人姓名"
    },
    {
      "id": "lxr_phone",
      "name": "lxr_phone",
      "display_name": "联系人手机"
    },
    {
      "id": "bsy_name",
      "name": "bsy_name",
      "display_name": "办税人姓名"
    },
    {
      "id": "bsy_phone",
      "name": "bsy_phone",
      "display_name": "办税人手机"
    },

  ]
  useEffect(() => {
    const defaultValue = []
    for (let i = 0; i < radioList.length; i++) {
      radioList[i].label = radioList[i].display_name
      radioList[i].value = radioList[i].name
      defaultValue.push(radioList[i].name)
    }
    setAllCheckList(defaultValue)
    setCheckList(defaultValue)
    setOptions(radioList)
  }, [])
  const exportExcel = () => {
    setExportDataLoading(true)
    getAllData().then(data => {
      const table_row_data = data
      for (let i = 0; i < table_row_data.length; i++) {
        const tax_paid_at = table_row_data[i].statistical_year + table_row_data[i].statistical_month
        table_row_data[i].tax_paid_at = tax_paid_at
      }

      var tmp_related_data = []
      var header_data = ['序号']
      const selected_item = checkList


      //序号的索引
      var header_data_key = ['index']//这是给表格数据的表头数据的key
      for (var i = 0; i < radioList.length; i++) {
        for (let j = 0; j < selected_item.length; j++) {
          if (selected_item[j] == radioList[i].name) {
            header_data_key.push(radioList[i].name)
            header_data.push(radioList[i].display_name)//这个是表头行导出数据
          }
        }
      }
      tmp_related_data.push(header_data)//表头数据
      // //表格数据部分


      for (var i = 0; i < table_row_data.length; i++) {
        table_row_data[i].index = String(i + 1)
        var single_row_data = []
        for (var j = 0; j < header_data_key.length; j++) {
          for (var key in table_row_data[i]) {
            if (header_data_key[j] == key) {
              if (table_row_data[i][key] == null) {
                single_row_data.push('')//单行数据
              } else {
                single_row_data.push(table_row_data[i][key])//单行数据
              }
            } else {
            }
          }

        }
        tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，可以导入
      }


      // for (let i = 0; i < table_col_header_data.length; i++) {
      //   for (let j = 0; j < selected_item.length; j++) {
      //     if (table_col_header_data[i].name == selected_item[j]) {
      //       header_data.push(table_col_header_data[i].display_name)
      //     }
      //     else {
      //     }
      //   }
      // }
      // tmp_related_data.push(header_data)
      // //表头数据部分
      // for (var i = 0; i < table_row_data.length; i++) {
      //   var single_row_data = []
      //   for (var j = 0; j < selected_item.length; j++) {
      //     for (var key in table_row_data[i]) {
      //       if (selected_item[j] == key) {
      //         if (table_row_data[i][key] == null) {
      //           single_row_data.push('')//单行数据
      //         } else {
      //           single_row_data.push(table_row_data[i][key])//单行数据
      //         }
      //       } else {
      //       }
      //     }

      //   }
      //   tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，可以导入
      // }
      //表的数据部分
      const fileName = "企业信息库"
      exportExcelApi(tmp_related_data, fileName)
      setExportDataLoading(false)
    })

  }


  const checkClick = (checkedValue) => {
    const checkAll = checkedValue.length === allCheckList.length
    setCheckAll(checkAll)
    setCheckList(checkedValue)
  }
  const onCheckAllChange = (e) => {
    const options = e.target.checked ? allCheckList : []
    setCheckAll(e.target.checked)
    setCheckList(options)
  }
  return (
    <>
      <Checkbox.Group value={checkList} onChange={checkClick}
      >
        <Row>
          {
            options.map(item => {
              return (
                <Col
                  key={item.value}
                  span={12}
                >
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              )
            })
          }
        </Row>

      </Checkbox.Group>
      <Checkbox
        style={{ paddingTop: 10 }}
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        全选
      </Checkbox>
      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <Space>
          <Button
            onClick={hideModal}
          >
            取消
          </Button>
          <Button
            type='primary'
            loading={exportDataLoading}
            onClick={exportExcel}
          >
            确认导出
          </Button>
        </Space>
      </div>
    </>
  );
}

export default SelectFielsModal;