import React, { useState, useEffect } from "react";

import { Button, Input, Space, Row, Col, message, Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

import { paasql, queryEnterpriseInfo } from "@api";

const primaryIdSchemaList = [
  {
    schema: "cp_staff",
    name: "园区工作人员",
    method: "eip.cp_staff.list",
  },
  {
    schema: "eip_enterprise",
    name: "企业",
    method: "eip.cp_staff.list1",
  },
];
const { Option } = Select;
const CreateInvestmentAgency = (props) => {
  const { hideModal, reflushData } = props;

  const [parkId, setParkId] = useState("2b9312bccbf94297a5172f3277ac8fa8");
  const [acountNamaDisabled, setAcountNamaDisabled] = useState(true);

  const [primaryIdIdList, setPrimaryIdIdList] = useState([]);
  const [primaryIdIdListAll, setPrimaryIdIdListAll] = useState([]);
  const [accountInfo, setAccountInfo] = useState({ name: null });

  const [roleList, setRoleList] = useState([]);
  const [selectRoleList, setSelectRoleList] = useState([]);
  const [nowShowSchema, setNowShowSchema] = useState(null);

  useEffect(() => {
    paasql("eip.park.list").then((data) => {
      if (data.error == null) {
        const parkId = data.result[0].id;
        setParkId(parkId);
      } else {
        message.error(data.error.message);
      }
    }, []);
    paasql("eip.cp_role.list").then((data) => {
      const result = data.result;
      setRoleList(result);
    });
  }, []);

  const changePrimarySchema = (v, option) => {
    setPrimaryIdIdList([]);
    setAccountInfo({
      ...accountInfo,
      primary_id_id: undefined,
      primary_id_id_title: null,
    });
    const { key, value, children } = option;

    setAccountInfo({
      ...accountInfo,
      primarySchema: value,
      primarySchemaTitle: children,
    });
    setNowShowSchema(value);
    setAcountNamaDisabled(false);

    //如果是企业的话
    if (value == "eip_enterprise") {
      const cmd_data = {
        eip_park_id: parkId,
        attribute_filter: [],
      };
      queryEnterpriseInfo(cmd_data).then((data) => {
        const { enterpriseList } = data;
        setPrimaryIdIdList(enterpriseList);
      });
      return;
    }
    const method = key;

    paasql(method).then((data) => {
      if (data.error == null) {
        const result = data.result;
        setPrimaryIdIdList(result);
        setPrimaryIdIdListAll(result);
      } else {
        message.error(data.error.message);
      }
    });
  };

  // 搜索账号主体的id

  const debounceQueryEnterpriseInfo = debounce(function (cmd_data) {
    queryEnterpriseInfo(cmd_data).then((data) => {
      const { enterpriseList } = data;
      setPrimaryIdIdList(enterpriseList);
    });
  }, 500);

  const seachPrimaryIdId = (value) => {
    if (nowShowSchema === "eip_enterprise") {
      // 处理数据
      const cmd_data = {
        eip_park_id: parkId,
        attribute_filter: [
          {
            attribute: "name",
            operator: "contain",
            value: [value],
          },
        ],
      };
      debounceQueryEnterpriseInfo(cmd_data);
    } else {
      const arr = fuzzyQuery(primaryIdIdListAll, value);
      setPrimaryIdIdList(arr);
      return;
    }
  };

  const changePrimaryIdId = (value, option) => {
    const { children } = option;
    setAccountInfo({
      ...accountInfo,
      primary_id_id: value,
      primary_id_id_title: children,
    });
  };
  const changeName = (e) => {
    const value = e.target.value;
    setAccountInfo({
      ...accountInfo,
      name: value,
    });
  };
  const changeNickName = (e) => {
    const value = e.target.value;

    setAccountInfo({
      ...accountInfo,
      nick_name: value,
    });
  };

  const changeRole = (v, option) => {
    if (option.length >= 3) {
      message.error("最多只能选择两个角色");
      return;
    }
    setSelectRoleList(option);
  };

  const fuzzyQuery = (list, keyWord) => {
    //查找列表中的名字包含了搜索的关键字的
    var reg = new RegExp(keyWord);
    var arr = [];
    for (var i = 0; i < list.length; i++) {
      if (reg.test(list[i].name)) {
        arr.push(list[i]);
      }
    }
    return arr;
  };
  const showList = [
    {
      label: "账号名称",
      value: accountInfo.name,
      field: "name",
      component: <Input onChange={changeName} />,
    },
    {
      label: "用户类型",
      value: accountInfo.primarySchema,
      component: (
        <Select
          suffixIcon={<CaretDownOutlined />}
          style={{ width: "100%" }}
          value={accountInfo.primarySchema}
          onChange={changePrimarySchema}
        >
          {primaryIdSchemaList.map((item) => {
            return (
              <Option key={item.method} value={item.schema}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
    {
      label: "账号主体名称",
      value: accountInfo.bank_account_name,
      component: (
        <Select
          suffixIcon={<CaretDownOutlined />}
          style={{ width: "100%" }}
          disabled={acountNamaDisabled}
          showSearch
          onSearch={seachPrimaryIdId}
          onChange={changePrimaryIdId}
          filterOption={false}
          value={accountInfo.primary_id_id}
        >
          {primaryIdIdList.map((item) => {
            return (
              <Option key={item.name} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
    {
      label: "昵称",
      value: accountInfo.bank_name,
      component: <Input onChange={changeNickName} />,
    },
    {
      label: "角色",
      value: accountInfo.bank_branch,
      component: (
        <Select
          mode={"multiple"}
          onChange={changeRole}
          style={{ width: "100%" }}
          suffixIcon={<CaretDownOutlined />}
          showSearch
        >
          {roleList.map((item) => {
            return (
              <Option key={item.name} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
  ];

  const saveChange = () => {
    //这里需要处理角色列表

    const {
      name,
      primarySchema,
      primarySchemaTitle,
      nick_name,
      primary_id_id,
      primary_id_id_title,
    } = accountInfo;
    if (
      name == null ||
      primarySchema == undefined ||
      primary_id_id == undefined
    ) {
      message.error("名字,用户类型和账号主体名称是必填项");
      return;
    }
    const cmd_data = {
      object: {
        name,
        primary_id_schema_id: primarySchema,
        nickname: nick_name,
        primary_id_id,
        primary_id_id_title,
        primary_id_schema_id_title: primarySchemaTitle,
      },
    };
    paasql("eip.cp_account.create", cmd_data).then((data) => {
      if (data.error == null) {
        const { id, name } = data.result;
        const new_objects = [];
        for (let i = 0; i < selectRoleList.length; i++) {
          const obj = new Object();
          obj.cp_role_name = selectRoleList[i].key;
          obj.cp_role_id = selectRoleList[i].value;
          obj.name = name;
          obj.cp_account_id = id;
          new_objects.push(obj);
        }

        const cmd_data = {
          new_objects,
        };

        paasql("eip.cp_account_m2m_cp_role.batch_create", cmd_data).then(
          (data) => {
            if (data.error == null) {
              message.success("创建账号和关联角色成功");
            } else {
              message.error(data.error.message);
            }
          }
        );
        reflushData(id);
        hideModal();
      } else {
        message.error(data.error.message);
      }
    });
  };
  return (
    <div>
      <div>
        <Row>
          {showList.map((item) => {
            return (
              <Col span={24} key={item.label}>
                <div className="edit-bank-info-label">{item.label}</div>
                {item.component}
              </Col>
            );
          })}
        </Row>
      </div>
      <div style={{ textAlign: "right", marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>取消</Button>
          <Button type="primary" onClick={saveChange}>
            保存
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default CreateInvestmentAgency;
