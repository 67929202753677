import React, { useState, useEffect } from "react";

import { Button, Table, message, DatePicker, Select, Row, Col } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import { paasql, exportExcel } from "@api";
import ButtonLabel from "@component/ButtonLabel";
import { ExportDataIcon, BackToIcon } from "@icon";
import { moneyFormat } from "@utils/index";

import { export_json_to_excel } from "@assets/xlsx";

const ButtonGroup = Button.Group;
const { Option } = Select;
const currentYear = moment().year();
const ShowTaxPaymentRecord = (props) => {
  const enterpriseId = props.match.params.id;

  const { Frame, dispatch } = props;
  const [businessYearList, setBusinessYearList] = useState([]);

  const [tableDataAll, setTableDataAll] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRecordObj, setTotalRecordObj] = useState({});

  //设置面包屑导航
  useEffect(() => {
    const { breadcurumbList } = Frame;
    const cmd_data = {
      eip_enterprise_id: enterpriseId,
    };
    paasql(
      "eip.eip_enterprise.enterprise_and_standard_data_by_enterprise_id",
      cmd_data
    ).then((data) => {
      if (data.error == null) {
        const { name } = data.result[0];
        const arr = ["基本信息管理", "企业信息管理", name, "纳税记录"];
        dispatch({
          type: "SET_BREADCRUMB_LIST",
          value: arr,
        });
      } else {
        message.error(data.error.message);
      }
    });
  }, [enterpriseId]);

  function exportData() {
    const multiHeader = [["Id", "Main Information", "", "", "Date"]];
    const header = ["", "应税销售额", "消费税", "增值税", ""];
    const filterVal = [
      "id",
      "taxable_income",
      "consumption_tax",
      "value_added_tax",
      "display_time",
    ];
    const list = tableData;

    const data = formatJson(filterVal, list);

    console.log(data);
    const merges = [ "A1:D2", "E1:E2"];

    export_json_to_excel({
      multiHeader,
      header,
      merges,
      data,
    });
    // this.downloadLoading = false;
  }

  function formatJson(filterVal, jsonData) {
    return jsonData.map((v) =>
      filterVal.map((j) => {
        return Number(v[j]);
      })
    );
  }
  useEffect(() => {
    //处理年份

    paasql("eip.eip_business_year.list").then((data) => {
      if (data.error == null) {
        const businessYearList = data.result;
        var businessYearListWithSort = businessYearList.sort(function (
          first_attr_item,
          last_attr_item
        ) {
          return Number(first_attr_item.name) - Number(last_attr_item.name);
        }); //table_row_data
        setBusinessYearList(businessYearListWithSort);
      } else {
        message.error(data.error.message);
      }
    });

    const cmd_data = {
      eip_enterprise_id: enterpriseId,
    };
    paasql(
      "eip.eip_enterprise_monthly_tax_payment_record.query_by_eip_enterprise_id",
      cmd_data
    ).then((data) => {
      if (data.error == null) {
        //最新年
        const result = data.result;
        setTableDataAll(result);
        handleTableData(result, currentYear);
      } else {
        message.error(data.error.message);
      }
    });
  }, [enterpriseId]);

  const handleTableData = (tableData, dafaultYear) => {
    const tmpTableData = [];
    for (var i = 0; i < tableData.length; i++) {
      //增加年的字段
      var attr_obj = tableData[i];
      var year = attr_obj.tax_paid_at.slice(0, 4);
      var month = attr_obj.tax_paid_at.slice(4, 6);
      var time = year + "年" + month + "月";
      attr_obj.tax_paid_at = time;
      attr_obj.year = year;
      attr_obj.month = month;
      tmpTableData.push(attr_obj);
    }
    setTableDataAll(tmpTableData);
    queryDataByYear(tmpTableData, dafaultYear);
  };

  const queryDataByYear = (tmpTableData, year) => {
    var tmpTableDataWithYear = [];
    for (var i = 0; i < tmpTableData.length; i++) {
      if (Number(tmpTableData[i].year) == Number(year)) {
        tmpTableDataWithYear.push(tmpTableData[i]);
      }
    }
    //增加索引

    var tmpTableDataWithYearAndSort = tmpTableDataWithYear.sort(function (
      first_attr_item,
      last_attr_item
    ) {
      return Number(first_attr_item.month) - Number(last_attr_item.month);
    });

    for (let i = 0; i < tmpTableDataWithYearAndSort.length; i++) {
      tmpTableDataWithYearAndSort[i].index = i + 1;
    }

    const calculateTotaltaxObj = calculateTotaltax(tmpTableDataWithYearAndSort);
    setTotalRecordObj(calculateTotaltaxObj);
    setTableData(tmpTableDataWithYearAndSort);
  };

  const calculateTotaltax = (data) => {
    const total = {};
    const arr = [
      "taxable_income",
      "consumption_tax",
      "value_added_tax",
      "tax_payable",
      "corporate_income_tax",
      "city_maintenance_and_construction_tax",
      "vehicle_and_vessel_usage_tax",
      "property_tax",
      "land_use_tax",
      "environmental_tax",
    ];
    for (let i = 0; i < data.length; i++) {
      for (const key in data[i]) {
        arr.forEach((element) => {
          if (key == element) {
            const former_value = total[key] == undefined ? 0 : total[key];
            const new_value = Number(former_value) + Number(data[i][key]);
            total[key] = new_value.toFixed(2);
          }
        });
      }
    }
    return total;
  };
  const tableHeaders = [
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: 70,
      fixed: "left",
      align: "center",
    },
    {
      title: "税收期间",
      dataIndex: "tax_paid_at",
      key: "tax_paid_at",
      width: 120,
    },
    {
      title: "应税销售额(元)",
      dataIndex: "taxable_income",
      key: "taxable_income",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "增值税(元)",
      dataIndex: "value_added_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "营业税(元)",
      dataIndex: "business_tax",
      key: "business_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "企业所得税(元)",
      dataIndex: "corporate_income_tax",
      key: "corporate_income_tax",
      key: "cellphone_number",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "消费税(元)",
      dataIndex: "consumption_tax",
      key: "consumption_tax",
      key: "consumption_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "所得税(元)",
      dataIndex: "individual_income_tax",
      key: "individual_income_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "城市维护建设税(元)",
      dataIndex: "city_maintenance_and_construction_tax",
      key: "city_maintenance_and_construction_tax",
      width: 200,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "车船使用税(元)",
      dataIndex: "vehicle_and_vessel_usage_tax",
      key: "vehicle_and_vessel_usage_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "房产税(元)",
      dataIndex: "property_tax",
      key: "property_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "印花税(元)",
      dataIndex: "stamp_tax",
      key: "stamp_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "土地增值税(元)",
      dataIndex: "land_appreciation_tax",
      key: "land_appreciation_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "土地使用税(元)",
      dataIndex: "land_use_tax",
      key: "land_use_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "环保税(元)",
      dataIndex: "environmental_tax",
      key: "environmental_tax",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
    {
      title: "税收合计(元)",
      dataIndex: "tax_payable",
      key: "tax_payable",
      width: 150,
      align: "right",
      render: (text, record) => {
        return moneyFormat(text);
      },
    },
  ];

  //选择框的回调
  const changeYear = (v, option) => {
    const { key, value } = option;
    queryDataByYear(tableDataAll, key);
  };

  //返回企业详情页
  const backToDetail = () => {
    props.history.push(`/enterprise-database/detail/${enterpriseId}`);
  };

  const table_col_header_data = [
    //表头数据的配置项
    { display_name: "序号", name: "index" },
    { display_name: "税收期间", name: "tax_paid_at" },
    { display_name: "应税销售额", name: "taxable_income" },
    { display_name: "消费税", name: "consumption_tax" },
    { display_name: "增值税", name: "value_added_tax" },
    { display_name: "营业税", name: "business_tax" },
    { display_name: "企业所得税", name: "corporate_income_tax" },
    { display_name: "所得税", name: "individual_income_tax" },
    {
      display_name: "城市维护建设税",
      name: "city_maintenance_and_construction_tax",
    },
    { display_name: "车船使用税", name: "vehicle_and_vessel_usage_tax" },
    { display_name: "房产税", name: "property_tax" },
    { display_name: "印花税", name: "stamp_tax" },
    { display_name: "土地增值税", name: "land_appreciation_tax" },
    { display_name: "土地使用税", name: "land_use_tax" },
    { display_name: "环保税", name: "environmental_tax" },
    { display_name: "税收合计", name: "tax_payable" },
  ];

  const keyArr = [
    "taxable_income",
    "tax_payable",
    "consumption_tax",
    "value_added_tax",
    "business_tax",
    "corporate_income_tax",
    "individual_income_tax",
    "city_maintenance_and_construction_tax",
    "vehicle_and_vessel_usage_tax",
    "property_tax",
    "stamp_tax",
    "land_appreciation_tax",
    "land_use_tax",
    "environmental_tax",
  ];
  //导出数据
  const HandleExportData = () => {
    var tmp_related_data = [];

    const tmpTableData = tableData;

    tmpTableData.forEach((element) => {
      keyArr.forEach((key) => {
        element[key] = `$${element[key]}`;
      });
    });

    var header_data = [];
    var header_data_key = []; //这是给表格数据的表头数据的key
    for (var i = 0; i < table_col_header_data.length; i++) {
      header_data.push(table_col_header_data[i].display_name); //这个是表头行导出数据
      header_data_key.push(table_col_header_data[i].name);
    }
    tmp_related_data.push(header_data); //表头数据

    // //表格数据部分
    for (var i = 0; i < tmpTableData.length; i++) {
      tmpTableData[i].index = String(i + 1);
      var single_row_data = [];
      for (var k = 0; k < header_data_key.length; k++) {
        //循环单行数据，根据表头的key
        for (var key in tmpTableData[i]) {
          if (header_data_key[k] == key) {
            if (tmpTableData[i][key] == null) {
              single_row_data.push("");
            } else {
              single_row_data.push(tmpTableData[i][key]); //单行数据
            }
          } else {
          }
        }
      }
      tmp_related_data.push(single_row_data); //将每个行数据都赋给tmp_related_data这个数组，导入
    }
    exportExcel(tmp_related_data, "导出纳税记录");
  };

  const totalRecord = [
    {
      label: "应税销售额",
      value: totalRecordObj.taxable_income,
    },
    {
      label: "增值税",
      value: totalRecordObj.value_added_tax,
    },
    {
      label: "营业税",
      value: totalRecordObj.business_tax,
    },
    {
      label: "消费税",
      value: totalRecordObj.consumption_tax,
    },
    {
      label: "企业所得税",
      value: totalRecordObj.corporate_income_tax,
    },
    {
      label: "所得税",
      value: totalRecordObj.individual_income_tax,
    },
    {
      label: "城市维护建设税",
      value: totalRecordObj.city_maintenance_and_construction_tax,
    },
    {
      label: "车船使用税",
      value: totalRecordObj.vehicle_and_vessel_usage_tax,
    },
    {
      label: "房产税",
      value: totalRecordObj.property_tax,
    },
    {
      label: "印花税",
      value: totalRecordObj.stamp_tax,
    },
    {
      label: "土地增值税",
      value: totalRecordObj.land_appreciation_tax,
    },
    {
      label: "土地使用税",
      value: totalRecordObj.land_use_tax,
    },
    {
      label: "环保税",
      value: totalRecordObj.environmental_tax,
    },
    {
      label: "税收合计",
      value: totalRecordObj.tax_payable,
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <ButtonGroup>
            <ButtonLabel size="large" />

            <Button size="large" onClick={backToDetail} icon={<BackToIcon />}>
              返回企业
            </Button>
            <Button size="large" onClick={exportData} icon={<ExportDataIcon />}>
              导出数据
            </Button>
          </ButtonGroup>
        </div>

        <div>
          <Select
            suffixIcon={<CaretDownOutlined />}
            defaultValue={currentYear}
            onChange={changeYear}
            style={{ width: "200px" }}
          >
            {businessYearList.map((item) => {
              return (
                <Option key={item.name} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      <div>
        <Row className="total-record-container">
          {totalRecord.map((item) => {
            return (
              <Col key={item.label} span={6} className="total-record-item">
                <Row>
                  <Col
                    span={10}
                    style={{ textAlign: "right" }}
                    className="total-record-item-label"
                  >
                    {`${item.label}:`}
                  </Col>
                  <Col span={14} className="total-record-item-value">
                    {moneyFormat(item.value)}
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
      <div>
        <Table
          columns={tableHeaders}
          bordered
          rowKey={(record) => record.id}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 50,
          }}
          dataSource={tableData}
          scroll={{ x: "calc(2000px )", y: "calc(100vh - 460px)" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Frame: state.Frame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowTaxPaymentRecord);
