import React, { useState, useEffect } from 'react';
import { Table, message, Button, Modal, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment'
import { paasql } from '@api'

const { Text } = Typography;
const EnterpriseTable = (props) => {
  const {
    reflushData,
    tableData,
    totalPage, currentPage, pageSize } = props
  const [data, setData] = useState([])
  useEffect(() => {
    (tableData).forEach((element, index) => {
      element.index = (currentPage - 1) * pageSize + index + 1
    });
    setData(tableData)
  }, [tableData])

  const clickRow = (record) => {
    const { id } = record
    props.history.push(`/enterprise-database/detail/${id}`)
  }
  const deleteEnterprise = (record) => {
    Modal.confirm({
      title: '删除企业',
      content:
        <span>
          本操作将删除所选企业(<Text type="danger" >{record.name}</Text>)，删除后不可恢复，是否继续？
      </span>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        confirmDel(record)
      }
    })
  }
  function confirmDel(record) {
    const cmd_data = {
      id: record.id
    }
    paasql('eip.eip_enterprise.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        reflushData()
      } else {
        message.error(data.error.message)
      }
    })
  }


  const tableHeaders = [{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 40,
    fixed: 'left',
    align: 'center',
  }, {
    title: '档案号',
    dataIndex: 'file_number',
    key: 'file_number',
    width: 60,
    fixed: 'left',
    render: (text, record) => {
      return (
        <a onClick={() => clickRow(record)}>{text}</a>
      )
    }
  }, {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    ellipsis: true,
    fixed: 'left',
    render: (text, record) => {
      return (
        <a onClick={() => clickRow(record)}>{text}</a>
      )
    }
  }, {
    title: '税管码',
    dataIndex: 'tax_file_number',
    key: 'tax_file_number',
    width: 120,
    ellipsis: true
  }, {
    title: '税号',
    dataIndex: 'taxpayer_registration_number',
    key: 'taxpayer_registration_number',
    width: 100,
    ellipsis: true
  }, {
    title: '统一征信代码',
    dataIndex: 'uscc',
    key: 'uscc',
    width: 100,
    ellipsis: true
  }, {
    title: '企业情况',
    dataIndex: 'settled_status',
    key: 'settled_status',
    width: 50,
    ellipsis: true
  }, {
    title: '工商状态',
    dataIndex: 'license_status',
    key: 'license_status',
    width: 50,
    ellipsis: true

  }, {
    title: '企业来源',
    dataIndex: 'enterprise_source',
    key: 'enterprise_source',
    width: 50,
    ellipsis: true
  }, {
    title: '招商部门',
    dataIndex: 'settled_at',
    key: 'settled_at',
    width: 70,
    render: (text, record) => {
      return (
        record.settled_by == '中介' ? record.eip_investment_agency_name : '本部'
      )
    }
  }, {
    title: '招商人员',
    dataIndex: 'settled_at',
    key: 'settled_at',
    width: 100,
    ellipsis: true,
    render: (text, record) => {
      return (
        record.settled_by == '中介' ? record.eip_investment_agency_staff_name : record.cp_staff_name
      )
    }
  }, {
    title: '成立日期',
    dataIndex: 'established_in',
    key: 'established_in',
    width: 70,
    render: (text, record) => {
      return (
        text === null ||
          text === '' ? null
          : moment(text).format('YYYY-MM-DD')
      )
    }
  }, {
    title: '操作',
    dataIndex: 'operator',
    key: 'operator',
    width: 65,
    align: 'center',
    render: (text, record) => {
      return (
        <Button type="danger"
          size='small'

          onClick={() => deleteEnterprise(record)}
        >
          <div
           className='btn-sm'
          >
            删除
          </div>

        </Button>

      )
    }
  }]


  return (
    <Table
      size='small'
      columns={tableHeaders}
      dataSource={data}
      bordered
      rowKey={
        record => record.id
      }
      pagination={{
        pageSize: 20,
        totalPage: totalPage,
        hideOnSinglePage: true
      }}
      //设置宽度
      scroll={{ x: 'calc(1550px)', y: 'calc(100vh - 300px)' }}
    />
  );
}

export default EnterpriseTable;
