import React, { useState, useEffect } from "react";
import {
  Button,
  message,
  Radio,
  Checkbox,
  Modal,
  Pagination,
  Spin,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { export_json_to_excel } from "@assets/xlsx";

import {
  paasql,
  gpi,
  exportExcel,
  exportSeasonTax,
  exportCreditTax,
} from "@api";

import { moneyFormat } from "@utils/index";
import TaxRefundTable from "./component/TaxRefundTable";
import CreateTaxRefundStatement from "./component/CreateTaxRefundStatement";
import SetLastStatementBalance from "./component/SetLastStatementBalance";
import UploadModal from "./UploadModal";
import {
  CreateIcon,
  ImportDataIcon,
  ExportDataIcon,
  DeleteDocIcon,
  LastBalanceIcon,
} from "@icon";
import { ButtonLabel } from "@component";

import { multiHeader, header, filterVal, merges } from "./config";
import "./index.less";

const ButtonGroup = Button.Group;

const table_col_header_data = [
  { display_name: "序号", name: "index" },
  { display_name: "招商点", name: "settled_name" },
  { display_name: "企业名称", name: "name" },
  { display_name: "税管码", name: "tax_file_number" },
  { display_name: "银行户名", name: "bank_account_name" },
  { display_name: "开户行", name: "bank_name" },
  { display_name: "银行网点", name: "bank_branch" },
  { display_name: "银行账号", name: "bank_account_number" },
  {
    display_name: "增值税（本季）税额",
    name: "total_value_added_tax_in_quarter",
  },
  {
    display_name: "增值税（本季）扶持比例",
    name: "enterprise_value_added_tax_refund_rate",
  },
  {
    display_name: "增值税（本季）扶持额",
    name: "enterprise_value_added_tax_refund",
  },
  {
    display_name: "企业所得税（本季）税额",
    name: "total_corporate_income_tax_in_quarter",
  },
  {
    display_name: "企业所得税（本季）扶持比例",
    name: "enterprise_corporate_income_tax_refund_rate",
  },
  {
    display_name: "企业所得税（本季）扶持额",
    name: "enterprise_corporate_income_tax_refund",
  },

  // { display_name: "所得税（本季）税额", name: "total_individual_income_tax_in_quarter" },
  // { display_name: "所得税（本季）扶持比例", name: "enterprise_individual_income_tax_refund_rate" },
  // { display_name: "所得税（本季）扶持额", name: "enterprise_individual_income_tax_refund" },

  // { display_name: "房产税（本季）税额", name: "total_value_added_tax_in_quarter" },
  // { display_name: "房产税（本季）扶持比例", name: "enterprise_value_added_tax_refund_rate" },
  // { display_name: "房产税（本季）扶持额", name: "enterprise_value_added_tax_refund" },
  {
    display_name: "本季度扶持金额合计",
    name: "total_enterprise_tax_refund_in_quarter",
  },
  { display_name: "加：上次余留", name: "last_statement_balance" },
  { display_name: "是否计算上次余留", name: "is_last_statement_balance_added" },
  { display_name: "加：补差金额", name: "payment_difference" },
  { display_name: "补差备注", name: "payment_difference_remark" },
  { display_name: "减：管理费", name: "management_fee" },
  { display_name: "减：代扣其他", name: "payment_on_behalf" },
  { display_name: "减：预付金额", name: "prepayment" },
  { display_name: "本季应扶持金额", name: "accounts_payable" },
  { display_name: "减：本次余留", name: "current_balance" },
  { display_name: "本季待付款金额", name: "pending_payment" },
  { display_name: "是否支付成功", name: "is_payment_succeeded" },
  { display_name: "支付日期", name: "paid_at" },
  { display_name: "实际支付金额", name: "actual_amount_paid" },
  { display_name: "备注", name: "remark" },
];

const lackBankInfoAttributeFilter = [
  { attribute: "bank_name", operator: "!=", value: [""] },
  { attribute: "bank_account_name", operator: "!=", value: [""] },
];
const ParkTaxRefund = () => {
  //数据层
  const [taxRefundStatementList, setTaxRefundStatementList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [parkId, setParkId] = useState("");
  const [parkName, setParkName] = useState("");
  const [totalPage, setTotalPage] = useState(null);
  const [selectTaxRefundStatement, setSelectTaxRefundStatement] = useState(
    null
  );
  const [selectTaxId, setSelectTaxId] = useState(null);
  const [attributeFilter, setAttributeFilter] = useState([]);
  //业务

  const [loading, setLoading] = useState(false);
  const [
    createTaxRefundModalvisable,
    setCreateTaxRefundModalvisable,
  ] = useState(false); //新建核算表的模态框
  const [LastStatementModalvisable, setLastStatementModalvisable] = useState(
    false
  ); //结算上期余留的的模态框
  const [archived, setArchived] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [uploadFileModalChange, setUploadFileModalChange] = useState(false);
  const [uploadFileModalVisable, setUploadFileModalVisable] = useState(false);

  //统计数据
  const [total, setTotal] = useState({});
  //查询的总数
  const [totalNumber, setTotalNumber] = useState(0);
  useEffect(() => {
    paasql("eip.park.list").then((data) => {
      if (data.error == null) {
        if (data.result.length !== 0) {
          //不为0的话
          const id = data.result[0].id;
          const parkName = data.result[0].name;
          setParkId(id);
          setParkName(parkName);
          getTaxRefundStatementListByParkId(id);
        }
      } else {
        message.error(data.error.message);
      }
    });
  }, []);

  //获得数据
  const getTaxRefundStatementItemById = (request) => {
    const { id, page, size } = request;
    const cmd_data = {
      attached_to_object_id: id,
      page: page,
      size: size,
      attribute_filter: attributeFilter,
      table: "eip_park_administered_enterprises_tax_refund_statement_record",
    };
    const method = "query_statement_and_statistic_record_by_page";
    gpi(method, cmd_data).then((res) => {
      const {
        list,
        total_page,
        total,
        actual_amount_paid,
        current_balance,
        accounts_payable,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      } = res;

      setTotal({
        actual_amount_paid,
        current_balance,
        accounts_payable,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      });
      setTotalNumber(total);
      setTotalPage(total_page);
      setTableData(list);
    });
  };
  const handelChangeFilter = (request) => {
    const { id, page, size, attribute_filter } = request;
    const cmd_data = {
      attached_to_object_id: id,
      page: page,
      size: size,
      attribute_filter,
      table: "eip_park_administered_enterprises_tax_refund_statement_record",
    };

    const method = "query_statement_and_statistic_record_by_page";
    gpi(method, cmd_data).then((res) => {
      const {
        list,
        total_page,
        total,
        actual_amount_paid,
        current_balance,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      } = res;

      setTotal({
        actual_amount_paid,
        current_balance,
        enterprise_corporate_income_tax_refund,
        enterprise_value_added_tax_refund,
        last_statement_balance,
        management_fee,
        payment_difference,
        payment_on_behalf,
        pending_payment,
        prepayment,
        total_corporate_income_tax_in_quarter,
        total_enterprise_tax_refund_in_quarter,
        total_value_added_tax_in_quarter,
      });
      setTotalNumber(total);
      setTotalPage(total_page);
      setTableData(list);
    });
  };
  //控制页面的lading
  const handleLoading = (loadingStatus) => {
    setLoading(loadingStatus);
  };

  const handleCreateTaxStatementLoading = (loadingStatus) => {
    setLoading(loadingStatus);
  };

  const getTaxRefundStatementListByParkId = (parkId) => {
    const cmd_data = {
      eip_park_id: parkId,
    };
    paasql(
      "eip.eip_park_administered_enterprises_tax_refund_statement.list",
      cmd_data
    ).then((list) => {
      if (list.error == null) {
        const result = list.result;
        //处理核算表的list
        if (result.length === 0) {
          setTaxRefundStatementList([]);
          setTotalPage(1);
          setTableData([]);
        } else {
          for (let i = 0; i < result.length; i++) {
            const year_and_quarter = result[i].year + result[i].quarter;
            result[i].year_and_quarter = year_and_quarter;
            const archivedText = result[i].archived === 1 ? "已归档" : "未归档";
            result[i].archivedText = archivedText;
          }
          const result_with_sort = result.sort(function (first, last) {
            return (
              Number(last.year_and_quarter) - Number(first.year_and_quarter)
            );
          });

          //处理所有的核算表
          setTaxRefundStatementList(result_with_sort);

          //这里处理的是年的列表
          //处理月份
          const isArchived = result_with_sort[0].id == "1" ? true : false;
          setArchived(isArchived);
          setSelectTaxId(result_with_sort[0].id);
          const cmd_data = {
            id: result_with_sort[0].id,
            page: 1, //默认为
            size: pageSize,
            table:
              "eip_park_administered_enterprises_tax_refund_statement_record",
          };
          setSelectTaxRefundStatement(result_with_sort[0]);
          getTaxRefundStatementItemById(cmd_data);
        }
      } else {
        message.error(list.error.message);
      }
    });
  };

  //新增核算表弹出模态框
  const createTaxRefund = () => {
    setCreateTaxRefundModalvisable(true);
  };

  //隐藏模态框
  const hideModal = () => {
    setCreateTaxRefundModalvisable(false);
  };

  //结算上次余留的模态框
  const setLastStatementBalance = () => {
    setLastStatementModalvisable(true);
  };

  // //结算上次余留，放在了组件中完成，这里就没用了
  // const handleSetLastStatementBalance = (condition) => {
  //   setLastStatementModalvisable(false)
  //   const cmd_data = {
  //     eip_park_id: parkId,
  //     is_agency: false,
  //     is_last_statement_balance_added: condition,
  //     eip_business_year: selectTaxRefundStatement.year,
  //     quarter: selectTaxRefundStatement.quarter
  //   }
  //   setLoading(true)
  //   administeredSeasonTaxRecordUpdate(cmd_data).then(data => {
  //     if (data.success) {
  //       //刷新当前的数据
  //       message.success('更新成功')
  //     }
  //   }).finally(() => {
  //     setLoading(false)
  //   })
  // }
  //导入核对表
  const uploadFile = () => {
    setUploadFileModalVisable(true);
    setUploadFileModalChange(!uploadFileModalChange);
  };

  //导出
  const exportData = () => {
    //直接导出
    if (selectTaxId == null) {
      message.error("当前没有选择核算表，无法导出数据");
      return;
    }
    const cmd_data = {
      attached_to_object_id: selectTaxId,
      page: 1,
      size: 20 * totalPage,
      table: "eip_park_administered_enterprises_tax_refund_statement_record",
    };
    const method = "query_statement_and_statistic_record_by_page";
    //请求所有数据

    gpi(method, cmd_data).then((res) => {
      const tmp_table_row_data = res.list;
      const tmp_table_row_data_with_settle_name = [];
      for (let i = 0; i < tmp_table_row_data.length; i++) {
        tmp_table_row_data[i].index = String(i + 1);
        if (tmp_table_row_data[i].is_last_statement_balance_added == "1") {
          //是否计算上次余留
          tmp_table_row_data[i].is_last_statement_balance_added = "是";
        } else {
          tmp_table_row_data[i].is_last_statement_balance_added = "否";
        }
        if (tmp_table_row_data[i].is_payment_succeeded == "1") {
          //是否支付成功
          tmp_table_row_data[i].is_payment_succeeded = "是";
        } else {
          tmp_table_row_data[i].is_payment_succeeded = "否";
        }

        var attr_object = tmp_table_row_data[i];
        attr_object.settled_name = "本部";
        tmp_table_row_data_with_settle_name.push(attr_object);
      }

      //有数据了
      var table_row_data = tmp_table_row_data_with_settle_name;

      //这里需要处理数据的千分位隔断



      const _header   = [
        "",
        "",
        `${selectTaxRefundStatement.year}年本部企业扶持资金结算申报表(第${selectTaxRefundStatement.quarter}季度)`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      multiHeader.unshift(_header)
      const keyArr = [
        "total_value_added_tax_in_quarter",
        "enterprise_value_added_tax_refund",
        "total_corporate_income_tax_in_quarter",
        "enterprise_corporate_income_tax_refund",
        "total_enterprise_tax_refund_in_quarter",
        "last_statement_balance",
        "payment_difference",
        "management_fee",
        "payment_on_behalf",
        "prepayment",
        "accounts_payable",
        "current_balance",
        "pending_payment",
        "actual_amount_paid",
      ];

      table_row_data.forEach((element) => {
        keyArr.forEach((key) => {
          element[key] = Number(element[key]);
        });
      });

    const data = formatJson(filterVal, table_row_data);

      export_json_to_excel({
        multiHeader,
        header,
        merges,
        data,
        filename:`${selectTaxRefundStatement.name}园区本部退税核算表`,
        centerNumber: 3,
      });


      function formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            return v[j];
          })
        );
      }
      // const header_data = []
      // const header_data_key = []
      // const tmp_related_data = []
      // for (let i = 0; i < table_col_header_data.length; i++) {
      //   header_data.push(table_col_header_data[i].display_name)
      //   header_data_key.push(table_col_header_data[i].name)//表头的key的数组
      // }
      // tmp_related_data.push(header_data)
      //表头数据部分
      // //表格数据部分

      //这里是没有银行缺失企业的
      // for (let i = 0; i < table_row_data.length; i++) {
      //   var single_row_data = []
      //   for (let k = 0; k < header_data_key.length; k++) {//循环单行数据，根据表头的key
      //     for (let key in table_row_data[i]) {
      //       if (header_data_key[k] === key) {
      //         if (table_row_data[i][key] == null) {
      //           //如果为null cpx是无法导入的
      //           single_row_data.push('')//单行数据
      //         } else {
      //           //单行数据,一定要保持是string
      //           single_row_data.push(String(table_row_data[i][key]))
      //         }
      //       } else {
      //       }
      //     }
      //   }
      //   tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，导入
      // }

      // const fileName = `${selectTaxRefundStatement.name}园区本部退税核算表`
      // exportExcel(tmp_related_data, fileName)
    });
  };

  //导出企业扶持资金直接拨付情况表（空表）
  const exportSupportFund = () => {
    const cmd_data = {
      //模版文件名
      original_file_id: "3be4b38478c04d7caa5b54b26eacc8f0",
      eip_park_id: parkId,
      season: String(selectTaxRefundStatement.quarter),
      year: selectTaxRefundStatement.year,
    };
    const fileName = `企业扶持资金拨付情况表(${selectTaxRefundStatement.name})`;
    exportSeasonTax(cmd_data, fileName);
  };

  //贷记凭证批量发放数据模版（空表）
  const exportCreditVoucher = () => {
    const cmd_data = {
      original_file_id: "template",
      eip_park_id: parkId,
      season: String(selectTaxRefundStatement.quarter),
      year: selectTaxRefundStatement.year,
    };
    const fileName = `贷记凭证批量发放数据表(${selectTaxRefundStatement.name})`;
    exportCreditTax(cmd_data, fileName);
  };

  //确认删除
  const destroyTaxRefund = () => {
    const cmd_data = {
      id: selectTaxId,
    };
    setLoading(true);
    return paasql(
      "eip.eip_park_administered_enterprises_tax_refund_statement_and_record.delete",
      cmd_data
    )
      .then((data) => {
        if (data.error == null) {
          //刷新当前的年和月的list，并选中
          //设置上一张表的archived，怎么拿到上一张表的id呢
          if (taxRefundStatementList.length == 1) {
            //删除最后一张表的时候
            message.success("删除核算表成功", 3);
            getTaxRefundStatementListByParkId(parkId);
          } else {
            const cmd_data = {
              id: taxRefundStatementList[1].id,
              archived: 0,
            };
            return paasql(
              "eip.eip_park_administered_enterprises_tax_refund_statement.update_archived",
              cmd_data
            )
              .then((data) => {
                if (data.error == null) {
                  message.success(
                    "删除核算表成功，成功将上一张核算表取消归档",
                    3
                  );
                } else {
                  message.error("删除核算表成功，但取消归档上一张失败", 3);
                }
              })
              .finally(() => {
                reflushData();
              });
          }

          getTaxRefundStatementListByParkId(parkId);
        } else {
          message.error(data.error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //显示删除的确认提示
  const reflushTableData = () => {
    const cmd_data = {
      size: pageSize,
      page: currentPage,
      id: selectTaxId,
    };
    getTaxRefundStatementItemById(cmd_data);
  };
  const destroy = () => {
    Modal.confirm({
      onOk() {
        destroyTaxRefund();
      },
      width: 600,
      content: "删除后不可恢复，是否继续",
      icon: <ExclamationCircleOutlined />,
      title: `确认要删除${selectTaxRefundStatement.name}的园区本部企业扶持资金结算表吗？`,
    });
  };

  //显示缺失企业
  const showInfoLackAccount = (e) => {
    //页码需要回到第一页
    setCurrentPage(1);
    const show = e.target.checked;
    if (show) {
      setAttributeFilter(lackBankInfoAttributeFilter);
      const cmd_data = {
        id: selectTaxId,
        page: 1,
        size: pageSize,
        attribute_filter: lackBankInfoAttributeFilter,
        table: "eip_park_administered_enterprises_tax_refund_statement_record",
      };
      handelChangeFilter(cmd_data);
    } else {
      setAttributeFilter([]);
      const cmd_data = {
        id: selectTaxId,
        page: 1,
        size: pageSize,
        attribute_filter: [],
        table: "eip_park_administered_enterprises_tax_refund_statement_record",
      };
      handelChangeFilter(cmd_data);
    }
  };

  const handleTaxStatementListChange = (item) => {
    setCurrentPage(1);
    const id = item.id;
    const isArchived = item.archived == "1" ? true : false;
    setArchived(isArchived);
    setSelectTaxId(id);
    const cmd_data = {
      id,
      page: 1,
      size: pageSize,
    };
    getTaxRefundStatementItemById(cmd_data);
    setSelectTaxRefundStatement(item);
  };

  const reflushData = () => {
    getTaxRefundStatementListByParkId(parkId);
    // window.location.reload();
  };

  const totalList = [
    {
      label: "增值税税额",
      value: total.total_value_added_tax_in_quarter,
    },
    {
      label: "增值税扶持额",
      value: total.enterprise_value_added_tax_refund,
    },
    {
      label: "企业所得税税额",
      value: total.total_corporate_income_tax_in_quarter,
    },
    {
      label: "企业所得税扶持额",
      value: total.enterprise_corporate_income_tax_refund,
    },
    {
      label: "本季扶持额合计",
      value: total.total_enterprise_tax_refund_in_quarter,
    },
    {
      label: "上次余留",
      value: total.last_statement_balance,
    },
    {
      label: "补差金额",
      value: total.payment_difference,
    },
    {
      label: "管理费",
      value: total.management_fee,
    },
    {
      label: "代扣其他",
      value: total.payment_on_behalf,
    },
    {
      label: "预付金额",
      value: total.prepayment,
    },
    {
      label: "本季应扶持金额",
      value: total.accounts_payable,
    },
    {
      label: "本次余留",
      value: total.current_balance,
    },
    {
      label: "本季待付款金额",
      value: total.pending_payment,
    },
    {
      label: "实际支付",
      value: total.actual_amount_paid,
    },
  ];
  const notArchivedButtonList = [
    {
      name: "新增核算表",
      size: "large",
      width: 146,
      action: createTaxRefund,
      icon: <CreateIcon />,
    },
    {
      name: "结算上次余留",
      type: "",
      width: 146,

      size: "large",
      action: setLastStatementBalance,
      className: "ces",
      icon: <LastBalanceIcon />,
    },
    {
      name: "导入核对表",
      type: "",
      size: "large",
      action: uploadFile,
      icon: <ImportDataIcon />,
    },
    {
      name: "导出数据",
      type: "",
      width: 146,

      size: "large",
      action: exportData,
      icon: <ExportDataIcon />,
    },
    {
      name: "扶持资金拨付表",
      type: "",
      width: 146,
      action: exportSupportFund,
      className: "ces",
      width: 146,
      icon: <ExportDataIcon />,
    },
    {
      name: "贷记凭证表",
      type: "",
      width: 146,
      action: exportCreditVoucher,
      className: "ces",
      width: 146,
      icon: <ExportDataIcon />,
    },
    {
      name: "删除",
      size: "large",
      action: destroy,
      icon: <DeleteDocIcon />,
    },
  ];
  const archivedButtonList = [
    {
      name: "新增核算表",
      size: "large",
      action: createTaxRefund,
      icon: <CreateIcon />,
    },
    {
      name: "导出数据",
      size: "large",
      action: exportData,
      icon: <ExportDataIcon />,
    },
    {
      name: "导出扶持资金拨付表",
      type: "",
      width: 146,
      action: exportSupportFund,
      className: "ces",
      width: 146,
      icon: <ExportDataIcon />,
    },
    {
      name: "导出贷记凭证表",
      type: "",
      width: 146,
      action: exportCreditVoucher,
      className: "ces",
      width: 146,
      icon: <ExportDataIcon />,
    },
  ];

  //页码更改了以后的处理,设置当前页
  const pageChange = (page) => {
    setCurrentPage(page);

    const cmd_data = {
      id: selectTaxId,
      page: page,
      size: pageSize,
      table: "eip_park_administered_enterprises_tax_refund_statement_record",
    };
    getTaxRefundStatementItemById(cmd_data);
  };

  const hideSetLastStatementBalance = () => {
    setLastStatementModalvisable(false);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <Col style={{ width: "calc(100% - 160px)" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div style={{ overflow: "auto" }}>
                <ButtonGroup size={10}>
                  <ButtonLabel size="large" />
                  {archived
                    ? archivedButtonList.map((item) => {
                        return (
                          <Button
                            size="large"
                            icon={item.icon}
                            key={item.name}
                            type={item.type}
                            onClick={item.action}
                          >
                            {item.name}
                          </Button>
                        );
                      })
                    : notArchivedButtonList.map((item) => {
                        return (
                          <Button
                            size="large"
                            icon={item.icon}
                            key={item.name}
                            type={item.type}
                            onClick={item.action}
                          >
                            {item.name}
                          </Button>
                        );
                      })}
                </ButtonGroup>
                <div
                  style={{ height: 40, margin: "0 auto", lineHeight: "40px" }}
                >
                  <Checkbox onChange={showInfoLackAccount}>
                    只看银行账户缺失企业
                  </Checkbox>
                </div>
              </div>
              <div>
                <Row className="seach-total-record-container">
                  {totalList.map((item) => {
                    return (
                      <Col
                        key={item.label}
                        span={6}
                        className="total-record-item"
                      >
                        <Row>
                          <Col
                            span={11}
                            style={{ textAlign: "right" }}
                            className="total-record-item-label"
                          >
                            {`${item.label}:`}
                          </Col>
                          <Col span={13} className="total-record-item-value">
                            {`${moneyFormat(item.value)}`}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div>
                <TaxRefundTable
                  pageSize={pageSize}
                  currentPage={currentPage}
                  reflushTableData={reflushTableData}
                  selectTaxRefundStatement={selectTaxRefundStatement}
                  archived={archived}
                  tableData={tableData}
                ></TaxRefundTable>
              </div>

              <div style={{ textAlign: "right", marginTop: 20 }}>
                <Pagination
                  showQuickJumper={true}
                  showSizeChanger={false}
                  current={currentPage}
                  pageSize={1}
                  total={totalPage}
                  showTotal={(total, range) => `共 ${totalNumber}条`}
                  onChange={pageChange}
                ></Pagination>
              </div>
            </div>
          </Col>
          <Col
            style={{
              paddingLeft: 20,
              textAlign: "right",
              width: 160,
              height: "calc(100vh - 150px)",
              overflow: "auto",
            }}
          >
            <div className="right-selector1">
              <div className="right-quarter-list">
                <Radio.Group
                  value={selectTaxRefundStatement}
                  className="quarter-list-button-group"
                >
                  {taxRefundStatementList.map((item, index) => {
                    return (
                      <Radio.Button
                        className="quarter-list-button"
                        key={index}
                        onClick={() => handleTaxStatementListChange(item)}
                        value={item}
                      >
                        <div className="quarter-list-button-group-text">
                          {item.name}
                        </div>
                        <div className="quarter-list-button-group-text">
                          {item.archivedText}
                        </div>
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          //结算上次余留的本部核算表
          destroyOnClose={true}
          closable={false}
          visible={LastStatementModalvisable}
          title="结算上次余留"
          footer={null}
          onCancel={() => {
            setLastStatementModalvisable(false);
          }}
        >
          <SetLastStatementBalance
            reflushData={reflushData}
            handleLoading={handleCreateTaxStatementLoading}
            hideSetLastStatementBalance={hideSetLastStatementBalance}
            selectTaxRefundStatement={selectTaxRefundStatement}
          />
        </Modal>
        <Modal
          //新增核算表的模态框
          destroyOnClose={true}
          visible={createTaxRefundModalvisable}
          title="创建核算表"
          footer={null}
          onCancel={() => {
            setCreateTaxRefundModalvisable(false);
          }}
        >
          <CreateTaxRefundStatement
            parkId={parkId}
            parkName={parkName}
            taxRefundStatementList={taxRefundStatementList}
            handleLoading={handleCreateTaxStatementLoading}
            hideModal={hideModal}
            reflushData={reflushData}
          ></CreateTaxRefundStatement>
        </Modal>
      </Spin>

      <UploadModal
        parkId={parkId}
        parkName={parkName}
        taxRefundId={selectTaxId}
        reflushData={reflushData}
        selectTaxRefundStatement={selectTaxRefundStatement}
        uploadFileModalChange={uploadFileModalChange}
        visible={uploadFileModalVisable}
      />
    </>
  );
};

export default ParkTaxRefund;
