import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  InputNumber,
  Divider,
  Checkbox,
  Input,
  Button,
  Space,
  DatePicker,
  Typography,
  message,
} from "antd";
import moment from "moment";

import { moneyFormat } from "@utils/index";
import { paasql } from "@api";
const { TextArea } = Input;
const { Text } = Typography;
const ModalContainer = (props) => {
  const { handleCancel, handleOk, selectTaxRefundStatement, id } = props;
  const [info, setInfo] = useState({}); //
  const [isPaymentSuccessed, setIsPaymentSuccessed] = useState(true); //是否支付成功
  const [paidAt, setPaidAt] = useState(""); //支付日期
  const [enterpriseInfo, setEnterpriseInfo] = useState(null);

  const [isLastStatement, setIsLastStatement] = useState(false); //是否计算上次余留
  var [
    isCalculatIndividualIncomeTax,
    setIsCalculatIndividualIncomeTax,
  ] = useState(false);
  var [isCalculatePropertytax, setIsCalculatePropertytax] = useState(false);
  const [defaultManagementFee, setDefaultManagementFee] = useState();
  useEffect(() => {
    const cmd_data = {
      id: id,
    };
    paasql(
      "eip.eip_park_administered_enterprises_tax_refund_statement_record.query_by_id",
      cmd_data
    ).then((data) => {
      setEnterpriseInfo(data.result[0]);
    });
  }, [id]);
  useEffect(() => {
    if (enterpriseInfo != null) {
      const {
        is_last_statement_balance_added,
        is_payment_succeeded,
        bank_name,
        paid_at,
        calculate_enterprise_individual_income_tax_refund,
        calculate_enterprise_property_tax_refund,
        remain_management_fee,
      } = enterpriseInfo;

      const is_payment_successed = is_payment_succeeded == "1" ? true : false;
      const isLastStatement =
        is_last_statement_balance_added == "1" ? true : false;
      const isCalculatIndividualIncomeTax =
        calculate_enterprise_individual_income_tax_refund == "1" ? true : false;
      const isCalculatPropertyTax =
        calculate_enterprise_property_tax_refund == "1" ? true : false;
      setIsLastStatement(isLastStatement); //是否计算上次余留
      setIsCalculatIndividualIncomeTax(isCalculatIndividualIncomeTax); //是否计算个人所得税
      setIsCalculatePropertytax(isCalculatPropertyTax); //是否计算房产税

      const now = moment().format("YYYY-MM-DD");
      const paidAtStr = paid_at == null || paid_at == "" ? now : paid_at;
      const paidAt = moment(paidAtStr).format("YYYY-MM-DD");
      setPaidAt(paidAt);
      setIsPaymentSuccessed(is_payment_successed);

      //设置默认管理费
      setDefaultManagementFee(remain_management_fee);

      setInfo({
        ...enterpriseInfo,
      });
    } else {
    }
  }, [enterpriseInfo]);

  const changeCalculateIndividualIncomeTax = (e) => {
    const calculate_individual_income = e.target.checked;

    if (calculate_individual_income) {
      const {
        total_individual_income_tax,
        enterprise_individual_income_tax_refund_rate,
      } = info;
      const tmp_value =
        (enterprise_individual_income_tax_refund_rate / 100) *
        total_individual_income_tax;
      const enterprise_individual_income_tax_refund = formatDecimal(
        tmp_value,
        2
      );
      info.enterprise_individual_income_tax_refund = enterprise_individual_income_tax_refund;
      setInfo({
        ...info,
        enterprise_individual_income_tax_refund,
      });
    }
    isCalculatIndividualIncomeTax = calculate_individual_income;
    setIsCalculatIndividualIncomeTax(calculate_individual_income);
    const value = calculate_individual_income ? "1" : "0";
    info.calculate_enterprise_individual_income_tax_refund = value;
    setInfo({
      ...info,
      calculate_enterprise_individual_income_tax_refund: value,
    });
    handelTotalTaxRefund();
  };
  var changeCalculatePropertyTaxRefund = (e) => {
    const calculate_property_tax = e.target.checked;

    if (calculate_property_tax) {
      const { total_property_tax, enterprise_property_tax_refund_rate } = info;
      const tmp_value =
        (enterprise_property_tax_refund_rate / 100) * total_property_tax;
      const enterprise_property_tax_refund = formatDecimal(tmp_value, 2);

      info.enterprise_property_tax_refund = enterprise_property_tax_refund;

      setInfo({
        ...info,
        enterprise_property_tax_refund,
      });
    }
    isCalculatePropertytax = calculate_property_tax;
    setIsCalculatePropertytax(calculate_property_tax);
    const value = calculate_property_tax ? "1" : "0";
    info.calculate_enterprise_property_tax_refund = value;
    setInfo({
      ...info,
      calculate_enterprise_property_tax_refund: value,
    });
    handelTotalTaxRefund();
  };

  const handelTotalTaxRefund = () => {
    const {
      enterprise_value_added_tax_refund,
      enterprise_corporate_income_tax_refund,
      enterprise_individual_income_tax_refund,
      enterprise_property_tax_refund,
    } = info;

    var tmp_total_enterprise_tax_refund_in_quarter = null;
    if (isCalculatIndividualIncomeTax) {
      if (isCalculatePropertytax) {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_individual_income_tax_refund) +
          Number(enterprise_property_tax_refund);
      } else {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_individual_income_tax_refund);
      }
    } else {
      if (isCalculatePropertytax) {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund) +
          Number(enterprise_property_tax_refund);
      } else {
        tmp_total_enterprise_tax_refund_in_quarter =
          Number(enterprise_value_added_tax_refund) +
          Number(enterprise_corporate_income_tax_refund);
      }
    }

    const total_enterprise_tax_refund_in_quarter = formatDecimal(
      tmp_total_enterprise_tax_refund_in_quarter,
      2
    );
    info.total_enterprise_tax_refund_in_quarter = total_enterprise_tax_refund_in_quarter;
    //这里的扶持额发生了变化
    handelEnterpriseTax();
    setInfo({
      ...info,
      total_enterprise_tax_refund_in_quarter,
    });
  };

  //更改增值税比
  const changevalueAddedTaxRefundRate = (value) => {
    const enterprise_value_added_tax_refund_rate = value / 100;

    const { total_value_added_tax_in_quarter } = info;
    const tmp_value =
      enterprise_value_added_tax_refund_rate * total_value_added_tax_in_quarter;
    const enterprise_value_added_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_value_added_tax_refund_rate = value;
    info.enterprise_value_added_tax_refund = enterprise_value_added_tax_refund;
    setInfo({
      ...info,
      enterprise_value_added_tax_refund_rate: value,
      enterprise_value_added_tax_refund,
    });
    handelTotalTaxRefund();
  };
  //更改所得税比
  const changeCorporateIncomeTaxRefundRate = (value) => {
    const enterprise_corporate_income_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_corporate_income_tax_in_quarter } = info;
    const tmp_value =
      enterprise_corporate_income_tax_refund_rate *
      total_corporate_income_tax_in_quarter;
    const enterprise_corporate_income_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_corporate_income_tax_refund_rate = value;
    info.enterprise_corporate_income_tax_refund = enterprise_corporate_income_tax_refund;
    setInfo({
      ...info,
      enterprise_corporate_income_tax_refund_rate: value,
      enterprise_corporate_income_tax_refund,
    });
    handelTotalTaxRefund();
  };

  const changeIndividualIncomeTax = (value) => {
    const enterprise_individual_income_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_individual_income_tax } = info;
    const tmp_value =
      enterprise_individual_income_tax_refund_rate *
      total_individual_income_tax;
    const enterprise_individual_income_tax_refund = formatDecimal(tmp_value, 2);
    info.enterprise_individual_income_tax_refund_rate = value;
    info.enterprise_individual_income_tax_refund = enterprise_individual_income_tax_refund;
    setInfo({
      ...info,
      enterprise_individual_income_tax_refund_rate: value,
      enterprise_individual_income_tax_refund,
    });
    handelTotalTaxRefund();
  };

  //计算房产税
  const changePropertyTax = (value) => {
    const enterprise_property_tax_refund_rate = value / 100;
    //设置显示的税额

    const { total_property_tax } = info;
    const tmp_value = enterprise_property_tax_refund_rate * total_property_tax;
    const enterprise_property_tax_refund = formatDecimal(tmp_value, 2);

    info.enterprise_property_tax_refund_rate = value;
    info.enterprise_property_tax_refund = enterprise_property_tax_refund;

    setInfo({
      ...info,
      enterprise_property_tax_refund_rate: value,
      enterprise_property_tax_refund,
    });
    handelTotalTaxRefund();
  };

  //显示列表
  const enterpriseRateAndValueList = [
    {
      field: "enterprise_value_added_tax_refund_rate",
      component: (
        <>
          <Col
            span={12}
            style={{ paddingRight: 20, borderBottom: "1px solid #F0F0F0" }}
          >
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  增值税扶持比例：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ width: 80 }}
                    onChange={changevalueAddedTaxRefundRate}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_value_added_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_value_added_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      field: "enterprise_corporate_income_tax_refund_rate",
      component: (
        <>
          <Col
            span={12}
            style={{ paddingRight: 20, borderBottom: "1px solid #F0F0F0" }}
          >
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  所得税扶持比例：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeCorporateIncomeTaxRefundRate}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_corporate_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_corporate_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      field: "enterprise_individual_income_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    style={{ paddingRight: "10px" }}
                    checked={isCalculatIndividualIncomeTax}
                    onChange={changeCalculateIndividualIncomeTax}
                  ></Checkbox>
                  个税扶持比例：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changeIndividualIncomeTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_individual_income_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {moneyFormat(info.enterprise_individual_income_tax_refund)} 元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      field: "enterprise_property_tax_refund_rate",
      component: (
        <>
          <Col span={12} style={{ paddingRight: 20, paddingTop: 10 }}>
            <Row>
              <Col span={12}>
                <div style={{ textAlign: "right", lineHeight: "32px" }}>
                  <Checkbox
                    style={{ paddingRight: "10px" }}
                    checked={isCalculatePropertytax == "1" ? true : false}
                    onChange={changeCalculatePropertyTaxRefund}
                  ></Checkbox>
                  房产税扶持比例：
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <InputNumber
                    min={0}
                    max={100}
                    onChange={changePropertyTax}
                    style={{ width: 80 }}
                    className="rate-info-input"
                    precision={2}
                    placeholder="0.00"
                    value={info.enterprise_property_tax_refund_rate}
                  />
                  %
                </div>
              </Col>
            </Row>
            <Row style={{ height: 32, lineHeight: "32px" }}>
              <Col span={12}>
                <div style={{ textAlign: "right" }}>扶持额：</div>
              </Col>
              <Col
                span={12}
                style={{ paddingLeft: 20 }}
                className="tax-refund-modal-header-item-value"
              >
                {info.enterprise_property_tax_refund === null ||
                info.enterprise_property_tax_refund === ""
                  ? "0"
                  : info.enterprise_property_tax_refund}{" "}
                元
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
  ];

  //定义一个取整的函数
  const formatDecimal = function (num, decimal) {
    num = num.toFixed(4); //精度为小数点后4位数
    num = num.toString();
    let index = num.lastIndexOf(".");
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1);
    } else {
      num = num.substring(0);
    }
    return parseFloat(num).toFixed(decimal);
  };

  //计算管理费，待付款，当前余留
  const handelEnterpriseTax = () => {
    const {
      payment_difference, //补差金额
      total_enterprise_tax_refund_in_quarter,
      is_last_statement_balance_added, //是否计算上次余留
      last_statement_balance, //
      payment_on_behalf,
      prepayment,
    } = info;
    var {
      management_fee,
      accounts_payable,
      current_balance,
      pending_payment,
    } = info;
    //计算管理费
    if (is_last_statement_balance_added === "1") {
      //如果计算上次余留
      const tmp_calculation_management_fee =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference);
      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );
      accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
      if (accounts_payable >= 0) {
        //如果本季应扶持金额 >=0
        var tmp_value = Number(accounts_payable % 100);
        current_balance = formatDecimal(tmp_value, 2);
      } else {
        current_balance = 0;
      }
      if (calculation_management_fee > Number(defaultManagementFee)) {
        //为默认管理费
        management_fee = defaultManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费
        management_fee = calculation_management_fee;
      } else {
        //为0
        management_fee = 0;
      }
      //计算上次余留情况下的本季应扶持金额
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //设置管理费和应扶持金额
    }
    //如果不计算上次余留
    else {
      const tmp_calculation_management_fee =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference);

      const calculation_management_fee = Number(
        formatDecimal(tmp_calculation_management_fee, 2)
      );

      if (calculation_management_fee > Number(defaultManagementFee)) {
        //默认管理费
        management_fee = defaultManagementFee;
      } else if (calculation_management_fee > 0) {
        //为计算出来的管理费

        management_fee = calculation_management_fee;
      } else {
        //为0
        management_fee = 0;
      }

      //不计算上次余留情况下的本季应扶持金额
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      //应扶持金额

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);

      //设置管理费和应扶持金额
    }
    //计算本季应扶持金额

    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项

    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    // ！！！！！这里有疑问
    info.pending_payment = String(pending_payment);
    info.management_fee = String(management_fee);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);

    //设置这里的数值页面未同步
    setInfo({
      ...info,
      management_fee,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };

  //计算待付款，当前余留

  const handelPendingPayment = () => {
    //判断是否计算本季余留
    const {
      payment_difference, //补差金额
      total_enterprise_tax_refund_in_quarter,
      is_last_statement_balance_added, //是否计算上次余留
      last_statement_balance, //
      payment_on_behalf,
      prepayment,
    } = info;

    var {
      management_fee,
      accounts_payable,
      current_balance,
      pending_payment,
    } = info;

    if (is_last_statement_balance_added === "1") {
      //计算上次余留
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //设置管理费和应扶持金额
    } else {
      var tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(management_fee) -
        Number(payment_on_behalf) -
        Number(prepayment);
      //应扶持金额

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
      //应扶持金额
    }

    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项
    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    // ！！！！！这里有疑问
    info.pending_payment = String(pending_payment);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);
    setInfo({
      ...info,
      pending_payment,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };

  //更改是否计算上次余留
  const changeIsLastStatement = (e) => {
    const is_last_statement_balance_added = e.target.checked;
    setIsLastStatement(is_last_statement_balance_added);
    //这里也需要处理
    const value = is_last_statement_balance_added ? "1" : "0";
    info.is_last_statement_balance_added = value;
    setInfo({
      ...info,
      is_last_statement_balance_added: value,
    });
    handelEnterpriseTax();
  };

  //更改管理费

  //更改补差

  const changePaymentDifference = (value) => {

    const tmp_info = info;
    const f_value = value === null ? "0" : value;
    tmp_info.payment_difference = f_value;

    setInfo({
      ...info,
      payment_difference: f_value,
    });
    handelEnterpriseTax();
  };

  //更改补差备注
  const changePaymentDifferenceRemark = (e) => {
    const value = e.target.value;
    setInfo({
      ...info,
      payment_difference_remark: value,
    });
  };
  //更改代扣
  const changePaymentOnBehalf = (value) => {
    const tmp_info = info;
    const f_value = value === null ? "0" : String(value);
    tmp_info.payment_on_behalf = f_value;
    setInfo({
      ...info,
      payment_on_behalf: f_value,
    });
    handelPendingPayment();
  };
  //更改预付
  const changePrepayment = (value) => {
    const tmp_info = info;
    const f_value = value === null ? "0" : String(value);
    tmp_info.prepayment = f_value;
    setInfo({
      ...info,
      prepayment: f_value,
    });
    handelEnterpriseTax();
  };

  //更改本次余留
  const changeCurrentBalance = (value) => {
    const { accounts_payable } = info;
    const f_value = value === null ? "0" : String(value);
    const tmp_pending_payment = Number(accounts_payable) - Number(f_value);
    const pending_payment = formatDecimal(tmp_pending_payment, 2);
    setInfo({
      ...info,
      pending_payment,
      current_balance: f_value,
    });
  };
  const changeManagementFee = (value) => {
    var f_value = value === null ? 0 : value;
    //管理费的事
    if (f_value > Number(defaultManagementFee)) {
      message.error("输入的管理费不能大于剩余管理费");
      f_value = defaultManagementFee;
    }
    f_value = String(f_value);
    info.management_fee = f_value;
    const {
      is_last_statement_balance_added,
      total_enterprise_tax_refund_in_quarter,
      last_statement_balance,
      payment_difference,
      payment_on_behalf,
      prepayment,
    } = info;
    var { accounts_payable, current_balance, pending_payment } = info;
    //计算应扶持金额

    if (is_last_statement_balance_added === "1") {
      const tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(last_statement_balance) +
        Number(payment_difference) -
        Number(f_value) - //管理费
        Number(payment_on_behalf) -
        Number(prepayment);

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    } else {
      const tmp_accounts_payable =
        Number(total_enterprise_tax_refund_in_quarter) +
        Number(payment_difference) -
        Number(f_value) - //管理费
        Number(payment_on_behalf) -
        Number(prepayment);

      accounts_payable = formatDecimal(tmp_accounts_payable, 2);
    }
    //计算本次余留
    accounts_payable = Number(accounts_payable); //拿到本季应扶持金额
    if (accounts_payable >= 0) {
      //如果本季应扶持金额 >=0
      var tmp_value = Number(accounts_payable % 100);
      current_balance = formatDecimal(tmp_value, 2);
    } else {
      current_balance = 0;
    }

    //计算本次代付款项
    pending_payment = accounts_payable - current_balance;

    const tmp_pending_payment = accounts_payable - current_balance;
    pending_payment = formatDecimal(tmp_pending_payment, 2);

    info.pending_payment = String(pending_payment);
    info.management_fee = String(f_value);
    info.accounts_payable = String(accounts_payable);
    info.current_balance = String(current_balance);
    //计算代付款
    setInfo({
      ...info,
      management_fee: f_value,
      accounts_payable: String(accounts_payable),
      current_balance: String(current_balance),
    });
  };

  //是否支付成功
  const changeIsPaymentSuccessed = (e) => {
    const is_payment_succeeded = e.target.checked;
    setIsPaymentSuccessed(is_payment_succeeded);
    const value = is_payment_succeeded ? "1" : "0";
    setInfo({
      ...info,
      is_payment_succeeded: value,
    });
  };
  //更改实际支付
  const changeActualAmountPaid = (value) => {
    const f_value = value === null ? "0" : String(value);
    const actual_amount_paid = f_value;
    setInfo({
      ...info,
      actual_amount_paid,
    });
  };
  //更改支付日期
  const changePaidAt = (date, dateString) => {
    setPaidAt(dateString);
    const paid_at = dateString.replace(/-/g, "");
    setInfo({
      ...info,
      paid_at,
    });
  };
  //更改备注
  const changeRemark = (e) => {
    const remark = e.target.value;
    setInfo({
      ...info,
      remark,
    });
  };

  //更改开户行
  const changeBankName = (e) => {
    const bank_name = e.target.value;
    setInfo({
      ...info,
      bank_name,
    });
  };
  //更改银行账户
  const changeBankAccountName = (e) => {
    const bank_account_name = e.target.value;
    setInfo({
      ...info,
      bank_account_name,
    });
  };

  //更改银行账户名
  const changeBankAccountNumber = (e) => {
    const bank_account_number = e.target.value;
    setInfo({
      ...info,
      bank_account_number,
    });
  };

  //更改开户行
  const changeBankBranch = (e) => {
    const bank_branch = e.target.value;
    setInfo({
      ...info,
      bank_branch,
    });
  };

  const changeBankCode = (e) => {
    const bank_code = e.target.value;
    setInfo({
      ...info,
      bank_code,
    });
  };
  const taxList = [
    {
      label: "本季增值税:",
      value: moneyFormat(info.total_value_added_tax_in_quarter),
    },
    {
      label: "本季企业所得税:",
      value: moneyFormat(info.total_corporate_income_tax_in_quarter),
    },
    {
      label: "本季所得税:",
      value: moneyFormat(info.total_individual_income_tax),
    },
    {
      label: "本季房产税:",
      value: moneyFormat(info.total_property_tax),
    },
  ];
  const refundList = [
    {
      id: "payment_difference",
      labelComponent: "补差:",
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          precision={2}
          placeholder={"0.00"}
          value={info.payment_difference}
          onChange={changePaymentDifference}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "payment_difference_remark",
      labelComponent: "备注:",
      action: changePaymentDifferenceRemark,
      value: info.payment_difference_remark,
      type: "string",
      component: (
        <Input
          placeholder={"请输入"}
          value={info.payment_difference_remark}
          onChange={changePaymentDifferenceRemark}
          className={`enterprise-info-input`}
        />
      ),
    },
    {
      id: "payment_on_behalf",
      labelComponent: <Text type="danger">代扣:</Text>,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.payment_on_behalf}
          onChange={changePaymentOnBehalf}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "prepayment",
      labelComponent: <Text type="danger">预付:</Text>,
      unit: "元",
      type: "number",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.prepayment}
          onChange={changePrepayment}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "current_balance",
      labelComponent: <Text type="danger">本次预留:</Text>,
      action: changeCurrentBalance,
      value: info.current_balance,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.current_balance}
          onChange={changeCurrentBalance}
          className={`enterprise-info-input number`}
        />
      ),
    },
    {
      id: "management_fee",
      labelComponent: <Text type="danger">管理费:</Text>,
      className: "reducer",
      action: changeManagementFee,
      value: info.management_fee,
      unit: "元",
      component: (
        <InputNumber
          placeholder={"0.00"}
          value={info.management_fee}
          onChange={changeManagementFee}
          className={`enterprise-info-input  number`}
        />
      ),
    },
  ];

  const confirmUpdata = () => {
    //eip.eip_agency_administered_enterprises_tax_refund_statement_record.update
    handleOk(info);
  };
  return (
    <>
      <div>
        <div
          style={{
            fontSize: "12px",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Row>
            <Col span={16} style={{ paddingRight: 20 }}>
              <Row>
                {taxList.map((item, index) => {
                  return (
                    <Col span={12} key={index}>
                      <Row>
                        <Col span={12} style={{ textAlign: "right" }}>
                          {item.label}
                        </Col>
                        <Col
                          span={12}
                          style={{ textAlign: "right" }}
                          className="tax-refund-modal-header-item-value"
                        >
                          {item.value}元
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col span={8}>
              <div>
                本季税额合计:
                <span className="tax-refund-modal-header-total-value">
                  {(
                    Number(info.total_value_added_tax_in_quarter) +
                    Number(info.total_corporate_income_tax_in_quarter)
                  ).toFixed(2)}
                  元
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="tax-refund-modal-container"
        style={{ padding: 10, width: 860 }}
      >
        <Row style={{ height: 120 }}>
          <Col span={18}>
            <Row>
              {enterpriseRateAndValueList.map((item) => {
                return (
                  <React.Fragment key={item.field}>
                    {item.component}
                  </React.Fragment>
                );
              })}
            </Row>
          </Col>
          <Col
            className="tax-refund-total"
            span={6}
            style={{ height: 120, paddingLeft: 20, textAlign: "center" }}
          >
            <div>本季扶持额合计:</div>
            <span className="tax-refund-modal-header-item-value">
              {moneyFormat(info.total_enterprise_tax_refund_in_quarter)}元
            </span>
          </Col>
        </Row>
        <Divider style={{ margin: "15px 0" }} />
        <div
          style={{
            height: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ height: 20, lineHeight: "20px" }}>
            上季余留：{moneyFormat(info.last_statement_balance)}
          </div>
          <div style={{ height: 20, lineHeight: "20px", textAlign: "right" }}>
            <div style={{ textAlign: "right" }}>
              <Checkbox
                onChange={changeIsLastStatement}
                checked={isLastStatement}
              >
                计入上次余额
              </Checkbox>
            </div>
          </div>
        </div>
        <Row className="park-refund-list" style={{ marginTop: "10px" }}>
          {refundList.map((item) => {
            return (
              <Col
                className="park-refund-list-item"
                key={item.id}
                span={12}
                style={{
                  height: 28,
                  marginBottom: "10px",
                  lineHeight: "28px",
                  display: "flex",

                  justifyContent: "flex-start",
                }}
              >
                <div className="info-text">{item.labelComponent}</div>
                <div className="info-input">
                  {item.component}
                  <span>{item.unit}</span>
                </div>
              </Col>
            );
          })}
        </Row>
        <Divider style={{ margin: "15px 0 10px 0" }} />
        <Row className="park-refund-bank-list" style={{ marginTop: "10px" }}>
          <Col span={8}>应扶持金额：{moneyFormat(info.accounts_payable)}元</Col>
          <Col
            span={8}
            style={{
              textAlign: "center",
            }}
          >
            待付金额：{moneyFormat(info.pending_payment)}元
          </Col>
          <Col
            span={8}
            style={{
              textAlign: "right",
            }}
          >
            实付总额：{moneyFormat(info.actual_amount_paid)}元
          </Col>

          {/* Todo */}
          <Col
            span={12}
            style={{
              paddingRight: "20px",
              margin: "10px 0px 10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="info-label">户名：</div>
            <Input
              disabled={true}
              value={info.bank_account_name}
              className="info-input"
              onChange={changeBankName}
            />
          </Col>
          <Col
            span={12}
            style={{
              paddingRight: "20px",
              margin: "10px 0px 10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="info-label">银行网点：</div>
            <Input
              value={info.bank_branch}
              className="info-input"
              onChange={changeBankBranch}
            />
          </Col>

          {/*  */}

          <Col
            span={12}
            style={{
              paddingRight: "20px",
              margin: "0 0px 10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="info-label">开户行：</div>
            <Input
              value={info.bank_name}
              className="info-input"
              onChange={changeBankName}
            />
          </Col>
          <Col
            span={12}
            style={{
              paddingRight: "20px",
              margin: "0 0px 10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="info-label">银行账号：</div>
            <Input
              value={info.bank_account_number}
              className="info-input"
              onChange={changeBankAccountNumber}
            />
          </Col>

          <Col
            span={12}
            style={{
              paddingRight: "20px",
              margin: "0 0px 10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="info-label">银行行联号：</div>
            <Input
              value={info.bank_code}
              className="info-input"
              onChange={changeBankCode}
            />
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0" }} />
        <Row style={{ marginTop: "10px" }}>
          <Col span={24} style={{ height: 28, lineHeight: "28px" }}>
            <Checkbox
              checked={isPaymentSuccessed}
              onChange={changeIsPaymentSuccessed}
            >
              是否支付成功
            </Checkbox>
          </Col>
          <Col
            className="park-refund-tax-paid-info"
            span={12}
            style={{ height: 28, lineHeight: "28px" }}
          >
            <span className="info-text">支付日期：</span>

            <DatePicker
              size={"small"}
              allowClear={false}
              disabled={!isPaymentSuccessed}
              value={moment(paidAt, "YYYY-MM-DD")}
              onChange={changePaidAt}
            />
          </Col>
          <Col
            className="park-refund-tax-paid-info"
            span={12}
            style={{ height: 28, lineHeight: "28px" }}
          >
            <span className="info-text">实际支付：</span>

            <InputNumber
              size="large"
              disabled={!isPaymentSuccessed}
              value={info.actual_amount_paid}
              onChange={changeActualAmountPaid}
              className="info-input"
            />
          </Col>
        </Row>
      </div>
      <div
        className="tax-refund-modal-container"
        style={{ padding: 10, width: 820 }}
      >
        <div>
          <TextArea
            value={info.remark}
            onChange={changeRemark}
            placeholder="请填写备注"
          />
        </div>
      </div>
      <div style={{ textAlign: "right", marginTop: 10 }}>
        <Space>
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={confirmUpdata} type="primary">
            确定更改
          </Button>
        </Space>
      </div>
    </>
  );
};

export default ModalContainer;
