import React, { useState, useEffect } from 'react';
import { paasql, rankTop, rankIn, rankOut, exportExcel } from '@api'
import { Table, Button, Tag, Radio, InputNumber, message, Popover } from 'antd'
import moment from 'moment'
import { moneyFormat } from '@utils/index'
import EnterpriseInfoPopup from './EnterpriseInfoPopup'
import increse from '@svg/increse.svg'
import decrice from '@svg/decrice.svg'
import exportIcon from '@svg/export.svg'
import {
  TotalIcon,
  TotalIconSelect,
  InIcon, InIconSelected,

  OutIcon,
  OutIconSelect,
  DecriceIcon,
  DecriceIconSelect,
  IncreseIcon,
  IncreseIconSelect
} from '@icon'
const config = [
  {
    display_name: "法人代表",
    field: "legal_representative"
  }, {
    display_name: "工商状态",
    field: "license_status"
  }, {
    display_name: "成立时间",
    field: "established_in"
  }, {
    display_name: "招商中介",
    field: "eip_investment_agency_name"
  }, {
    display_name: "税务状态",
    field: "tax_status"
  }, {
    display_name: "注册类型",
    field: "registered_genre"
  }, {
    display_name: "入驻状态",
    field: "settled_status_code"
  },
  {
    display_name: "入园时间",
    field: "settled_at"
  },
  {
    display_name: "注册地址",
    field: "registered_address"
  }, {
    display_name: "迁入前地址",
    field: "former_registered_at"
  }
]
const TaxNewGetOn = () => {

  //表格展示使用
  const [tableData, setTableData] = useState([])
  const [enterpriseInfo, setEnterpriseInfo] = useState([])


  //给导出用的
  const [exportTableData, setExportTableData] = useState([])

  const [parkId, setParkId] = useState(null)
  const [keyEnterpriseRange, setKeyEnterpriseRange] = useState(0)

  const [loading, setLoading] = useState(false)
  const headerData = [//表头数据的配置项//导出数据时使用
    { display_name: "序号", name: "index" },
    { display_name: "企业名称", name: "name" },
    { display_name: "累计纳税金额(元)", name: "tax_payable" },
    { display_name: "税收期间", name: "tax_paid_at" },
    { display_name: "排名变化", name: "rank" },
    { display_name: "所属行业", name: "industry" },
    { display_name: "同比变化", name: "compare_change" },
  ]


  const [selectRadio, setSelectRadio] = useState('a')
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    paasql("eip.park.list").then(data => {
      let result = data.result
      const { id, key_enterprise_range } = result[0]
      setParkId(id)
      setKeyEnterpriseRange(key_enterprise_range)
      const cmd_data = {
        eip_park_id: id
      }
      setLoading(true)
      rankTop(cmd_data).then(res => {
        const { keyEnterpriseList } = res
        const tableData = handleTableData(keyEnterpriseList)
        setTableData(tableData)
        setExportTableData(tableData)
      }).finally(() => {
        setLoading(false)
      })
    })
  }, [])
  const handleTableData = (data) => {

    let name = []
    for (let i = 0; i < data.length; i++) {
      const attrObj = data[i]
      if (attrObj.rank == null) {
        attrObj.rank = 0
      }
      if (attrObj.compare_change == null) {
        attrObj.compare_change = 0
      }
      attrObj.index = i + 1
      let end_at = attrObj.end_at
      var year = end_at.slice(0, 4)
      var month = end_at.slice(4, 6)
      var tax_paid_at = year + "年" + month + "月"//拼装税收期间
      attrObj.tax_paid_at = tax_paid_at

      name.push(attrObj)
    }
    return name
  }
  const columns = [
    {
      title: '排序',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      fixed: 'left',
      align: 'center',
    },
    {
      title: '企业名称',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
      // render: (text, record, index) =>
      //   <Popover key={index}
      //     placement='leftTop'
      //     content={
      //       <EnterpriseInfoPopup
      //         enterpriseInfo={enterpriseInfo}
      //       />
      //     } title={text} trigger="click">
      //     <a onClick={() => clickEnterprise(text, record, index)}>{text}</a>
      //   </Popover>
    },
    {
      title: '累计纳税金额(元)',
      dataIndex: 'tax_payable',
      key: 'tax_payable',
      width: 150,
      align: 'right',
      render: (text, record) => {
        return moneyFormat(text)
      }
    },
    {
      title: '税收期间',
      dataIndex: 'tax_paid_at',
      key: 'tax_paid_at',
      width: 150,
    }, {
      title: '排名变化',
      dataIndex: 'rank',
      key: 'rank',
      width: 150,
      render: (text, record) => {
        const { rank } = record
        return (
          <>
            <Tag color={rank >= 0 ? 'red' : 'green'}> <span>{record.rank}    <img style={{ height: 12, lineHeight: '12px' }} src={rank >= 0 ? decrice : increse} /></span></Tag>
          </>
        )
      }
    },
    {
      title: '所属行业',
      dataIndex: 'industry',
      key: 'industry',
      width: 200,
    }, {
      title: '同比变化',
      dataIndex: 'compare_change',
      key: 'compare_change',
      width: 150,
      render: (text, record) => {
        const { compare_change } = record
        return (
          <>
            <Tag color={compare_change >= 0 ? 'red' : 'green'}>{record.compare_change}  <img style={{
              height:
                12, lineHeight: '12px'
            }} src={compare_change >= 0 ? decrice : increse} /></Tag>
          </>
        )
      }
    }]
  const exportData = () => {
    const result = exportTableData
    var tmp_related_data = []
    var header_data = []
    var header_data_key = []
    //将选中的数据放在一个数组，后续根据这个数组中的属性来作为key，来将key对应的value值push进一个数组
    for (let i = 0; i < headerData.length; i++) {
      header_data.push(headerData[i].display_name)
      header_data_key.push(headerData[i].name)//表头的key的数组
    }
    tmp_related_data.push(header_data)
    //表头数据部分
    // //表格数据部分
    for (var i = 0; i < tableData.length; i++) {
      var single_row_data = []
      tableData[i].index = String(i + 1)
      for (var k = 0; k < header_data_key.length; k++) {//循环单行数据，根据表头的key
        for (var key in tableData[i]) {
          if (header_data_key[k] == key) {
            if (tableData[i][key] != null) {
              single_row_data.push(String(tableData[i][key]))//单行数据
            } else {
              single_row_data.push("")//单行数据
            }
          } else {
          }
        }
      }
      tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，导入
    }

    exportExcel(tmp_related_data, '重点企业排行榜')
  }

  const clickEnterprise = (text, record, index) => {
    const eip_enterprise = record.eip_enterprise_id
    const cmd_data = {
      "eip_enterprise_id": eip_enterprise
    }
    paasql("eip.eip_enterprise.enterprise_and_standard_data_by_enterprise_id", cmd_data).then(data => {
      //处理结果
      if (data.result.length == 0) {
        setEnterpriseInfo([])
      } else {
        const result = data.result[0]
        const {
          paasql_parallel
        } = result
        var tax_status = null//code
        var registered_genre = null //code
        var settled_status_code = null//code
        for (var i = 0; i < paasql_parallel.length; i++) {
          for (const key in paasql_parallel[i]) {
            if (key == "settled_code") {
              tax_status = paasql_parallel[i][key].name
            }
            if (key == "registered_genre_code") {
              registered_genre = paasql_parallel[i][key].name
            }
            if (key == "settled_status_code_code") {
              settled_status_code = paasql_parallel[i][key].name
            }
          }
        }
        //获取企业的信息，包含标准数据

        var enterprise_info = {
          ...result,
          tax_status,
          registered_genre,
          settled_status_code
        }
        for (let i = 0; i < config.length; i++) {
          for (const key in enterprise_info) {
            if (key == config[i].field) {

              config[i].value = enterprise_info[key]
            }
          }
        }

        setEnterpriseInfo(config)
      }
    })
  }
  const newGetOn = () => {
    setCurrentPage(1)

    const cmd_data = {
      eip_park_id: parkId
    }
    setLoading(true)
    rankTop(cmd_data).then(res => {
      const { keyEnterpriseList } = res
      const tableData = handleTableData(keyEnterpriseList)
      setTableData(tableData)
      setExportTableData(tableData)
    }).finally(() => {
      setLoading(false)
    })
  }
  const growthRankingListYoy = () => {
    setCurrentPage(1)

    const cmd_data = {
      eip_park_id: parkId
    }
    setLoading(true)
    rankTop(cmd_data).then(res => {
      const { keyEnterpriseList } = res
      const dataWithSort = keyEnterpriseList.sort(function (first, last) {
        return Number(last.compare_change) - Number(first.compare_change)
      })
      const tableData = handleTableData(dataWithSort)
      setTableData(tableData)
      // setExportTableData(dataWithSort)
      setExportTableData(tableData)
    }).finally(() => {
      setLoading(false)
    })


  }
  const downRankingListYoy = () => {
    setCurrentPage(1)

    const cmd_data = {
      eip_park_id: parkId
    }
    setLoading(true)
    rankTop(cmd_data).then(res => {
      const { keyEnterpriseList } = res
      const dataWithSort = keyEnterpriseList.sort(function (first, last) {
        return Number(first.compare_change) - Number(last.compare_change)
      })
      const tableData = handleTableData(dataWithSort)
      setTableData(tableData)
      setExportTableData(tableData)
    }).finally(() => {
      setLoading(false)

    })
  }
  const taxRankOut = () => {
    setCurrentPage(1)

    const cmd_data = {
      eip_park_id: parkId
    }
    setLoading(true)
    rankOut(cmd_data).then(data => {
      const tableData = handleTableData(data)
      setTableData(tableData)
      setExportTableData(tableData)
    }).finally(() => {
      setLoading(false)

    })
  }
  const taxRankIn = () => {
    setCurrentPage(1)
    const cmd_data = {
      eip_park_id: parkId
    }
    setLoading(true)
    rankIn(cmd_data).then(data => {
      const tableData = handleTableData(data)
      setTableData(tableData)
      setExportTableData(tableData)
    }).finally(() => {
      setLoading(false)
    })
  }
  const changeKeyEnterpriseRange = (value) => {
    //eip.eip_park.update_key_enterprise_range
    setKeyEnterpriseRange(value)
  }

  const saveKeyEnterpriseRange = () => {
    const cmd_data = {
      eip_park_id: parkId,
      key_enterprise_range: keyEnterpriseRange
    }
    paasql('eip.eip_park.update_key_enterprise_range', cmd_data).then(data => {
      if (data.error == null) {
        message.success('保存成功')
        window.location.reload()
      } else {
        message.error(data.error.message)
      }
    })
  }
  const clickRow = () => {

  }

  const changePage = (page) => {
    setCurrentPage(page)
  }
  const changeRadio = (e) => {
    const value = e.target.value
    setSelectRadio(value)
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Radio.Group
            value={selectRadio}
            onChange={changeRadio}
            defaultValue="a" size="large">
            <Radio.Button
              className='tax-warning-radio' onClick={newGetOn}
              value="a">
              {
                selectRadio !== 'a' ?
                  <TotalIcon /> : <TotalIconSelect />
              }累计纳税金额</Radio.Button>
            <Radio.Button
              className='tax-warning-radio'
              onClick={taxRankIn}
              value="b">
              {
                selectRadio !== 'b' ?
                  <InIcon /> : <InIconSelected />
              }今年入榜</Radio.Button>

            <Radio.Button className='tax-warning-radio'
              onClick={taxRankOut}
              value="c">
              {
                selectRadio !== 'c' ?
                  <OutIcon /> : <OutIconSelect />
              }
              今年落榜</Radio.Button>
            <Radio.Button
              className='tax-warning-radio'
              onClick={growthRankingListYoy}
              value="d">
              {
                selectRadio !== 'd' ?
                  <DecriceIcon /> : <DecriceIconSelect />
              }
              增长率升序</Radio.Button>
            <Radio.Button
              className='tax-warning-radio'
              onClick={downRankingListYoy}
              value="f">
              {
                selectRadio !== 'f' ?
                  <IncreseIcon /> : <IncreseIconSelect />
              }

              增长率降序</Radio.Button>
          </Radio.Group>
          <span style={{ paddingLeft: 20 }}>
            前 <InputNumber onChange={changeKeyEnterpriseRange} style={{ width: 100, height: '40px', lineHeight: '40px' }} placeholder={"请输入数值"} />

            <Button type="link" onClick={saveKeyEnterpriseRange}>确定</Button>
          </span>

        </div>
        <div>
          <Button
            size='large'
            icon={<img className='nav-icon' src={exportIcon} />}
            type="primary" onClick={exportData}>
            导出数据
          </Button>
        </div>

      </div>
      <div
        style={{ margin: '20px 0px' }}
      >


        <Table
          size='small'
          loading={loading}
          columns={columns}
          dataSource={tableData}
          bordered
          onRow={record => {
            return {
              onClick: () => clickRow(record)
            };
          }}
          rowKey={
            record => record.name
          }
          pagination={{
            current: currentPage,
            size: 'default',
            showQuickJumper: true,
            pageSize: 20,
            onChange: changePage,
            hideOnSinglePage: true,
            showSizeChanger: false
          }}
          //设置宽度
          scroll={{ y: 'calc(100vh - 290px)' }}
        />
      </div>
    </div>
  );
}

export default TaxNewGetOn;