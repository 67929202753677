//用来整合前端涉及金额千分位的格式化函数

/*
  @
*/
function moneyFormat(s, n = 2) {
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse();
  var r = s.split(".")[1];
  var t = "";
  if (s < 0) {
    l = ((Math.abs(s.split(".")[0])) + "").split("").reverse();
  }
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  if (s < 0) {
    t += "-";
  }
  var result = t.split("").reverse().join("") + "." + r;
  if (result == null || result == undefined || result.indexOf('NaN') >= 0 || result.indexOf('Undefined') >= 0) {
    result = '0.00';
  }
  return result;
}




function fuzzyQuery(list, keyWord, matchKey) {
  if (matchKey === undefined) {
    let reg = new RegExp(keyWord);
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      if (reg.test(list[i])) {
        arr.push(list[i]);
      }
    }
    return arr;
  }
  let reg = new RegExp(keyWord);
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    if (reg.test(list[i][matchKey])) {
      arr.push(list[i]);
    }
  }
  return arr;
}


function isNil(value) {
  const _isNil = value === null || value === undefined || value === ''
  return _isNil
}

function formatJson(filterVal, jsonData) {
  return jsonData.map((v) =>
    filterVal.map((j) => {
      return v[j];
    })
  );
}

export {
  moneyFormat,
  fuzzyQuery,
  isNil,
  formatJson
}