import React, { memo,useState,useEffect } from "react";

import { Divider } from "antd";
import moment from "moment";
function RichText(props) {
  const { content, name, time } = props;
  const [Time, setTime] = useState(0);
  useEffect(() => {
    if (time !== null) {
      let _time = moment(time / 1000).format("YYYY-MM-DD HH:mm");

      setTime(_time);
    }
  }, [time]);
  return (
    // <div className="virtual-park-introduction">
    //   <div dangerouslySetInnerHTML={{ __html: content }}></div>
    // </div>

    <div className="virtual-park-introduction">
      <div className="title">
        <div>{name}</div>
        <div className="time">{Time}</div>
      </div>
      <Divider>
        <div className="divider-container"></div>
      </Divider>

      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  );
}

export default memo(RichText);
