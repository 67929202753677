export const multiHeader = [
  [
    "",
    "",
    "申报单位盖章",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "序号",
    "招商点",
    "企业名称",
    "税管码",
    "企业扶持资金按税种分",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  [
    "",
    "",
    "",
    "",
    "增值税",
    "",
    "企业所得税",
    "",
    "所得税",
    "",
    "房产税",
    "",
    "扶持金额合计",
    "上季余留",
    "累计补差",
    "累计管理费",
    "累计代扣",
    "累计预付款",
    "累计应支付金额",
    "累计已支付金额",
    "所选时段未支付金额",
  ]

]

export const header = ["", "", "", "", "总税额", "扶持金额", "总税额", "扶持金额", "总税额", "扶持金额",  "总税额", "扶持金额",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
]

export const filterVal = ["index", "eip_park_name", "name", "tax_file_number", "total_value_added_tax", "total_value_added_tax_refund", "total_corporate_income_tax",
  "total_corporate_income_tax_refund", "total_individual_income_tax", "total_individual_income_tax_refund", "total_property_tax", "total_property_tax_refund", "total_tax_refund", "last_year_statement_balance", "total_payment_difference", "total_management_fee", "total_payment_on_behalf", "total_prepayment", "total_accounts_payable", "total_actual_amount_paid", "unpaid_amount"
]

export const merges = [
  "C1:Z1",
  "A3:A5",
  "B3:B5",
  "C3:C5",
  "D3:D5",
  "E3:U3",
  "E4:F4",
  "G4:H4",
  "I4:J4",
  "K4:L4",
  "M4:M5",
  "N4:N5",
  "O4:O5",
  "P4:P5",
  "Q4:Q5",
  "R4:R5",
  "S4:S5",
  "T4:T5",
  "U4:U5",
];