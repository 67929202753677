import React, { useState, useEffect, memo } from "react";
import echarts from "echarts";

import { agencySettleExitedRecord } from "@api";
function AgencySettleExitedRecord(props) {
  const { parkId, virtualParkId, eipInvestmentAgencyId } = props;
  const [inNumber, setInNumber] = useState(0);
  const [outNumber, setOutNumber] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (eipInvestmentAgencyId !== null) {
      initChart();
    }
  }, [eipInvestmentAgencyId]);

  //初始化表格
  function initChart() {
    agencySettleExitedRecord(eipInvestmentAgencyId, parkId).then((res) => {
      const { data, list } = res.result;
      const { In, Out, Total } = data;
      setInNumber(In);
      setOutNumber(Out);
      setTotal(Total);
      const inList = [];
      const outList = [];
      list.forEach((element) => {
        if (element.type === 1) {
          inList.push(element.amount);
        } else {
          outList.push(element.amount);
        }
      });

      const option = {
        color: ["#3398DB"],
        title: {
          text: "每月离进园企业",
          left: 20,
          top: 10,
          subtext: "家",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "进园",
            type: "bar",
            data: inList,
          },
          {
            name: "离园",
            type: "bar",
            data: outList,
          },
        ],
      };
      const myEchart = echarts.init(
        window.document.getElementById("agencySettleExitedRecord")
      );
      window.addEventListener("resize", () => {
        myEchart.resize();
      });
      if (myEchart !== null) {
        myEchart.setOption(option);
      }
    });
  }
  return (
    <div className="agency-settle-exited-container">
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        id="agencySettleExitedRecord"
      ></div>
    </div>
  );
}
export default memo(AgencySettleExitedRecord);
