import React, { useState, useEffect } from 'react';

import { Button, message, Row, Col, Empty, List, Modal } from 'antd'

import { paasql } from '@api'
import ButtonLabel from '@component/ButtonLabel'
import ParkStaffEdit from './ParkStaffEdit'
import ParkStaffDetail from './ParkStaffDetail'
import CreateParkStaff from './CreateParkStaff'
import './index.less'
import { RightOutlined } from '@ant-design/icons'

import { RightOutlinedIcon, CreateIcon } from '@icon'
const ButtonGroup = Button.Group

const VirtualParkManagement = () => {
  const [parkId, setParkId] = useState(null)
  const [staffList, setStaffList] = useState([])
  const [empty, setEmpty] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [activeId, setActiveId] = useState(null)


  const [createParkStaffVisiable, setCreateParkStaffVisiable] = useState(false)
  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const parkId = data.result[0].id
        setParkId(parkId)
        const cmd_data = {
          eip_park_id: parkId
        }
        paasql('eip.cp_staff.list_by_eip_park_id', cmd_data).then(staffList => {
          if (staffList.error == null) {
            const result = staffList.result

            if (result.length == 0) {
              setEmpty(true)
            } else {
              result[0].isActive = true
              setStaffList(result)
              setActiveId(result[0].id)
            }
          } else {
            message.error(staffList.error.message)
          }
        })
      } else {
        message.error(data.error.message)
      }
    })

    return () => {

    }
  }, [])

  const createStaff = () => {
    setCreateParkStaffVisiable(true)
  }

  const reflushData = (id) => {
    const cmd_data = {
      eip_park_id: parkId
    }
    paasql('eip.cp_staff.list_by_eip_park_id', cmd_data).then(staffList => {
      if (staffList.error == null) {
        const result = staffList.result

        if (result.length == 0) {
          setEmpty(true)
        } else {
          result[0].isActive = true
          setStaffList(result)
          if (id === undefined) {
            setActiveId(result[0].id)
            return
          }
          setActiveId(id)

        }
      } else {
        message.error(staffList.error.message)
      }
    })
  }

  const changeListClick = (item, index) => {
    setActiveId(item.id)
    setIsEdit(false)

    const tmpVirtualParkList = staffList
    for (let i = 0; i < tmpVirtualParkList.length; i++) {
      if (i === index) {
        tmpVirtualParkList[i].isActive = true

      } else {
        tmpVirtualParkList[i].isActive = false
      }
    }
    setStaffList([...tmpVirtualParkList])
  }

  const changeEdit = (status) => {
    setIsEdit(status)
  }

  const hideModal = () => {
    setCreateParkStaffVisiable(false)
  }

  return (
    <div >
      <div>
        <ButtonGroup>
          <ButtonLabel
            size='large'

          >

          </ButtonLabel>
          <Button
            size='large'
            icon={<CreateIcon />}
            onClick={createStaff}
          >
            新增园区工作人员
          </Button>
        </ButtonGroup>

      </div>
      <div className='park-staff-container' style={{
        maxHeight: 'calc(100vh  - 230px)',
        overflow: 'auto',
      }}>
        {
          empty ? <Empty
          /> :
            <Row
            >
              <Col span={4}
                style={{ borderRight: '1px solid #E0E0E0' }}
              >
                <List
                  className='park-staff-list'
                  pagination={{
                    simple: true,
                    pageSize: 15,
                    hideOnSinglePage: true
                  }}
                  dataSource={staffList}
                  renderItem={(item, index) => (
                    <List.Item
                      className="park-staff-list-item"
                      onClick={() =>
                        changeListClick(item, index)}
                    >
                      <span className={activeId === item.id ? 'active-staff-item' : ''}>
                        {item.name}
                      </span>
                      <div>
                        {
                          activeId === item.id ? <>
                            <RightOutlinedIcon style={{ width: '8px !important' }} />
                          </> : <> </>
                        }
                      </div>

                    </List.Item>
                  )}
                />
              </Col>
              <Col span={20}
                className="park-staff-detail-edit-container"
              >
                {
                  isEdit ?
                    <ParkStaffEdit
                      parkId={parkId}
                      isEdit={isEdit}
                      changeEdit={changeEdit}
                      reflushData={reflushData}
                      staffId={activeId}
                    />

                    : <ParkStaffDetail
                      changeEdit={changeEdit}
                      reflushData={reflushData}
                      staffId={activeId}
                    />
                }
              </Col>
            </Row>

        }
        <div>
        </div>
      </div>
      <Modal
        title='新增园区工作人员'
        onCancel={() => {
          setCreateParkStaffVisiable(false)
        }}
        destroyOnClose={true}
        footer={null}
        visible={createParkStaffVisiable}
      >
        <CreateParkStaff
          hideModal={hideModal}
          parkId={parkId}
          reflushData={reflushData}
        />
      </Modal>
    </div>
  );
}

export default VirtualParkManagement;