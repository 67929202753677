import React, { useState, useEffect, memo } from "react";

import { Select, Row, Col } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
//四个图表
import AgencySettleExitedRecord from "./VirtualPark/AgencySettleExitedRecord";
import RichText from "./VirtualPark/RichText";
import TaxCompare from "./VirtualPark/TaxCompare";
import TaxCumulative from "./VirtualPark/TaxCumulative";
import "./VirtualPark/index.less";
import {
  paasql,
  getEipParkId,
  eipVirtualParkQueryById,
  eipVirtualParkList,
} from "@api";

const { Option } = Select;
const VirtualPark = () => {
  const [parkId, setParkId] = useState(null);
  const [virtualParkList, setVirtualParkList] = useState([]);

  const [selectedVirtualParkId, setSelectedVirtualParkId] = useState(null);
  const [content, setContent] = useState(null);
  const [name, setName] = useState(null);
  const [time ,setTime] = useState(null)
  const [eipInvestmentAgencyId, setEipInvestmentAgencyId] = useState(null);

  useEffect(() => {
    getEipParkId().then((res) => {
      const parkId = res;
      setParkId(parkId);
      eipVirtualParkList(parkId).then((res) => {
        setVirtualParkList(res);
        if (res.length !== 0) {

          const {
            id,
            brief_introduction,
            name,
            eip_investment_agency_id,
            cp_timestamp
          } = res[0];


          setEipInvestmentAgencyId(eip_investment_agency_id);
          setContent(brief_introduction);
          setTime(cp_timestamp)
          setName(name);
          setSelectedVirtualParkId(id);

          eipVirtualParkQueryById(id).then((res) => {});
        }
      });
    });
  }, []);

  //选中不同虚拟园区后的回调函数
  function changeVirtualPark(value, option) {
    const activeselectedVirtualParkId = value;
    const index = virtualParkList.findIndex(
      (item) => item.id === activeselectedVirtualParkId
    );

    setContent(virtualParkList[index].brief_introduction);
    setName(virtualParkList[index].name);
    setTime(virtualParkList[index].cp_timestamp)
    setSelectedVirtualParkId(value);
    setEipInvestmentAgencyId(option.agency_id);
  }
  return (
    <div >
      <div
      >
        园中园 ：
        <Select
          onChange={changeVirtualPark}
          suffixIcon={<CaretDownOutlined />}
          value={selectedVirtualParkId}
          style={{
            width: 200,
          }}
        >
          {virtualParkList.map((item, index) => {
            return (
              <Option
                key={index}
                agency_id={item.eip_investment_agency_id}
                value={item.id}
              >
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>

      {/* 图表 */}
      <div className="virtual-park-chart-container">
        <Row className="first-row">
          <Col span={12} className="first-col">
            <RichText 
              time={time}
              content={content} name={name} />
          </Col>
          <Col className="secound-col" span={12}>
            <AgencySettleExitedRecord
              parkId={parkId}
              virtualParkId={selectedVirtualParkId}
              eipInvestmentAgencyId={eipInvestmentAgencyId}
            />
          </Col>
        </Row>
        <Row className="secound-row">
          <Col span={12} className="first-col">
            <TaxCompare
              parkId={parkId}
              virtualParkId={selectedVirtualParkId}
              eipInvestmentAgencyId={eipInvestmentAgencyId}
            />
          </Col>
          <Col span={12} className="secound-col">
            <TaxCumulative
              parkId={parkId}
              virtualParkId={selectedVirtualParkId}
              eipInvestmentAgencyId={eipInvestmentAgencyId}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(VirtualPark);
