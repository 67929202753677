import React, { useState, useEffect, createRef } from 'react';

import { Button, Input, Space, Row, Col, message, Select } from 'antd'
import E from 'wangeditor'
import { paasql } from '@api'
import { uploadImg } from '@api/paasql'

import {CaretDownOutlined} from '@ant-design/icons'

const editorRef = createRef()

const { Option } = Select;
const CreateInvestmentAgency = (props) => {
  const { parkId, hideModal, reflushData, parkName } = props

  const [content, setContent] = useState('')
  const [name, setName] = useState(null)
  const [investmentAgencyId, setInvestmentAgencyId] = useState(null)
  const [investmentAgencyName, setInvestmentAgencyName] = useState(null)

  const [investmentAgencyList, setInvestmentAgencyList] = useState([])
  useEffect(() => {
    initEditor()
    const cmd_data = {
      eip_park_id: parkId
    }
    paasql('eip.eip_investment_agency.list', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result
        setInvestmentAgencyList(result)
      } else {
        message.error(data.error.message)
      }
    })
  }, [])


  const initEditor = () => {
    const editor = new E(editorRef.current)

    editor.customConfig.onchange = (html) => {
      // html 即变化之后的内容

      setContent(html)
    }
    editor.customConfig.menus = [
      'head',
      'bold',
      'italic',
      'underline',
      'justify',
      'image',
    ]
    editor.customConfig.zIndex = 100
    editor.customConfig.customUploadImg = function (files, insert) {
      const formData = new FormData();
      files.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file);
      })

      const request = {
        cp_schema_id: "cp_file_meta",
        cp_object_attribute: "id",
        file_object: formData
      }
      uploadImg(request).then(data => {
        if (data.error == null) {
          const fileId = data.result.id
          const url = `/com.ctyle.eip.demo.local_file_storage/${fileId}/${fileId}`
          insert(url)
        } else {
          message.error(data.error.message)
        }
      })
    }

    editor.create()
    editor.txt.html(content)
  }
  const saveChange = () => {
    if (name == null) {
      message.error('名字是必填项')
      return
    }

    const cmd_data = {
      object: {
        eip_park_id: parkId,
        eip_park_name: parkName,
        name,
        brief_introduction: content,
        eip_investment_agency_id: investmentAgencyId,
        eip_investment_agency_name: investmentAgencyName
      }
    }
    paasql("eip.eip_virtual_park.create", cmd_data).then(data => {
      if (data.error == null) {
        message.success("保存成功")
        const { id } = data.result
        reflushData(id)
        hideModal()
      } else {
        message.error(data.error.message)
      }
    })
  }

  const changeName = (e) => {
    const value = e.target.value
    setName(value)
  }
  const changeInvestmentAgency = (v, option) => {
    const { key, value } = option

    setInvestmentAgencyId(key)
    setInvestmentAgencyName(value)

  }
  return (
    <div>
      <div>
        <Row>
          <Col span={12} style={{ paddingRight: 20 }} >
            <div className='edit-bank-info-label'>
              名称
            </div>
            <Input
              placeholder={'请输入名称'} className='create-investment-agency-input'
              style={{ width: '100%' }} min={0} max={100} value={name}
              onChange={changeName} />
          </Col>
          <Col span={12}  >
            <div className='edit-bank-info-label'>
              招商中介名称
            </div>
            <Select
              placeholder={'请选择招商中介'}
              showSearch
              onChange={changeInvestmentAgency}
              style={{ width: '100%' }}
              suffixIcon={<CaretDownOutlined />}
            >
              {
                investmentAgencyList.map(item => {
                  return (
                    <Option
                      key={item.id}
                      value={item.name}
                    >
                      {item.name}
                    </Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col span={24} >
            <div className='edit-bank-info-label'>
              简介
            </div>
            <div ref={editorRef}>

            </div>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Space>
          <Button onClick={hideModal}>
            取消
          </Button>
          <Button
            type='primary'
            onClick={saveChange}
          >
            保存
          </Button>
        </Space>
      </div>

    </div>
  );
}

export default CreateInvestmentAgency;