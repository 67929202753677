import { combineReducers } from 'redux'

import ImportData from './ImportData'
import AgencyEnterpriseInfo from './AgencyEnterpriseInfo'
import PoliceManagement from './PoliceManagement'
import PolicyOnline from './PolicyOnline'
import Frame from './Frame'

//招商中介管理
import InvestmentAgencyManagement from './InvestmentAgencyManagement'

export default combineReducers({
  ImportData,
  AgencyEnterpriseInfo,
  PoliceManagement,
  PolicyOnline,
  Frame,
  InvestmentAgencyManagement
})
