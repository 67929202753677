import React from 'react';
//引入相关路由组件
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { mainRouter } from './router'
import store from './store'
import { Provider } from 'react-redux'
function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Switch>
            {
              mainRouter.map(route => {
                return (
                  <Route
                    key={route.pathname}
                    path={route.pathname}
                    exact={route.exact}
                    render={(routeProps) => {
                      document.title = route.title || '华严智慧园区'
                      return (
                        <route.component {...routeProps} />
                      )
                    }}
                  >

                  </Route>
                )
              })
            }
            <Redirect to='/admin' from='/' exact />
          </Switch>

        </Router>

      </Provider>

    </>

  );
}

export default App;
