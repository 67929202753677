
//更改企业核算记录的action

export default {

  //政策管理的
  SET_DEFAULT_STATE: 'SET_DEFAULT_STATE',
  SET_POLICE_LIST: 'SET_POLICE_LIST',
  SET_NEXT_POLICEID: 'SET_NEXT_POLICEID',

  //在线政策的
  SET_POLICE_ONLINE_LIST:'SET_POLICE_ONLINE_LIST',
  SET_DEFAULT_POLICE_ONLINE_STATE: 'SET_DEFAULT_POLICE_ONLINE_STATE',
  SET_NEXT_POLICE_ONLINE_ID: 'SET_NEXT_POLICE_ONLINE_ID',
}
