
import React from 'react';
import { Descriptions, Row, Col } from 'antd'
import { moneyFormat } from '@utils/index'
const TaxInfoPopup = (props) => {
  const { taxInfo } = props
  return (
    <>
      <Descriptions
        size='small'
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        style={{ width: 800 }} >
        {
          taxInfo.map(item => {
            return (
              <Descriptions.Item
                key={item.name}
                label={item.display_name}>
                {moneyFormat(item.value)}
              </Descriptions.Item>
            )
          })
        }
      </Descriptions>
    </>
  );
}

export default TaxInfoPopup;