
const defaultState = {
  'cp_staff_List': []
}


export default (state = defaultState, action) => {
  switch (action.type) {
    case "CHANGE_CP_STAFF_LIST":
      const cp_staff_List = state.value
      return {
        cp_staff_List
      }
    default:
      return state
  }
}
