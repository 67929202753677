
const defaultState = {

}


export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_DEFAULT_STATE": {
      return {
        ...state,
        ...action.value
      }
    }
    default:
      return state
  }
}

