import React, { useState, useEffect } from 'react';

import { Layout, Menu, Breadcrumb, Dropdown, Avatar, Modal, message } from 'antd';
import { connect } from 'react-redux'
import { UserOutlined, LaptopOutlined, NotificationOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './frame.less'
import logo from '@svg/huayanlogo.svg'
import avatar from '@svg/adminAvatar.svg'
import dropdown from '@svg/header-right-dropdown.svg'

import { getMenuList } from '../../router/menuConfig'
import { getSession } from '@api'



import loginOut from '@svg/退出登陆.svg'


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { confirm } = Modal;

//路由菜单的配置，可以模块化到另一个文件
const Frame = (props) => {
  const { location, Frame, dispatch } = props

  const { breadcurumbList } = Frame
  const [menuList, setMenuList] = useState([{
    title: '',
    main_menu_key: 'test',
    sub_menu: []
  }]
  )
  const [breadcrumbFirst, setBreadcrumbFirst] = useState('')
  const [breadcrumbSecound, setBreadcrumbSecound] = useState('')
  const [openKeys, setOpenKeys] = useState([''])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [showBreadcurumbList, setBreadcrumbList] = useState([])

  useEffect(() => {
    setBreadcrumbList(breadcurumbList)
  }, [breadcurumbList])
  useEffect(() => {
    const { session } = getSession()
    if (session == null) {
      message.error("还未登录")
      props.history.push('/login')
      return
    }

    getMenuList().then(data => {
      const { list } = data
      const menuList = list
      setMenuList(list)

      const path = props.location.pathname
      if (path == '/') {
        props.history.push(menuList[0].sub_menu[0].route)
        return
      } else {
        const pathStr = path.replace('/', '')
        const pathArr = [pathStr]
        //还需要处理初始化状态下的子菜单的选中状态
        const arr1 = []
        for (let i = 0; i < menuList.length; i++) {
          const index = menuList[i].sub_menu.findIndex(item => item.key === pathStr)
          const isOpen = index === -1 ? false : true
          if (isOpen) {
            const arr = [menuList[i].main_menu_key]
            //处理面包屑导航
            arr1.push(menuList[i].main_menu_title)
            arr1.push(menuList[i].sub_menu[index].title)
            //处理子菜单展开
            setOpenKeys(arr)
          }
        }

        dispatch({
          type: 'SET_BREADCRUMB_LIST',
          value: arr1
        })
        setSelectedKeys(pathArr)
      }
    })
    //进入该页面的时候，跳转到第一项
    //这里还需要处理进入路由时的选项控制

  }, [location])
  const ClickMenu = (route, parentTitle, title) => {
    setBreadcrumbFirst(parentTitle)
    setBreadcrumbSecound(title)
    props.history.push(route)
  }
  const SelectMenu = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setSelectedKeys(selectedKeys)
  }
  const onOpenChange = (openKeys) => {
    setOpenKeys(openKeys)
  }
  const onDropdownMenuClick = () => {
  }
  const LoginModel = () => {
    confirm({
      title: '确认退出',
      icon: <ExclamationCircleOutlined />,
      content: '你确定要退出登录吗？',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      //确认退出的回调函数
      onOk() {

        //需要做的事：
        //1、清除session
        window.localStorage.clear();
        //2、返回到登陆页完成
        message.success("已退出登录", 0.4, () => {
          props.history.push('/login')
        })

      },
      onCancel() {
        // message.success("退出选择")
      },
    });
  }
  const renderDropdown = () => (
    <div style={{ paddingTop: '10px' }}>
      <Menu onClick={onDropdownMenuClick} style={{ borderRadius: "8px" }}>
        <Menu.Item
          key="login-out"
          onClick={LoginModel}
        >
          <img src={loginOut} alt="退出登录" /> 退出登录
      </Menu.Item>
      </Menu>
    </div>
  )
  return (
    <>
      <Layout style={{ width: "100vw", height: "100vh" }} >
        <Header className="header" style={{ paddingLeft: '24px' }} >
          <div className="hy-logo" >
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div>
            <Dropdown
              trigger={['click']}
              overlay={renderDropdown()}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                <Avatar src={avatar} />
                <div>
                  华严开发区
                </div>
                <img src={dropdown} alt="" style={{ paddingLeft: 10 }} />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Layout
        >
          <Sider
            width={200}
          >
            <Menu
              mode="inline"
              className="menu"
              onOpenChange={onOpenChange}
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              style={{ height: '100%', borderRight: 0 }}
              onSelect={SelectMenu}
            >
              {
                menuList.map(menu => {
                  return (
                    <SubMenu
                      key={menu.main_menu_key}
                      title={
                        <span >
                          {menu.icon}

                          {menu.main_menu_title}
                        </span>
                      }
                    >
                      {
                        menu.sub_menu.map(submenu => {
                          return (
                            <Menu.Item
                              className="menu-item"
                              key={submenu.key}
                              onClick={() => ClickMenu(submenu.route, submenu.parent_menu, submenu.title)}
                            >
                              <div className="menu-text">
                                {submenu.title}
                              </div>
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                  )
                })
              }

            </Menu>
          </Sider>

          <Layout
            style={{ padding: '10px 30px 20px ', overflow: 'auto' }}>
            <Breadcrumb style={{ height: '24px', maxHeight: "24px", fontSize: "24px" }}>
              {
                showBreadcurumbList.map((item, index) => {
                  return (
                    <Breadcrumb.Item
                      key={index}
                    >
                      {item}
                    </Breadcrumb.Item>
                  )
                })
              }
            </Breadcrumb>
            <Content
              className='child-content'
            >
              {props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    Frame: state.Frame
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Frame);