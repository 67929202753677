import React, { useState, useEffect, createContext } from 'react';
import { Modal, message, Checkbox, Row, Col, Button, Steps, Spin } from 'antd'
import SelectInfo from './component/SelectInfo'//上传文件组件
import SelectHeader from './component/SelectHeader'
import FieldMapping from './component/FieldMapping'
import ImportResult from './component/ImportResult'
import { paasql,readExcelHeader } from '@api'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return state
}

export const ReachableContext = createContext();

const { Step } = Steps;

const steps = [
  {
    title: '第1步',
    description: "导入文档",
    content:
      <SelectInfo />,
  },
  {
    title: '第2步',
    description: "导入设置 ",
    content: <SelectHeader />,
  },
  {
    title: '第3步',
    description: "建立映射",
    content: <FieldMapping />,
  },
  {
    title: '第4步',
    description: "导入工作表",
    content: <ImportResult />,
  },
];


const UploadModel = (props) => {
  const { visible, modalChange, parkId } = props

  const [visiblable, setVisiblable] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [importResult, setImportResult] = useState(false)
  const [importResultData, setImportResultData] = useState({
    not_exist_tax: [{
      name: "上海聿泓贸易有限公司",
      reason: "本次导入入未涉及",
      tax_file_number: "310226342034200",
    }],
    not_include_enterprise: [
      {
        name: "上海聿泓贸易有限公司",
        reason: "本次导入入未涉及",
        tax_file_number: "310226342034200",
      }
    ],
    success_num: 1868
  })
  const [current, setCurrent] = useState(0)

  const [tableHeaderData, setTableHeaderData] = useState([])

  //导入数据有关的数据

  //总库表的的数据id ,文件的id
  const [taxID, setTaxId] = useState("3063641a2aee430284ff7f0c01bcfa9f")
  const [startLine, setStartLine] = useState(1)//！！！注意 这里的开始行的是值除了表头以外的表的内容的开始行，最小为1，即开始行为第1行  

  const [endAt, setEndAt] = useState('202004')
  const [page, setPage] = useState(0)
  const [originalFileId, setOriginalFileId] = useState("aefcaef736f14dfba1d87f6110e2a47a")

  //这两个需要做持久化处理
  const [parkName, setParkName] = useState('华严工业园区')

  useEffect(() => {
    setVisiblable(visible)
  }, [visible, modalChange])



  //创建好了总库表，这里需要更新总库表的信息/设置taxId,endAt
  const goSelectHeader = (cmd_data) => {
    const { eip_monthly_accumulated_tax_id, ended_at, name } = cmd_data//处理新上传总库表的id

    paasql('eip.eip_monthly_accumulated_tax.update', cmd_data).then(data => {
      if (data.error == null) {
        //设置上传文件的页面所需的参数
        setTaxId(eip_monthly_accumulated_tax_id)
        setEndAt(ended_at)

        //转到选择表头页面
        setCurrent(1);
      } else {
        message.error(data.error.message, 2)
      }
    })
  }
  const hide = () => {
    setVisiblable(false)
  }

  //返回选择填写信息页面
  const backToSelectInfo = () => {
    setCurrent(0)
  }


  //给选择表头页面使用的，下一步 //设置文件id,page，startline，
  const choseHeader = (data) => {
    const { startLine, endLine, page, originalRawDataId } = data
    fetch("/cpx/office/excel/head",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              "start_line": startLine,
              "end_line": endLine,
              "original_raw_data_id": originalRawDataId,
              "page": Number(page)
            }
          }
        })
      })
      .then(res => res.json()).then(res => {
        if (res.error == null) {
          const HeaderData = res.result.table_headers
          //处理第三步的表头数据
          setTableHeaderData(HeaderData)

          //处理导入的所需参数
          setStartLine(Number(startLine))
          setOriginalFileId(originalRawDataId)
          setPage(page)

          //下一步
          setCurrent(2)
        }
        else {
          alert(res.error.message)
        }
      })
  }


  //返回选择表头页面
  const backToSelectHeader = () => {
    setCurrent(1)
  }


  //这里是上传数据的处理
  const importData = (paramsfro) => {
    const relationMap = paramsfro

    const cmd_data = {
      "start_line": startLine,
      "page": Number(page),//来源于选择表头的界面
      "original_file_id": originalFileId,//来源于上传文件界面
      "ended_at": endAt,//来源于填写表信息的界面
      "eip_monthly_accumulated_tax_id": taxID,
      "related_map": relationMap,
      "eip_park_id": parkId,//来源于填写表信息的界面
      "eip_park_name": parkName//来源于填写表信息的界面
    }
    setImportLoading(true)
    return fetch("/cpx/office/excel/upload",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              ...cmd_data
            }
          }
        })
      })
      .then(res => res.json()).then(res => {
        if (res.error == null) {
          message.success('导入成功')
          const importResultData = res.result

          setImportResultData(importResultData)
          setCurrent(3)
          setImportResult(true)
        }
        else {
          if (res.error.code == 40001) {//说明请求体的参数错误
            message.error(res.error.message)
            //这里需要回到第一个页面
            setCurrent(0)
          }
          else {
            message.error('导入失败，请返回重新导入')
            setCurrent(0)
          }

        }
      }).finally(() => {
        setImportLoading(false)
      })
  }


  return (
    <>

      <ReachableContext.Provider value={
        {
          parkId, parkName,
          hide, goSelectHeader, originalFileId, taxID, choseHeader, current, tableHeaderData,
          current, backToSelectInfo, backToSelectHeader, importData, importLoading,
          importResult, importResultData
        }
      }>
        <Modal
          title="导入数据"
          destroyOnClose={true}
          style={{ maxHeight: '900px', overflow: 'auto' }}
          maskClosable={false}
          width={1000}
          centered={true}
          footer={null}
          visible={visiblable}
          onCancel={() => {
            //判断当前的阶段
            //删除文件
            setVisiblable(false)
            setCurrent(0)
          }}
        >

          <Steps progressDot current={current}>
            {
              steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} />
              ))
            }
          </Steps>
          <div className="steps-content">
            {
              current == 0 ? <SelectInfo /> : <></>
            }
            {
              current == 1 ? <SelectHeader /> : <></>
            }
            {
              current == 2 ? <FieldMapping /> : <></>
            }
            {
              current == 3 ? <ImportResult /> : <></>
            }
          </div>

        </Modal>

      </ReachableContext.Provider>

    </>
  );
}
export default connect(mapStateToProps)(UploadModel);