import React, { useState, useEffect, createRef } from 'react';

import { Button, Input, Col, Row, Space, Divider, message, Modal, Typography } from 'antd'


import { paasql } from '@api'


const VirtualParkDetail = (props) => {
  const { VirtualParkId, reflushData, parkId, isEdit, changeEdit } = props
  const [virtualPark, setVirtualPark] = useState({
    name: null,
    eip_investment_agency_name: null
  })
  const [investmentAgencyList, setInvestmentAgencyList] = useState([])

  //富文本的内容
  const [content, setContent] = useState(null)
  useEffect(() => {
    if (VirtualParkId == null) {
      return
    }
    const cmd_data = {
      id: VirtualParkId
    }
    paasql('eip.eip_virtual_park.query_by_id', cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result

        const { name, brief_introduction, eip_investment_agency_name, eip_investment_agency_staff_id } = result
        setContent(brief_introduction)
        setVirtualPark(result)
      } else {
        message.error(data.error.message)
      }
    })

    return (() => {

    })
    //获得招商中介的列表
  }, [VirtualParkId])


  const editVirtualPark = () => {
    changeEdit(true)
  }

  const delVirtualPark = () => {
    Modal.confirm({
      title: '删除园中园',
      content: <span>
        将删除所选园中园(<Typography.Text type="danger" >{virtualPark.name}</Typography.Text>)和关联招商中介人员，不可恢复，是否继续？
    </span>,
      onOk() {
        confrimDelVirtualPark()
      },
    })
  }
  const confrimDelVirtualPark = () => {
    const cmd_data = {
      id: VirtualParkId
    }
    paasql('eip.eip_virtual_park.destroy', cmd_data).then(data => {
      if (data.error == null) {
        message.success('删除成功')
        reflushData()
      } else {
        message.error(data.error.message)
      }
    })
  }

  const showList = [
    {
      label: '名称',
      component:
        <div className="value">
          {virtualPark.name}
        </div>
    },
    {
      label: '招商中介名称',
      component:
        <div className="value">
          {virtualPark.eip_investment_agency_name}
        </div>
    },
    {
      label: '简介',
      component:
        < >
          <Divider>
            <div
              className='divider-container'
            >

            </div>
          </Divider>
          <div
            dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </>
    },
  ]
  return (
    <div>

      <div className='virtual-park-detail-nav'>
        <div className='virtual-park-detail-title'>
          园中园详情
        </div>
        <Space>
          <Button
            onClick={delVirtualPark}
          >
            删除
        </Button>
          <Button
            type='primary'
            onClick={editVirtualPark}
          >
            编辑
        </Button>
        </Space>

      </div>
      <div className="park-staff-detail-info-list">
        {
          showList.map(item => {
            return (
              <div key={item.label} className='park-staff-detail-info-item'>
                <div className="label" >
                  {item.label}
                </div>
                {item.component}
              </div>
            )
          })
        }
      </div>


    </div>
  );
}

export default VirtualParkDetail;