const defaultState = {
  breadcurumbList: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_BREADCRUMB_LIST": {
      return {
        ...state,
        breadcurumbList: action.value
      }
    }
    default:
      return state
  }
}

