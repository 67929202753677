import React, { useState, useEffect, createContext } from 'react';
import { Modal, message, Row, Steps, Button, Space } from 'antd'
import UploadFile from './component/UploadFile'
import SelectHeader from './component/SelectHeader'
import FieldMapping from './component/FieldMapping'
import FieldPurpose from './component/FieldPurpose'
import ImportResult from './component/ImportResult'
import { readExcelHeader, agencySeasonTaxRecordMatch } from '@api'


export const ReachableContext = createContext();

const { Step } = Steps;



const UploadModel = (props) => {
  //配置
  const steps = [
    {
      title: '第1步',
      description: "上传文件",
      content: <UploadFile />
    },
    {
      title: '第2步',
      description: "选择表头",
      content: <SelectHeader />
    },
    {
      title: '第3步',
      description: "建立映射",
      content: <FieldMapping />
    },
    {
      title: '第4步',
      description: "选择字段用途",
      content: <FieldPurpose />
    },
    {
      title: '第5步',
      description: "导出结果",
      content: <ImportResult />
    },
  ];
  const {
    visible,
    uploadFileModalChange,
    taxRefundId,
    parkId,
    parkName,
    selectQuater,
    selectTaxRefundStatement,
    reflushData
  } = props
  const [modalVisable, setModalVisable] = useState(false)
  const [fieldList, setFieldList] = useState([])
  const [importting, setImportting] = useState(false)
  const [importLoading, setImportLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  const [tableHeaderData, setTableHeaderData] = useState([])

  //导入数据有关的数据

  //1、taxRefundId来自index页面

  const [relationMap, setRelationMap] = useState([])
  const [startLine, setStartLine] = useState(1)//！！！注意 这里的开始行的是值除了表头以外的表的内容的开始行，
  const [page, setPage] = useState(0)
  //来自字段用途页面
  const [match, setMatch] = useState({})

  //总库表的的文件id ,文件的id
  const [originalRawDataId, setOriginalFileId] = useState(null)
  //这两个需要做持久化处理


  const [importResultData, setImportResultData] = useState([
    {
      name: "上海市奉贤区霍杨种子经销部",
      reason: "未能匹配",
      tax_file_number: "13013219830425318101"
    },
    {
      name: "上海市奉贤区霍杨种子经销部",
      reason: "未能匹配",
      tax_file_number: "13013219830425318101"
    }
  ])
  useEffect(() => {
    setModalVisable(visible)
  }, [visible, uploadFileModalChange])

  //去选择表头页面，这里更新了文件的id
  const goSelectHeader = () => {
    setCurrent(1)
  }

  //返回上传文件页面
  const backToUploadFile = () => {
    setCurrent(0)
  }


  //处理表头数据，设置文件id，page， 开始行，
  const choseHeader = (request) => {
    const { endLine, page, originalRawDataId } = request
    readExcelHeader(request).then(data => {
      const tableHeaderData = data.table_headers
      setTableHeaderData(tableHeaderData)
      //处理导入的所需参数
      setStartLine(Number(endLine))//结束行
      setOriginalFileId(originalRawDataId)
      setPage(page)
      setCurrent(2)
    })

  }

  //回到选择表头页
  const backToSelectHeader = () => {
    setCurrent(1)
  }




  //去选择字段用途的页面
  //需要设置映射字段
  const goToFieldPurpose = (props) => {
    const { relationMap, fieldList } = props
    setFieldList(fieldList)
    setRelationMap(relationMap)
    setCurrent(3)
    //处理
    //处理主建，匹配字段，
    //写入支付日期，支付金额
  }


  //回到字段映射页面
  const backToFieldMapping = () => {
    setCurrent(2)
  }


  //导入数据，待处理
  const importData = (data) => {
    const { match } = data
    setMatch(match)
    setImportting(true)
    // setCurrent(4)
  }
  const confirmImport = () => {
    const cmd_data = {
      "is_agency": false,
      "eip_park_id": parkId,
      "eip_business_year": selectTaxRefundStatement.year,
      "refund_statement_id": taxRefundId,
      "original_file_id": originalRawDataId,
      "related_map": relationMap,
      "start_line": startLine,
      "page": Number(page),
      "quarter": Number(selectTaxRefundStatement.quarter),
      "match": match
    }
    setImportLoading(true)
    fetch("/cpx/administeredSeasonTaxRecordMatch",
      {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          "id": "0",
          "domain": "eip",
          "params": {
            "define": {
              ...cmd_data
            }
          }
        })
      })
      .then(res => res.json()).then(res => {
        if (res.error == null) {
          // this.set("upload_result", res)
          message.success('导入成功')
          const importResultData = res.result
          setImportResultData(importResultData)
          setCurrent(4)
          setImportting(false)
        }
        else {
          if (res.error.code == 40001) {//说明请求体的参数错误
            message.error(res.error.message)
          } else {

          }
        }
      }).finally(() => {
        setImportLoading(false)
      })
  }
  const hideImportModal = () => {
    setImportting(false)
  }
  const closeModal = () => {
    setModalVisable(false)
    setCurrent(0)
    reflushData()
  }
  return (
    <>

      <ReachableContext.Provider
        value={{
          parkId,
          parkName,
          goSelectHeader,
          backToUploadFile,
          taxRefundId, choseHeader,
          tableHeaderData, backToSelectHeader,
          goToFieldPurpose,
          relationMap,
          fieldList,
          backToFieldMapping,
          importData,
          importResultData
        }}>

        <Modal
          title="导入数据"
          style={{ maxHeight: '800px', overflow: 'auto' }}
          maskClosable={false}
          width={1000}
          centered={true}
          footer={null}
          visible={modalVisable}
          onCancel={closeModal}
        >

          <Steps progressDot current={current}>
            {
              steps.map(item => (
                <Step key={item.title} title={item.title} description={item.description} />
              ))
            }
          </Steps>
          <div className="steps-content">
            {
              steps[current].content
            }
          </div>

        </Modal>
        <Modal
          closable={false}
          visible={importting}
          footer={null}
          title="确认导入支付核对表"
        >
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={hideImportModal}>
                取消
            </Button>
              <Button
                type='primary'
                loading={importLoading}
                onClick={confirmImport}
              >
                {
                  importLoading ? "正在上传中..." : '确认上传'
                }
              </Button>
            </Space>

          </div>
        </Modal>
      </ReachableContext.Provider>

    </>
  );
}
export default UploadModel;