import axios from "axios";
import { paasql, createObjectWithBinary } from "./paasql";
import { message } from "antd";
export { paasql, queryMonthlyPaymentRecord, createObjectWithBinary };
const service = axios.create({
  // baseURL: isDeV ? "http://101.91.195.222" : "",
  // baseURL: isDeV ? "http://localhost:4000" : ""
});
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.data = Object.assign({}, config.data, {
      // authToken: window.localStorage.getItem('authToken')
    });
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

const queryMonthlyPaymentRecord = () => {};

//获取session
export const getSession = () => {
  const MyIdentity = window.localStorage.getItem("my_identity");
  if (MyIdentity != null) {
    const MyIDObject = JSON.parse(MyIdentity);

    const {
      session,
      account,
      permissions,
      roles,
      primary_identity,
    } = MyIDObject;

    const { domain } = primary_identity;

    return {
      session,
      domain,
      account,
      permissions,
      roles,
      primary_identity,
    };
  } else {
    return {
      session: null,
      domain: null,
      account: null,
      permissions: null,
      roles: null,
      primary_identity: {
        domain: null,
      },
    };
  }
};

export const gpi = (method, params) => {
  const { session, domain } = getSession();
  return service
    .post("/cpx/gpi", {
      id: 0,
      method: method,
      session: session,
      domain: domain,
      params: {
        define: {
          ...params,
        },
      },
    })
    .then(function (res) {
      return res.data.result;
    });
};

export const enterpriseGpi = (method, keyword) => {
  return service
    .get(`/cpx/enterprise/gpi/?method=${method}&keyword=${keyword}`)
    .then(function (res) {

      return res.data.result;
    });
};
//

//筛选企业月度记录
export const queryMonthlyPaymentRecordApi = (request) => {
  return fetch("/cpx/queryMonthlyPaymentRecord", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: request,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          ...res.result,
          table_row_data: res.result.list,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//
export const queryTaxRecord = (request) => {
  return fetch("/cpx/queryTaxRecord", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          ...res.result,
          table_row_data: res.result.list,
        };
      } else {
        message.error(res.error.message);
      }
    });
};
//通过企业id去查找

//更新园区本部的资金结算表
export const administeredSeasonTaxRecordUpdate = (request) => {
  return fetch("/cpx/administeredSeasonTaxRecordUpdate", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          success: res.result.success,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//更新中介的的资金结算表
export const agencySeasonTaxRecordUpdate = (request) => {
  return fetch("/cpx/agencySeasonTaxRecordUpdate", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          success: res.result.success,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//导出excel
export const exportExcel = (request, filename) => {
  const name = filename == undefined ? "新建文件" : filename;
  fetch("/cpx/office/excel/export", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          related_data: request,
        },
      },
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      // 通过 blob 对象获取对应的 url
      const url = URL.createObjectURL(blob);
      let a = document.createElement("a");

      //这里还可以优化
      a.download = `${name}.xlsx`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove(); // document.body.removeChild(a)
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const enterpriseExportExcel = (request, filename) => {
  const name = filename == undefined ? "新建文件" : filename;
  fetch("/cpx/office/excel/export", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          related_data: request,
          is_enterprise: true,
        },
      },
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      // 通过 blob 对象获取对应的 url
      const url = URL.createObjectURL(blob);
      let a = document.createElement("a");

      //这里还可以优化
      a.download = `${name}.xlsx`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove(); // document.body.removeChild(a)
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error(err);
    });
};

//读取excel的前十行数据
export const readExcel = (original_raw_data_id) => {
  return fetch("/cpx/office/excel/read", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          original_raw_data_id: original_raw_data_id,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          data: res.result.data,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//读取表头数据
export const readExcelHeader = (request) => {
  const { startLine, endLine, originalRawDataId, page } = request;
  return fetch("/cpx/office/excel/head", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          start_line: String(startLine),
          end_line: String(endLine),
          original_raw_data_id: originalRawDataId,
          page: Number(page),
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        const result = res.result;
        return {
          table_headers: result.table_headers,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//导入中介核对表
export const agencySeasonTaxRecordMatch = (request) => {
  return fetch("/cpx/agencySeasonTaxRecordMatch", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          importResult: res.result,
        };
      } else {
        if (res.error.code == 40001) {
          //说明请求体的参数错误
          message.error(res.error.message);
        } else {
        }
        return {
          importResult: [],
        };
      }
    });
};

//创建园区退税统计表
export const createAdministeredTaxRefundStatistics = (request) => {
  return fetch("/cpx/administeredTaxRefundStatisticsCreate", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          ...res,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//创建中介退税统计表
export const createAgencyTaxRefundStatistics = (request) => {
  return fetch("/cpx/agencyTaxRefundStatisticsCreate", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          ...res,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

//根据手机号筛选信息 （政策管理）
export const postGpi = (cmd_data, method) => {
  return fetch("/cpx/enterprise/postGpi", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      method: method,
      params: {
        define: cmd_data,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        var list = res.result.list;
        return {
          list,
        };
      } else {
        message.error(res.error.message);
      }
    });
};

export const queryEnterpriseInfo = (cmd_data) => {
  return fetch("/cpx/queryEnterpriseInfo", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: cmd_data,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          enterpriseList: res.result.list,
        };
      } else {
        message.error(res.error.message);
        return {
          enterpriseList: [],
        };
      }
    });
};

export const rankTop = (cmd_data) => {
  return fetch("/cpx/warning/rankTop", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      method: "query_enterprise_contact_info",
      params: {
        define: cmd_data,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          keyEnterpriseList: res.result,
        };
      } else {
        message.error(res.error.message);
        return {
          keyEnterpriseList: [],
        };
      }
    });
};

export const rankIn = (request) => {
  return fetch("/cpx/warning/rankIn", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        message.error(res.error.message);
      }
    });
};

export const rankOut = (request) => {
  return fetch("/cpx/warning/rankOut", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        message.error(res.error.message);
      }
    });
};

//企业库
export const queryEnterpriseByPage = (request) => {
  return fetch("/cpx/queryEnterpriseByPage", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        if (res.error.code == 40001) {
          //说明请求体的参数错误
          message.error(res.error.message);
        } else {
        }
      }
    });
};

export const enterpriseRefundRateUpdate = (request) => {
  return fetch("/cpx/enterpriseRefundRateUpdate", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return {
          importResult: res.result,
        };
      } else {
        return {
          importResult: [],
        };
      }
    });
};

export const queryKeyEnterpriseByPage = (request) => {
  return fetch("/cpx/queryKeyEnterpriseByPage", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        return {
          importResult: [],
        };
      }
    });
};

export const queryActiveKeyEnterpriseByPage = (request) => {
  return fetch("/cpx/queryActiveKeyEnterpriseByPage", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        return {
          importResult: [],
        };
      }
    });
};

export const querySameIndustryKeyEnterpriseByPage = (request) => {
  return fetch("/cpx/querySameIndustryKeyEnterpriseByPage", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error == null) {
        return res.result;
      } else {
        return {
          importResult: [],
        };
      }
    });
};

//导出季度扶持额
export const exportSeasonTax = (request, fileName) => {
  var name = "新建文件";
  if (fileName) {
    name = fileName;
  }
  return fetch("/cpx/exportSeasonTax", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      // 通过 blob 对象获取对应的 url
      const url = URL.createObjectURL(blob);
      let a = document.createElement("a");

      //这里还可以优化
      a.download = `${name}.xlsx`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove(); // document.body.removeChild(a)
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error(err);
    });
};

//导出贷记凭证
export const exportCreditTax = (request, fileName) => {
  var name = "贷记凭证表";
  if (fileName) {
    name = fileName;
  }
  return fetch("/cpx/exportCreditTax", {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      id: 0,
      domain: "eip",
      params: {
        define: {
          ...request,
        },
      },
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      // 通过 blob 对象获取对应的 url
      const url = URL.createObjectURL(blob);
      let a = document.createElement("a");

      //这里还可以优化
      a.download = `${name}.xlsx`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove(); // document.body.removeChild(a)
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error(err);
    });
};
//导入管理费
export const importManagementFee = (request) => {
  const { session, domain } = getSession();
  return service
    .post("/cpx/enterprise/postGpi", {
      id: 0,
      method: "enterprise_fee_upload",
      session: session,
      domain: domain,
      params: {
        define: {
          ...request,
        },
      },
    })
    .then(function (res) {

      return res.data;
    });
};

export const getCpStaffListAndInvestmentStaffList = (request) => {
  const { session, domain } = getSession();
  return service
    .post("/cpx/gpi", {
      id: 0,
      method: "query_staff_by_name",
      session: session,
      domain: domain,
      params: {
        define: {
          ...request,
        },
      },
    })
    .then(function (res) {
      return res.data;
    });
};

export const queryCmsDoc = (space_id, park_id) => {
  return axios
    .post("/cpx/gpi", {
      id: "0",
      domain: "eip",
      method: "query_cms_doc",
      params: {
        define: {
          eip_park_id: park_id,
          eip_cms_space_id: space_id,
          level: "0",
        },
      },
    })
    .then((res) => {
      if (res.error == null) {

        return res.data.result.data;
      }
      message.error(res.error.message);
      return [];
    });
};

//年度纳税指标各月完成额
export const parkYearCompletionAmount = async () => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/parkYearCompletionAmount?id=0&domain=&method=&eip_park_name=华严工业园区&eip_business_year=${year}&eip_park_id=2b9312bccbf94297a5172f3277ac8fa8`
    )
    .then((res) => {
      return res.data;
    });
};

//每季度各纳税档位企业数
export const parkEnterpriseLevelCount = () => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/parkEnterpriseLevelCount?id=0&domain=eip&method=&eip_park_name=华严工业园区&eip_business_year=${year}&eip_park_id=2b9312bccbf94297a5172f3277ac8fa8`
    )
    .then((res) => {
      return res.data;
    });
};

//三年的纳税趋势
export const queryEipMonthlyByYear = async (req) => {
  return axios
    .post(`/cpx/gpi`, {
      id: 0,
      method: "query_eip_monthly_by_year",
      session: "session",
      domain: "domain",
      params: {
        define: {
          ...req,
        },
      },
    })
    .then((res) => {
      //处理返回的数据，处理
      if (res.data.error === null) {
        const data = res.data.result.list;
        const dataWithSort = data.sort(function (first, next) {
          return (
            Number(first.statistical_month) - Number(next.statistical_month)
          );
        });
        return dataWithSort;
      } else {
        message.error(res.data.message);
        return [];
      }
    });
};

//离进园记录
export const parkSettleExitedRecord = () => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/parkSettleExitedRecord?domain=eip&year=${year}&eip_park_id=2b9312bccbf94297a5172f3277ac8fa8`
    )
    .then((res) => {
      return res.data;
    });
};

export const parkEnterpriseFirstPayout = () => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/parkEnterpriseFirstPayout?id=0&domain=eip&method=&eip_business_year=${year}&eip_park_id=2b9312bccbf94297a5172f3277ac8fa8`
    )
    .then((res) => {
      return res.data;
    });
};

//园中园的离进园记录
export const agencySettleExitedRecord = (eip_investment_agency_id, parkId) => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/agencySettleExitedRecord?id=0&year=${year}&eip_park_id=${parkId}&eip_investment_agency_id=${eip_investment_agency_id}`
    )
    .then((res) => {

      return res.data;
    });
};

//园中园的截止个月纳税累计
export const agencyAccumulatedTax = (eip_investment_agency_id, parkId) => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `cpx/warning/agencyAccumulatedTax?id=0&domain=&eip_business_year=${year}&eip_park_name=aa&eip_park_id=${parkId}&eip_investment_agency_id=${eip_investment_agency_id}`
    )
    .then((res) => {
      return res.data.result;
    });
};

//每月纳税额
export const agencyMonthlyTotalTax = (eip_investment_agency_id, parkId) => {
  const year = new Date().getFullYear();
  return axios
    .get(
      `/cpx/warning/agencyMonthlyTotalTax?eip_park_id=${parkId}&eip_business_year=${year}&eip_park_name=aaa&eip_investment_agency_id=${eip_investment_agency_id}`
    )
    .then((res) => {
      if (res.error == null) {
        return res.data.result;
      } else {
        message.error(res.error.message);
        return [];
      }
    });
};

//根据ID查找数据
export const eipVirtualParkQueryById = (virtualId) => {
  const cmd_data = {
    id: virtualId,
  };
  return paasql("eip.eip_virtual_park.query_by_id", cmd_data).then((data) => {
    return data;
  });
};

export const eipVirtualParkList = (parkId) => {
  const cmd_data = {
    eip_park_id: parkId,
  };
  return paasql("eip.eip_virtual_park.list", cmd_data).then((data) => {
    if (data.error == null) {
      return data.result;
    } else {
      message.error(data.error.message);
      return [];
    }
  });
};

//拿到parkId
export const getEipParkId = () => {
  return paasql("eip.park.list").then((data) => {
    if (data.error == null) {
      return data.result[0].id;
    } else {
      message.error(data.error.message);
      return "2b9312bccbf94297a5172f3277ac8fa8";
    }
  });
};
