import React from 'react';
 
const NotFound = () => {
  return (
    <div>
      404,NotFound
    </div>
  );
}
 
export default NotFound;