import React, { useState, useEffect } from 'react';
import { Modal, message, Checkbox, Row, Col } from 'antd'
import { paasql, exportExcel as exportExcelApi } from '@api'
import { moneyFormat } from '@utils/index'
import { keyArr } from './index'

const table_col_header_data = [//表头数据的配置项
  { display_name: "企业名称", name: "name", },
  { display_name: "招商部门", name: "investment_department", },
  { display_name: "招商员", name: "investment_staff", },
  { display_name: "税收期间", name: "tax_paid_at", },//这个还未进行处理
  { display_name: "税号", name: "taxpayer_registration_number", },
  { display_name: "税务管理码", name: "tax_file_number", },
  { display_name: "统一征信代码", name: "uscc", },
  { display_name: "应税销售额(元)", name: "taxable_income", },
  { display_name: "税收合计(元)", name: "tax_payable", },
  { display_name: "消费税(元)", name: "consumption_tax", },
  { display_name: "增值税(元)", name: "value_added_tax", },
  { display_name: "营业税(元)", name: "business_tax", },
  { display_name: "企业所得税(元)", name: "corporate_income_tax", },
  { display_name: "所得税(元)", name: "individual_income_tax", },
  { display_name: "城市维护建设税(元)", name: "city_maintenance_and_construction_tax", },
  { display_name: "车船使用税(元)", name: "vehicle_and_vessel_usage_tax", },
  { display_name: "房产税(元)", name: "property_tax", },
  { display_name: "印花税(元)", name: "stamp_tax", },
  { display_name: "土地增值税(元)", name: "land_appreciation_tax", },
  { display_name: "土地使用税(元)", name: "land_use_tax", },
  { display_name: "环保税(元)", name: "environmental_tax", },

]
const SelectFieldModal = (props) => {
  const { modelVisible, totalRecord,
    totalTax
  } = props
  const [visiblable, setVisiblable] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)

  const [allCheckList, setAllCheckList] = useState([])
  const [checkAll, setCheckAll] = useState(true)

  const [checkList, setCheckList] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    setVisiblable(true)
  }, [modelVisible])
  useEffect(() => {
    setVisiblable(false)
    const cmd_data = {

    }
    paasql("eip.eip_enterprise_monthly_tax_payment_record.attrs", cmd_data).then(data => {
      if (data.error == null) {
        const result = data.result.body.fields
        const defaultValue = []
        for (let i = 0; i < result.length; i++) {
          result[i].label = result[i].display_name
          result[i].value = result[i].name
          defaultValue.push(result[i].name)
        }
        setAllCheckList(defaultValue)
        setCheckList(defaultValue)
        setOptions(result)
      } else {
        message.error(data.error.message)
      }
    })
  }, [totalRecord])
  const exportExcel = () => {

    var table_row_data = totalRecord



    //处理合计的数值问题

    const tmp_total_tax = totalTax
    keyArr.forEach(key => {
      tmp_total_tax[key] = `$${tmp_total_tax[key]}`
    })

    //增加序号，名称，还有税管码的只用来导出的数据
    //增加千分位
    const totalTaxWithInfo = {
      index: '',
      name: '合计',
      investment_department: '',
      investment_staff: '',
      tax_file_number: '',
      uscc: '',
      taxpayer_registration_number: '',
      tax_paid_at: '',
      ...tmp_total_tax
    }
    table_row_data.push(totalTaxWithInfo)
    var tmp_related_data = []
    var header_data = ['序号']
    const selected_item = checkList
    var header_data_key = ['index']//这是给表格数据的表头数据的key
    for (var i = 0; i < table_col_header_data.length; i++) {
      for (let j = 0; j < selected_item.length; j++) {
        if (selected_item[j] == table_col_header_data[i].name) {
          header_data_key.push(table_col_header_data[i].name)
          header_data.push(table_col_header_data[i].display_name)//这个是表头行导出数据
        }
      }
    }

    tmp_related_data.push(header_data)
    //表头数据部分
    for (var i = 0; i < table_row_data.length; i++) {
      var single_row_data = []
      for (var j = 0; j < header_data_key.length; j++) {
        for (var key in table_row_data[i]) {
          if (header_data_key[j] == key) {
            if (table_row_data[i][key] != null) {
              single_row_data.push(table_row_data[i][key])//单行数据
            } else {
              single_row_data.push('')
            }

          } else {
          }
        }

      }
      tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，可以导入
    }
    //表的数据部分
    // const fileName = `搜索结果`

    exportExcelApi(tmp_related_data)
  }


  const checkClick = (checkedValue) => {
    const checkAll = checkedValue.length === allCheckList.length
    setCheckAll(checkAll)
    setCheckList(checkedValue)
  }
  const onCheckAllChange = (e) => {
    const options = e.target.checked ? allCheckList : []
    setCheckAll(e.target.checked)
    setCheckList(options)
  }
  return (
    <>
      <Modal
        title="请选择要导出的列"
        visible={visiblable}
        onOk={exportExcel}
        destroyOnClose={true}
        cancelText="取消"
        okText='确认导出'
        onCancel={() => {
          setVisiblable(false)
        }}
      >
        <Checkbox.Group value={checkList} onChange={checkClick}
        >
          <Row>
            {
              options.map(item => {
                return (
                  <Col
                    key={item.value}
                    span={12}
                  >
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                )
              })
            }
          </Row>

        </Checkbox.Group>
        <Checkbox
          style={{ paddingTop: 10 }}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          全选
        </Checkbox>
      </Modal>
    </>
  );
}

export default SelectFieldModal;