import React, { useState, useEffect } from 'react';

import { Button, Radio, Table, message, Pagination } from 'antd'

import {
  paasql, queryKeyEnterpriseByPage, exportExcel,
  queryActiveKeyEnterpriseByPage, querySameIndustryKeyEnterpriseByPage,
} from '@api'


import { getSession } from '@api/paasql'
import {
  ExportDataIcon as ExportIcon,
  ActiveEnterpriseIcon,
  ActiveEnterpriseSelectIcon,
  KeyEnterpriseIcon,
  KeyEnterpriseSelectIcon,
  SameIndustryIcon,
  SameIndustrySelectIcon
} from '@icon'
import exportIcon from '@svg/export.svg'

const EnterpriseBiosphere = () => {

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [parkId, setParkId] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalPage, setTotalPage] = useState(0)
  const [currentKey, setCurrentKey] = useState('taxRankingList')
  const table_col_header_data = [//表头数据的配置项
    { display_name: "序号", name: "index", },
    { display_name: "企业名称", name: "name", },
    { display_name: "统一信用代码", name: "uscc", },//这个还未进行处理
    { display_name: "注册资本(万元)", name: "registered_capital", },
    { display_name: "法定代表人", name: "legal_representative", },
    { display_name: "所属行业", name: "industry", },
    { display_name: "注册地址", name: "registered_address", },
    { display_name: "经营地址", name: "business_address", },
    { display_name: "公司邮编", name: "zip_code", },
    { display_name: "固定电话", name: "telephone_number", },
  ]

  const [selectRadio, setSelectRadio] = useState('a')

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      fixed: 'left',
      align: 'center',
    },
    {
      title: '企业名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
    },
    {
      title: '统一信用代码',
      dataIndex: 'uscc',
      key: 'uscc',
      width: 180,
    },
    {
      title: '注册资本(万元)',
      dataIndex: 'registered_capital',
      key: 'registered_capital',
      width: 100,
    }, {
      title: '法定代表人',
      dataIndex: 'legal_representative',
      key: 'legal_representative',
      width: 80,
    },
    {
      title: '所属行业',
      dataIndex: 'industry',
      key: 'industry',
      width: 250,
    }, {
      title: '注册地址',
      dataIndex: 'registered_address',
      key: 'registered_address',
      width: 300,
    }, {
      title: '经营地址',
      dataIndex: 'business_address',
      key: 'business_address',
      width: 300,

    }, {
      title: '公司邮编',
      dataIndex: 'zip_code',
      key: 'zip_code',
      width: 80,
    }, {
      title: '固定电话',
      dataIndex: 'telephone_number',
      key: 'telephone_number',
      width: 150,
    }]

  useEffect(() => {
    paasql('eip.park.list').then(data => {
      if (data.error == null) {
        const parkId = data.result[0].id
        setParkId(parkId)
        const cmd_data = {
          eip_park_id: parkId,
          page: 1,
          size: pageSize,
        }

        //处理重点企业名录
        queryKeyEnterpriseByPage(cmd_data).then(data => {
          if (data.error == null) {
            const { list, total_page } = data
            list.forEach((item, index) => {
              item.index = index + 1
            })
            setTotalPage(total_page)
            setTableData(list)
          } else {
            message.error(data.error.message)
          }
        })
      } else {
        message.error(data.error.message)
      }

    })
  }, [])



  const taxRankingList = () => {
    setCurrentPage(1)
    setCurrentKey('taxRankingList')
    const cmd_data = {
      eip_park_id: parkId,
      page: 1,
      size: pageSize,
    }
    queryKeyEnterprise(cmd_data)
  }

  const queryKeyEnterprise = (cmd_data) => {
    queryKeyEnterpriseByPage(cmd_data).then(data => {
      if (data.error == null) {
        const { list, total_page } = data
        list.forEach((item, index) => {
          item.index = index + 1
        })
        setTotalPage(total_page)
        setTableData(list)
      } else {
        message.error(data.error.message)
      }
    })
  }

  const currentTaxPaid = () => {
    setCurrentPage(1)
    setCurrentKey('currentTaxPaid')
    setCurrentPage(1)
    const cmd_data = {
      eip_park_id: parkId,
      page: 1,
      size: pageSize,
    }
    queryActiveKeyEnterpriseByPage(cmd_data).then(data => {
      if (data.error == null) {
        const { list, total_page } = data
        list.forEach((item, index) => {
          item.index = index + 1
        })
        setTotalPage(total_page)
        setTableData(list)
      } else {
        message.error(data.error.message)
      }
    })
  }
  const sameIndustry = () => {
    setCurrentPage(1)
    setCurrentKey('sameIndustry')
    const { primary_identity } = getSession()
    //当前登陆者的id //角色为企业时就是企业id，能查出来结果，其他情况是查不出来结果的
    const { id } = primary_identity

    const cmd_data = {
      eip_park_id: parkId,
      eip_enterprise_id: id
    }

    querySameIndustryKeyEnterpriseByPage(cmd_data).then(data => {
      if (data.error == null) {
        const { list, total_page } = data
        list.forEach((item, index) => {
          item.index = index + 1
        })
        setTotalPage(total_page)
        setTableData(list)
      } else {
        message.error(data.error.message)
      }
    })
  }


  const exportData = () => {
    //导入数据
    const cmd_data = {
      eip_park_id: parkId,
      size: pageSize * totalPage,
      page: 1,
    }


    if (currentKey == 'taxRankingList') {
      //创建处理
      queryKeyEnterpriseByPage(cmd_data).then(data => {
        if (data.error == null) {
          const { list } = data
          exportDataToExcel(list)
        } else {
          message.error(data.error.message)
        }
      })
      return
    } else if (currentKey == 'currentTaxPaid') {
      queryActiveKeyEnterpriseByPage(cmd_data).then(data => {
        if (data.error == null) {
          const { list } = data
          exportDataToExcel(list)
        } else {
          message.error(data.error.message)
        }
      })
      return
    } else {
      const cmd_data1 = {
        eip_enterprise_id: "e47e714ee70349628bc8f5ea5a59cada",
        eip_park_id: parkId,
      }
      querySameIndustryKeyEnterpriseByPage(cmd_data1).then(data => {
        if (data.error == null) {
          const { list } = data
          exportDataToExcel(list)
        } else {
          message.error(data.error.message)
        }
      })
      return
    }
  }




  const exportDataToExcel = (data) => {
    var table_row_data = data
    var tmp_related_data = []
    var header_data = []
    var header_data_key = []

    //将选中的数据放在一个数组，后续根据这个数组中的属性来作为key，来将key对应的value值push进一个数组
    for (var i = 0; i < table_col_header_data.length; i++) {
      header_data.push(table_col_header_data[i].display_name)
      header_data_key.push(table_col_header_data[i].name)//表头的key的数组
    }
    tmp_related_data.push(header_data)
    //表头数据部分
    // //表格数据部分
    for (var i = 0; i < table_row_data.length; i++) {
      var single_row_data = []
      table_row_data[i].index = String(i + 1)
      for (var k = 0; k < header_data_key.length; k++) {//循环单行数据，根据表头的key
        for (var key in table_row_data[i]) {
          if (header_data_key[k] == key) {
            if (table_row_data[i][key] == null) {//为null是不能导出的
              single_row_data.push("")//单行数据
            } else {
              single_row_data.push(table_row_data[i][key])//单行数据
            }
          } else {
          }
        }
      }
      tmp_related_data.push(single_row_data)//将每个行数据都赋给tmp_related_data这个数组，导入
    }
    exportExcel(tmp_related_data)
  }
  const pageChange = (page) => {
    setCurrentPage(page)
    const cmd_data = {
      eip_park_id: parkId,
      size: pageSize,
      page: page,
    }
    if (currentKey == 'taxRankingList') {
      setCurrentPage(page)
      queryKeyEnterpriseByPage(cmd_data).then(data => {
        if (data.error == null) {
          const { list, total_page } = data
          list.forEach((item, index) => {
            item.index = (page - 1) * pageSize + index + 1
          })
          setTotalPage(total_page)
          setTableData(list)
        } else {
          message.error(data.error.message)
        }
      })
      return
    }
    if (currentKey == 'currentTaxPaid') {
      queryActiveKeyEnterpriseByPage(cmd_data).then(data => {
        if (data.error == null) {
          const { list, total_page } = data
          list.forEach((item, index) => {
            item.index = (page - 1) * pageSize + index + 1
          })
          setTotalPage(total_page)
          setTableData(list)
        } else {
          message.error(data.error.message)
        }
      })
      return
    }
    const cmd_data1 = {
      eip_park_id: parkId,
      eip_enterprise_id: "e47e714ee70349628bc8f5ea5a59cada",
    }
    querySameIndustryKeyEnterpriseByPage(cmd_data1).then(data => {
      if (data.error == null) {
        const { list, total_page } = data
        list.forEach((item, index) => {
          item.index = (page - 1) * pageSize + index + 1
        })
        setTotalPage(total_page)
        setTableData(list)
      } else {
        message.error(data.error.message)
      }
    })
    return

  }

  const changeRadio = (e) => {
    const value = e.target.value
    setSelectRadio(value)
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Radio.Group defaultValue="a"
            value={selectRadio}
            onChange={changeRadio}
            size="large">
            <Radio.Button
              className='tax-warning-radio' onClick={taxRankingList} value="a">
              {selectRadio === 'a' ? <SameIndustrySelectIcon /> : <SameIndustryIcon />}
              重点企业名录</Radio.Button>
            <Radio.Button className='tax-warning-radio'
              onClick={currentTaxPaid} value="b">{
                selectRadio === 'b' ? <ActiveEnterpriseSelectIcon /> : <ActiveEnterpriseIcon />
              }当前活跃重点企业</Radio.Button>
            <Radio.Button className='tax-warning-radio' onClick={sameIndustry} value="c">
              {selectRadio === 'c' ? <SameIndustrySelectIcon /> : <SameIndustryIcon />}

              同行业重点企业</Radio.Button>

          </Radio.Group>

        </div>
        <div>
          <Button
            size='large'
            icon={<img className='nav-icon' src={exportIcon} />}
            type="primary"
            onClick={exportData}>
            导出数据
          </Button>
        </div>

      </div>
      <div
        style={{ margin: '20px 0px' }}
      >


        <Table
          size='small'
          loading={loading}
          columns={columns}
          dataSource={tableData}
          bordered
          rowKey={
            record => record.name
          }
          pagination={{
            current: currentPage,
            pageSize: 20,
            hideOnSinglePage: true,
            showSizeChanger: false
          }}
          //设置宽度
          scroll={{ x: 'calc(2500px)', y: 'calc(100vh - 300px)' }}
        />

      </div>
      <div
        style={{ textAlign: 'right' }}
      >
        <Pagination
          size='large'
          pageSize={20}
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={currentPage}
          total={totalPage * pageSize}
          onChange={pageChange}
        />
      </div>
    </div>
  );
}

export default EnterpriseBiosphere;