import React, { useState, useEffect } from "react";
import {
  Button,
  Spin,
  Row,
  Col,
  Space,
  Pagination,
  Radio,
  Modal,
  message,
} from "antd";
import TaxRefundStatisticsTable from "./component/TaxRefundStatisticsTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { paasql, gpi, exportExcel } from "@api";
import CreateTaxRefundStatistics from "./component/CreateTaxRefundStatistics";
import { CreateIcon, ExportDataIcon, DeleteDocIcon } from "@icon";
import { ButtonLabel } from "@component";
import { moneyFormat } from "@utils/index";
import "./index.less";

import { header, multiHeader, filterVal, merges } from "./config";

import { export_json_to_excel } from "@assets/xlsx";

const table_col_header_data = [
  { display_name: "序号", name: "index" },
  { display_name: "招商点", name: "eip_park_name" },
  { display_name: "企业名称", name: "name" },
  { display_name: "税务管理码", name: "tax_file_number" },
  { display_name: "增值税总税额", name: "total_value_added_tax" },
  { display_name: "增值税扶持金额", name: "total_value_added_tax_refund" },
  { display_name: "企业所得税总税额", name: "total_corporate_income_tax" },
  {
    display_name: "企业所得税扶持金额",
    name: "total_corporate_income_tax_refund",
  },
  { display_name: "所得税总税额", name: "total_individual_income_tax" },
  {
    display_name: "所得税扶持金额",
    name: "total_individual_income_tax_refund",
  },
  { display_name: "房产税总税额", name: "total_property_tax" },
  {
    display_name: "房产税扶持金额",
    name: "total_property_tax_refund",
  },
  { display_name: "扶持金额合计", name: "total_tax_refund" },
  { display_name: "上季余留", name: "last_year_statement_balance" },
  { display_name: "累计补差", name: "total_payment_difference" },
  { display_name: "累计管理费", name: "total_management_fee" },
  { display_name: "累计代扣", name: "total_payment_on_behalf" },
  { display_name: "累计预付款", name: "total_prepayment" },
  { display_name: "累计应支付金额", name: "total_accounts_payable" },
  { display_name: "累计已支付金额", name: "total_actual_amount_paid" },
  { display_name: "所选时段未支付金额", name: "unpaid_amount" },
];

const keyArr = [
  "total_value_added_tax",
  "total_value_added_tax_refund",
  "total_corporate_income_tax",
  "total_corporate_income_tax_refund",
  "total_individual_income_tax_refund",
  "total_individual_income_tax",
  "total_property_tax_refund",
  "total_property_tax",
  "total_tax_refund",
  "last_year_statement_balance",
  "total_payment_difference",
  "total_management_fee",
  "total_payment_on_behalf",
  "total_prepayment",
  "total_accounts_payable",
  "total_actual_amount_paid",
  "unpaid_amount",
];
const ParkTaxRefundStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [parkId, setParkId] = useState(null);
  const [parkName, setParkName] = useState(null);

  const [taxRefundStatisticsList, setTaxRefundStatisticsList] = useState([]);
  const [selectTaxRefundStatistics, setSelectTaxRefundStatistics] = useState(
    null
  );
  const [selectTaxId, setSelectTaxId] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //每页大小
  const [pageSize, setPageSize] = useState(20);
  const [totalPage, setTotalPage] = useState(1);

  //统计数据
  const [total, setTotal] = useState({});

  //查询的数据总数
  const [totalNumber, setTotalNumber] = useState(0);
  const [
    createTaxRefundStatisticsModalvisable,
    setCreateTaxRefundStatisticsModalvisable,
  ] = useState(false);
  useEffect(() => {
    paasql("eip.park.list").then((data) => {
      const parkId = data.result[0].id;
      const parkName = data.result[0].name;
      //id和name的持久化处理
      setParkId(parkId);
      setParkName(parkName);
      getTaxRefundStatementListByParkId(parkId);
    });
  }, []);
  const getTaxRefundStatementListByParkId = (parkId) => {
    //eip.eip_park_administered_enterprises_tax_refund_statistics.list
    const cmd_data = {
      eip_park_id: parkId,
    };
    paasql(
      "eip.eip_park_administered_enterprises_tax_refund_statistics.list",
      cmd_data
    ).then((data) => {
      const result = data.result;
      //如果当前的列表为空
      if (result.length != 0) {
        for (let i = 0; i < result.length; i++) {
          const year_and_quarter = `${result[i].statistics_year}${result[i].quarter_ended_at}`;
          result[i].year_and_quarter = year_and_quarter; //增加年和季度的字段
        }
        const result_with_sort = result.sort(function (first, last) {
          return Number(last.year_and_quarter) - Number(first.year_and_quarter);
        });
        setSelectTaxRefundStatistics(result_with_sort[0]);

        //选中的id
        setSelectTaxId(result_with_sort[0].id);
        const cmd_data = {
          size: pageSize,
          page: 1,
          id: result_with_sort[0].id,
        };
        getTaxRefundStatisticsItemById(cmd_data);
        setTaxRefundStatisticsList(result_with_sort);
      } else {
        setTotalPage(1);
        setTableData([]);
        setTaxRefundStatisticsList([]);
      }
    });
  };
  const getTaxRefundStatisticsItemById = (request) => {
    const { id, page, size } = request;
    const cmd_data = {
      attached_to_object_id: id,
      page: page,
      size: size,
      table: "eip_park_administered_enterprises_tax_refund_statistics_record",
    };
    const method = "query_statement_and_statistic_record_by_page";
    gpi(method, cmd_data).then((data) => {
      const { list, total_page, total } = data;
      setTotalNumber(total);
      //处理税收项的合计
      const {
        last_year_statement_balance,
        total_accounts_payable,
        total_actual_amount_paid,
        total_corporate_income_tax,
        total_corporate_income_tax_refund,
        total_management_fee,
        total_payment_difference,
        total_payment_on_behalf,
        total_prepayment,
        total_tax_refund,
        total_value_added_tax,
        total_value_added_tax_refund,
        unpaid_amount,
      } = data;

      setTotal({
        last_year_statement_balance,
        total_accounts_payable,
        total_actual_amount_paid,
        total_corporate_income_tax,
        total_corporate_income_tax_refund,
        total_management_fee,
        total_payment_difference,
        total_payment_on_behalf,
        total_prepayment,
        total_tax_refund,
        total_value_added_tax,
        total_value_added_tax_refund,
        unpaid_amount,
      });
      setTableData(list);
      setTotalPage(total_page);
    });
  };
  //新增统计表
  const createTaxRefundStatistics = () => {
    setCreateTaxRefundStatisticsModalvisable(true);
  };
  const exportData = () => {
    if (selectTaxId == null) {
      message.error("当前没有选择统计表，无法导出数据");
      return;
    }
    const cmd_data = {
      attached_to_object_id: selectTaxId,
      page: 1,
      size: pageSize * totalPage,
      table: "eip_park_administered_enterprises_tax_refund_statistics_record",
    };

    const method = "query_statement_and_statistic_record_by_page";

    //请求所有数据

    gpi(method, cmd_data).then((res) => {
      const tmp_table_row_data = res.list;

      tmp_table_row_data.forEach((element) => {
        keyArr.forEach((key) => {
          element[key] = Number(element[key]);
        });
      });

      const tmp_table_row_data_with_settle_name = [];
      for (let i = 0; i < tmp_table_row_data.length; i++) {
        var attr_object = tmp_table_row_data[i];
        attr_object.eip_park_name = parkName;
        attr_object.index = String(i + 1);
        tmp_table_row_data_with_settle_name.push(attr_object);
      }
      //有数据了
      var table_row_data = tmp_table_row_data_with_settle_name;

      const _header = [
        "",
        "",
        `${selectTaxRefundStatistics.name}园区本部企业扶持资金结算统计表`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      multiHeader.unshift(_header);

      const data = formatJson(filterVal, table_row_data);

      export_json_to_excel({
        multiHeader,
        header,
        merges,
        data,
        noBorderNumber: 2,
        filename:`${selectTaxRefundStatistics.name}园区本部统计表`,
        centerNumber: 3,
      });

      function formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            return v[j];
          })
        );
      }

      // const header_data = [];
      // const header_data_key = [];
      // const tmp_related_data = [];
      // for (var i = 0; i < table_col_header_data.length; i++) {
      //   header_data.push(table_col_header_data[i].display_name);
      //   header_data_key.push(table_col_header_data[i].name); //表头的key的数组
      // }
      // tmp_related_data.push(header_data);
      // //表头数据部分
      // // //表格数据部分

      // //这里是没有银行缺失企业的
      // for (var i = 0; i < table_row_data.length; i++) {
      //   var single_row_data = [];
      //   for (var k = 0; k < header_data_key.length; k++) {
      //     //循环单行数据，根据表头的key
      //     for (var key in table_row_data[i]) {
      //       if (header_data_key[k] == key) {
      //         if (table_row_data[i][key] == null) {
      //           single_row_data.push(""); //单行数据
      //         } else {
      //           single_row_data.push(table_row_data[i][key]); //单行数据
      //         }
      //       } else {
      //       }
      //     }
      //   }
      //   tmp_related_data.push(single_row_data); //将每个行数据都赋给tmp_related_data这个数组，导入
      // }

      // const fileName = `${selectTaxRefundStatistics.name}园区本部统计表`;
      // exportExcel(tmp_related_data, fileName);
    });
  };

  const totalList = [
    {
      label: "增值税总税额",
      value: total.total_value_added_tax,
    },
    {
      label: "企业所得税总税额",
      value: total.total_corporate_income_tax,
    },
    {
      label: "上季余留",
      value: total.last_year_statement_balance,
    },
    {
      label: "增值税扶持金额",
      value: total.total_value_added_tax_refund,
    },
    {
      label: "企业所得税扶持金额",
      value: total.total_corporate_income_tax_refund,
    },
    {
      label: "扶持金额合计",
      value: total.total_tax_refund,
    },
    {
      label: "累计补差",
      value: total.total_payment_difference,
    },
    {
      label: "累计管理费",
      value: total.total_management_fee,
    },
    {
      label: "累计代扣",
      value: total.total_payment_on_behalf,
    },
    {
      label: "累计预付款",
      value: total.total_prepayment,
    },
    {
      label: "累计应支付金额",
      value: total.total_accounts_payable,
    },
    {
      label: "累计已支付金额",
      value: total.total_actual_amount_paid,
    },
    {
      label: "未支付金额",
      value: total.unpaid_amount,
    },
  ];
  const destroy = () => {
    Modal.confirm({
      onOk() {
        destroyTaxRefundStatistic();
      },
      width: 600,
      icon: <ExclamationCircleOutlined />,
      content: "删除后不可恢复，是否继续",
      title: `确认要删除${selectTaxRefundStatistics.name}的园区退税统计表吗？`,
    });
  };

  const destroyTaxRefundStatistic = () => {
    const cmd_data = {
      id: selectTaxId,
    };
    paasql(
      "eip.eip_park_administered_enterprises_tax_refund_statistics_and_record.delete",
      cmd_data
    ).then((data) => {
      if (data.error == null) {
        message.success("删除成功");
        getTaxRefundStatementListByParkId(parkId);
      } else {
        message.error(data.error.message);
      }
    });
  };
  const buttonList = [
    {
      name: "新增统计表",
      width: 146,
      action: createTaxRefundStatistics,
      icon: <CreateIcon />,
    },
    {
      name: "导出数据",
      width: 118,
      action: exportData,
      icon: <ExportDataIcon />,
    },
    {
      name: "删除",
      width: 118,
      action: destroy,
      className: "danger",
      icon: <DeleteDocIcon />,
    },
  ];

  const handleTaxRefundStatisticsList = (item) => {
    setCurrentPage(1);
    const id = item.id;
    setSelectTaxId(id);
    const cmd_data = {
      id,
      page: 1,
      size: pageSize,
    };
    getTaxRefundStatisticsItemById(cmd_data);
    setSelectTaxRefundStatistics(item);
  };
  //页码改变
  const pageChange = (page) => {
    setCurrentPage(page);
    const cmd_data = {
      id: selectTaxId,
      page: page,
      size: pageSize,
      table: "eip_park_administered_enterprises_tax_refund_statement_record",
    };
    getTaxRefundStatisticsItemById(cmd_data);
  };

  //刷新数据
  const reflushData = () => {
    getTaxRefundStatementListByParkId(parkId);
  };

  const handleCreateTaxStatisticsLoading = (loadingStatus) => {
    setLoading(loadingStatus);
  };

  //隐藏模态框
  const hideModal = () => {
    setCreateTaxRefundStatisticsModalvisable(false);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <Col style={{ width: "calc(100% - 220px)" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div style={{ overflow: "auto" }}>
                <Button.Group>
                  <ButtonLabel size="large" />
                  {buttonList.map((item) => {
                    return (
                      <Button
                        size="large"
                        key={item.name}
                        icon={item.icon}
                        onClick={item.action}
                      >
                        {item.name}
                      </Button>
                    );
                  })}
                </Button.Group>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Row className="seach-total-record-container">
                  {totalList.map((item) => {
                    return (
                      <Col
                        key={item.label}
                        span={8}
                        className="total-record-item"
                      >
                        <Row>
                          <Col
                            span={11}
                            style={{ textAlign: "right" }}
                            className="total-record-item-label"
                          >
                            {`${item.label}:`}
                          </Col>
                          <Col span={13} className="total-record-item-value">
                            {`${moneyFormat(item.value)}`}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div style={{ marginTop: "20px" }}>
                <TaxRefundStatisticsTable
                  tableData={tableData}
                  pageSize={pageSize}
                  currentPage={currentPage}
                ></TaxRefundStatisticsTable>
              </div>

              <div style={{ textAlign: "right", marginTop: 20 }}>
                <Pagination
                  showQuickJumper={true}
                  showSizeChanger={false}
                  current={currentPage}
                  pageSize={1}
                  total={totalPage}
                  showTotal={(total, range) => `共 ${totalNumber}条`}
                  onChange={pageChange}
                ></Pagination>
              </div>
            </div>
          </Col>
          <Col
            style={{
              width: 220,
              height: "calc(100vh -  150px)",
              overflow: "auto",
            }}
          >
            <div className="right-selector">
              <div className="right-quarter-list">
                <Radio.Group value={selectTaxRefundStatistics} className="">
                  {taxRefundStatisticsList.map((item, index) => {
                    return (
                      <Radio.Button
                        className="statistics-list-button"
                        key={item.id}
                        onClick={() => handleTaxRefundStatisticsList(item)}
                        value={item}
                      >
                        <div className="">{item.name}</div>
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          //新增统计表的模态框
          destroyOnClose={true}
          visible={createTaxRefundStatisticsModalvisable}
          title="创建园区退税统计表"
          footer={null}
          onCancel={() => {
            setCreateTaxRefundStatisticsModalvisable(false);
          }}
        >
          <CreateTaxRefundStatistics
            parkId={parkId}
            parkName={parkName}
            taxRefundStatisticsList={taxRefundStatisticsList}
            handleLoading={handleCreateTaxStatisticsLoading}
            hideModal={hideModal}
            reflushData={reflushData}
          ></CreateTaxRefundStatistics>
        </Modal>
      </Spin>
    </>
  );
};

export default ParkTaxRefundStatistics;
