import React, { useEffect, useState } from 'react';
import { Table, Modal, Row, Col, message, Typography } from 'antd'
import { moneyFormat } from '@utils/index'
import './taxRefundTable.less'

import ModalContainer from './ModalContainer'
//配置表头
import { paasql } from '@api'

const { Text } = Typography;
const table_col_header_data = [
  { display_name: "招商点", width: 100, name: "settled_name", weight: 20 },
  { display_name: "企业名称", width: 300, name: "name", weight: 20 },
  { display_name: "税管码", width: 200, name: "tax_file_number", weight: 30 },
  { display_name: "银行户名", width: 200, name: "bank_account_name", weight: 30 },
  { display_name: "开户行", width: 200, name: "bank_name", weight: 30 },
  { display_name: "银行网点", width: 200, name: "bank_branch", weight: 30 },
  { display_name: "银行账号", width: 200, name: "bank_account_number", weight: 30 },
  { display_name: "增值税（本季）税额", width: 150, name: "total_value_added_tax_in_quarter", weight: 40 },
  { display_name: "增值税（本季）扶持比例", width: 150, name: "enterprise_value_added_tax_refund_rate", weight: 40 },
  { display_name: "增值税（本季）扶持额", width: 150, name: "enterprise_value_added_tax_refund", weight: 50 },
  { display_name: "企业所得税（本季）税额", width: 150, name: "total_corporate_income_tax_in_quarter", weight: 50 },
  { display_name: "企业所得税（本季）扶持比例", width: 150, name: "enterprise_corporate_income_tax_refund_rate", weight: 50 },
  { display_name: "企业所得税（本季）扶持额", width: 150, name: "enterprise_corporate_income_tax_refund", weight: 50 },
  { display_name: "本季度扶持金额合计", width: 150, name: "total_enterprise_tax_refund_in_quarter", weight: 50 },
  { display_name: "加：上次余留", width: 150, name: "last_statement_balance", weight: 50 },
  { display_name: "是否计算上次余留", width: 150, name: "is_last_statement_balance_added", weight: 50 },
  { display_name: "加：补差金额", width: 150, name: "payment_difference", weight: 50 },
  { display_name: "补差备注", width: 150, name: "payment_difference_remark", weight: 50 },
  { display_name: "减：管理费", width: 150, name: "management_fee", weight: 50 },
  { display_name: "减：代扣其他", width: 150, name: "payment_on_behalf", weight: 50 },
  { display_name: "减：预付金额", width: 150, name: "prepayment", weight: 50 },
  { display_name: "本季应扶持金额", width: 150, name: "accounts_payable", weight: 50 },
  { display_name: "减：本次余留", width: 150, name: "current_balance", weight: 50 },
  { display_name: "本季待付款金额", width: 150, name: "pending_payment", weight: 50 },
  { display_name: "是否支付成功", width: 150, name: "is_payment_succeeded", weight: 50 },
  { display_name: "支付日期", width: 150, name: "paid_at", weight: 50 },
  { display_name: "实际支付金额", width: 150, name: "actual_amount_paid", weight: 50 },
  { display_name: "备注", width: 150, name: "remark", weight: 50 },


]

const TaxRefundTable = (props) => {
  const { tableData, archived, selectTaxRefundStatement, pageSize, currentPage, reflushTableData } = props
  const [data, setData] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [activedId, setActiveId] = useState(null)
  const [modalTitle, setModalTitle] = useState('测试使用')
  const [taxFileNumber, setTaxFileNumber] = useState('')
  useEffect(() => {
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].index = (currentPage - 1) * pageSize + i + 1
      //增加是否支付成功
      tableData[i].is_payment_succeeded_name = tableData[i].is_payment_succeeded == '1' ? '是' : '否'
    }
    setData(tableData)
  }, [tableData])

  const clickRow = (record) => {
    if (archived) {

    } else {
      const { name, tax_file_number } = record
      setTaxFileNumber(tax_file_number)
      setModalTitle(`${name}`)
      setModalVisible(true)
      setActiveId(record.id)
    }

  }
  const handleOk = (info) => {
    const cmd_data = {
      ...info
    }
    //eip.eip_agency_administered_enterprises_tax_refund_statement_record.update

    paasql("eip.eip_park_administered_enterprises_tax_refund_statement_record.update", cmd_data).then(data => {
      if (data.error == null) {
        message.success("更改成功")
        setModalVisible(false)
        reflushTableData()
      } else {
        message.error(data.error.message)
      }
    })
  }
  const handleCancel = () => {
    setModalVisible(false)
  }


  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
      fixed: 'left',
      align: 'center',
    },
    {
      title: '企业名称',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      fixed: 'left',
    },
    {
      title: '扶持金额',
      dataIndex: 'total_enterprise_tax_refund_in_quarter',
      key: 'total_enterprise_tax_refund_in_quarter',
      width: 150,
      align: 'right',
      render: (text, record) => {
        return (
          <>
            <Text type=
              {record.total_enterprise_tax_refund_in_quarter < 0 ?
                "danger" : ''}>
              {moneyFormat(record.total_enterprise_tax_refund_in_quarter)}
            </Text>
          </>
        )
      }
    },
    {
      title: '增值税',
      children: [
        {
          title: '税额',
          dataIndex: 'total_value_added_tax_in_quarter',
          key: 'total_value_added_tax_in_quarter',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.total_value_added_tax_in_quarter < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.total_value_added_tax_in_quarter)}
                </Text>
              </>
            )
          }
        },
        {
          title: '扶持比例',
          dataIndex: 'enterprise_value_added_tax_refund_rate',
          key: 'enterprise_value_added_tax_refund_rate',
          width: 100,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                {`${
                  text === null ||
                    text === ''
                    ? 0.00 : text} %`}
              </>
            )
          }
        },
        {
          title: '扶持额',
          dataIndex: 'enterprise_value_added_tax_refund',
          key: 'enterprise_value_added_tax_refund',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.enterprise_value_added_tax_refund < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.enterprise_value_added_tax_refund)}
                </Text>
              </>
            )
          }
        },
      ],
    },
    {
      title: '企业所得税',
      children: [
        {
          title: '税额',
          dataIndex: 'total_corporate_income_tax_in_quarter',
          key: 'total_corporate_income_tax_in_quarter',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.total_corporate_income_tax_in_quarter < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.total_corporate_income_tax_in_quarter)}
                </Text>
              </>
            )
          }
        },
        {
          title: '扶持比例',
          dataIndex: 'enterprise_corporate_income_tax_refund_rate',
          key: 'enterprise_corporate_income_tax_refund_rate',
          width: 100,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                {`${
                  text === null ||
                    text === ''
                    ? 0.00 : text} %`}
              </>
            )
          }
        },
        {
          title: '扶持额',
          dataIndex: 'enterprise_corporate_income_tax_refund',
          key: 'enterprise_corporate_income_tax_refund',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.enterprise_corporate_income_tax_refund < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.enterprise_corporate_income_tax_refund)}
                </Text>
              </>
            )
          }
        },
      ],
    },
    {
      title: '调整',
      children: [
        {
          title: '余额转结',
          dataIndex: 'last_statement_balance',
          key: 'last_statement_balance',
          width: 200,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.last_statement_balance < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.last_statement_balance)}
                </Text>
              </>
            )
          }
        },
        {
          title: '补差',
          dataIndex: 'payment_difference',
          key: 'payment_difference',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.payment_difference < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.payment_difference)}
                </Text>
              </>
            )
          }
        },
        {
          title: '管理费',
          dataIndex: 'management_fee',
          key: 'management_fee',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.management_fee < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.management_fee)}
                </Text>
              </>
            )
          }
        },
        {
          title: '代扣',
          dataIndex: 'payment_on_behalf',
          key: 'payment_on_behalf',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.payment_on_behalf < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.payment_on_behalf)}
                </Text>
              </>
            )
          }
        },
        {
          title: '预付',
          dataIndex: 'prepayment',
          key: 'prepayment',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.prepayment < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.prepayment)}
                </Text>
              </>
            )
          }
        },
        {
          title: '本次余留',
          dataIndex: 'current_balance',
          key: 'current_balance',
          width: 150,
          align: 'right',
          render: (text, record) => {
            return (
              <>
                <Text type=
                  {record.current_balance < 0 ?
                    "danger" : ''}>
                  {moneyFormat(record.current_balance)}
                </Text>
              </>
            )
          }
        },
      ],
    },
    {
      title: '待付总额',
      dataIndex: 'pending_payment',
      key: 'pending_payment',
      width: 150,
      align: 'right',
      render: (text, record) => {
        return (
          <>
            <Text type=
              {record.pending_payment < 0 ?
                "danger" : ''}>
              {moneyFormat(record.pending_payment)}
            </Text>
          </>
        )
      }
    },
    {
      title: '实付金额',
      dataIndex: 'actual_amount_paid',
      key: 'actual_amount_paid',
      width: 150,
      align: 'right',
      render: (text, record) => {
        return (
          <>
            <Text type=
              {record.actual_amount_paid < 0 ?
                "danger" : ''}>
              {moneyFormat(record.actual_amount_paid)}
            </Text>
          </>
        )
      }
    },
    {
      title: '支付情况',
      dataIndex: 'is_payment_succeeded_name',
      key: 'is_payment_succeeded_name',
      width: 150,
      align: 'center',
    },
    {
      title: '支付日期',
      dataIndex: 'paid_at',
      key: 'paid_at',
      width: 120,
      align: 'center',

    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 150,
    },
  ];
  return (
    <>
      <Table
        size='small'
        columns={columns}
        dataSource={data}
        bordered

        rowKey={
          record => record.id
        }
        onRow={record => {
          return {
            onClick: () => clickRow(record)
          };
        }}

        pagination={{
          pageSize: 20,
          hideOnSinglePage: true
        }}
        //设置宽度
        scroll={{ x: 'calc(1990px + 50%)', y: 'calc(100vh - 520px)' }}
      />
      <Modal
        destroyOnClose={true}
        closable={false}

        title={
          <div >
            <div style={{ fontSize: '14px', textAlign: 'center' }} className='tax-refund-modal-title'>
              {modalTitle}
            </div>
            <div style={{ fontSize: '14px', textAlign: 'center' }} >
              税管码:{taxFileNumber}
            </div>
          </div>
        }
        width={1020}
        className='park-refund'
        centered={true}
        footer={null}
        visible={modalVisible}
      >
        <ModalContainer
          selectTaxRefundStatement={selectTaxRefundStatement}
          archived={archived}
          id={activedId}
          handleCancel={handleCancel}
          handleOk={handleOk}
        >
        </ModalContainer>
      </Modal>
    </>
  );



}

export default TaxRefundTable;